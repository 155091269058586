import React, { useState } from 'react';
import {
    View,
    Text,
    ScrollView,
    Image,
    useWindowDimensions
} from 'react-native';
import { Button } from 'react-native-elements';
require("firebase/auth");
require("firebase/firestore");
import PublicTopHeading from '../../../PublicTopHeading.js'
import { MaterialIcons, MaterialCommunityIcons, AntDesign, Entypo, Ionicons, Feather } from '@expo/vector-icons';
import { Video } from 'expo-av';
import { BACKGROUND_COLOR, BUTTON_COLOR_ONE, BUTTON_COLOR_TWO, HEADER_TEXT_COLOR, TEXT_COLOR_THREE } from '../../../constants';

export default function PublicHome({ navigation }) {
    const video = React.useRef(null);
    const dimensions = useWindowDimensions();
    const [status, setStatus] = useState({});
    const [imageWidth, setImageWidth] = useState(500)
    const [imageHeight, setImageHeight] = useState(333)
    const imageViewLayoutChange = event => {
        const { width } = event.nativeEvent.layout;
        if (width != imageWidth) {
            console.log("Changing image size")
            setImageWidth(width)
            setImageHeight(width / 1.5)
        }
    }

    let widthPercent = "70%";
    let marginPercent = "15%";

    if (dimensions.width >= 1100) {
        widthPercent = 1000;
        marginPercent = (dimensions.width - 1000) / 2;
    }
    else if ((dimensions.width >= 500) && (dimensions.width < 1100)) {
        widthPercent = "90%";
        marginPercent = "5%";
    }
    else {
        widthPercent = "95%";
        marginPercent = "2.5%"
    }

    return (
        <View style={{ maxHeight: dimensions.height }}>
            <View style={{ zIndex: 10, width: widthPercent, marginLeft: marginPercent, position: "absolute" }}>
                <PublicTopHeading navigation={navigation} currentPage="PublicHome" />
            </View>
            <View style={{ flex: 1, marginTop: 50, zIndex: 0, width: "100%" }}>
                <ScrollView style={{ width: "100%", flex: 1 }}>
                    <View style={{ width: widthPercent, marginLeft: marginPercent }}>
                        <View style={{ width: "100%", alignItems: "center", backgroundColor: BACKGROUND_COLOR }}>
                            <Image source={require("../../assets/Tumble_Logo.png")} style={{ width: 150, height: 150 }} />
                        </View>
                        <View style={{ width: "100", backgroundColor: BACKGROUND_COLOR, alignItems: 'center' }}>
                            <Text style={{ fontSize: 30, textAlign: 'center', color: TEXT_COLOR_THREE, fontWeight: "bold", marginTop: 20 }}>Goodbye Laundry Day.</Text>
                            <Text style={{ fontSize: 30, textAlign: 'center', color: TEXT_COLOR_THREE, fontWeight: "bold", marginBottom: 30 }}>Hello, Free Time.</Text>
                            <Text style={{ fontSize: 20, textAlign: 'center', color: TEXT_COLOR_THREE, marginBottom: 30, width: "90%" }}>With Tumble, you'll never have to stress over laundry day again. We pick up, wash, fold, and deliver your laundry right back to your doorstep. Smile, Tumble has you covered.</Text>
                        </View>
                        <View style={{ width: "100", backgroundColor: BACKGROUND_COLOR, alignItems: 'center', }}>
                            <View style={{ justifyContent: "flex-end", width: "70%", maxWidth: 400 }} onLayout={imageViewLayoutChange}>
                                <Image source={require("../../assets/Tumble_Home_Page_Filter.png")} style={{ width: imageWidth, height: imageHeight }} />
                            </View>
                            <Text style={{ fontSize: 15, textAlign: 'center', color: TEXT_COLOR_THREE, marginTop: 20, width: "90%" }}>Create an account to receive giveaways and discounts or visit our subscriptions for more information!</Text>
                            <View style={{ width: "80%", maxWidth: 500, marginTop: 20 }}>
                                <Button
                                    onPress={() => navigation.navigate("SignUp")}
                                    title='Create your FREE account!'
                                    buttonStyle={{
                                        backgroundColor: BUTTON_COLOR_ONE,
                                        width: "100%",
                                    }}
                                />
                            </View>
                            <View style={{ width: "80%", maxWidth: 500, marginTop: 20, marginBottom: 40 }}>
                                <Button
                                    onPress={() => navigation.navigate("PublicPricing")}
                                    title='Our Services'
                                    buttonStyle={{
                                        backgroundColor: BUTTON_COLOR_ONE,
                                        width: "100%",
                                    }}
                                />
                            </View>
                        </View>
                        <View style={{ width: "100%", height: 40 }}></View>
                        <View style={{ width: "100", backgroundColor: BACKGROUND_COLOR, alignItems: 'center' }}>
                            <Text style={{ fontSize: 30, textAlign: 'center', color: TEXT_COLOR_THREE, fontWeight: "bold", marginTop: 20 }}>Tumble Process</Text>
                            <Text style={{ fontSize: 15, textAlign: 'center', color: TEXT_COLOR_THREE, marginTop: 20, width: "90%" }}>Relax, we've got the wash you've been looking for.</Text>
                            <Text style={{ fontSize: 25, textAlign: 'center', color: TEXT_COLOR_THREE, fontWeight: "bold", marginTop: 20 }}><AntDesign name="contacts" size={24} color={HEADER_TEXT_COLOR} />  Sign Up</Text>
                            {/* <Text style={{ fontSize: 15, textAlign: 'center', color: TEXT_COLOR_THREE, marginTop: 20, width: "90%" }}>The process begins when you <Text onPress={() => navigation.navigate('Sign Up')} style={{ textDecorationLine: 'underline' }}>create a FREE Tumble account</Text> & fill out your information on our member profile page.</Text> */}
                            <Text style={{ fontSize: 15, textAlign: 'center', color: TEXT_COLOR_THREE, marginTop: 20, width: "90%" }}>The process begins when you create a FREE Tumble account & provide us with your laundry care preferences.</Text>
                            <Text style={{ fontSize: 25, textAlign: 'center', color: TEXT_COLOR_THREE, fontWeight: "bold", marginTop: 20 }}><AntDesign name="calendar" size={24} color={HEADER_TEXT_COLOR} />  Pick Up</Text>
                            <Text style={{ fontSize: 15, textAlign: 'center', color: TEXT_COLOR_THREE, marginTop: 20, width: "90%" }}>Choose a time and date for one of our Tumble Associates to come and pickup your precious cargo. </Text>
                            <Text style={{ fontSize: 25, textAlign: 'center', color: TEXT_COLOR_THREE, fontWeight: "bold", marginTop: 20 }}><MaterialCommunityIcons name="washing-machine" size={24} color={HEADER_TEXT_COLOR} />  Wash & Fold</Text>
                            <Text style={{ fontSize: 15, textAlign: 'center', color: TEXT_COLOR_THREE, marginTop: 20, width: "90%" }}>Our Team will wash, dry, and fold your items based on the preferences you have provided us at the time of your sign up.</Text>
                            <Text style={{ fontSize: 25, textAlign: 'center', color: TEXT_COLOR_THREE, fontWeight: "bold", marginTop: 20 }}><MaterialCommunityIcons name="cellphone-iphone" size={24} color={HEADER_TEXT_COLOR} />  Confirmation</Text>
                            {/* <Text style={{ fontSize: 15, textAlign: 'center', color: TEXT_COLOR_THREE, marginTop: 20, marginBottom: 40, width: "90%" }}>Don't worry, before we set out to deliver your laundry we'll send you a confirmation text or email asking when you'd like us to drop off your items. <Text onPress={() => navigation.navigate('Sign Up')} style={{ textDecorationLine: 'underline' }}>Create your FREE account</Text> today!</Text> */}
                            <Text style={{ fontSize: 15, textAlign: 'center', color: TEXT_COLOR_THREE, marginTop: 20, width: "90%" }}>Don't worry, before we set out to deliver your laundry we'll send you a confirmation text or email asking when you'd like us to drop off your items.  Create your FREE account today!</Text>
                            <Text style={{ fontSize: 25, textAlign: 'center', color: TEXT_COLOR_THREE, fontWeight: "bold", marginTop: 20 }}><MaterialCommunityIcons name="truck-fast" size={24} color={HEADER_TEXT_COLOR} />  Delivery</Text>
                            <Text style={{ fontSize: 15, textAlign: 'center', color: TEXT_COLOR_THREE, marginTop: 20, marginBottom: 40, width: "90%" }}>Voilà! Standard delivery time is 2-4 days.</Text>
                        </View>
                        <View style={{ width: "100%", height: 40 }}></View>
                        <View style={{ width: "100", backgroundColor: BACKGROUND_COLOR, alignItems: 'center' }}>
                            <Text style={{ fontSize: 30, textAlign: 'center', color: TEXT_COLOR_THREE, fontWeight: "bold", marginTop: 20 }}>Tumble Delivery Locations</Text>
                            <Text style={{ fontSize: 15, textAlign: 'center', color: TEXT_COLOR_THREE, marginTop: 20 }}>We pick up & deliver within a 20 mile radius of Stockton, Tracy, Manteca, Modesto, and Livermore.</Text>
                            <View style={{ width: "100%", alignItems: "center", backgroundColor: BACKGROUND_COLOR, marginTop: 20, marginBottom: 30 }}>
                                <Image source={require("../../assets/tumble_map.png")} style={{ width: 330, height: 200 }} />
                            </View>
                        </View>
                        <View style={{ width: "100%", height: 40 }}></View>
                        <View style={{ width: "100%", height: 40 }}></View>
                        <View style={{ width: "100", backgroundColor: BACKGROUND_COLOR, alignItems: 'center' }}>
                            <Text style={{ fontSize: 30, textAlign: 'center', color: TEXT_COLOR_THREE, fontWeight: "bold", marginTop: 20 }}>Why Tumble?</Text>
                            <Text style={{ fontSize: 15, textAlign: 'center', color: TEXT_COLOR_THREE, marginTop: 20 }}>"We believe that every delivery is an opportunity to pay it forward".</Text>
                            <Text style={{ fontSize: 15, textAlign: 'center', color: TEXT_COLOR_THREE, marginTop: 5 }}>-Sam Lewis, Founder of Tumble</Text>
                            <Text style={{ fontSize: 15, textAlign: 'center', color: TEXT_COLOR_THREE, marginTop: 20, marginBottom: 20, width: "70%" }}>Located in the heart of a commuters town, we understand that life can get busy and laundry is the last thing anyone wants to do after a long day. This is our way of paying it forward.</Text>
                            <View style={{ width: "80%", maxWidth: 500 }}>
                                <Button
                                    onPress={() => navigation.navigate("SignUp")}
                                    title='Get Started'
                                    buttonStyle={{
                                        backgroundColor: BUTTON_COLOR_ONE,
                                        width: "100%",
                                    }}
                                />
                            </View>
                            <Text style={{ fontSize: 15, textAlign: 'center', color: TEXT_COLOR_THREE, marginTop: 20 }}>Our pickup times:</Text>
                            <Text style={{ fontSize: 15, textAlign: 'center', color: TEXT_COLOR_THREE, marginTop: 5, marginBottom: 40 }}>Monday-Friday 8AM-1PM</Text>
                        </View>
                        <View style={{ width: "100%", height: 40 }}></View>
                        <View style={{ width: "100", backgroundColor: BACKGROUND_COLOR, alignItems: 'center' }}>
                            <View style={{ flex: 1, justifyContent: 'center', marginTop: 60 }}>
                                <Video
                                    ref={video}
                                    style={{ alignSelf: 'center', width: 400, height: 230 }}
                                    source={require("../../assets/tumble_video.MP4")}
                                    useNativeControls
                                    resizeMode="contain"
                                    isLooping
                                    onPlaybackStatusUpdate={status => setStatus(() => status)}
                                />
                            </View>
                            <Text style={{ fontSize: 30, textAlign: 'center', color: TEXT_COLOR_THREE, fontWeight: "bold", marginTop: 20, marginBottom: 20 }}>Questions?</Text>
                            <View style={{ width: "80%", maxWidth: 500 }}>
                                <Button
                                    onPress={() => navigation.navigate("PublicContact")}
                                    title='Contact Us!'
                                    buttonStyle={{
                                        backgroundColor: BUTTON_COLOR_ONE,
                                        width: "100%",
                                        marginBottom: 40
                                    }}
                                />
                            </View>
                        </View>
                    </View>
                </ScrollView>
            </View>
        </View>
    );
}