import React, { useEffect, useState, useLayoutEffect, useRef } from 'react';
import {
    View,
    SafeAreaView,
    Text,
    ScrollView,
    Image,
    Dimensions,
    TouchableOpacity,
    Alert,
    ActivityIndicator,
    TouchableHighlight,
    Animated
} from 'react-native';
import firebase from "firebase/app"
import "firebase/auth"
import "firebase/firestore"
import "firebase/functions"
import styles from '../Contact/styles';
import { Card, Button } from 'react-native-elements';
import { ACCENT_COLOR_FOUR, ACCENT_COLOR_THREE, ACCENT_COLOR_FIVE, ACCENT_COLOR_TWO, TEXT_COLOR_ONE, TEXT_COLOR_THREE } from '../../../constants';


export default function FAQ({ navigation }) {

    const [loading, setLoading] = useState(true)
    // const [collapsed, setCollapsed] = useState(true)
    const [collapsed, setCollapsed] = useState({})
    // const [maxHeight, setMaxHeight] = useState(0)
    const [maxHeight, setMaxHeight] = useState({})
    // const animationHeight = useRef(new Animated.Value(0)).current
    const [animationHeight, setAnimationHeight] = useState({})
    const [FAQs, setFAQs] = useState({})

    useLayoutEffect(() => {
        navigation.setOptions({
            headerRight: () => (
                <Button
                    onPress={() => navigation.goBack()}
                    title="Done"
                    type="clear"
                    titleStyle={{
                        fontWeight: "normal"
                    }}
                    buttonStyle={{
                        marginRight: 10
                    }}
                />
            ),
            headerLeft: null
        })
    })

    const dimensions = { window, screen }

    const getFAQs = async () => {
        const faqsCollection = await firebase.firestore().collection('pageInfo').doc('FAQs').collection('questions').get()
        const localFAQs = {}
        faqsCollection.docs.forEach(doc => {
            const docData = doc.data()
            const docId = doc.id
            if (docData.question && docData.answer) {
                localFAQs[docId] = docData
            }
        })
        setFAQs(localFAQs)
        setLoading(false)
    }

    useEffect(() => {
        getFAQs()
    }, []);

    if (loading) {
        return (
            <View style={styles.parent}>
                <ActivityIndicator />
            </View>
        )
    }

    const toggleCollapsed = (index) => {
        if (collapsed[index] === true) {
            expandView(index)
            collapsed[index] = false
        }
        else {
            collapseView(index)
            collapsed[index] = true
        }
    }

    const expandView = (index) => {
        Animated.timing(animationHeight[index], {
            duration: 500,
            toValue: maxHeight[index],
            useNativeDriver: false
        }).start();
    }

    const collapseView = (index) => {
        Animated.timing(animationHeight[index], {
            duration: 500,
            toValue: 5,
            useNativeDriver: false
        }).start();
    }

    const createFAQs = () => {
        var renderArray = []
        var i = 0
        for (const FAQ in FAQs) {
            if (FAQs.hasOwnProperty(FAQ)) {
                collapsed[FAQ] = true
                animationHeight[FAQ] = new Animated.Value(5)
                const question = FAQs[FAQ]['question']
                const answer = FAQs[FAQ]['answer']
                var answerText = []
                var j = 0
                answer.forEach((answer) => {
                    answerText.push(
                        <Text key={j} style={{ textAlign: 'left', color: TEXT_COLOR_THREE, marginLeft: 15, marginTop: 5, marginBottom: 5, marginRight: 15, fontSize: 20 }} >
                            {answer}
                        </Text>
                    )
                    j++
                })
                renderArray.push(
                    <TouchableOpacity key={i} style={{ marginTop: 40, alignItems: 'center' }} onPress={() => toggleCollapsed(FAQ)}>
                        <View style={{ width: "90%", maxWidth: 600, flexDirection: "row", backgroundColor: ACCENT_COLOR_THREE }}>
                            <Text style={{ textAlign: 'left', color: TEXT_COLOR_ONE, marginLeft: 15, marginTop: 5, marginBottom: 5, fontSize: 25 }}>{question}</Text>
                        </View>
                        <View style={{ overflow: 'hidden', width: "90%", maxWidth: 600, backgroundColor: ACCENT_COLOR_FIVE }}>
                            <Animated.View style={{ maxHeight: animationHeight[FAQ] }}>
                                <View onLayout={(event) => {
                                    var { x, y, width, height } = event.nativeEvent.layout
                                    maxHeight[FAQ] = height
                                }}>
                                    {answerText}
                                </View>
                            </Animated.View>
                        </View>
                    </TouchableOpacity>
                )
                i++
            }
        }
        return renderArray
    }

    return (
        <View style={styles.parent}>
            <ScrollView style={{ width: "100%" }}>
                <View style={styles.imageContainer}>
                    <Image source={require("../../assets/Tumble_Logo.png")} style={{ height: 150, width: 150 }} />
                </View>
                <View style={{ marginBottom: 50 }}>
                    {createFAQs()}
                </View>
            </ScrollView>
        </View>
    );
}