import React, { useEffect, useState, useRef, useLayoutEffect } from 'react';
import {
    View,
    ScrollView,
    Alert,
    TextInput,
    ActivityIndicator,
    BackHandler,
} from 'react-native';
import { Button } from 'react-native-elements';
import styles from './styles';
import { Cell, Section } from 'react-native-tableview-simple';
import firebase from "firebase/app"
import "firebase/auth"
import "firebase/firestore"
import "firebase/functions"
import { HeaderBackButton } from '@react-navigation/stack'
import AddressPicker from '../AddressPicker';
import { CELL_BACKGROUND_COLOR, CONNECTION_ERROR_BUTTON, CONNECTION_ERROR_HEADER, CONNECTION_ERROR_MESSAGE, DISABLED_TEXT_INPUT_COLOR, HEADER_TEXT_COLOR, PLACEHOLDER_TEXT_INPUT_COLOR, TEXT_INPUT_COLOR } from '../../../constants';
import AlertModal from '../AlertModal'


export default function DeliveryAddress({ navigation }) {
    const [userAddress, setUserAddress] = useState("")
    const [addressDialogVisible, setAddressDialogVisible] = useState(false)
    const [addressMainText, setAddressMainText] = useState("Street Address")
    const [addressSecondaryText, setAddressSecondaryText] = useState("City & State")
    const [aptNum, setAptNum] = useState('')
    const [pageLoading, setPageLoading] = useState(true)
    const [editMode, setEditMode] = useState(false)
    const [defaultTextColor, setDefaultTextColor] = useState(DISABLED_TEXT_INPUT_COLOR)
    const [additionalInstructions, setAdditionalInstructions] = useState('')
    const [alertModalVisibile, setAlertModalVisible] = useState(false);
    const [alertInfo, setAlertInfo] = useState({})
    const closeAlertModal = () => {
        setAlertModalVisible(false)
    }
    const openAlertModal = (title, subtitle, primaryButtonText, primaryButtonAction, secondaryButtonText = null, secondaryButtonAction = null) => {
        setAlertInfo({
            alertTitleText: title,
            alertSubtitleText: subtitle,
            alertPrimaryButtonText: primaryButtonText,
            alertPrimaryAction: () => primaryButtonAction(),
            alertSecondaryButtonText: secondaryButtonText,
            alertSecondaryAction: () => secondaryButtonAction()
        })
        setAlertModalVisible(true)
    }

    useLayoutEffect(() => {
        navigation.setOptions({
            headerRight: () => {
                if (editMode) {
                    return <Button
                        onPress={() => confirmSaveChanges()}
                        title="Done"
                        type="clear"
                        titleStyle={{
                            fontWeight: "bold",
                            color: HEADER_TEXT_COLOR
                        }}
                        buttonStyle={{
                            marginRight: 10
                        }}
                        disabled={pageLoading}
                    />
                }
                else {
                    return <Button
                        onPress={() => startEditing()}
                        title="Edit"
                        type="clear"
                        titleStyle={{
                            fontWeight: "normal",
                            color: HEADER_TEXT_COLOR
                        }}
                        buttonStyle={{
                            marginRight: 10
                        }}
                        disabled={editButtonDisabled()}
                    />
                }
            },
            headerLeft: () => {
                if (editMode) {
                    return <Button
                        onPress={() => cancelEditing()}
                        title="Cancel"
                        type="clear"
                        titleStyle={{
                            fontWeight: "normal",
                            color: HEADER_TEXT_COLOR
                        }}
                        buttonStyle={{
                            marginLeft: 10
                        }}
                        disabled={pageLoading}
                    />
                }
                else {
                    return <HeaderBackButton
                        label="Back"
                        tintColor={HEADER_TEXT_COLOR}
                        disabled={backButtonDisabled()}
                        onPress={() => navigation.goBack()}
                    />
                }

            },
        })
    })

    const backButtonDisabled = () => {
        return pageLoading
    }

    const editButtonDisabled = () => {
        return pageLoading
    }

    const cancelEditing = () => {
        getUserInfo()
        setEditMode(false)
        setDefaultTextColor(DISABLED_TEXT_INPUT_COLOR)
    }

    const startEditing = () => {
        setEditMode(true)
        setDefaultTextColor(TEXT_INPUT_COLOR)
    }

    const refs = {
        "address": useRef(),
        'additionalInstructions': useRef()
    }

    const getUserInfo = async () => {
        closeAlertModal()
        setPageLoading(true)
        const user = firebase.auth().currentUser;
        // console.log(user);
        const userInfo = (await firebase.firestore().collection("userProfiles").doc(user.uid).get()).data();
        if (userInfo) {
            if (userInfo.userAddress && userInfo.addressMainText && userInfo.addressSecondaryText) {
                setAddressMainText(userInfo.addressMainText)
                setAddressSecondaryText(userInfo.addressSecondaryText)
                setUserAddress(userInfo.userAddress)
            }
            setAptNum(userInfo.aptNum)
            setAdditionalInstructions(userInfo.additionalInstructions)
        }
        setPageLoading(false);
    }

    useEffect(() => {
        getUserInfo();
        BackHandler.addEventListener('hardwareBackPress', onBackPress);
        return () => {
            BackHandler.removeEventListener('hardwareBackPress', onBackPress);
        }
    }, []);

    const onBackPress = () => {
        return true;
    }

    const confirmSaveChanges = () => {
        // check that an address is selected
        if (!userAddress || userAddress === '') {
            setPageLoading(false)
            openAlertModal('Error', 'Please enter an address.', 'Ok', closeAlertModal)
            return
        }
        openAlertModal('Confirm Changes', 'Changes will be applied to all upcoming plan pickups.', 'Ok', saveChanges, 'Cancel', closeAlertModal)
    }

    const saveChanges = async () => {
        closeAlertModal()
        setPageLoading(true)
        const updateDict = {}
        updateDict.addressMainText = addressMainText
        updateDict.addressSecondaryText = addressSecondaryText
        updateDict.userAddress = userAddress
        updateDict.aptNum = aptNum
        updateDict.additionalInstructions = additionalInstructions
        try {
            const updateUserAddress = firebase.functions().httpsCallable('updateUserAddress');
            const response = await updateUserAddress({
                updateDict: updateDict,
                updateContext: 'account_page'
            })
            if (response.data) {
                if (response.data.success) {
                    await getUserInfo()
                    setEditMode(false)
                    setDefaultTextColor(DISABLED_TEXT_INPUT_COLOR)
                    setPageLoading(false)
                    return
                }
                else {
                    console.log('error updating address:', response.data.message)
                    openAlertModal('Error', 'Error updating your account info.  Please try again.  If this error persists, please contact us.', 'Ok', closeAlertModal)
                    setPageLoading(false)
                }
            }
            else {
                openAlertModal('Error', 'Error updating your account info.  Please try again.  If this error persists, please contact us.', 'Ok', closeAlertModal)
                setPageLoading(false)
            }
        }
        catch (err) {
            console.log('error:', err)
            setPageLoading(false)
            openAlertModal(CONNECTION_ERROR_HEADER, CONNECTION_ERROR_MESSAGE, CONNECTION_ERROR_BUTTON, closeAlertModal)
            return
        }
    }


    const openAddressDialog = () => {
        setAddressDialogVisible(true)
    }

    const onSubmitAptNum = () => {
        refs['additionalInstructions'].current.focus()
    }

    const onSubmitAdditionalInstructions = () => {
        refs['additionalInstructions'].current.blur()
    }

    if (pageLoading) {
        return (
            <View style={styles.parent}>
                <ActivityIndicator />
            </View>
        )
    }

    return (
        <View style={styles.parent}>
            <ScrollView keyboardShouldPersistTaps='handled'>
                <Section headerTextColor={HEADER_TEXT_COLOR} header="ADDRESS" footer="">
                    <Cell backgroundColor={CELL_BACKGROUND_COLOR} cellStyle="Basic" isDisabled={!editMode} titleTextColor={defaultTextColor} title={addressMainText} onPress={openAddressDialog} />
                    <Cell backgroundColor={CELL_BACKGROUND_COLOR} cellStyle="Basic" isDisabled={!editMode} titleTextColor={defaultTextColor} title={addressSecondaryText} onPress={openAddressDialog} />
                    <Cell backgroundColor={CELL_BACKGROUND_COLOR} cellContentView={
                        <TextInput
                            style={{ fontSize: 16, flex: 1, color: defaultTextColor }}
                            placeholder="Apt/Suite # (optional)"
                            ref={refs["aptNum"]}
                            autoCorrect={false}
                            enablesReturnKeyAutomatically={true}
                            onChangeText={(value) => setAptNum(value)}
                            value={aptNum}
                            onSubmitEditing={onSubmitAptNum}
                            returnKeyType="next"
                            placeholderTextColor={PLACEHOLDER_TEXT_INPUT_COLOR}
                            editable={editMode}
                        />
                    } />
                </Section>
                <Section headerTextColor={HEADER_TEXT_COLOR} header="ADDITIONAL INSTRUCTIONS">
                    <Cell backgroundColor={CELL_BACKGROUND_COLOR} cellContentView={
                        <TextInput
                            style={{ fontSize: 16, flex: 1, color: defaultTextColor }}
                            placeholder="Additional Instructions (optional)"
                            ref={refs["additionalInstructions"]}
                            autoCorrect={false}
                            enablesReturnKeyAutomatically={true}
                            onChangeText={(value) => setAdditionalInstructions(value)}
                            value={additionalInstructions}
                            onSubmitEditing={onSubmitAdditionalInstructions}
                            returnKeyType="done"
                            editable={editMode}
                            placeholderTextColor={PLACEHOLDER_TEXT_INPUT_COLOR}
                        />
                    } />
                </Section>
            </ScrollView>
            <AddressPicker
                modalVisible={addressDialogVisible}
                setModalVisible={setAddressDialogVisible}
                setUserAddress={setUserAddress}
                setAddressMainText={setAddressMainText}
                setAddressSecondaryText={setAddressSecondaryText}
            />
            <AlertModal
                modalVisible={alertModalVisibile}
                setModalVisible={setAlertModalVisible}
                alertTitleText={alertInfo.alertTitleText}
                alertSubtitleText={alertInfo.alertSubtitleText}
                alertPrimaryButtonText={alertInfo.alertPrimaryButtonText}
                alertPrimaryAction={alertInfo.alertPrimaryAction}
                alertSecondaryButtonText={alertInfo.alertSecondaryButtonText}
                alertSecondaryAction={alertInfo.alertSecondaryAction}
            />
        </View>
    );
}