import React, { useContext, useEffect, useState } from 'react';
import {
    Alert,
    View,
    Text,
    ScrollView,
    Dimensions,
    ActivityIndicator,
    useWindowDimensions,
    Image
} from 'react-native';
import { MaterialIcons, MaterialCommunityIcons, AntDesign, Entypo, Ionicons, Feather } from '@expo/vector-icons';
import { Cell, Section, TableView } from 'react-native-tableview-simple';
import styles from './styles';
import firebase from "firebase/app"
import "firebase/auth"
import "firebase/firestore"
import "firebase/functions"
import AuthContext from '../../../AuthContext.js'
import TopHeading from '../../../TopHeading.js'
import AlertModal from '../AlertModal'
import { CELL_TITLE_TEXT_COLOR, CELL_BACKGROUND_COLOR, HEADER_TEXT_COLOR, BACKGROUND_COLOR, ACCENT_COLOR_TWO, ACCENT_COLOR_ONE, SEPARATOR_TINT_COLOR_ONE } from '../../../constants';


export default function MainPage({ navigation }) {
    const [loading, setLoading] = useState(false);
    const [alertModalVisibile, setAlertModalVisible] = useState(false);
    const [alertInfo, setAlertInfo] = useState({})
    const openLogoutAlert = () => {
        setAlertInfo({
            alertTitleText: 'Log Out',
            alertSubtitleText: 'Are you sure you want to log out?',
            alertPrimaryButtonText: 'Yes',
            alertPrimaryAction: () => logout(),
            alertSecondaryButtonText: 'Cancel',
            alertSecondaryAction: () => closeAlertModal()
        })
        setAlertModalVisible(true)
    }
    const closeAlertModal = () => {
        setAlertModalVisible(false)
    }

    const logout = async () => {
        try {
            await firebase.auth().signOut();
        } catch (error) {
            const errorMessage = 'Error trying to log out: ' + error + '.  Please try again later.  If this error persists, please contact us.'
            setAlertInfo({
                alertTitleText: 'Error Logging Out',
                alertSubtitleText: errorMessage,
                alertPrimaryButtonText: 'Ok',
                alertPrimaryAction: () => closeAlertModal(),
                alertSecondaryButtonText: '',
                alertSecondaryAction: () => () => { }
            })
            console.log(error);
        }
    }

    useEffect(() => {

    }, []);

    if (loading) {
        return (
            <View style={styles.parent}>
                <ActivityIndicator />
            </View>
        )
    }

    return (
        <View style={styles.parent}>
            <View style={{ zIndex: 10 }}>
                <TopHeading navigation={navigation} currentPage="Account" />
            </View>
            <View style={{ flex: 1, marginTop: 50, zIndex: 0 }}>
                <ScrollView style={{ width: "100%", height: "100%", marginLeft: "0%", position: "absolute" }}>
                    <Text style={styles.heading}>My Account</Text>
                    <TableView style={{ flexDirection: "column" }}>
                        <Section header="MEMBER PROFILE" headerTextColor={HEADER_TEXT_COLOR} footer="" separatorTintColor={SEPARATOR_TINT_COLOR_ONE} >
                            <Cell image={<AntDesign name="contacts" size={24} color={CELL_TITLE_TEXT_COLOR} />} cellStyle="Basic" backgroundColor={CELL_BACKGROUND_COLOR} contentContainerStyle={{ height: 50 }} titleTextColor={CELL_TITLE_TEXT_COLOR} titleTextStyle={{ fontWeight: 'bold' }} title="Contact Info" accessory="DisclosureIndicator" onPress={() => navigation.navigate("Contact Info")} />
                            <Cell image={<Entypo name="location" size={24} color={CELL_TITLE_TEXT_COLOR} />} cellStyle="Basic" backgroundColor={CELL_BACKGROUND_COLOR} titleTextColor={CELL_TITLE_TEXT_COLOR} titleTextStyle={{ fontWeight: 'bold' }} title="Delivery Address" accessory="DisclosureIndicator" onPress={() => navigation.navigate("Delivery Address")} />
                            <Cell image={<Entypo name="notification" size={24} color={CELL_TITLE_TEXT_COLOR} />} cellStyle="Basic" backgroundColor={CELL_BACKGROUND_COLOR} titleTextColor={CELL_TITLE_TEXT_COLOR} titleTextStyle={{ fontWeight: 'bold' }} title="Push Notifications" accessory="DisclosureIndicator" onPress={() => navigation.navigate("Push Notifications")} />
                            <Cell image={<MaterialIcons name="home" size={24} color={CELL_TITLE_TEXT_COLOR} />} cellStyle="Basic" backgroundColor={CELL_BACKGROUND_COLOR} titleTextColor={CELL_TITLE_TEXT_COLOR} titleTextStyle={{ fontWeight: 'bold' }} title="Household Size" accessory="DisclosureIndicator" onPress={() => navigation.navigate("Household Size")} />
                            <Cell image={<Feather name="repeat" size={24} color={CELL_TITLE_TEXT_COLOR} />} cellStyle="Basic" backgroundColor={CELL_BACKGROUND_COLOR} titleTextColor={CELL_TITLE_TEXT_COLOR} titleTextStyle={{ fontWeight: 'bold' }} title="Manage Subscription" accessory="DisclosureIndicator" onPress={() => navigation.navigate("Manage Subscription")} />
                            {/* <Cell image={<MaterialCommunityIcons name="login" size={24} color={CELL_TITLE_TEXT_COLOR} />} cellStyle="Basic" backgroundColor={CELL_BACKGROUND_COLOR} titleTextColor={CELL_TITLE_TEXT_COLOR} titleTextStyle={{ fontWeight: 'bold' }} title="Login Info" accessory="DisclosureIndicator" onPress={() => navigation.navigate("Login Info")} /> */}
                        </Section>
                        <Section header="PREFERENCES" headerTextColor={HEADER_TEXT_COLOR} footer="" separatorTintColor={SEPARATOR_TINT_COLOR_ONE} >
                            <Cell image={<Ionicons name="ios-shirt" size={24} color={CELL_TITLE_TEXT_COLOR} />} cellStyle="Basic" backgroundColor={CELL_BACKGROUND_COLOR} titleTextColor={CELL_TITLE_TEXT_COLOR} titleTextStyle={{ fontWeight: 'bold' }} title="Laundry Preferences" accessory="DisclosureIndicator" onPress={() => navigation.navigate("Laundry")} />
                            <Cell image={<MaterialCommunityIcons name="truck-fast" size={24} color={CELL_TITLE_TEXT_COLOR} />} cellStyle="Basic" backgroundColor={CELL_BACKGROUND_COLOR} titleTextColor={CELL_TITLE_TEXT_COLOR} titleTextStyle={{ fontWeight: 'bold' }} title="Delivery Preferences" accessory="DisclosureIndicator" onPress={() => navigation.navigate("Delivery")} />
                        </Section>
                        <Section header="PAYMENT" headerTextColor={HEADER_TEXT_COLOR} footer="" separatorTintColor={SEPARATOR_TINT_COLOR_ONE} >
                            <Cell image={<MaterialIcons name="payment" size={24} color={CELL_TITLE_TEXT_COLOR} />} cellStyle="Basic" backgroundColor={CELL_BACKGROUND_COLOR} titleTextColor={CELL_TITLE_TEXT_COLOR} titleTextStyle={{ fontWeight: 'bold' }} title="Manage Payment Information" accessory="DisclosureIndicator" onPress={() => navigation.navigate("Payment")} />
                        </Section>
                        <Section header="MY PICKUPS" headerTextColor={HEADER_TEXT_COLOR} footer="" separatorTintColor={SEPARATOR_TINT_COLOR_ONE} >
                            <Cell image={<MaterialCommunityIcons name="clipboard-arrow-right" size={24} color={CELL_TITLE_TEXT_COLOR} />} cellStyle="Basic" backgroundColor={CELL_BACKGROUND_COLOR} titleTextColor={CELL_TITLE_TEXT_COLOR} titleTextStyle={{ fontWeight: 'bold' }} title="Upcoming Pickups" accessory="DisclosureIndicator" onPress={() => navigation.navigate("Upcoming")} />
                            <Cell image={<MaterialCommunityIcons name="clipboard-arrow-left" size={24} color={CELL_TITLE_TEXT_COLOR} />} cellStyle="Basic" backgroundColor={CELL_BACKGROUND_COLOR} titleTextColor={CELL_TITLE_TEXT_COLOR} titleTextStyle={{ fontWeight: 'bold' }} title="Past Pickups" accessory="DisclosureIndicator" onPress={() => navigation.navigate("Past")} />
                        </Section>
                        <Section header="" footer="" separatorTintColor={SEPARATOR_TINT_COLOR_ONE} >
                            <Cell image={<MaterialCommunityIcons name="logout" size={24} color={CELL_TITLE_TEXT_COLOR} />} cellStyle="Basic" backgroundColor={CELL_BACKGROUND_COLOR} titleTextColor={CELL_TITLE_TEXT_COLOR} titleTextStyle={{ fontWeight: 'bold' }} title="Log Out" onPress={openLogoutAlert} />
                        </Section>
                    </TableView>
                </ScrollView>
            </View>
            <AlertModal
                modalVisible={alertModalVisibile}
                setModalVisible={setAlertModalVisible}
                alertTitleText={alertInfo.alertTitleText}
                alertSubtitleText={alertInfo.alertSubtitleText}
                alertPrimaryButtonText={alertInfo.alertPrimaryButtonText}
                alertPrimaryAction={alertInfo.alertPrimaryAction}
                alertSecondaryButtonText={alertInfo.alertSecondaryButtonText}
                alertSecondaryAction={alertInfo.alertSecondaryAction}
            />
        </View>
    );
}