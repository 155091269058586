import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
import {
    View,
    Dimensions,
    ActivityIndicator,
    Alert,
    Switch,
    ScrollView
} from 'react-native';
import { Button } from 'react-native-elements';
import styles from './styles';
import { Cell, Section } from 'react-native-tableview-simple';
import firebase from "firebase/app"
import "firebase/auth"
import "firebase/firestore"
import "firebase/functions"
import { HeaderBackButton } from '@react-navigation/stack'
import AddCreditCard from '../../AddCreditCard'
import AlertModal from '../../AlertModal'
import { BACKGROUND_COLOR, BUTTON_COLOR_TWO, CELL_BACKGROUND_COLOR, CELL_TITLE_TEXT_COLOR, CONNECTION_ERROR_BUTTON, CONNECTION_ERROR_HEADER, CONNECTION_ERROR_MESSAGE, HEADER_TEXT_COLOR, RIGHT_DETAIL_TEXT_COLOR } from '../../../../constants';


export default function Payment({ route, navigation }) {

    useLayoutEffect(() => {
        navigation.setOptions({
            headerRight: () => (
                <Button
                    onPress={cancelPickup}
                    title="Cancel"
                    type="clear"
                    titleStyle={{
                        fontWeight: "normal",
                        color: HEADER_TEXT_COLOR
                    }}
                    buttonStyle={{
                        marginRight: 10,
                    }}
                    disabled={pageLoading}
                />
            ),
            headerLeft: () => (
                <HeaderBackButton
                    onPress={() => navigation.goBack()}
                    disabled={pageLoading}
                    tintColor={HEADER_TEXT_COLOR}
                />
            )
        })
    })

    const cancelPickup = () => {
        setPageLoading(false)
        navigation.navigate("Home")
    }

    const { pickupDay, pickupTime, pickupDate } = route.params

    const [minimumCharge, setMinimumCharge] = useState(0)
    const [userCity, setUserCity] = useState('Stockton')

    const [cardSelectionAccessory, setCardSelectionAccessory] = useState({})
    const [paymentList, setPaymentList] = useState([])
    const [pageLoading, setPageLoading] = useState(true)
    const [modalVisible, setModalVisible] = useState(false)
    const [noExistingPayment, setNoExistingPayment] = useState(false)
    const [selectedCard, setSelectedCard] = useState()
    const [pickupObject, setPickupObject] = useState({})
    const [alertModalVisibile, setAlertModalVisible] = useState(false);
    const [alertInfo, setAlertInfo] = useState({})
    const closeAlertModal = () => {
        setAlertModalVisible(false)
    }
    const openAlertModal = (title, subtitle, primaryButtonText, primaryButtonAction, secondaryButtonText = null, secondaryButtonAction = null) => {
        setAlertInfo({
            alertTitleText: title,
            alertSubtitleText: subtitle,
            alertPrimaryButtonText: primaryButtonText,
            alertPrimaryAction: () => primaryButtonAction(),
            alertSecondaryButtonText: secondaryButtonText,
            alertSecondaryAction: () => secondaryButtonAction()
        })
        setAlertModalVisible(true)
    }

    const getUserAndPickupInfo = async () => {
        setPageLoading(true)
        const user = firebase.auth().currentUser;
        const userInfo = (await firebase.firestore().collection("userProfiles").doc(user.uid).get()).data();
        await getPriceInformation(userInfo)
        await getCustomerPaymentInfo(true)

        const localPickupObject = {
            pickupDay: pickupDay,
            pickupTime: pickupTime,
            pickupDate: pickupDate
        }
        setPickupObject(localPickupObject)
        setPageLoading(false)
    }

    const getPriceInformation = async (userInfo) => {
        setPageLoading(true)
        // TODO: move this to firebase function?
        try {
            const pricingInfoByCityCollection = await firebase.firestore().collection('residentialInfo').doc('Pricing').collection('minimumCharges').get()
            pricingInfoByCityCollection.forEach((doc) => {
                if (userInfo.addressSecondaryText.includes(doc.id)) {
                    setUserCity(doc.id)
                    setMinimumCharge(doc.data().minimumCharge)
                }
            })
        }
        catch (err) {
            console.log(err)
            setPageLoading(false)
            openAlertModal(CONNECTION_ERROR_HEADER, CONNECTION_ERROR_MESSAGE, CONNECTION_ERROR_BUTTON, getUserAndPickupInfo)
            return
        }
    }

    const getCustomerPaymentInfo = async (initialCall = false) => {
        setPageLoading(true)
        try {
            var getPaymentMethods = firebase.functions().httpsCallable('getPaymentMethods');
            const response = await getPaymentMethods({})
            console.log('response data:', response.data)
            if (response.data && !response.data.error) {
                setCustomerPaymentInfo(response.data.payment_methods, response.data.default_payment_method)
            }
            else {
                setPageLoading(false)
                openAlertModal(CONNECTION_ERROR_HEADER, CONNECTION_ERROR_MESSAGE, CONNECTION_ERROR_BUTTON, getUserAndPickupInfo)
                return
            }
        }
        catch (err) {
            setPageLoading(false)
            openAlertModal(CONNECTION_ERROR_HEADER, CONNECTION_ERROR_MESSAGE, CONNECTION_ERROR_BUTTON, getUserAndPickupInfo)
            return
        }
        if (!initialCall) {
            setPageLoading(false)
        }
    }

    useEffect(() => {
        getUserAndPickupInfo();
    }, []);

    const schedulePickup = async () => {
        setPageLoading(true)
        const user = firebase.auth().currentUser;
        const userInfo = (await firebase.firestore().collection("userProfiles").doc(user.uid).get()).data();
        const createDryCleaningPickup = firebase.functions().httpsCallable('createDryCleaningPickup');
        try {
            const response = await createDryCleaningPickup({
                pickup: pickupObject
            })
            if (response.data && response.data.success) {
                navigation.navigate('Dry Cleaning Pickup Confirmation', {
                    message: response.data.message,
                    orderSummaryFields: response.data.orderSummaryFields
                })
                setPageLoading(false)
            }
            else {
                setPageLoading(false)
                openAlertModal('Error', 'Sorry, there was an error creating your pickup.  Please try again.  If this issue persists, please contact us.', 'Ok', closeAlertModal)
                return
            }
        }
        catch (err) {
            setPageLoading(false)
            console.log('error:', err)
            openAlertModal(CONNECTION_ERROR_HEADER, CONNECTION_ERROR_MESSAGE, CONNECTION_ERROR_BUTTON, closeAlertModal)
            return
        }
    }

    const addCard = () => {
        setModalVisible(true)
    }

    const setCustomerPaymentInfo = (paymentMethods, defaultPaymentMethod) => {
        var temp_dict = {}
        for (var i = 0; i < paymentMethods.length; i++) {
            if (paymentMethods[i].id == defaultPaymentMethod) {
                temp_dict[paymentMethods[i].id] = "Checkmark"
            }
            else {
                temp_dict[paymentMethods[i].id] = "none"
            }
        }
        if (paymentMethods.length == 0) {
            setNoExistingPayment(true)
        }
        else {
            setNoExistingPayment(false)
        }
        setCardSelectionAccessory(temp_dict)
        setPaymentList(paymentMethods)
        setSelectedCard(defaultPaymentMethod)
    }

    const selectCard = (cardIndex) => {
        if (!pageLoading) {
            var temp_dict = { ...cardSelectionAccessory }
            for (var key in cardSelectionAccessory) {
                if (key == cardIndex) {
                    temp_dict[key] = "Checkmark"
                }
                else {
                    temp_dict[key] = "none"
                }
            }
            console.log(temp_dict)
            setCardSelectionAccessory(temp_dict)
            setSelectedCard(cardIndex)
        }
    }

    const paymentMethodTable = () => {
        var valueArray = []
        for (var i = 0; i < paymentList.length; i++) {
            let this_id = paymentList[i].id
            var brand = paymentList[i].card.brand
            if (paymentList[i].card.brand == "visa") {
                brand = "Visa"
            }
            let title = brand + " Ending In " + paymentList[i].card.last4
            valueArray.push(<Cell backgroundColor={CELL_BACKGROUND_COLOR} titleTextColor={CELL_TITLE_TEXT_COLOR} key={i} cellStyle="Basic" title={title} accessory={cardSelectionAccessory[this_id]} isDisabled={pageLoading} onPress={() => selectCard(this_id)} />)
        }
        if (paymentList.length == 0) {
            valueArray.push(<Cell backgroundColor={CELL_BACKGROUND_COLOR} titleTextColor={CELL_TITLE_TEXT_COLOR} key={0} cellStyle="Basic" title="No cards on file.  Please add a card." />)
        }
        return valueArray
    }

    const summaryTable = () => {
        var index = 0
        var valueArray = []
        valueArray.push(
            <Cell key={0} backgroundColor={CELL_BACKGROUND_COLOR} titleTextColor={CELL_TITLE_TEXT_COLOR} rightDetailColor={RIGHT_DETAIL_TEXT_COLOR} cellStyle="RightDetail" title={"Pickup Day"} detail={`${pickupDay}`} />
        )
        valueArray.push(
            <Cell key={1} backgroundColor={CELL_BACKGROUND_COLOR} titleTextColor={CELL_TITLE_TEXT_COLOR} rightDetailColor={RIGHT_DETAIL_TEXT_COLOR} cellStyle="RightDetail" title={"Pickup Time"} detail={`${pickupTime}`} />
        )
        valueArray.push(
            <Cell key={3} backgroundColor={CELL_BACKGROUND_COLOR} titleTextColor={CELL_TITLE_TEXT_COLOR} rightDetailColor={RIGHT_DETAIL_TEXT_COLOR} cellStyle="RightDetail" title={`Minimum Charge - ${userCity}`} detail={`$${minimumCharge / 100}`} />
        )
        return valueArray
    }

    if (pageLoading) {
        return (
            <View style={styles.parent}>
                <ActivityIndicator />
            </View>
        )
    }

    return (
        <View style={styles.parent}>
            <ScrollView>
                <Section headerTextColor={HEADER_TEXT_COLOR} header="ORDER SUMMARY" footer="">
                    {summaryTable()}
                </Section>
                <Section headerTextColor={HEADER_TEXT_COLOR} header="PAYMENT METHOD" footer="">
                    {paymentMethodTable()}
                </Section>
                <Section header="" footer="">
                    <Cell backgroundColor={CELL_BACKGROUND_COLOR} titleTextColor={CELL_TITLE_TEXT_COLOR} cellStyle="Basic" title="+ Add New Card..." isDisabled={pageLoading} onPress={addCard} />
                </Section>
                <View style={{ height: 50, width: "100%", marginTop: 40, alignItems: 'center', marginBottom: 50 }}>
                    <View style={{ width: "70%", maxWidth: 250, minWidth: 200 }} >
                        <Button
                            onPress={schedulePickup}
                            title='Schedule Pickup'
                            buttonStyle={{
                                backgroundColor: BUTTON_COLOR_TWO
                            }}
                            disabled={noExistingPayment}
                        />
                    </View>
                </View>
            </ScrollView>
            <AddCreditCard
                modalVisible={modalVisible}
                setModalVisible={setModalVisible}
                noExistingPayment={noExistingPayment}
                setPageLoading={setPageLoading}
                getCustomerPaymentInfo={getCustomerPaymentInfo}
            />
            <AlertModal
                modalVisible={alertModalVisibile}
                setModalVisible={setAlertModalVisible}
                alertTitleText={alertInfo.alertTitleText}
                alertSubtitleText={alertInfo.alertSubtitleText}
                alertPrimaryButtonText={alertInfo.alertPrimaryButtonText}
                alertPrimaryAction={alertInfo.alertPrimaryAction}
                alertSecondaryButtonText={alertInfo.alertSecondaryButtonText}
                alertSecondaryAction={alertInfo.alertSecondaryAction}
            />
        </View>
    )
}
