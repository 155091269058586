import React, { useState, useEffect, useRef, useLayoutEffect } from 'react'
import { View, ActivityIndicator, ScrollView, BackHandler, Dimensions, TextInput, Alert, Text } from 'react-native'
import firebase from "firebase/app"
import "firebase/auth"
import "firebase/firestore"
import "firebase/functions"
import { Cell, Section, TableView } from 'react-native-tableview-simple';
import NumericInput from 'react-native-numeric-input';
import { HeaderBackButton } from '@react-navigation/stack'
import styles from './styles';
import { Button } from 'react-native-elements';
import AddressPicker from '../AddressPicker';
import { CELL_BACKGROUND_COLOR, CELL_TITLE_TEXT_COLOR, DISABLED_TEXT_COLOR, DISABLED_TEXT_INPUT_COLOR, HEADER_TEXT_COLOR, PLACEHOLDER_TEXT_INPUT_COLOR, TEXT_INPUT_COLOR } from '../../../constants';
import AlertModal from '../AlertModal';


export default function PickupDetail({ route, navigation }) {

    useLayoutEffect(() => {
        navigation.setOptions({
            headerRight: () => (
                <Button
                    title="Edit"
                    type="clear"
                    titleStyle={{
                        fontWeight: "normal",
                        color: HEADER_TEXT_COLOR
                    }}
                    buttonStyle={{
                        marginRight: 10
                    }}
                    disabled={true}
                />
            ),
            headerLeft: () => (
                <HeaderBackButton
                    tintColor={HEADER_TEXT_COLOR}
                    disabled={pageLoading}
                    onPress={() => navigation.goBack()}
                />
            )
        })
    })

    const { pickupId, pickupCurrent } = route.params
    const [pageLoading, setPageLoading] = useState(true)
    const [priceMessage, setTotalMessage] = useState('')
    const [editCancelMessage, setEditCancelMessage] = useState('')
    const [upcomingPickup, setUpcomingPickup] = useState(false)
    const [cancelable, setCancelable] = useState(false)
    const [pickupObj, setPickupObj] = useState({})
    const [alertModalVisibile, setAlertModalVisible] = useState(false);
    const [alertInfo, setAlertInfo] = useState({})
    const closeAlertModal = () => {
        setAlertModalVisible(false)
    }
    const openAlertModal = (title, subtitle, primaryButtonText, primaryButtonAction, secondaryButtonText = null, secondaryButtonAction = null) => {
        setAlertInfo({
            alertTitleText: title,
            alertSubtitleText: subtitle,
            alertPrimaryButtonText: primaryButtonText,
            alertPrimaryAction: () => primaryButtonAction(),
            alertSecondaryButtonText: secondaryButtonText,
            alertSecondaryAction: () => secondaryButtonAction()
        })
        setAlertModalVisible(true)
    }

    const goBack = () => {
        setAlertModalVisible(false)
        navigation.goBack()
    }

    const getPickupInfo = async () => {
        setAlertModalVisible(false)
        setPageLoading(true)
        try {
            const getPickupInfoV1 = firebase.functions().httpsCallable('getPickupInfoV1');
            const response = await getPickupInfoV1({
                pickupId: pickupId
            })
            if (response.data.success) {
                console.log(response.data)
                setTotalMessage(response.data.totalMessage)
                setEditCancelMessage(response.data.editCancelMessage)
                setUpcomingPickup(response.data.upcoming)
                setCancelable(response.data.cancelable)
                setPickupObj(response.data.pickupObj)
                setPageLoading(false)
            }
            else {
                console.log('error getting pickup info', response.data.error)
                setPageLoading(false)
                openAlertModal('Error', 'Sorry, there was an error getting the pickup information.  Please try again.  If this issue persists, please contact us.', 'Try Again', getPickupInfo, 'Go Back', goBack)
            }
        }
        catch (err) {
            console.log('error getting pickup information:', err)
            setPageLoading(false)
            openAlertModal('Error', 'Sorry, there was an error getting the pickup information.  Please try again.  If this issue persists, please contact us.', 'Try Again', getPickupInfo, 'Go Back', goBack)
        }
    }


    useEffect(() => {
        getPickupInfo()
    }, [])

    const displayAptNumAndAdditionalInstructions = () => {
        var valueArray = []
        if (pickupObj.aptNum !== '') {
            valueArray.push(
                <Cell titleTextColor={DISABLED_TEXT_COLOR} backgroundColor={CELL_BACKGROUND_COLOR} cellStyle="Basic" title={pickupObj.aptNum} />
            )
        }
        if (pickupObj.additionalInstructions !== '') {
            valueArray.push(
                <Cell titleTextColor={DISABLED_TEXT_COLOR} backgroundColor={CELL_BACKGROUND_COLOR} cellStyle="Basic" title={pickupObj.additionalInstructions} />
            )
        }
    }

    const confirmCancelPickup = async () => {
        closeAlertModal()
        if (cancelable) {
            setPageLoading(true)
            try {
                const cancelPickupV1 = firebase.functions().httpsCallable('cancelPickupV1');
                const response = await cancelPickupV1({
                    pickupId: pickupId
                })
                console.log('response:', response)
                if (response.data.success) {
                    setPageLoading(false)
                    navigation.goBack()
                }
                else {
                    console.log('error canceling pickup:', response.data.error)
                    openAlertModal('Error', 'Sorry, we experienced an error connecting to our services.  Please try again.', 'Ok', () => navigation.goBack())
                    setPageLoading(false)
                }
            }
            catch (err) {
                console.log('error canceling pickup:', err)
                openAlertModal('Error', 'Sorry, we experienced an error connecting to our services.  Please try again.', 'Ok', () => navigation.goBack())
                setPageLoading(false)
            }
        }
    }

    const cancelPickup = async () => {
        openAlertModal('Confirm', 'Are you sure you want to cancel this pickup?  This can not be undone.', 'Yes', confirmCancelPickup, 'Cancel', closeAlertModal)
    }

    const displayCancelButton = () => {
        if (upcomingPickup) {
            return (
                <>
                    <Section header="">
                        <Cell titleTextColor={CELL_TITLE_TEXT_COLOR} backgroundColor={CELL_BACKGROUND_COLOR} cellStyle="Basic" title='Cancel Pickup' onPress={cancelPickup} isDisabled={!cancelable} />
                    </Section>
                    <View style={{ marginLeft: 10, marginRight: 10, marginTop: 10, marginBottom: 50 }}>
                        <Text style={styles.subsubheading}>{editCancelMessage}</Text>
                    </View>
                </>

            )
        }
        else {
            return (
                <View style={{ marginLeft: 10, marginRight: 10, marginTop: 10, marginBottom: 50 }}>
                </View>
            )
        }
    }

    if (pageLoading) {
        return (
            <View style={styles.parent}>
                <ActivityIndicator />
            </View>
        )
    }

    const priceInfo = () => {
        if (pickupObj.flatRate) {
            return (
                <>
                    <View style={{ marginLeft: 10, marginRight: 10, marginTop: 10, marginBottom: 10 }}>
                        <Text style={styles.subsubheading}>{priceMessage}</Text>
                    </View>
                    <Section header="TOTAL">
                        <Cell titleTextColor={DISABLED_TEXT_COLOR} backgroundColor={CELL_BACKGROUND_COLOR} cellStyle="Basic" title={pickupObj.total} />
                    </Section>
                </>
            )
        }
        else {
            if (upcomingPickup) {
                return (
                    <>
                        <View style={{ marginLeft: 10, marginRight: 10, marginTop: 10, marginBottom: 10 }}>
                            <Text style={styles.subsubheading}>{priceMessage}</Text>
                        </View>
                        <Section header="PRICE PER POUND">
                            <Cell titleTextColor={DISABLED_TEXT_COLOR} backgroundColor={CELL_BACKGROUND_COLOR} cellStyle="Basic" title={pickupObj.pricePerPound} />
                        </Section>
                    </>
                )
            }
            else {
                return (
                    <>
                        <View style={{ marginLeft: 10, marginRight: 10, marginTop: 10, marginBottom: 10 }}>
                            <Text style={styles.subsubheading}>{priceMessage}</Text>
                        </View>
                        <Section header="TOTAL">
                            <Cell titleTextColor={DISABLED_TEXT_COLOR} backgroundColor={CELL_BACKGROUND_COLOR} cellStyle="Basic" title={pickupObj.total} />
                        </Section>
                        <Section header="PRICE PER POUND">
                            <Cell titleTextColor={DISABLED_TEXT_COLOR} backgroundColor={CELL_BACKGROUND_COLOR} cellStyle="Basic" title={pickupObj.pricePerPound} />
                        </Section>
                        <Section header="NUMBER OF POUNDS">
                            <Cell titleTextColor={DISABLED_TEXT_COLOR} backgroundColor={CELL_BACKGROUND_COLOR} cellStyle="Basic" title={pickupObj.numberOfPounds} />
                        </Section>
                    </>
                )
            }
        }
    }

    return (
        <View style={styles.parent}>
            <ScrollView keyboardShouldPersistTaps='handled'>

                {priceInfo()}

                <Section header="PICKUP DATE">
                    <Cell titleTextColor={DISABLED_TEXT_COLOR} backgroundColor={CELL_BACKGROUND_COLOR} cellStyle="Basic" title={pickupObj.pickupDate} />
                </Section>
                <Section header="PICKUP TIME">
                    <Cell titleTextColor={DISABLED_TEXT_COLOR} backgroundColor={CELL_BACKGROUND_COLOR} cellStyle="Basic" title={pickupObj.pickupTime} />
                </Section>
                <Section header="CONNECTED PLAN">
                    <Cell titleTextColor={DISABLED_TEXT_COLOR} backgroundColor={CELL_BACKGROUND_COLOR} cellStyle="Basic" title={pickupObj.planName} />
                </Section>
                <Section header="ADDRESS">
                    <Cell titleTextColor={DISABLED_TEXT_COLOR} backgroundColor={CELL_BACKGROUND_COLOR} cellStyle="Basic" title={pickupObj.addressMainText} />
                    <Cell titleTextColor={DISABLED_TEXT_COLOR} backgroundColor={CELL_BACKGROUND_COLOR} cellStyle="Basic" title={pickupObj.addressSecondaryText} />
                    {displayAptNumAndAdditionalInstructions()}
                </Section>
                <Section header="CONTACT">
                    <Cell titleTextColor={DISABLED_TEXT_COLOR} backgroundColor={CELL_BACKGROUND_COLOR} cellStyle="Basic" title={pickupObj.phoneNumber} />
                    <Cell titleTextColor={DISABLED_TEXT_COLOR} backgroundColor={CELL_BACKGROUND_COLOR} cellStyle="Basic" title={pickupObj.email} />
                    <Cell titleTextColor={DISABLED_TEXT_COLOR} backgroundColor={CELL_BACKGROUND_COLOR} cellStyle="Basic" title={`Preferred Contact Method: ${pickupObj.preferredContactMethod}`} />
                </Section>
                <Section header="DETERGENT">
                    <Cell titleTextColor={DISABLED_TEXT_COLOR} backgroundColor={CELL_BACKGROUND_COLOR} cellStyle="Basic" title={pickupObj.detergent} />
                </Section>
                <Section header="DRYER SHEET">
                    <Cell titleTextColor={DISABLED_TEXT_COLOR} backgroundColor={CELL_BACKGROUND_COLOR} cellStyle="Basic" title={pickupObj.dryerSheet} />
                </Section>
                <Section header="FABRIC SOFTENER">
                    <Cell titleTextColor={DISABLED_TEXT_COLOR} backgroundColor={CELL_BACKGROUND_COLOR} cellStyle="Basic" title={pickupObj.fabricSoftener} />
                </Section>
                {displayCancelButton()}
            </ScrollView>
            <AlertModal
                modalVisible={alertModalVisibile}
                setModalVisible={setAlertModalVisible}
                alertTitleText={alertInfo.alertTitleText}
                alertSubtitleText={alertInfo.alertSubtitleText}
                alertPrimaryButtonText={alertInfo.alertPrimaryButtonText}
                alertPrimaryAction={alertInfo.alertPrimaryAction}
                alertSecondaryButtonText={alertInfo.alertSecondaryButtonText}
                alertSecondaryAction={alertInfo.alertSecondaryAction}
            />
        </View>
    )
}