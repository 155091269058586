import React, { useState, useEffect } from 'react'
import { View, Text, SafeAreaView, ActivityIndicator, FlatList, ScrollView } from 'react-native'
import styles from './styles'
import firebase from "firebase/app"
import "firebase/auth"
import "firebase/firestore"
import "firebase/functions"
import AuthContext from '../../../AuthContext.js'
import { Cell, Section, TableView } from 'react-native-tableview-simple';
import { BACKGROUND_COLOR, CELL_BACKGROUND_COLOR, CELL_TITLE_TEXT_COLOR, HEADER_TEXT_COLOR } from '../../../constants';


export default function UpcomingPickups({ navigation }) {
    const [pageLoading, setPageLoading] = useState(true); // Set loading to true on component mount
    const [pickups, setPickups] = useState(); // Initial empty array of pickups

    useEffect(() => {
        const user = firebase.auth().currentUser;
        const snapshotSubscriber = firebase.firestore()
            .collection('pickups')
            .where('userId', '==', user.uid)
            .onSnapshot(querySnapshot => {
                setPageLoading(true)
                const pickupsLocal = querySnapshot.docs.map(doc => doc)
                const upcomingPickups = []
                let currentDate = new Date()
                for (var i = 0; i < pickupsLocal.length; i++) {
                    let pickupDate = new Date(pickupsLocal[i].data().pickupDate)
                    if (pickupDate >= currentDate) {
                        upcomingPickups.push(pickupsLocal[i])
                    }
                }
                console.log('upcomingPickups:', upcomingPickups)
                upcomingPickups.sort((a, b) => {
                    return new Date(a.data().pickupDate) - new Date(b.data().pickupDate)
                })
                setPickups(upcomingPickups);
                setPageLoading(false);
            });
        return function cleanup() {
            snapshotSubscriber();
        }
    }, []);

    const openDetails = (pickupId) => {
        navigation.navigate("Pickup Detail", {
            pickupId: pickupId,
            pickupCurrent: true
        })
    }

    const createTable = () => {
        const valueArray = []
        for (var i = 0; i < pickups.length; i++) {
            const title = pickups[i].data().pickupDay
            const pickupId = pickups[i].id
            valueArray.push(<Cell key={i} cellStyle="Basic" backgroundColor={CELL_BACKGROUND_COLOR} titleTextColor={CELL_TITLE_TEXT_COLOR} title={title} accessory="DisclosureIndicator" onPress={() => openDetails(pickupId)} />)
        }
        if (valueArray.length == 0) {
            valueArray.push(<Cell key={0} cellStyle="Basic" backgroundColor={CELL_BACKGROUND_COLOR} titleTextColor={CELL_TITLE_TEXT_COLOR} title="No upcoming pickups" />)
        }
        return valueArray
    }

    if (pageLoading) {
        return (
            <View style={styles.parent}>
                <ActivityIndicator />
            </View>
        )
    }

    return (
        <View style={{ backgroundColor: BACKGROUND_COLOR, flex: 1 }}>
            <ScrollView keyboardShouldPersistTaps='handled'>
                <Section headerTextColor={HEADER_TEXT_COLOR} header="UPCOMING PICKUPS" footer="">
                    {createTable()}
                </Section>
            </ScrollView>
        </View>
    );
}