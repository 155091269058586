import React, { useEffect, useLayoutEffect, useState } from 'react';
import {
    View,
    SafeAreaView,
    ScrollView,
    Dimensions,
    ActivityIndicator,
    BackHandler
} from 'react-native';
import styles from './styles';
import KeyboardSpacer from '../KeyboardSpacer';
import { Cell, Section } from 'react-native-tableview-simple';
import firebase from "firebase/app"
import "firebase/auth"
import "firebase/firestore"
import "firebase/functions"
import { BACKGROUND_COLOR, CELL_BACKGROUND_COLOR, CELL_TITLE_TEXT_COLOR, CONNECTION_ERROR_BUTTON, CONNECTION_ERROR_HEADER, CONNECTION_ERROR_MESSAGE, HEADER_TEXT_COLOR } from '../../../constants';
import { HeaderBackButton } from '@react-navigation/stack';
import { Text } from 'react-native';
import AlertModal from '../AlertModal';


export default function PickupTime({ navigation }) {

    useLayoutEffect(() => {
        navigation.setOptions({
            headerRight: null,
            headerLeft: () => {
                return <HeaderBackButton
                    label="Back"
                    tintColor={HEADER_TEXT_COLOR}
                    disabled={pageLoading}
                    onPress={() => navigation.goBack()}
                />

            },
        })
    })

    const [times, setTimes] = useState([])
    const [timeAccessory, setTimeAccessory] = useState({})
    const [time, setTime] = useState("")
    const [pageLoading, setPageLoading] = useState(true)
    const [alertModalVisibile, setAlertModalVisible] = useState(false);
    const [alertInfo, setAlertInfo] = useState({})
    const closeAlertModal = () => {
        setAlertModalVisible(false)
    }
    const openAlertModal = (title, subtitle, primaryButtonText, primaryButtonAction, secondaryButtonText = null, secondaryButtonAction = null) => {
        setAlertInfo({
            alertTitleTsext: title,
            alertSubtitleText: subtitle,
            alertPrimaryButtonText: primaryButtonText,
            alertPrimaryAction: () => primaryButtonAction(),
            alertSecondaryButtonText: secondaryButtonText,
            alertSecondaryAction: () => secondaryButtonAction()
        })
        setAlertModalVisible(true)
    }

    const getPageAndUserInfo = async () => {
        try {
            const pickupDateDoc = await firebase.firestore().collection('pageInfo').doc('pickupDayTime').get()
            var pickupTimes = []
            var timeAccessoryLocal = {}
            if (pickupDateDoc.data() && pickupDateDoc.data().times) {
                pickupTimes = pickupDateDoc.data().times
                var index = 0
                pickupDateDoc.data().times.forEach((time) => {
                    if (index === 0) {
                        setTime(time)
                        timeAccessoryLocal[time] = "Checkmark"
                        index++
                    }
                    else {
                        timeAccessoryLocal[time] = "none"
                    }
                })
                setTimeAccessory(timeAccessoryLocal)
                setTimes(pickupTimes)
            }
            else {
                setPageLoading(false)
                openAlertModal('Error', 'Sorry, we had an error connecting to our services.  If this issue persists, please contact us.', 'Try Again', () => getPageAndUserInfo(), 'Go Back', () => navigation.goBack())
                return
            }

            // get user info
            const user = firebase.auth().currentUser;
            const userInfo = (await firebase.firestore().collection("userProfiles").doc(user.uid).get()).data();
            if (userInfo) {
                if (userInfo.preferredPickupTime) {
                    selectTime(userInfo.preferredPickupTime, timeAccessoryLocal, false)
                }
            }
            else {
                setPageLoading(false)
                openAlertModal('Error', 'Sorry, we had an error connecting to our services.  If this issue persists, please contact us.', 'Try Again', () => getPageAndUserInfo(), 'Go Back', () => navigation.goBack())
                return
            }
            setPageLoading(false)
        }
        catch (err) {
            console.log('error:', err)
            setPageLoading(false)
            openAlertModal('Error', 'Sorry, we had an error connecting to our services.  If this issue persists, please contact us.', 'Try Again', () => getPageAndUserInfo(), 'Go Back', () => navigation.goBack())
            return
        }
    }

    const selectTime = async (selectedTime, accessoryParam = null, changeDatabase = true) => {
        setPageLoading(true)
        if (changeDatabase) {
            try {
                const updateDatabaseDict = {}
                updateDatabaseDict.preferredPickupTime = selectedTime
                const updatePickupDayAndTime = firebase.functions().httpsCallable('updatePickupDayAndTime');
                const response = await updatePickupDayAndTime({
                    updateDict: updateDatabaseDict,
                    updateContext: 'account_page'
                })
                if (response.data) {
                    if (response.data.success) {
                        // await getPageAndUserInfo()
                        // setPageLoading(false)
                    }
                    else {
                        console.log('error updating address:', response.data.message)
                        openAlertModal('Error', 'Error updating your account info.  Please try again.  If this error persists, please contact us.', 'Ok', closeAlertModal)
                        setPageLoading(false)
                        return
                    }
                }
                else {
                    openAlertModal('Error', 'Error updating your account info.  Please try again.  If this error persists, please contact us.', 'Ok', closeAlertModal)
                    setPageLoading(false)
                    return
                }
            }
            catch (err) {
                console.log('error:', err)
                setPageLoading(false)
                openAlertModal('Error', 'Error updating your account info.  Please try again.  If this error persists, please contact us.', 'Ok', closeAlertModal)
                return
            }
        }
        var accessoryLocal = accessoryParam
        if (!accessoryLocal) {
            accessoryLocal = timeAccessory
        }
        setTime(selectedTime)
        for (var key in accessoryLocal) {
            if (key == selectedTime) {
                accessoryLocal[key] = "Checkmark"
            }
            else {
                accessoryLocal[key] = "none"
            }
        }
        setTimeAccessory(accessoryLocal)
        setPageLoading(false)
    }

    useEffect(() => {
        getPageAndUserInfo()
        BackHandler.addEventListener('hardwareBackPress', onBackPress);
        return () => {
            BackHandler.removeEventListener('hardwareBackPress', onBackPress);
        }
    }, []);

    const onBackPress = () => {
        return true;
    }

    const createPickupTimeTable = () => {
        var valueArray = []
        var index = 0
        times.forEach((time) => {
            valueArray.push(
                <Cell key={index} backgroundColor={CELL_BACKGROUND_COLOR} cellStyle="Basic" titleTextColor={CELL_TITLE_TEXT_COLOR} title={time} accessory={timeAccessory[time]} isDisabled={pageLoading} onPress={() => selectTime(time)} />
            )
            index++
        })
        return valueArray
    }

    if (pageLoading) {
        return (
            <View style={styles.parent}>
                <ActivityIndicator />
            </View>
        )
    }

    return (
        <View style={styles.parent}>
            <ScrollView>
                <View style={{ marginLeft: 10, marginRight: 10, marginTop: 10, marginBottom: 10 }}>
                    <Text style={styles.subheading}>NOTE: This will change settings for upcoming plan pickups.</Text>
                </View>
                <Section headerTextColor={HEADER_TEXT_COLOR} header="" footer="">
                    {createPickupTimeTable()}
                </Section>
            </ScrollView>
            <AlertModal
                modalVisible={alertModalVisibile}
                setModalVisible={setAlertModalVisible}
                alertTitleText={alertInfo.alertTitleText}
                alertSubtitleText={alertInfo.alertSubtitleText}
                alertPrimaryButtonText={alertInfo.alertPrimaryButtonText}
                alertPrimaryAction={alertInfo.alertPrimaryAction}
                alertSecondaryButtonText={alertInfo.alertSecondaryButtonText}
                alertSecondaryAction={alertInfo.alertSecondaryAction}
            />
        </View>
    );
}