import React, { useEffect, useState } from 'react';
import {
    Text,
    View
} from 'react-native';
import styles from './styles';
import firebase from "firebase/app"
import "firebase/auth"
import "firebase/firestore"
import "firebase/functions"
import { MaterialIcons, MaterialCommunityIcons, AntDesign, Entypo, Ionicons, FontAwesome } from '@expo/vector-icons';
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs'
import { ACCENT_COLOR_TWO, CELL_TITLE_TEXT_COLOR, CONNECTION_ERROR_BUTTON, CONNECTION_ERROR_HEADER, CONNECTION_ERROR_MESSAGE, TEXT_COLOR_ONE, TOP_TAB_HEADER_ICON_COLOR, TOP_TAB_HEADER_ICON_SIZE, BUTTON_COLOR_ONE, WASH_AND_FOLD_PICKUP_DISPLAY_NAME, DRY_CLEAN_PICKUP_DISPLAY_NAME } from '../../../constants';
import { ActivityIndicator } from 'react-native';
import { ScrollView } from 'react-native-gesture-handler';
import { Button } from 'react-native-elements';
import WashAndFoldPickupPage from './WashAndFoldPickupPage';
import PlanPage from './PlanPage.js';
import DryCleaningPickupPage from './DryCleaningPickupPage';

const TopTab = createMaterialTopTabNavigator();

export default function PickupOptionSelector() {

    useEffect(() => {
        setPageLoading(true)
        getPlanAndUserInfo()
    }, []);

    const [pageLoading, setPageLoading] = useState(true)
    const [connectionError, setConnectionError] = useState(false)
    const [userCity, setUserCity] = useState('')
    const [minimumCharge, setMinimumCharge] = useState(0)
    const [planInfo, setPlanInfo] = useState([])
    const [userPlan, setUserPlan] = useState(null)
    const [pricePerPound, setPricePerPound] = useState(0)

    const getPlanAndUserInfo = async () => {
        try {
            const plansCollection = await firebase.firestore().collection('residentialInfo').doc('Plans').collection('options').get()
            const user = firebase.auth().currentUser;
            const userInfoDoc = await firebase.firestore().collection('userProfiles').doc(user.uid).get()
            const userInfo = userInfoDoc.data()
            const pricingInfoDoc = await firebase.firestore().collection('residentialInfo').doc('Pricing').get()
            const pricingInfo = pricingInfoDoc.data()
            const pricingInfoByCityCollection = await firebase.firestore().collection('residentialInfo').doc('Pricing').collection('minimumCharges').get()
            const pricePerPoundLocal = pricingInfoDoc.data().pricePerPound
            setPricePerPound(pricePerPoundLocal)
            pricingInfoByCityCollection.forEach((doc) => {
                if (userInfo.addressSecondaryText.includes(doc.id)) {
                    setUserCity(doc.id)
                    setMinimumCharge(doc.data().minimumCharge)
                }
            })
            setPricePerPound(pricingInfo.pricePerPound)
            setUserPlan(userInfo.plan)
            const planInfoLocal = []
            plansCollection.forEach((doc) => {
                planInfoLocal.push(doc.data())
            })
            setPlanInfo(planInfoLocal)
            setConnectionError(false)
            setPageLoading(false)
        }
        catch (err) {
            console.log(err)
            setConnectionError(true)
            setPageLoading(false)
        }
    }

    const topTabScreens = () => {
        const topTabScreens = []
        var index = 0
        topTabScreens.push(
            <TopTab.Screen name={WASH_AND_FOLD_PICKUP_DISPLAY_NAME}
                key={index}
                component={WashAndFoldPickupPage}
                initialParams={{
                    usersCurrentPlan: userPlan,
                    userHasAPlanAlready: userPlan !== 'None',
                    pricePerPound: pricePerPound,
                    userCity: userCity,
                    minimumCharge: minimumCharge
                }}
                options={{
                    tabBarIcon: ({ tintColor, focused }) => (
                        <View style={{ alignItems: 'center' }}>
                            <MaterialCommunityIcons name="bag-personal" size={TOP_TAB_HEADER_ICON_SIZE} color={TOP_TAB_HEADER_ICON_COLOR} />
                        </View>
                    ),
                    cardStyle: { backgroundColor: 'rgba(20, 21, 24, .0)' }
                }}
            />
        )
        topTabScreens.push(
            <TopTab.Screen name={DRY_CLEAN_PICKUP_DISPLAY_NAME}
                key={index}
                component={DryCleaningPickupPage}
                initialParams={{
                    usersCurrentPlan: userPlan,
                    userHasAPlanAlready: userPlan !== 'None',
                    pricePerPound: pricePerPound,
                    userCity: userCity,
                    minimumCharge: minimumCharge
                }}
                options={{
                    tabBarIcon: ({ tintColor, focused }) => (
                        <View style={{ alignItems: 'center' }}>
                            <MaterialCommunityIcons name="hanger" size={TOP_TAB_HEADER_ICON_SIZE} color={TOP_TAB_HEADER_ICON_COLOR} />
                        </View>
                    ),
                    cardStyle: { backgroundColor: 'rgba(20, 21, 24, .0)' }
                }}
            />
        )
        // planInfo.forEach((planObj) => {
        //     index++
        //     topTabScreens.push(
        //         <TopTab.Screen name={planObj['displayName']}
        //             key={index}
        //             component={PlanPage}
        //             initialParams={{
        //                 optionName: planObj['name'],
        //                 displayName: planObj['displayName'],
        //                 description: planObj['description'],
        //                 usersCurrentPlan: userPlan,
        //                 thisIsUsersCurrentPlan: userPlan === planObj['name'],
        //                 userHasAPlanAlready: userPlan !== 'None',
        //                 pricePerPound: pricePerPound,
        //                 userCity: userCity,
        //                 minimumCharge: minimumCharge
        //             }}
        //             options={{
        //                 tabBarIcon: ({ tintColor, focused }) => (
        //                     <View style={{ alignItems: 'center' }}>
        //                         <MaterialCommunityIcons name="repeat" size={TOP_TAB_HEADER_ICON_SIZE} color={TOP_TAB_HEADER_ICON_COLOR} />
        //                     </View>
        //                 ),
        //                 cardStyle: { backgroundColor: 'rgba(20, 21, 24, .0)' }
        //             }}
        //         />
        //     )
        // })
        return topTabScreens
    }

    if (connectionError) {
        return (
            <View style={styles.parent}>
                <ScrollView keyboardShouldPersistTaps='handled'>
                    <View style={{ marginTop: 10, flex: 1, alignItems: 'center' }}>
                        <View style={{ height: 65, width: "100%", marginLeft: 0, marginTop: 5, justifyContent: "center" }}>
                            <Text style={styles.heading}>Connection Error</Text>
                        </View>
                    </View>
                    <View style={{ width: "100%", alignItems: 'center' }}>
                        <View style={{ height: 50, width: "70%", maxWidth: 400, marginLeft: 0, marginTop: 50, flex: 1 }}>
                            <Button
                                onPress={() => getPlanAndUserInfo()}
                                title='Try Again'
                                buttonStyle={{
                                    backgroundColor: BUTTON_COLOR_ONE
                                }}
                            />
                        </View>
                    </View>
                </ScrollView>
            </View>
        )
    }

    if (pageLoading) {
        return (
            <View style={styles.parent}>
                <ActivityIndicator />
            </View>
        )
    }

    return (
        <TopTab.Navigator screenOptions={{ cardStyle: { backgroundColor: 'rgba(20, 21, 24, .0)' } }} initialRouteName={userPlan} tabBarOptions={{ showIcon: true, showLabel: true, labelStyle: { fontSize: 15, textTransform: 'none' }, style: { backgroundColor: ACCENT_COLOR_TWO }, activeTintColor: TEXT_COLOR_ONE }}>
            {topTabScreens()}
        </TopTab.Navigator>
    );
}