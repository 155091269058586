import { StyleSheet } from 'react-native'
import { BACKGROUND_COLOR } from '../../../constants';

export default StyleSheet.create({
    heading: {
        fontSize: 25,
        textAlign: 'center'
    },
    subheading: {
        fontSize:20,
        fontWeight:"bold",
        textAlign: 'left'
    },
    input: {
        marginTop: 10,
        marginLeft: 5,
        marginRight: 5,
    },
    button: {
        marginTop: 20,
    },
    parent: {
        flex:1,
        backgroundColor: BACKGROUND_COLOR
    },
    safeContainer: {
        marginTop:0
    },
    twoPickers: {
        width: 50,
        height: 100,
        backgroundColor: '#FFF0E0',
        borderColor: 'black',
        borderWidth: 1,
      },
      twoPickerItems: {
        height: 125,
        color: 'black'
      },
      imageContainer: {
        justifyContent: 'center',
        alignItems: 'center',
    },
    row: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingVertical: 8,
        paddingHorizontal: 16,
    }
});