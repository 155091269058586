import React, { useRef, useState, useContext } from 'react';
import {
    View,
    SafeAreaView,
    Text,
    ScrollView,
    Image,
    Dimensions,
    Alert,
    TextInput,
    useWindowDimensions,
    ActivityIndicator
} from 'react-native';
import { Button, Input, Card } from 'react-native-elements';
import { Cell, Section, TableView } from 'react-native-tableview-simple';
import styles from './styles';
import firebase from "firebase/app"
import "firebase/auth"
import "firebase/firestore"
import "firebase/functions"
import PublicTopHeading from '../../../PublicTopHeading.js'
import { BACKGROUND_COLOR, BUTTON_COLOR_ONE, BUTTON_COLOR_TWO, CELL_BACKGROUND_COLOR, FOOTER_TEXT_COLOR, PLACEHOLDER_TEXT_INPUT_COLOR, TEXT_COLOR_THREE, TEXT_INPUT_COLOR } from '../../../constants';
import AlertModal from '../AlertModal'


export default function SignUp({ navigation }) {

    const dimensions = useWindowDimensions();
    const refs = { "email": useRef(), "password": useRef(), "confirmPassword": useRef() }
    const [pageLoading, setPageLoading] = useState(false)
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [confirmPassword, setConfirmPassword] = useState("")
    const [alertModalVisibile, setAlertModalVisible] = useState(false);
    const [alertInfo, setAlertInfo] = useState({})
    const openAlertModal = (title, subtitle, primaryButtonText, primaryButtonAction, secondaryButtonText = null, secondaryButtonAction = null) => {
        setAlertInfo({
            alertTitleText: title,
            alertSubtitleText: subtitle,
            alertPrimaryButtonText: primaryButtonText,
            alertPrimaryAction: () => primaryButtonAction(),
            alertSecondaryButtonText: secondaryButtonText,
            alertSecondaryAction: () => secondaryButtonAction()
        })
        setAlertModalVisible(true)
    }
    const closeAlertModal = () => {
        setAlertModalVisible(false)
    }

    let widthPercent = "70%";
    let marginPercent = "15%";

    if (dimensions.width >= 1100) {
        widthPercent = 1000;
        marginPercent = (dimensions.width - 1000) / 2;
    }
    else if ((dimensions.width >= 500) && (dimensions.width < 1200)) {
        widthPercent = "90%";
        marginPercent = "5%";
    }
    else {
        widthPercent = "95%";
        marginPercent = "2.5%"
    }

    const signUserUp = async () => {
        if (email === '') {
            openAlertModal('Error', 'Please enter your email.', 'Ok', closeAlertModal)
            return
        }
        if (password === '') {
            openAlertModal('Error', 'Please enter a password.', 'Ok', closeAlertModal)
            return
        }
        if (confirmPassword === '') {
            openAlertModal('Error', 'Please enter a password.', 'Ok', closeAlertModal)
            return
        }
        const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
        if (!emailRegex.test(email)) {
            openAlertModal('Invalid Email', 'Please enter a valid email address.', 'Ok', closeAlertModal)
            return
        }
        // Minimum eight characters, at least one uppercase letter, one lowercase letter, one number, and one special character (!@#$%^&*)
        const passwordRegex = /^(?=.*[\d])(?=.*[A-Z])(?=.*[a-z])(?=.*[!@#$%^&*])[\w!@#$%^&*]{8,}$/
        if (!passwordRegex.test(password)) {
            openAlertModal('Invalid Password', 'Passwords must be at least 8 characters, with at least 1 uppercase letter, 1 lowercase letter, 1 number, and 1 special character !@#$%^&*', 'Ok', closeAlertModal)
            return
        }
        if (password.length > 50) {
            openAlertModal('Invalid Password', 'Passwords must be 50 characters or less', 'Ok', closeAlertModal)
            return
        }
        if (password !== confirmPassword) {
            openAlertModal('Error', 'Passwords do not match', 'Ok', closeAlertModal)
            return
        }
        setPageLoading(true)
        try {
            await firebase.auth().createUserWithEmailAndPassword(email, password)
            setPageLoading(false)
        }
        catch (err) {
            console.log('error:', err)
            setPageLoading(false)
            openAlertModal('Error', err.message, 'Ok', closeAlertModal)
            return
        }
        // createNewAccount()
    }

    const onSubmitEmail = () => {
        refs['password'].current.focus()
    }

    const onSubmitPassword = () => {
        refs['confirmPassword'].current.focus()
    }

    const onSubmitConfirmPassword = () => {
        signUserUp()
    }

    if (pageLoading) {
        return (
            <View style={{ backgroundColor: BACKGROUND_COLOR, width: widthPercent, marginLeft: marginPercent, height: dimensions.height, position: "absolute", justifyContent: 'center' }}>
                <ActivityIndicator size='large' />
            </View>
        )
    }

    return (
        <View style={{ maxHeight: dimensions.height }}>
            {/* <View style={{ zIndex: 10, width: widthPercent, marginLeft: marginPercent, position: "absolute" }}>
                <PublicTopHeading navigation={navigation} currentPage="Login/SignUp" />
            </View> */}
            <View style={{ flex: 1, marginTop: 0, zIndex: 0, height: dimensions.height }}>
                <ScrollView>
                    <View style={{ width: widthPercent, marginLeft: marginPercent, backgroundColor: BACKGROUND_COLOR, flex: 1, minHeight: dimensions.height, alignItems: 'center' }}>
                        <View style={{ width: "100%", alignItems: "center" }}>
                            <Image source={require("../../assets/Tumble_Logo.png")} style={{ width: 150, height: 150 }} />
                        </View>
                        <View style={{ width: "80%", maxWidth: 500, marginBottom: 20, justifyContent: "center" }}>
                            <Text style={{ fontSize: 30, textAlign: 'center', color: TEXT_COLOR_THREE }}>Sign Up</Text>
                            <Section header="EMAIL">
                                <Cell cellContentView={
                                    <TextInput
                                        style={{ fontSize: 16, flex: 1, color: TEXT_INPUT_COLOR }}
                                        placeholder="Email"
                                        ref={refs["email"]}
                                        autoCorrect={false}
                                        enablesReturnKeyAutomatically={true}
                                        onChangeText={(value) => setEmail(value)}
                                        value={email}
                                        autoCapitalize="none"
                                        onSubmitEditing={onSubmitEmail}
                                        keyboardType='email-address'
                                        returnKeyType="next"
                                        placeholderTextColor={PLACEHOLDER_TEXT_INPUT_COLOR}
                                    />
                                }
                                    backgroundColor={CELL_BACKGROUND_COLOR}
                                />
                            </Section>
                            <Section header="PASSWORD" footer='Passwords must be at least 8 characters, with at least 1 uppercase letter, 1 lowercase letter, 1 number, and 1 special character !@#$%^&*' footerTextColor={FOOTER_TEXT_COLOR}>
                                <Cell cellContentView={
                                    <TextInput
                                        style={{ fontSize: 16, flex: 1, color: TEXT_INPUT_COLOR }}
                                        placeholder="Password"
                                        ref={refs["password"]}
                                        autoCorrect={false}
                                        enablesReturnKeyAutomatically={true}
                                        onChangeText={(value) => setPassword(value)}
                                        value={password}
                                        autoCapitalize="none"
                                        onSubmitEditing={onSubmitPassword}
                                        returnKeyType="next"
                                        placeholderTextColor={PLACEHOLDER_TEXT_INPUT_COLOR}
                                        secureTextEntry={true}
                                    />
                                }
                                    backgroundColor={CELL_BACKGROUND_COLOR}
                                />
                            </Section>
                            <Section header="CONFIRM PASSWORD">
                                <Cell cellContentView={
                                    <TextInput
                                        style={{ fontSize: 16, flex: 1, color: TEXT_INPUT_COLOR }}
                                        placeholder="Confirm Password"
                                        ref={refs["confirmPassword"]}
                                        autoCorrect={false}
                                        enablesReturnKeyAutomatically={true}
                                        onChangeText={(value) => setConfirmPassword(value)}
                                        value={confirmPassword}
                                        autoCapitalize="none"
                                        onSubmitEditing={onSubmitConfirmPassword}
                                        returnKeyType="go"
                                        placeholderTextColor={PLACEHOLDER_TEXT_INPUT_COLOR}
                                        secureTextEntry={true}
                                    />
                                }
                                    backgroundColor={CELL_BACKGROUND_COLOR}
                                />
                            </Section>
                        </View>

                        <View style={{ width: "60%", maxWidth: 300, marginTop: 20, justifyContent: "center" }}>
                            <Button
                                buttonStyle={{
                                    backgroundColor: BUTTON_COLOR_ONE
                                }}
                                onPress={signUserUp}
                                title='Sign Up!'
                            />
                        </View>
                        <View style={{ width: "60%", maxWidth: 300, marginTop: 40, justifyContent: "center", marginBottom: 50 }}>
                            <Button
                                buttonStyle={{
                                    backgroundColor: BUTTON_COLOR_ONE
                                }}
                                title="Go to Login"
                                onPress={() => navigation.navigate("Login")}
                            />
                        </View>
                    </View>
                </ScrollView>
            </View>
            <AlertModal
                modalVisible={alertModalVisibile}
                setModalVisible={setAlertModalVisible}
                alertTitleText={alertInfo.alertTitleText}
                alertSubtitleText={alertInfo.alertSubtitleText}
                alertPrimaryButtonText={alertInfo.alertPrimaryButtonText}
                alertPrimaryAction={alertInfo.alertPrimaryAction}
                alertSecondaryButtonText={alertInfo.alertSecondaryButtonText}
                alertSecondaryAction={alertInfo.alertSecondaryAction}
            />
        </View>
    );
}