import { View, ActivityIndicator, Image, useWindowDimensions } from 'react-native'
import { createStackNavigator } from '@react-navigation/stack'
import { NavigationContainer } from '@react-navigation/native'
import React, { useState, useEffect, useMemo } from 'react';
import firebase from "firebase/app"
import "firebase/auth"
import "firebase/firestore"
import "firebase/functions"
import { BACKGROUND_COLOR, DEV, HEADER_TEXT_COLOR } from './constants'
import Home from './app/components/Home';
import AccountCreationWelcome from './app/components/SignUp/AccountCreation/Welcome.js'
import AccountCreationContactInfo from './app/components/SignUp/AccountCreation/ContactInfo'
import AccountCreationDeliveryAddress from './app/components/SignUp/AccountCreation/DeliveryAddress'
import AccountCreationPickupDayTime from './app/components/SignUp/AccountCreation/PickupDayTime'
import AccountCreationLaundryPreferences from './app/components/SignUp/AccountCreation/LaundryPreferences'
import AccountCreationCompleted from './app/components/SignUp/AccountCreation/Completed'
import AuthContext from './AuthContext'
import MainPage from './app/components/Account/MainPage.js'
import ContactInfo from './app/components/Account/ContactInfo.js'
import ManageSubscription from './app/components/Account/ManageSubscription.js'
import DeliveryAddress from './app/components/Account/DeliveryAddress.js'
import UpcomingPickups from './app/components/Account/UpcomingPickups.js'
import PastPickups from './app/components/Account/PastPickups.js'
import PickupDetail from './app/components/Account/PickupDetail.js'
import ScheduleOptions from './app/components/SchedulePickups/ScheduleOptions'
import WashAndFoldSchedulePage from './app/components/SchedulePickups/WashAndFoldSchedulePage'
import WashAndFoldPickupDateTime from './app/components/SchedulePickups/WashAndFoldPickup/DateTime'
import WashAndFoldPickupDeliveryAddress from './app/components/SchedulePickups/WashAndFoldPickup/DeliveryAddress'
import WashAndFoldPickupContactInfo from './app/components/SchedulePickups/WashAndFoldPickup/ContactInfo'
import WashAndFoldPickupLaundryPreferences from './app/components/SchedulePickups/WashAndFoldPickup/LaundryPreferences'
import WashAndFoldPickupPayment from './app/components/SchedulePickups/WashAndFoldPickup/Payment'
import WashAndFoldPickupConfirmation from './app/components/SchedulePickups/WashAndFoldPickup/Confirmation'
import DryCleaningPickupDateTime from './app/components/SchedulePickups/DryCleaningPickup/DateTime'
import DryCleaningPickupDeliveryAddress from './app/components/SchedulePickups/DryCleaningPickup/DeliveryAddress'
import DryCleaningPickupContactInfo from './app/components/SchedulePickups/DryCleaningPickup/ContactInfo'
import DryCleaningPickupLaundryPreferences from './app/components/SchedulePickups/DryCleaningPickup/LaundryPreferences'
import DryCleaningPickupPayment from './app/components/SchedulePickups/DryCleaningPickup/Payment'
import DryCleaningPickupConfirmation from './app/components/SchedulePickups/DryCleaningPickup/Confirmation'
import PlanDayTime from './app/components/SchedulePickups/Plan/DayTime'
import PlanDeliveryAddress from './app/components/SchedulePickups/Plan/DeliveryAddress'
import PlanContactInfo from './app/components/SchedulePickups/Plan/ContactInfo'
import PlanLaundryPreferences from './app/components/SchedulePickups/Plan/LaundryPreferences';
import PlanPayment from './app/components/SchedulePickups/Plan/Payment'
import PlanConfirmation from './app/components/SchedulePickups/Plan/Confirmation'
import PushNotifications from './app/components/Account/PushNotifications.js'
import HouseholdSize from './app/components/Account/HouseholdSize.js'
import CleaningPreferences from './app/components/Account/CleaningPreferences.js'
import DeliveryPreferences from './app/components/Account/DeliveryPreferences.js'
import AdditionalInstructions from './app/components/Account/AdditionalInstructions.js'
import PickupDay from './app/components/Account/PickupDay.js'
import PickupTime from './app/components/Account/PickupTime.js'
import PaymentInfo from './app/components/Account/PaymentInfo.js'
import LoginInfo from './app/components/Account/LoginInfo.js'
import ChangeEmail from './app/components/Account/ChangeEmail.js'
import ChangePassword from './app/components/Account/ChangePassword.js'
import Rewards from './app/components/Rewards'
import Pricing from './app/components/Pricing'
import PricingDetail from './app/components/PricingDetail';
import PublicPricingDetail from './app/components/PublicPricingDetail';
import SignUp from './app/components/SignUp';
import Login from './app/components/Login';
import PublicHome from './app/components/PublicHome';
import PublicPricing from './app/components/PublicPricing';
import PublicContact from './app/components/PublicContact';
import Contact from './app/components/Contact';
import RewardsFAQ from './app/components/RewardsFAQ';
import UseRewards from './app/components/UseRewards';
import FAQs from './app/components/FAQ';
import PublicFAQ from './app/components/PublicFAQ';
import ConfirmUseRewards from './app/components/UseRewards/ConfirmUseRewards.js'

const Stack = createStackNavigator();

const firebaseConfig = {
    apiKey: "AIzaSyDGhmK4AFxlLOEsrkPpnFSC9txkjY0kiQo",
    authDomain: "tumbledelivery-45056.firebaseapp.com",
    databaseURL: "https://tumbledelivery-45056.firebaseio.com",
    projectId: "tumbledelivery-45056",
    storageBucket: "tumbledelivery-45056.appspot.com",
    messagingSenderId: "701924652329",
    appId: "1:701924652329:web:91b424460522a770bab338",
    measurementId: "G-FS7H1Y6DW9"
}

firebase.initializeApp(firebaseConfig)
if (DEV) {
    const origin = "localhost";
    firebase.auth().useEmulator(`http://${origin}:9099/`)
    firebase.firestore().useEmulator(origin, 8080)
    firebase.functions().useEmulator(origin, 5001)
}

export default function App({ navigation }) {

    let widthPercent = "70%";
    let marginPercent = "15%";
    const dimensions = useWindowDimensions();
    const [newAccount, setNewAccount] = useState(true)
    const [authenticated, setAuthenticated] = useState(false);
    const [loading, setLoading] = useState(true);
    const authContext = useMemo(() => ({
        finishNewAccountCreation: async () => {
            console.log("useMemo: finishNewAccountCreation")
            setLoading(true)
            setNewAccount(false)
            setLoading(false)
        },
    }), []);

    const makeId = length => {
        var result = '';
        var characters =
            'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for (var i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    };

    useEffect(() => {
        const sessionId = makeId(10)
        const websiteActivityNotification = firebase.functions().httpsCallable('websiteActivityNotification');
        var authUnsubscribe = firebase.auth().onAuthStateChanged(async (user) => {
            setLoading(true)
            if (user) {
                // signed in
                // check to see if new user or not
                const userInfo = (await firebase.firestore().collection("userProfiles").doc(user.uid).get()).data()
                if (userInfo) {
                    websiteActivityNotification({
                        sessionId: sessionId,
                        trafficType: 'authenticated_visit'
                    })
                    // existing user.  direct to homepage
                    // console.log("onAuthStateChanged: existing user logged in.  directing to homepage")
                    setNewAccount(false)
                    setAuthenticated(true)
                    setLoading(false)
                }
                else {
                    websiteActivityNotification({
                        sessionId: sessionId,
                        trafficType: 'new_account'
                    })
                    // console.log("onAuthStateChanged: new user logged in.  directing to accountcreation")
                    setAuthenticated(true)
                    setNewAccount(true)
                    setLoading(false)
                }
            }
            else {
                websiteActivityNotification({
                    sessionId: sessionId,
                    trafficType: 'public_visit'
                })
                // logged out
                // console.log('onAuthStateChanged: logged out.  directing to public homepage')
                setAuthenticated(false)
                setLoading(false)
            }
        })
        return () => {
            authUnsubscribe()
        }
    }, []);

    if (loading) {
        return (
            <View style={{ backgroundColor: BACKGROUND_COLOR, flex: 1 }}>
                <ActivityIndicator />
            </View>
        )
    }

    if (dimensions.width >= 1100) {
        widthPercent = 1000;
        marginPercent = (dimensions.width - 1000) / 2;
    }
    else if ((dimensions.width >= 500) && (dimensions.width < 1200)) {
        widthPercent = "90%";
        marginPercent = "5%";
    }
    else {
        widthPercent = "95%";
        marginPercent = "2.5%"
    }

    const publicLinking = {
        // config: {
        //     screens: {
        //         Login: "login",
        //         SignUp: "signup"
        //     },
        // },
        enabled: false,
    }

    const authenticatedLinking = {
        enabled: false,
    }

    if (authenticated) {
        return (
            <View style={{ flex: 1, backgroundColor: BACKGROUND_COLOR }}>
                <Image source="https://cdn.shopify.com/s/files/1/1368/1329/files/HANGING-3-0841.jpg?v=1535396014" style={{ width: "100%", height: "100%", zIndex: 1, position: "absolute" }} />
                {/* <View style={{ width:widthPercent, height: "0%", marginLeft:marginPercent, zIndex: 3, backgroundColor:"#DCDCDC", alignItems:"center" }}>
                    <Text style={{ fontSize:50 }}>Tumble Delivery</Text>
                </View> */}
                <View style={{ backgroundColor: 'rgba(20, 21, 24, .0)', zIndex: 1, position: "absolute", flex: 1, height: "100%", width: "100%" }}>
                    <View style={{ width: widthPercent, height: "100%", marginLeft: marginPercent, zIndex: 3, backgroundColor: 'transparent', position: "absolute" }}>
                        {/* <View style={{ width:"100%", height:"100%" }}> */}
                        <AuthContext.Provider value={authContext}>
                            <NavigationContainer linking={authenticatedLinking}>
                                {
                                    newAccount === true ? (
                                        <Stack.Navigator>
                                            <Stack.Screen name="Account Creation Welcome" component={AccountCreationWelcome} options={{ headerShown: true, animationEnabled: false, title: "New Account", headerStyle: { backgroundColor: BACKGROUND_COLOR }, headerTintColor: HEADER_TEXT_COLOR, headerTitleAlign: "center", }} />
                                            <Stack.Screen name="Account Creation Contact Info" component={AccountCreationContactInfo} options={{ headerShown: true, animationEnabled: false, title: "New Account", headerStyle: { backgroundColor: BACKGROUND_COLOR }, headerTintColor: HEADER_TEXT_COLOR, headerTitleAlign: "center", }} />
                                            <Stack.Screen name="Account Creation Delivery Address" component={AccountCreationDeliveryAddress} options={{ headerShown: true, animationEnabled: false, title: "New Account", headerStyle: { backgroundColor: BACKGROUND_COLOR }, headerTintColor: HEADER_TEXT_COLOR, headerTitleAlign: "center", }} />
                                            <Stack.Screen name="Account Creation Pickup Day Time" component={AccountCreationPickupDayTime} options={{ headerShown: true, animationEnabled: false, title: "New Account", headerStyle: { backgroundColor: BACKGROUND_COLOR }, headerTintColor: HEADER_TEXT_COLOR, headerTitleAlign: "center", }} />
                                            <Stack.Screen name="Account Creation Laundry Preferences" component={AccountCreationLaundryPreferences} options={{ headerShown: true, animationEnabled: false, title: "New Account", headerStyle: { backgroundColor: BACKGROUND_COLOR }, headerTintColor: HEADER_TEXT_COLOR, headerTitleAlign: "center", }} />
                                            <Stack.Screen name="Account Creation Completed" component={AccountCreationCompleted} options={{ headerShown: true, animationEnabled: false, title: "New Account", headerStyle: { backgroundColor: BACKGROUND_COLOR }, headerTintColor: HEADER_TEXT_COLOR, headerTitleAlign: "center", }} />
                                        </Stack.Navigator>
                                    ) : (
                                        <Stack.Navigator screenOptions={{ cardStyle: { backgroundColor: 'rgba(20, 21, 24, .0)' } }}>
                                            <Stack.Screen name="Home" component={Home} options={{ headerShown: false, animationEnabled: false, }} />
                                            <Stack.Screen name="Wash and Fold Schedule Page" component={WashAndFoldSchedulePage} options={{ headerShown: true, headerTitleAlign: 'center', gestureEnabled: false, animationEnabled: false, title: "Schedule Options", headerStyle: { backgroundColor: BACKGROUND_COLOR }, headerTintColor: HEADER_TEXT_COLOR }} />
                                            <Stack.Screen name="Schedule Options" component={ScheduleOptions} options={{ headerShown: true, headerTitleAlign: 'center', gestureEnabled: false, animationEnabled: false, title: "Schedule Options", headerStyle: { backgroundColor: BACKGROUND_COLOR }, headerTintColor: HEADER_TEXT_COLOR }} />
                                            <Stack.Screen name="Wash and Fold Pickup Date Time" component={WashAndFoldPickupDateTime} options={{ headerShown: true, animationEnabled: false, headerStyle: { backgroundColor: BACKGROUND_COLOR }, headerTitle: "Date & Time", headerTintColor: HEADER_TEXT_COLOR, headerTitleAlign: "center", }} />
                                            <Stack.Screen name="Wash and Fold Pickup Delivery Address" component={WashAndFoldPickupDeliveryAddress} options={{ headerShown: true, animationEnabled: false, headerStyle: { backgroundColor: BACKGROUND_COLOR }, headerTitle: "Delivery Address", headerTintColor: HEADER_TEXT_COLOR, headerTitleAlign: "center", }} />
                                            <Stack.Screen name="Wash and Fold Pickup Contact Info" component={WashAndFoldPickupContactInfo} options={{ headerShown: true, animationEnabled: false, headerStyle: { backgroundColor: BACKGROUND_COLOR }, headerTitle: "Contact Info", headerTintColor: HEADER_TEXT_COLOR, headerTitleAlign: "center", }} />
                                            <Stack.Screen name="Wash and Fold Pickup Laundry Preferences" component={WashAndFoldPickupLaundryPreferences} options={{ headerShown: true, animationEnabled: false, headerStyle: { backgroundColor: BACKGROUND_COLOR }, headerTitle: "Laundry Preferences", headerTintColor: HEADER_TEXT_COLOR, headerTitleAlign: "center", }} />
                                            <Stack.Screen name="Wash and Fold Pickup Payment" component={WashAndFoldPickupPayment} options={{ headerShown: true, animationEnabled: false, headerStyle: { backgroundColor: BACKGROUND_COLOR }, headerTitle: "Payment", headerTintColor: HEADER_TEXT_COLOR, headerTitleAlign: "center", }} />
                                            <Stack.Screen name="Wash and Fold Pickup Confirmation" component={WashAndFoldPickupConfirmation} options={{ headerShown: true, animationEnabled: false, headerStyle: { backgroundColor: BACKGROUND_COLOR }, headerTitle: "Confirmation", headerTintColor: HEADER_TEXT_COLOR, headerTitleAlign: "center", }} />
                                            <Stack.Screen name="Dry Cleaning Pickup Date Time" component={DryCleaningPickupDateTime} options={{ headerShown: true, animationEnabled: false, headerStyle: { backgroundColor: BACKGROUND_COLOR }, headerTitle: "Date & Time", headerTintColor: HEADER_TEXT_COLOR, headerTitleAlign: "center", }} />
                                            <Stack.Screen name="Dry Cleaning Pickup Delivery Address" component={DryCleaningPickupDeliveryAddress} options={{ headerShown: true, animationEnabled: false, headerStyle: { backgroundColor: BACKGROUND_COLOR }, headerTitle: "Delivery Address", headerTintColor: HEADER_TEXT_COLOR, headerTitleAlign: "center", }} />
                                            <Stack.Screen name="Dry Cleaning Pickup Contact Info" component={DryCleaningPickupContactInfo} options={{ headerShown: true, animationEnabled: false, headerStyle: { backgroundColor: BACKGROUND_COLOR }, headerTitle: "Contact Info", headerTintColor: HEADER_TEXT_COLOR, headerTitleAlign: "center", }} />
                                            <Stack.Screen name="Dry Cleaning Pickup Laundry Preferences" component={DryCleaningPickupLaundryPreferences} options={{ headerShown: true, animationEnabled: false, headerStyle: { backgroundColor: BACKGROUND_COLOR }, headerTitle: "Laundry Preferences", headerTintColor: HEADER_TEXT_COLOR, headerTitleAlign: "center", }} />
                                            <Stack.Screen name="Dry Cleaning Pickup Payment" component={DryCleaningPickupPayment} options={{ headerShown: true, animationEnabled: false, headerStyle: { backgroundColor: BACKGROUND_COLOR }, headerTitle: "Payment", headerTintColor: HEADER_TEXT_COLOR, headerTitleAlign: "center", }} />
                                            <Stack.Screen name="Dry Cleaning Pickup Confirmation" component={DryCleaningPickupConfirmation} options={{ headerShown: true, animationEnabled: false, headerStyle: { backgroundColor: BACKGROUND_COLOR }, headerTitle: "Confirmation", headerTintColor: HEADER_TEXT_COLOR, headerTitleAlign: "center", }} />
                                            <Stack.Screen name="Plan Day Time" component={PlanDayTime} options={{ headerShown: true, animationEnabled: false, headerStyle: { backgroundColor: BACKGROUND_COLOR }, headerTitle: "Day & Time", headerTintColor: HEADER_TEXT_COLOR, headerTitleAlign: "center", }} />
                                            <Stack.Screen name="Plan Delivery Address" component={PlanDeliveryAddress} options={{ headerShown: true, animationEnabled: false, headerStyle: { backgroundColor: BACKGROUND_COLOR }, headerTitle: "Delivery Address", headerTintColor: HEADER_TEXT_COLOR, headerTitleAlign: "center", }} />
                                            <Stack.Screen name="Plan Contact Info" component={PlanContactInfo} options={{ headerShown: true, animationEnabled: false, headerStyle: { backgroundColor: BACKGROUND_COLOR }, headerTitle: "Contact Info", headerTintColor: HEADER_TEXT_COLOR, headerTitleAlign: "center", }} />
                                            <Stack.Screen name="Plan Laundry Preferences" component={PlanLaundryPreferences} options={{ headerShown: true, animationEnabled: false, headerStyle: { backgroundColor: BACKGROUND_COLOR }, headerTitle: "Laundry Preferences", headerTintColor: HEADER_TEXT_COLOR, headerTitleAlign: "center", }} />
                                            <Stack.Screen name="Plan Payment" component={PlanPayment} options={{ headerShown: true, animationEnabled: false, headerStyle: { backgroundColor: BACKGROUND_COLOR }, headerTitle: "Payment", headerTintColor: HEADER_TEXT_COLOR, headerTitleAlign: "center", }} />
                                            <Stack.Screen name="Plan Confirmation" component={PlanConfirmation} options={{ headerShown: true, animationEnabled: false, headerStyle: { backgroundColor: BACKGROUND_COLOR }, headerTitle: "Confirmation", headerTintColor: HEADER_TEXT_COLOR, headerTitleAlign: "center", }} />
                                            <Stack.Screen name="Account" component={MainPage} options={{ headerShown: false, title: "Account" }} />
                                            <Stack.Screen name="Rewards" component={Rewards} options={{ headerShown: false, title: "Rewards" }} />
                                            <Stack.Screen name="Contact" component={Contact} options={{ headerShown: false, title: "Contact" }} />
                                            <Stack.Screen name="FAQs" component={FAQs} options={{ headerShown: true, headerTitleAlign: 'center', gestureEnabled: false, animationEnabled: false, title: "FAQs", headerStyle: { backgroundColor: BACKGROUND_COLOR }, headerTintColor: HEADER_TEXT_COLOR }} />
                                            <Stack.Screen name="Upcoming" component={UpcomingPickups} options={{ headerShown: true, animationEnabled: false, headerStyle: { backgroundColor: BACKGROUND_COLOR }, headerTintColor: HEADER_TEXT_COLOR, headerTitleAlign: "center", }} />
                                            <Stack.Screen name="Past" component={PastPickups} options={{ headerShown: true, animationEnabled: false, headerStyle: { backgroundColor: BACKGROUND_COLOR }, headerTintColor: HEADER_TEXT_COLOR, headerTitleAlign: "center", }} />
                                            <Stack.Screen name="Pickup Detail" component={PickupDetail} options={{ headerShown: true, animationEnabled: false, headerStyle: { backgroundColor: BACKGROUND_COLOR }, headerTintColor: HEADER_TEXT_COLOR, headerTitleAlign: "center", }} />
                                            <Stack.Screen name="Pricing Detail" component={PricingDetail} options={{ headerShown: true, headerTitleAlign: 'center', gestureEnabled: false, animationEnabled: false, title: "More Info", headerStyle: { backgroundColor: BACKGROUND_COLOR }, headerTintColor: HEADER_TEXT_COLOR }} />
                                            <Stack.Screen name="Rewards FAQs" component={RewardsFAQ} options={{ headerShown: true, headerTitleAlign: 'center', gestureEnabled: false, animationEnabled: false, title: "Rewards FAQs", headerStyle: { backgroundColor: BACKGROUND_COLOR }, headerTintColor: HEADER_TEXT_COLOR }} />
                                            <Stack.Screen name="Use Rewards" component={UseRewards} options={{ headerShown: true, headerTitleAlign: 'center', gestureEnabled: false, animationEnabled: false, title: "Use Rewards", headerStyle: { backgroundColor: BACKGROUND_COLOR }, headerTintColor: HEADER_TEXT_COLOR }} />
                                            <Stack.Screen name="Confirm Use Rewards" component={ConfirmUseRewards} options={{ headerShown: true, headerTitleAlign: 'center', gestureEnabled: false, animationEnabled: false, title: "Use Rewards", headerStyle: { backgroundColor: BACKGROUND_COLOR }, headerTintColor: HEADER_TEXT_COLOR }} />
                                            <Stack.Screen name="Contact Info" component={ContactInfo} options={{ headerShown: true, animationEnabled: false, headerStyle: { backgroundColor: BACKGROUND_COLOR }, headerTintColor: HEADER_TEXT_COLOR, headerTitleAlign: "center", }} />
                                            <Stack.Screen name="Manage Subscription" component={ManageSubscription} options={{ headerShown: true, animationEnabled: false, headerStyle: { backgroundColor: BACKGROUND_COLOR }, headerTintColor: HEADER_TEXT_COLOR, headerTitleAlign: "center", }} />
                                            <Stack.Screen name="Delivery Address" component={DeliveryAddress} options={{ headerShown: true, animationEnabled: false, headerStyle: { backgroundColor: BACKGROUND_COLOR }, headerTintColor: HEADER_TEXT_COLOR, headerTitleAlign: "center", }} />
                                            <Stack.Screen name="Push Notifications" component={PushNotifications} options={{ headerShown: true, animationEnabled: false, headerStyle: { backgroundColor: BACKGROUND_COLOR }, headerTintColor: HEADER_TEXT_COLOR, headerTitleAlign: "center", }} />
                                            <Stack.Screen name="Household Size" component={HouseholdSize} options={{ headerShown: true, animationEnabled: false, headerStyle: { backgroundColor: BACKGROUND_COLOR }, headerTintColor: HEADER_TEXT_COLOR, headerTitleAlign: "center", }} />
                                            <Stack.Screen name="Login Info" component={LoginInfo} options={{ headerShown: true, animationEnabled: false, headerStyle: { backgroundColor: BACKGROUND_COLOR }, headerTintColor: HEADER_TEXT_COLOR, headerTitleAlign: "center", }} />
                                            <Stack.Screen name="Change Email" component={ChangeEmail} options={{ headerShown: true, animationEnabled: false, headerStyle: { backgroundColor: BACKGROUND_COLOR }, headerTintColor: HEADER_TEXT_COLOR, headerTitleAlign: "center", }} />
                                            <Stack.Screen name="Change Password" component={ChangePassword} options={{ headerShown: true, animationEnabled: false, headerStyle: { backgroundColor: BACKGROUND_COLOR }, headerTintColor: HEADER_TEXT_COLOR, headerTitleAlign: "center", }} />
                                            <Stack.Screen name="Laundry" component={CleaningPreferences} options={{ headerShown: true, animationEnabled: false, headerStyle: { backgroundColor: BACKGROUND_COLOR }, headerTintColor: HEADER_TEXT_COLOR, headerTitleAlign: "center", }} />
                                            <Stack.Screen name="Delivery" component={DeliveryPreferences} options={{ headerShown: true, animationEnabled: false, headerStyle: { backgroundColor: BACKGROUND_COLOR }, headerTintColor: HEADER_TEXT_COLOR, headerTitleAlign: "center", }} />
                                            <Stack.Screen name="Instructions" component={AdditionalInstructions} options={{ headerShown: true, animationEnabled: false, headerStyle: { backgroundColor: BACKGROUND_COLOR }, headerTintColor: HEADER_TEXT_COLOR, headerTitleAlign: "center", }} />
                                            <Stack.Screen name="Pickup Day" component={PickupDay} options={{ headerShown: true, animationEnabled: false, headerStyle: { backgroundColor: BACKGROUND_COLOR }, headerTintColor: HEADER_TEXT_COLOR, headerTitleAlign: "center", }} />
                                            <Stack.Screen name="Pickup Time" component={PickupTime} options={{ headerShown: true, animationEnabled: false, headerStyle: { backgroundColor: BACKGROUND_COLOR }, headerTintColor: HEADER_TEXT_COLOR, headerTitleAlign: "center", }} />
                                            <Stack.Screen name="Payment" component={PaymentInfo} options={{ headerShown: true, animationEnabled: false, headerStyle: { backgroundColor: BACKGROUND_COLOR }, headerTintColor: HEADER_TEXT_COLOR, headerTitleAlign: "center", }} />
                                            <Stack.Screen name="Pricing" component={Pricing} options={{ headerShown: false, animationEnabled: false, headerStyle: { backgroundColor: BACKGROUND_COLOR }, headerTintColor: HEADER_TEXT_COLOR, headerTitleAlign: "center", }} />
                                        </Stack.Navigator>
                                    )
                                }
                            </NavigationContainer>
                        </AuthContext.Provider>
                    </View>
                </View>
            </View>
        )
    }
    else {
        return (
            <View style={{ flex: 1, backgroundColor: BACKGROUND_COLOR }}>
                <Image source="https://cdn.shopify.com/s/files/1/1368/1329/files/HANGING-3-0841.jpg?v=1535396014" style={{ width: "100%", height: "100%", zIndex: 1, position: "absolute" }} />
                {/* <View style={{ width:widthPercent, height: "0%", marginLeft:marginPercent, zIndex: 3, backgroundColor:"#DCDCDC", alignItems:"center" }}>
                    <Text style={{ fontSize:50 }}>Tumble Delivery</Text>
                </View> */}
                <View style={{ width: "100%", height: "100%", zIndex: 3, backgroundColor: 'transparent', position: "absolute" }}>
                    {/* <View style={{ width:"100%", height:"100%" }}> */}
                    <AuthContext.Provider value={authContext}>
                        <NavigationContainer linking={publicLinking}>
                            <Stack.Navigator>
                                {/* <Stack.Screen name="PublicHome" component={PublicHome} options={{ headerShown: false, title: "Home", cardStyle: { backgroundColor: 'rgba(20, 21, 24, .0)' } }} />
                                <Stack.Screen name="PublicPricing" component={PublicPricing} options={{ headerShown: false, title: "Pricing", cardStyle: { backgroundColor: 'rgba(20, 21, 24, .0)' } }} />
                                <Stack.Screen name="Public Pricing Detail" component={PublicPricingDetail} options={{ gestureEnabled: false, animationEnabled: false, headerShown: false, title: "Pricing Info", cardStyle: { backgroundColor: 'rgba(20, 21, 24, .0)' } }} />
                                <Stack.Screen name="PublicContact" component={PublicContact} options={{ headerShown: false, title: "Contact", cardStyle: { backgroundColor: 'rgba(20, 21, 24, .0)' } }} />
                                <Stack.Screen name="PublicFAQ" component={PublicFAQ} options={{ headerShown: false, title: "Contact", cardStyle: { backgroundColor: 'rgba(20, 21, 24, .0)' } }} /> */}
                                <Stack.Screen name="Login" component={Login} options={{ headerShown: false, title: "Login", cardStyle: { backgroundColor: 'rgba(20, 21, 24, .0)' } }} />
                                <Stack.Screen name="SignUp" component={SignUp} options={{ headerShown: false, title: "Sign Up", cardStyle: { backgroundColor: 'rgba(20, 21, 24, .0)' } }} />
                            </Stack.Navigator>
                        </NavigationContainer>
                    </AuthContext.Provider>
                </View>
            </View>
        )
    }
}