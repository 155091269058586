import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
import {
    ActivityIndicator,
    View,
    ScrollView,
    Dimensions,
    TextInput,
    Text
} from 'react-native';
import { Button } from 'react-native-elements';
import styles from './styles';
import { Cell, Section } from 'react-native-tableview-simple';
import firebase from "firebase/app"
import "firebase/auth"
import "firebase/firestore"
import "firebase/functions"
import { CELL_BACKGROUND_COLOR, SEPARATOR_TINT_COLOR_TWO, FOOTER_TEXT_COLOR, CELL_TITLE_TEXT_COLOR, DISABLED_TEXT_INPUT_COLOR, HEADER_TEXT_COLOR, PLACEHOLDER_TEXT_INPUT_COLOR, TEXT_INPUT_COLOR, BUTTON_COLOR_TWO, CONNECTION_ERROR_HEADER, CONNECTION_ERROR_MESSAGE, CONNECTION_ERROR_BUTTON } from '../../../../constants';
import { HeaderBackButton } from '@react-navigation/stack';
import AlertModal from '../../AlertModal';


export default function LaundryPreferences({ route, navigation }) {

    useLayoutEffect(() => {
        navigation.setOptions({
            headerRight: () => (
                <Button
                    onPress={cancelPickup}
                    title="Cancel"
                    type="clear"
                    titleStyle={{
                        fontWeight: "normal",
                        color: HEADER_TEXT_COLOR
                    }}
                    buttonStyle={{
                        marginRight: 10,
                    }}
                    disabled={pageLoading}
                />
            ),
            headerLeft: () => (
                <HeaderBackButton
                    onPress={() => navigation.goBack()}
                    disabled={pageLoading}
                    tintColor={HEADER_TEXT_COLOR}
                />
            )
        })
    })

    const { pickupDay, pickupTime, pickupDate } = route.params

    const [message1, setMessage1] = useState('')
    const [message2, setMessage2] = useState('')
    const [pageLoading, setPageLoading] = useState(true)
    const [detergents, setDetergents] = useState([])
    const [dryerSheets, setDryerSheets] = useState([])
    const [fabricSofteners, setFabricSofteners] = useState([])
    const [detergent, setDetergent] = useState("Fresh Linen Scent")
    const [originalDetergent, setOriginalDetergent] = useState("Fresh Linen Scent")
    const [fabricSoftener, setFabricSoftener] = useState("Fresh Linen Scent")
    const [originalFabricSoftener, setOriginalFabricSoftener] = useState("Fresh Linen Scent")
    const [dryerSheet, setDryerSheet] = useState("Fresh Linen Scent")
    const [originalDryerSheet, setOriginalDryerSheet] = useState("Fresh Linen Scent")
    const [detergentAccessory, setDetergentAccessory] = useState({})
    const [fabricSoftenerAccessory, setFabricSoftenerAccessory] = useState({})
    const [dryerSheetAccessory, setDryerSheetAccessory] = useState({})
    const [alertModalVisibile, setAlertModalVisible] = useState(false);
    const [alertInfo, setAlertInfo] = useState({})
    const openAlertModal = (title, subtitle, primaryButtonText, primaryButtonAction, secondaryButtonText = null, secondaryButtonAction = null) => {
        setAlertInfo({
            alertTitleText: title,
            alertSubtitleText: subtitle,
            alertPrimaryButtonText: primaryButtonText,
            alertPrimaryAction: () => primaryButtonAction(),
            alertSecondaryButtonText: secondaryButtonText,
            alertSecondaryAction: () => secondaryButtonAction()
        })
        setAlertModalVisible(true)
    }
    const closeAlertModal = () => {
        setAlertModalVisible(false)
    }

    const cancelPickup = () => {
        setPageLoading(false)
        navigation.navigate("Home")
    }

    const selectDetergent = (scent, accessoryParam = null) => {
        var accessoryLocal = accessoryParam
        if (!accessoryLocal) {
            accessoryLocal = detergentAccessory
        }
        setDetergent(scent)
        for (var key in accessoryLocal) {
            if (key == scent) {
                accessoryLocal[key] = "Checkmark"
            }
            else {
                accessoryLocal[key] = "none"
            }
        }
        setDetergentAccessory(accessoryLocal)
    }

    const selectDryerSheet = (scent, accessoryParam = null) => {
        var accessoryLocal = accessoryParam
        if (!accessoryLocal) {
            accessoryLocal = dryerSheetAccessory
        }
        setDryerSheet(scent)
        for (var key in accessoryLocal) {
            if (key == scent) {
                accessoryLocal[key] = "Checkmark"
            }
            else {
                accessoryLocal[key] = "none"
            }
        }
        setDryerSheetAccessory(accessoryLocal)
    }

    const selectFabricSoftenter = (scent, accessoryParam = null) => {
        var accessoryLocal = accessoryParam
        if (!accessoryLocal) {
            accessoryLocal = fabricSoftenerAccessory
        }
        setFabricSoftener(scent)
        for (var key in accessoryLocal) {
            if (key == scent) {
                accessoryLocal[key] = "Checkmark"
            }
            else {
                accessoryLocal[key] = "none"
            }
        }
        setFabricSoftenerAccessory(accessoryLocal)
    }

    const getPageAndUserInfo = async () => {
        setPageLoading(true)
        try {
            const laundryDoc = await firebase.firestore().collection('pageInfo').doc('laundry').get()
            var detergentsLocal = []
            var dryerSheetsLocal = []
            var fabricSoftenersLocal = []
            var detergentAccessoryLocal = {}
            var dryerSheetAccessoryLocal = {}
            var fabricSoftenerAccessoryLocal = {}
            if (laundryDoc.data() && laundryDoc.data().detergent && laundryDoc.data().dryerSheet && laundryDoc.data().fabricSoftener) {
                detergentsLocal = laundryDoc.data().detergent
                dryerSheetsLocal = laundryDoc.data().dryerSheet
                fabricSoftenersLocal = laundryDoc.data().fabricSoftener
                var index = 0
                detergentsLocal.forEach((detergentLocal) => {
                    if (index === 0) {
                        setDetergent(detergent)
                        detergentAccessoryLocal[detergentLocal] = "Checkmark"
                        index++
                    }
                    else {
                        detergentAccessoryLocal[detergentLocal] = "none"
                    }
                })
                index = 0
                dryerSheetsLocal.forEach((dryerSheetLocal) => {
                    if (index === 0) {
                        setDryerSheet(dryerSheetLocal)
                        dryerSheetAccessoryLocal[dryerSheetLocal] = "Checkmark"
                        index++
                    }
                    else {
                        dryerSheetAccessoryLocal[dryerSheetLocal] = "none"
                    }
                })
                index = 0
                fabricSoftenersLocal.forEach((fabricSoftenerLocal) => {
                    if (index === 0) {
                        setFabricSoftener(fabricSoftenerLocal)
                        fabricSoftenerAccessoryLocal[fabricSoftenerLocal] = "Checkmark"
                        index++
                    }
                    else {
                        fabricSoftenerAccessoryLocal[fabricSoftenerLocal] = "none"
                    }
                })
                setDetergentAccessory(detergentAccessoryLocal)
                setDryerSheetAccessory(dryerSheetAccessoryLocal)
                setFabricSoftenerAccessory(fabricSoftenerAccessoryLocal)
                setDetergents(detergentsLocal)
                setDryerSheets(dryerSheetsLocal)
                setFabricSofteners(fabricSoftenersLocal)
            }
            else {
                setPageLoading(false)
                openAlertModal(CONNECTION_ERROR_HEADER, CONNECTION_ERROR_MESSAGE, CONNECTION_ERROR_BUTTON, getPageAndUserInfo)
                return
            }

            // get page info
            const pageInfo = (await firebase.firestore().collection('pageInfo').doc('WashAndFoldPickup').collection('pages').doc('LaundryPreferences').get()).data();
            if (pageInfo) {
                if (pageInfo.message1) {
                    setMessage1(pageInfo.message1)
                }
                if (pageInfo.message2) {
                    setMessage2(pageInfo.message2)
                }
            }
            else {
                setPageLoading(false)
                openAlertModal(CONNECTION_ERROR_HEADER, CONNECTION_ERROR_MESSAGE, CONNECTION_ERROR_BUTTON, cancelPlan)
                return
            }

            // get user info
            const user = firebase.auth().currentUser;
            const userInfo = (await firebase.firestore().collection("userProfiles").doc(user.uid).get()).data();
            if (userInfo) {
                if (userInfo.detergent && userInfo.dryerSheet && userInfo.fabricSoftener) {
                    selectDetergent(userInfo.detergent, detergentAccessoryLocal)
                    selectDryerSheet(userInfo.dryerSheet, dryerSheetAccessoryLocal)
                    selectFabricSoftenter(userInfo.fabricSoftener, fabricSoftenerAccessoryLocal)
                }
            }
            else {
                console.log('Error: no account for this user')
                setPageLoading(false)
                openAlertModal('Error', 'We do not have a profile created for you.  Please logout and login again.', 'Log Out', logout)
                return
            }
            setPageLoading(false)
        }
        catch (err) {
            console.log('error:', err)
            setPageLoading(false)
            openAlertModal(CONNECTION_ERROR_HEADER, CONNECTION_ERROR_MESSAGE, CONNECTION_ERROR_BUTTON, getPageAndUserInfo)
            return
        }
    }

    useEffect(() => {
        getPageAndUserInfo()
    }, []);

    const nextPage = async () => {
        setPageLoading(true)
        try {
            if (originalDetergent !== detergent || originalDryerSheet !== dryerSheet || originalFabricSoftener !== fabricSoftener) {
                const user = firebase.auth().currentUser;
                const updateUserLaundryInfo = firebase.functions().httpsCallable('updateUserLaundryInfo');
                const updateDict = {
                    detergent: detergent,
                    dryerSheet: dryerSheet,
                    fabricSoftener: fabricSoftener
                }
                const response = await updateUserLaundryInfo({
                    userId: user.uid,
                    updateDict: updateDict,
                    updateContext: 'new_plan'
                })
                if (response.data.success) {
                    setPageLoading(false)
                    navigation.navigate("Wash and Fold Pickup Payment", {
                        pickupDay: pickupDay,
                        pickupTime: pickupTime,
                        pickupDate: pickupDate,
                    })
                    return
                }
                else {
                    if (response.data.message) {
                        setPageLoading(false)
                        openAlertModal('Error', response.data.message, 'Ok', closeAlertModal)
                        return
                    }
                    else {
                        setPageLoading(false)
                        openAlertModal(CONNECTION_ERROR_HEADER, CONNECTION_ERROR_MESSAGE, CONNECTION_ERROR_BUTTON, closeAlertModal)
                        return
                    }

                }
            }
            else {
                setPageLoading(false)
                navigation.navigate("Wash and Fold Pickup Payment", {
                    pickupDay: pickupDay,
                    pickupTime: pickupTime,
                    pickupDate: pickupDate,
                })
                return
            }
        }
        catch (err) {
            console.log('error updating preferred pickup day and time', err)
            setPageLoading(false)
            openAlertModal(CONNECTION_ERROR_HEADER, CONNECTION_ERROR_MESSAGE, CONNECTION_ERROR_BUTTON, closeAlertModal)
            return
        }
    }

    const createDetergentTable = () => {
        var valueArray = []
        var index = 0
        detergents.forEach((detergent) => {
            valueArray.push(
                <Cell key={index} cellStyle="Basic" backgroundColor={CELL_BACKGROUND_COLOR} titleTextColor={CELL_TITLE_TEXT_COLOR} title={detergent} accessory={detergentAccessory[detergent]} onPress={() => selectDetergent(detergent)} />
            )
            index++
        })
        return valueArray
    }

    const createFabricSoftenerTable = () => {
        var valueArray = []
        var index = 0
        fabricSofteners.forEach((fabricSoftener) => {
            valueArray.push(
                <Cell key={index} cellStyle="Basic" backgroundColor={CELL_BACKGROUND_COLOR} titleTextColor={CELL_TITLE_TEXT_COLOR} title={fabricSoftener} accessory={fabricSoftenerAccessory[fabricSoftener]} onPress={() => selectFabricSoftenter(fabricSoftener)} />
            )
            index++
        })
        return valueArray
    }

    const createDryerSheetTable = () => {
        var valueArray = []
        var index = 0
        dryerSheets.forEach((dryerSheet) => {
            valueArray.push(
                <Cell key={index} cellStyle="Basic" backgroundColor={CELL_BACKGROUND_COLOR} titleTextColor={CELL_TITLE_TEXT_COLOR} title={dryerSheet} accessory={dryerSheetAccessory[dryerSheet]} onPress={() => selectDryerSheet(dryerSheet)} />
            )
            index++
        })
        return valueArray
    }

    if (pageLoading) {
        return (
            <View style={styles.parent}>
                <ActivityIndicator />
            </View>
        )
    }

    return (
        <View style={styles.parent}>
            <ScrollView>
                <View style={{ marginLeft: 10, marginRight: 10, marginTop: 10, marginBottom: 10 }}>
                    <Text style={styles.subsubheading}>{message1}</Text>
                </View>
                <Section headerTextColor={HEADER_TEXT_COLOR} footerTextColor={FOOTER_TEXT_COLOR} separatorTintColor={SEPARATOR_TINT_COLOR_TWO} header="DETERGENT" footer="">
                    {createDetergentTable()}
                </Section>
                <Section headerTextColor={HEADER_TEXT_COLOR} footerTextColor={FOOTER_TEXT_COLOR} separatorTintColor={SEPARATOR_TINT_COLOR_TWO} header="FABRIC SOFTENER" footer="">
                    {createFabricSoftenerTable()}
                </Section>
                <Section headerTextColor={HEADER_TEXT_COLOR} footerTextColor={FOOTER_TEXT_COLOR} separatorTintColor={SEPARATOR_TINT_COLOR_TWO} header="DRYER SHEET" footer="">
                    {createDryerSheetTable()}
                </Section>
                <View style={{ marginLeft: 10, marginRight: 10, marginTop: 10, marginBottom: 10 }}>
                    <Text style={styles.subsubheading}>{message2}</Text>
                </View>
                <View style={{ height: 50, width: "100%", marginTop: 40, alignItems: 'center', marginBottom: 50 }}>
                    <View style={{ width: "70%", maxWidth: 250, minWidth: 200 }} >
                        <Button
                            onPress={nextPage}
                            title='Continue'
                            buttonStyle={{
                                backgroundColor: BUTTON_COLOR_TWO
                            }}
                        />
                    </View>
                </View>
            </ScrollView>
            <AlertModal
                modalVisible={alertModalVisibile}
                setModalVisible={setAlertModalVisible}
                alertTitleText={alertInfo.alertTitleText}
                alertSubtitleText={alertInfo.alertSubtitleText}
                alertPrimaryButtonText={alertInfo.alertPrimaryButtonText}
                alertPrimaryAction={alertInfo.alertPrimaryAction}
                alertSecondaryButtonText={alertInfo.alertSecondaryButtonText}
                alertSecondaryAction={alertInfo.alertSecondaryAction}
            />
        </View>
    );
}