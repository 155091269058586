import React, { useEffect, useState, useRef, useLayoutEffect } from 'react';
import {
    View,
    ScrollView,
    TextInput,
    ActivityIndicator,
    BackHandler,
} from 'react-native';
import { Button } from 'react-native-elements';
import styles from './styles';
import { Cell, Section } from 'react-native-tableview-simple';
import firebase from "firebase/app"
import "firebase/auth"
import "firebase/firestore"
import "firebase/functions"
import { HeaderBackButton } from '@react-navigation/stack'
import { BACKGROUND_COLOR, DISABLED_TEXT_INPUT_COLOR, HEADER_TEXT_COLOR, PLACEHOLDER_TEXT_INPUT_COLOR, TEXT_COLOR_THREE, TEXT_INPUT_COLOR } from '../../../constants';


export default function DeliveryAddress({ navigation }) {
    const [additionalInstructions, setAdditionalInstructions] = useState("")
    const [originalAdditionalInstructions] = useState("")
    const [pageLoading, setPageLoading] = useState(true)
    const [editMode, setEditMode] = useState(false)
    const [defaultTextColor, setDefaultTextColor] = useState(DISABLED_TEXT_INPUT_COLOR)
    const [initialPageLoading, setInitialPageLoading] = useState(true)

    useLayoutEffect(() => {
        navigation.setOptions({
            headerRight: () => {
                if (editMode) {
                    return <Button
                        onPress={() => checkUserInfo()}
                        title="Done"
                        type="clear"
                        titleStyle={{
                            fontWeight: "bold"
                        }}
                        buttonStyle={{
                            marginRight: 10
                        }}
                    />
                }
                else {
                    return <Button
                        onPress={() => startEditing()}
                        title="Edit"
                        type="clear"
                        titleStyle={{
                            fontWeight: "normal"
                        }}
                        buttonStyle={{
                            marginRight: 10
                        }}
                        disabled={editButtonDisabled()}
                    />
                }
            },
            headerLeft: () => {
                if (editMode) {
                    return <Button
                        onPress={() => cancelEditing()}
                        title="Cancel"
                        type="clear"
                        titleStyle={{
                            fontWeight: "normal"
                        }}
                        buttonStyle={{
                            marginLeft: 10
                        }}
                    />
                }
                else {
                    return <HeaderBackButton
                        label="Back"
                        tintColor={TEXT_COLOR_THREE}
                        disabled={backButtonDisabled()}
                        onPress={() => navigation.goBack()}
                    />
                }

            },
        })
    })

    const backButtonDisabled = () => {
        return !initialPageLoading && pageLoading
    }

    const editButtonDisabled = () => {
        return pageLoading
    }

    const cancelEditing = () => {
        getUserInfo()
        setEditMode(false)
        setDefaultTextColor(DISABLED_TEXT_INPUT_COLOR)
    }

    const startEditing = () => {
        setEditMode(true)
        setDefaultTextColor(TEXT_INPUT_COLOR)
    }

    const refs = {
        "additionalInstructions": useRef(),
    }

    async function getUserInfo() {
        const user = firebase.auth().currentUser;
        // console.log(user);
        const userInfo = (await firebase.firestore().collection("userProfiles").doc(user.uid).get()).data();
        if (userInfo) {
            setAdditionalInstructions(userInfo.additionalInstructions)
        }
        setPageLoading(false);
        setInitialPageLoading(false);
    }

    useEffect(() => {
        getUserInfo();
        BackHandler.addEventListener('hardwareBackPress', onBackPress);
        return () => {
            BackHandler.removeEventListener('hardwareBackPress', onBackPress);
        }
    }, []);

    const onBackPress = () => {
        return true;
    }

    const onSubmitAdditionalInstructions = () => {
        refs['additionalInstructions'].current.blur();
        checkUserInfo();
    }

    const checkUserInfo = async () => {
        let valid = true;

        if (valid) {
            const user = firebase.auth().currentUser;
            let update_dict = {};
            if (additionalInstructions && (additionalInstructions != originalAdditionalInstructions)) {
                update_dict.additionalInstructions = additionalInstructions;
            }
            else if (!additionalInstructions && originalAdditionalInstructions) {
                refs["additionalInstructions"].current.setValue(originalAdditionalInstructions)
            }
            firebase.firestore().collection("userProfiles").doc(user.uid).update(update_dict)
                .then(() => {
                    console.log("successfully updated info in firebase!")
                    getUserInfo()
                    setEditMode(false)
                    setDefaultTextColor(DISABLED_TEXT_INPUT_COLOR)
                })
        }
    }

    if (pageLoading) {
        return (
            <View style={styles.parent}>
                <ActivityIndicator />
            </View>
        )
    }

    return (
        <View style={styles.parent}>
            <ScrollView keyboardShouldPersistTaps='handled'>
                <Section headerTextColor={HEADER_TEXT_COLOR} header="ADDITIONAL PICKUP INSTRUCTIONS" footer="">
                    <Cell backgroundColor={BACKGROUND_COLOR} cellContentView={
                        <TextInput
                            style={{ fontSize: 16, flex: 1, color: defaultTextColor }}
                            placeholder="Additional Instructions"
                            ref={refs["address"]}
                            autoCorrect={false}
                            editable={editMode}
                            enablesReturnKeyAutomatically={true}
                            onChangeText={(value) => setAdditionalInstructions(value)}
                            value={additionalInstructions}
                            onSubmitEditing={onSubmitAdditionalInstructions}
                            placeholderTextColor={PLACEHOLDER_TEXT_INPUT_COLOR}
                            returnKeyType="done"
                            returnKeyType="done"
                        />
                    } />
                </Section>
            </ScrollView>
        </View>
    );
}