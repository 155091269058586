import React, { Component, useEffect, useState, useRef, useLayoutEffect } from 'react';
import {
    View,
    SafeAreaView,
    ScrollView,
    Dimensions,
    ActivityIndicator,
    BackHandler
} from 'react-native';
import styles from './styles';
import KeyboardSpacer from '../KeyboardSpacer';
import { Cell, Section, TableView } from 'react-native-tableview-simple';
import { BACKGROUND_COLOR, CELL_BACKGROUND_COLOR, CELL_TITLE_TEXT_COLOR, CONNECTION_ERROR_BUTTON, CONNECTION_ERROR_HEADER, CONNECTION_ERROR_MESSAGE, HEADER_TEXT_COLOR, SEPARATOR_TINT_COLOR_TWO } from '../../../constants';
import AlertModal from '../AlertModal';
import { HeaderBackButton } from '@react-navigation/stack';
import firebase from "firebase/app"
import "firebase/auth"
import "firebase/firestore"
import "firebase/functions"


export default function HouseholdSize({ navigation }) {

    useLayoutEffect(() => {
        navigation.setOptions({
            headerRight: null,
            headerLeft: () => {
                return <HeaderBackButton
                    label="Back"
                    tintColor={HEADER_TEXT_COLOR}
                    disabled={pageLoading}
                    onPress={() => navigation.goBack()}
                />

            },
        })
    })

    const [pageLoading, setPageLoading] = useState(true)
    const [householdSizes, setHouseholdSizes] = useState([])
    const [householdSize, setHouseholdSize] = useState('')
    const [householdSizeAccessory, setHouseholdSizeAccessory] = useState({})
    const [alertModalVisibile, setAlertModalVisible] = useState(false);
    const [alertInfo, setAlertInfo] = useState({})
    const closeAlertModal = () => {
        setAlertModalVisible(false)
    }
    const openAlertModal = (title, subtitle, primaryButtonText, primaryButtonAction, secondaryButtonText = null, secondaryButtonAction = null) => {
        setAlertInfo({
            alertTitleText: title,
            alertSubtitleText: subtitle,
            alertPrimaryButtonText: primaryButtonText,
            alertPrimaryAction: () => primaryButtonAction(),
            alertSecondaryButtonText: secondaryButtonText,
            alertSecondaryAction: () => secondaryButtonAction()
        })
        setAlertModalVisible(true)
    }

    const getPageAndUserInfo = async () => {
        setPageLoading(true)
        try {
            const householdSizeDoc = await firebase.firestore().collection('pageInfo').doc('householdSize').get()
            var householdSizesLocal = []
            var householdSizeAccessoryLocal = {}
            if (householdSizeDoc.data() && householdSizeDoc.data().householdSize) {
                householdSizesLocal = householdSizeDoc.data().householdSize
                var index = 0
                householdSizesLocal.forEach((householdSize) => {
                    if (index === 0) {
                        setHouseholdSize(householdSize)
                        householdSizeAccessoryLocal[householdSize] = "Checkmark"
                        index++
                    }
                    else {
                        householdSizeAccessoryLocal[householdSize] = "none"
                    }
                })
                setHouseholdSizeAccessory(householdSizeAccessoryLocal)
                setHouseholdSizes(householdSizesLocal)
            }
            else {
                setPageLoading(false)
                openAlertModal('Error', 'Sorry, we had an error connecting to our services.  If this issue persists, please contact us.', 'Try Again', () => getPageAndUserInfo(), 'Go Back', () => navigation.goBack())
                return
            }

            // get user info
            const user = firebase.auth().currentUser;
            const userInfo = (await firebase.firestore().collection("userProfiles").doc(user.uid).get()).data();
            if (userInfo) {
                if (userInfo.householdSize) {
                    selectHouseholdSize(userInfo.householdSize, householdSizeAccessoryLocal, false)
                }
            }
            else {
                console.log('Error: no account for this user')
                setPageLoading(false)
                openAlertModal('Error', 'Sorry, we had an error connecting to our services.  If this issue persists, please contact us.', 'Try Again', () => getPageAndUserInfo(), 'Go Back', () => navigation.goBack())
                return
            }
            setPageLoading(false)
        }
        catch (err) {
            console.log('error:', err)
            setPageLoading(false)
            openAlertModal('Error', 'Sorry, we had an error connecting to our services.  If this issue persists, please contact us.', 'Try Again', () => getPageAndUserInfo(), 'Go Back', () => navigation.goBack())
            return
        }
    }

    useEffect(() => {
        getPageAndUserInfo()
        BackHandler.addEventListener('hardwareBackPress', onBackPress);
        return () => {
            BackHandler.removeEventListener('hardwareBackPress', onBackPress);
        }
    }, []);

    const onBackPress = () => {
        return true;
    }

    const selectHouseholdSize = async (size, accessoryParam = null, changeDatabase = true) => {
        setPageLoading(true)
        if (changeDatabase) {
            try {
                const updateDatabaseDict = {}
                updateDatabaseDict.householdSize = size
                const updateHouseholdSize = firebase.functions().httpsCallable('updateHouseholdSize');
                const response = await updateHouseholdSize({
                    updateDict: updateDatabaseDict,
                    updateContext: 'account_page'
                })
                if (response.data) {
                    if (response.data.success) {
                        // await getPageAndUserInfo()
                        // setPageLoading(false)
                    }
                    else {
                        console.log('error updating address:', response.data.message)
                        openAlertModal('Error', 'Error updating your account info.  Please try again.  If this error persists, please contact us.', 'Ok', closeAlertModal)
                        setPageLoading(false)
                        return
                    }
                }
                else {
                    openAlertModal('Error', 'Error updating your account info.  Please try again.  If this error persists, please contact us.', 'Ok', closeAlertModal)
                    setPageLoading(false)
                    return
                }
            }
            catch (err) {
                console.log('error:', err)
                setPageLoading(false)
                openAlertModal('Error', 'Error updating your account info.  Please try again.  If this error persists, please contact us.', 'Ok', closeAlertModal)
                return
            }
        }
        var accessoryLocal = accessoryParam
        if (!accessoryLocal) {
            accessoryLocal = householdSizeAccessory
        }
        setHouseholdSize(size)
        for (var key in accessoryLocal) {
            if (key == size) {
                accessoryLocal[key] = "Checkmark"
            }
            else {
                accessoryLocal[key] = "none"
            }
        }
        setHouseholdSizeAccessory(accessoryLocal)
        setPageLoading(false)
    }

    const createHouseholdSizeTable = () => {
        var valueArray = []
        var index = 0
        householdSizes.forEach((size) => {
            valueArray.push(
                <Cell key={index} backgroundColor={CELL_BACKGROUND_COLOR} titleTextColor={CELL_TITLE_TEXT_COLOR} cellStyle="Basic" title={size} accessory={householdSizeAccessory[size]} onPress={() => selectHouseholdSize(size)} />
            )
            index++
        })
        return valueArray
    }

    if (pageLoading) {
        return (
            <View style={styles.parent}>
                <ActivityIndicator />
            </View>
        )
    }

    return (
        <View style={styles.parent}>
            <ScrollView>
                <Section header="HOUSEHOLD SIZE" footer="" headerTextColor={HEADER_TEXT_COLOR} separatorTintColor={SEPARATOR_TINT_COLOR_TWO}>
                    {createHouseholdSizeTable()}
                </Section>
            </ScrollView>
            <AlertModal
                modalVisible={alertModalVisibile}
                setModalVisible={setAlertModalVisible}
                alertTitleText={alertInfo.alertTitleText}
                alertSubtitleText={alertInfo.alertSubtitleText}
                alertPrimaryButtonText={alertInfo.alertPrimaryButtonText}
                alertPrimaryAction={alertInfo.alertPrimaryAction}
                alertSecondaryButtonText={alertInfo.alertSecondaryButtonText}
                alertSecondaryAction={alertInfo.alertSecondaryAction}
            />
        </View>
    );
}