import React, { useEffect, useState } from 'react';
import {
    View,
    SafeAreaView,
    Text,
    ScrollView,
    Image,
    Dimensions,
    TouchableOpacity,
    Alert,
    ActivityIndicator
} from 'react-native';
// import { RaisedTextButton } from 'react-native-material-buttons';
import { Card, Button } from 'react-native-elements';
import firebase from "firebase/app"
import "firebase/auth"
import "firebase/firestore"
import "firebase/functions"
import styles from './styles';
import KeyboardSpacer from '../KeyboardSpacer';
import { ACCENT_COLOR_ONE, ACCENT_COLOR_THREE, TEXT_COLOR_ONE, TEXT_COLOR_THREE } from '../../../constants';

const window = Dimensions.get("window");
const screen = Dimensions.get("screen");

export default function DetailPage({ navigation, route }) {

    const { pageName, displayName } = route.params

    const [pageLoading, setPageLoading] = useState(true)
    const [paragraphs, setParagraphs] = useState([])

    const getPageInfo = async () => {
        setPageLoading(true)
        const pageInfoDoc = await firebase.firestore().collection('pageInfo').doc('PricingDetail').collection('pages').doc(pageName).get()
        const pageInfo = pageInfoDoc.data()
        const paragraphsLocal = pageInfo.paragraphs
        setParagraphs(paragraphsLocal)
        setPageLoading(false)
    }

    useEffect(() => {
        getPageInfo()
    }, []);

    const displayParagraphs = () => {
        var index = 0
        const displayList = []
        paragraphs.forEach((paragraph) => {
            displayList.push(
                <View key={index} style={{ width: "95%", maxWidth: 600, backgroundColor: ACCENT_COLOR_ONE, }}>
                    <Text style={{ textAlign: 'left', color: TEXT_COLOR_THREE, flexWrap: "wrap", fontSize: 20, width: "90%", marginLeft: "5%", marginTop: "5%", marginBottom: "5%" }}>{paragraph}</Text>
                </View>
            )
            index += 1
        })
        return displayList
    }

    if (pageLoading) {
        return (
            <View style={styles.parent}>
                <ActivityIndicator />
            </View>
        )
    }

    return (
        <View style={styles.parent}>
            <ScrollView keyboardShouldPersistTaps='handled'>
                <SafeAreaView style={styles.safeContainer}>
                    <View style={{ marginTop: "10%", alignItems: 'center' }}>
                        <View style={{ width: "95%", maxWidth: 600, backgroundColor: ACCENT_COLOR_THREE, }}>
                            <Text style={{ textAlign: 'center', color: TEXT_COLOR_ONE, flexWrap: "wrap", fontSize: 25, width: "90%", marginLeft: "5%", marginTop: "5%", marginBottom: "5%" }}>{displayName}</Text>
                        </View>
                        {displayParagraphs()}
                    </View>
                </SafeAreaView>
            </ScrollView>
        </View>
    );
}