import React, { useLayoutEffect } from 'react';
import {
    View
} from 'react-native';
import styles from './styles';
import PickupOptionSelector from '../PickupOptionSelector';
import { HEADER_TEXT_COLOR } from '../../../constants';
import { Button } from 'react-native-elements';

export default function ScheduleOptions({ navigation }) {

    useLayoutEffect(() => {
        navigation.setOptions({
            headerRight: () => (
                <Button
                    onPress={cancelScheduling}
                    title="Cancel"
                    type="clear"
                    titleStyle={{
                        fontWeight: "normal",
                        color: HEADER_TEXT_COLOR
                    }}
                    buttonStyle={{
                        marginRight: 10,
                    }}
                    disabled={false}
                />
            ),
            headerLeft: null
        })
    })

    const cancelScheduling = () => {
        navigation.navigate("Home")
    }

    return (
        <View style={styles.parent}>
            <View style={{ flex: 1 }}>
                <PickupOptionSelector />
            </View>
        </View>
    );
}