import { StyleSheet } from 'react-native'
import { BACKGROUND_COLOR, TEXT_COLOR_THREE } from '../../../constants';

export default StyleSheet.create({
    heading: {
        fontSize: 25,
        textAlign: 'center',
        color: TEXT_COLOR_THREE
    },
    subheading: {
        fontSize: 20,
        color: TEXT_COLOR_THREE,
        textAlign: 'center',
        margin: 20
    },
    imageContainer: {
        justifyContent: 'center',
        alignItems: 'center',
    },
    input: {
        marginTop: 10,
        marginLeft: 5,
        marginRight: 5,
    },
    button: {
        marginTop: 20,
    },
    parent: {
        flex: 1,
        backgroundColor: BACKGROUND_COLOR
    },
    safeContainer: {

    },
    cardContainer: {
        backgroundColor: "black"
    }
});