import React, { useState, useEffect, useRef, useLayoutEffect } from 'react'
import { View, ActivityIndicator, ScrollView, BackHandler, TextInput, Alert, Switch } from 'react-native'
import firebase from "firebase/app"
import "firebase/auth"
import "firebase/firestore"
import "firebase/functions"
import { Cell, Section, TableView } from 'react-native-tableview-simple';
import styles from '../styles';
import { Button } from 'react-native-elements';
import { BACKGROUND_COLOR, BUTTON_COLOR_ONE, BUTTON_COLOR_TWO, CELL_TITLE_TEXT_COLOR, DISABLED_TEXT_COLOR, FOOTER_TEXT_COLOR, HEADER_TEXT_COLOR, PLACEHOLDER_TEXT_INPUT_COLOR, SEPARATOR_TINT_COLOR_ONE, SEPARATOR_TINT_COLOR_TWO, TEXT_INPUT_COLOR } from '../../../../constants';
import AlertModal from '../../AlertModal'


export default function ContactInfo({ navigation }) {

    useLayoutEffect(() => {
        navigation.setOptions({
            headerRight: null,
            headerLeft: null,
        });
    });

    const logout = async () => {
        try {
            await firebase.auth().signOut();
            setPageLoading(false)
        }
        catch (err) {
            openAlertModal('Error Logging Out', 'Please try again', 'Try Again', logout)
        }
    }

    const nextPage = async () => {
        setPageLoading(true)
        const phoneNumberPresent = phoneNumber !== null && phoneNumber !== ''
        const firstNamePresent = firstName !== null && firstName !== ''
        const lastNamePresent = lastName !== null && lastName !== ''
        if (!phoneNumberPresent || !firstNamePresent || !lastNamePresent) {
            setPageLoading(false)
            openAlertModal('Incomplete Fields', 'Please enter your phone number and name.', 'Ok', closeAlertModal)
            return
        }
        if (phoneNumber) {
            // regex check to make sure valid phone number
            let phoneNumberRegex = /^\([1-9]\d{2}\)\s\d{3}-\d{4}/;
            if (!phoneNumberRegex.test(phoneNumber)) {
                setPageLoading(false)
                openAlertModal('Invalid Phone Number', 'Please enter a valid phone number.', 'Ok', closeAlertModal)
                return
            }
        }
        try {
            const user = firebase.auth().currentUser;
            await firebase.firestore().collection('userProfiles').doc(user.uid).update({
                firstName: firstName,
                lastName: lastName,
                contactNumber: phoneNumber,
                textReminders: textReminders
            })
            navigation.navigate("Account Creation Delivery Address")
            setPageLoading(false)
        }
        catch (err) {
            console.log('error updating profile in firebase:', err)
            setPageLoading(false)
            openAlertModal('Error Updating Profile', 'We are unable to update your profile at this time.  Please try again.  If this issue persists, please log out and contact us.', 'Try Again', nextPage, 'Log Out', logout)
        }
    }

    const [pageLoading, setPageLoading] = useState(true);
    const [phoneNumber, setPhoneNumber] = useState('');
    const [email, setEmail] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [textReminders, setTextReminders] = useState(true);
    const [alertModalVisibile, setAlertModalVisible] = useState(false);
    const [alertInfo, setAlertInfo] = useState({})
    const openAlertModal = (title, subtitle, primaryButtonText, primaryButtonAction, secondaryButtonText = null, secondaryButtonAction = null) => {
        setAlertInfo({
            alertTitleText: title,
            alertSubtitleText: subtitle,
            alertPrimaryButtonText: primaryButtonText,
            alertPrimaryAction: () => primaryButtonAction(),
            alertSecondaryButtonText: secondaryButtonText,
            alertSecondaryAction: () => secondaryButtonAction()
        })
        setAlertModalVisible(true)
    }
    const closeAlertModal = () => {
        setAlertModalVisible(false)
    }

    const refs = {
        'firstName': useRef(),
        'lastName': useRef(),
        'phoneNumber': useRef()
    };

    const getUserInfo = async () => {
        setPageLoading(true)
        const user = firebase.auth().currentUser
        setEmail(user.email)
        try {
            const userInfo = (await firebase.firestore().collection("userProfiles").doc(user.uid).get()).data();
            if (userInfo) {
                if (userInfo.firstName != null) {
                    setFirstName(userInfo.firstName)
                }
                if (userInfo.lastName != null) {
                    setLastName(userInfo.lastName)
                }
                if (userInfo.contactEmail != null) {
                    setEmail(userInfo.contactEmail)
                }
                if (userInfo.contactNumber != null) {
                    setPhoneNumber(userInfo.contactNumber)
                }
                if (userInfo.textReminders != null) {
                    setTextReminders(userInfo.textReminders)
                }
            }
            else {
                console.log('Error: no account for this user')
                setPageLoading(false)
                openAlertModal('Error', 'We do not have a profile created for you.  Please logout and login again.', 'Log Out', logout)
                return
            }
            setPageLoading(false);
        }
        catch (err) {
            console.log('error getting page or data:', err)
            setPageLoading(false)
            openAlertModal('Error Creating Profile', 'We are unable to connect to our services.  Please try again.  If this issue persists, please log out and contact us.', 'Try Again', getUserInfo, 'Log Out', logout)
            return
        }

    }

    useEffect(() => {
        getUserInfo()
        BackHandler.addEventListener('hardwareBackPress', onBackPress);
        return () => {
            BackHandler.removeEventListener('hardwareBackPress', onBackPress);
        };
    }, []);

    const onBackPress = () => {
        return true;
    };

    const toggleTextRemindersSwitch = () => {
        setTextReminders(previousState => !previousState);
    };

    const insertCharacterAtIndex = (str, index, character) => {
        return str.substr(0, index) + character + str.substr(index);
    }

    const changePhoneNumber = (value) => {
        var numbersOnly = value.replace(/[^\d]/g, '')
        var newValue = ''
        if (numbersOnly.length > 0) {
            if (numbersOnly.length > 10) {
                numbersOnly = numbersOnly.substr(0, 10)
            }
            newValue = numbersOnly
            newValue = insertCharacterAtIndex(newValue, 0, '(')
            if (numbersOnly.length > 3) {
                newValue = insertCharacterAtIndex(newValue, 4, ') ')
            }
            if (numbersOnly.length > 6) {
                newValue = insertCharacterAtIndex(newValue, 9, '-')
            }

        }
        setPhoneNumber(newValue)
    }

    const onSubmitFirstName = () => {
        refs['lastName'].current.focus()
    };

    const onSubmitLastName = () => {
        refs['phoneNumber'].current.focus()
    };

    const onSubmitPhoneNumber = () => {
        nextPage()
    };

    if (pageLoading) {
        return (
            <View style={styles.parent}>
                <ActivityIndicator />
            </View>
        );
    }

    return (
        <View style={styles.parent}>
            <ScrollView>
                <Section header="NAME" footer="" headerTextColor={HEADER_TEXT_COLOR} separatorTintColor={SEPARATOR_TINT_COLOR_TWO} >
                    <Cell backgroundColor={BACKGROUND_COLOR} cellContentView={
                        <TextInput
                            style={{ fontSize: 16, flex: 1, color: TEXT_INPUT_COLOR }}
                            placeholder="First Name"
                            ref={refs['firstName']}
                            autoCorrect={false}
                            enablesReturnKeyAutomatically={true}
                            onChangeText={value => setFirstName(value)}
                            value={firstName}
                            onSubmitEditing={onSubmitFirstName}
                            returnKeyType="next"
                            placeholderTextColor={PLACEHOLDER_TEXT_INPUT_COLOR}
                            autoCapitalize="words"
                        />
                    } />
                    <Cell backgroundColor={BACKGROUND_COLOR} cellContentView={
                        <TextInput
                            style={{ fontSize: 16, flex: 1, color: TEXT_INPUT_COLOR }}
                            placeholder="Last Name"
                            ref={refs['lastName']}
                            autoCorrect={false}
                            enablesReturnKeyAutomatically={true}
                            onChangeText={value => setLastName(value)}
                            value={lastName}
                            onSubmitEditing={onSubmitLastName}
                            returnKeyType="next"
                            placeholderTextColor={PLACEHOLDER_TEXT_INPUT_COLOR}
                            autoCapitalize="words"
                        />
                    } />
                </Section>
                <Section header="CONTACT" footer="" headerTextColor={HEADER_TEXT_COLOR} separatorTintColor={SEPARATOR_TINT_COLOR_TWO} >
                    <Cell backgroundColor={BACKGROUND_COLOR} cellContentView={
                        <TextInput
                            style={{ fontSize: 16, flex: 1, color: TEXT_INPUT_COLOR }}
                            placeholder="Phone Number"
                            ref={refs['phoneNumber']}
                            autoCorrect={false}
                            enablesReturnKeyAutomatically={true}
                            onChangeText={value => changePhoneNumber(value)}
                            value={phoneNumber}
                            onSubmitEditing={onSubmitPhoneNumber}
                            returnKeyType="go"
                            placeholderTextColor={PLACEHOLDER_TEXT_INPUT_COLOR}
                        />
                    } />
                    <Cell backgroundColor={BACKGROUND_COLOR} cellContentView={
                        <TextInput
                            style={{ fontSize: 16, flex: 1, color: DISABLED_TEXT_COLOR }}
                            placeholder="Email"
                            ref={refs['email']}
                            autoCorrect={false}
                            enablesReturnKeyAutomatically={true}
                            onChangeText={value => setEmail(value)}
                            value={email}
                            returnKeyType="done"
                            placeholderTextColor={PLACEHOLDER_TEXT_INPUT_COLOR}
                            autoCapitalize="none"
                            editable={false}
                        />
                    } />
                </Section>
                <Section separatorTintColor={SEPARATOR_TINT_COLOR_TWO} header="" headerTextColor={HEADER_TEXT_COLOR} footerTextColor={FOOTER_TEXT_COLOR} footer="Would you like to receive text message reminders for upcoming pickups?">
                    <Cell backgroundColor={BACKGROUND_COLOR} title="Text Message Reminders" titleTextColor={CELL_TITLE_TEXT_COLOR} cellAccessoryView={
                        <Switch
                            trackColor={{ false: "#767577", true: "#81b0ff" }}
                            thumbColor={textReminders ? "#f5dd4b" : "#f4f3f4"}
                            ios_backgroundColor="#3e3e3e"
                            onValueChange={toggleTextRemindersSwitch}
                            value={textReminders}
                        />
                    } />
                </Section>
                <View style={{ height: 50, width: "100%", marginTop: 40, alignItems: 'center', marginBottom: 50 }}>
                    <View style={{ width: "70%", maxWidth: 250, minWidth: 200 }} >
                        <Button
                            onPress={nextPage}
                            title='Continue'
                            buttonStyle={{
                                backgroundColor: BUTTON_COLOR_TWO
                            }}
                        />
                    </View>
                </View>
            </ScrollView>
            <AlertModal
                modalVisible={alertModalVisibile}
                setModalVisible={setAlertModalVisible}
                alertTitleText={alertInfo.alertTitleText}
                alertSubtitleText={alertInfo.alertSubtitleText}
                alertPrimaryButtonText={alertInfo.alertPrimaryButtonText}
                alertPrimaryAction={alertInfo.alertPrimaryAction}
                alertSecondaryButtonText={alertInfo.alertSecondaryButtonText}
                alertSecondaryAction={alertInfo.alertSecondaryAction}
            />
        </View>
    );
}