import React from 'react';
import {
    View
} from 'react-native';
import styles from './styles';
import TopHeading from '../../../TopHeading.js'
import PickupOptionSelector from '../PickupOptionSelector';

export default function Pricing({ navigation }) {

    return (
        <View style={styles.parent}>
            <View style={{ zIndex: 10 }}>
                <TopHeading navigation={navigation} currentPage="Pricing" />
            </View>
            <View style={{ flex: 1, marginTop: 50, zIndex: 0 }}>
                <PickupOptionSelector />
            </View>
        </View>
    );
}