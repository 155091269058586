import React, { useState, useRef, useContext } from 'react';
import {
    View,
    Text,
    ScrollView,
    Image,
    TextInput,
    ActivityIndicator,
    useWindowDimensions
} from 'react-native';
import { Button } from 'react-native-elements';
import { Cell, Section } from 'react-native-tableview-simple';
import styles from './styles';
import firebase from "firebase/app"
import "firebase/auth"
import "firebase/firestore"
import "firebase/functions"
import AuthContext from '../../../AuthContext.js'
import PublicTopHeading from '../../../PublicTopHeading.js'
import AlertModal from '../AlertModal'
import { BACKGROUND_COLOR, BUTTON_COLOR_ONE, CELL_BACKGROUND_COLOR, PLACEHOLDER_TEXT_INPUT_COLOR, TEXT_COLOR_ONE, TEXT_COLOR_THREE, TEXT_INPUT_COLOR } from '../../../constants';


export default function Login({ navigation }) {

    const dimensions = useWindowDimensions();

    let widthPercent = "70%";
    let marginPercent = "15%";

    if (dimensions.width >= 1100) {
        widthPercent = 1000;
        marginPercent = (dimensions.width - 1000) / 2;
    }
    else if ((dimensions.width >= 500) && (dimensions.width < 1200)) {
        widthPercent = "90%";
        marginPercent = "5%";
    }
    else {
        widthPercent = "95%";
        marginPercent = "2.5%"
    }

    const refs = { "email": useRef(), "password": useRef() }
    const [pageLoading, setPageLoading] = useState(false)
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [alertModalVisibile, setAlertModalVisible] = useState(false);
    const [alertInfo, setAlertInfo] = useState({})
    const openAlertModal = (title, subtitle, primaryButtonText, primaryButtonAction, secondaryButtonText = null, secondaryButtonAction = null) => {
        setAlertInfo({
            alertTitleText: title,
            alertSubtitleText: subtitle,
            alertPrimaryButtonText: primaryButtonText,
            alertPrimaryAction: () => primaryButtonAction(),
            alertSecondaryButtonText: secondaryButtonText,
            alertSecondaryAction: () => secondaryButtonAction()
        })
        setAlertModalVisible(true)
    }
    const closeAlertModal = () => {
        setAlertModalVisible(false)
    }

    const logUserIn = async () => {
        if (email === '') {
            openAlertModal('Error', 'Please enter your email.', 'Ok', closeAlertModal)
            return
        }
        if (password === '') {
            openAlertModal('Error', 'Please enter your password.', 'Ok', closeAlertModal)
            return
        }
        setPageLoading(true)
        await firebase.auth().signInWithEmailAndPassword(email, password).catch((err) => {
            setPageLoading(false)
            openAlertModal('Error', 'Invalid email or password.  Please try again.', 'Ok', closeAlertModal)
            console.log('login error:', err)
        }).then(() => {
            setPageLoading(false)
        })
    }

    const onSubmitEmail = () => {
        refs['password'].current.focus()
    }

    const onSubmitPassword = () => {
        logUserIn()
    }

    if (pageLoading) {
        return (
            <View style={{ backgroundColor: BACKGROUND_COLOR, width: widthPercent, marginLeft: marginPercent, height: dimensions.height, position: "absolute", justifyContent: 'center' }}>
                <ActivityIndicator size='large' />
            </View>
        )
    }

    return (
        <View style={{ maxHeight: dimensions.height }}>
            <View style={{ flex: 1, marginTop: 0, zIndex: 0, height: dimensions.height }}>
                <ScrollView>
                    <View style={{ width: widthPercent, marginLeft: marginPercent, backgroundColor: BACKGROUND_COLOR, flex: 1, minHeight: dimensions.height, alignItems: 'center' }}>
                        <View style={{ width: "100%", alignItems: "center" }}>
                            <Image source={require("../../assets/Tumble_Logo.png")} style={{ width: 150, height: 150 }} />
                        </View>
                        <View style={{ width: "80%", maxWidth: 500, marginBottom: 20 }}>
                            <Text style={{ fontSize: 30, textAlign: 'center', color: TEXT_COLOR_THREE }}>Log In</Text>
                            <Section header="EMAIL">
                                <Cell cellContentView={
                                    <TextInput
                                        style={{ fontSize: 16, flex: 1, color: TEXT_INPUT_COLOR, height: "100%", width: "100%" }}
                                        placeholder="Email"
                                        ref={refs["email"]}
                                        autoCorrect={false}
                                        enablesReturnKeyAutomatically={true}
                                        onChangeText={(value) => setEmail(value)}
                                        value={email}
                                        autoCapitalize="none"
                                        onSubmitEditing={onSubmitEmail}
                                        keyboardType='email-address'
                                        returnKeyType="next"
                                        placeholderTextColor={PLACEHOLDER_TEXT_INPUT_COLOR}
                                    />
                                }
                                    backgroundColor={CELL_BACKGROUND_COLOR}
                                />
                            </Section>
                            <Section header="PASSWORD">
                                <Cell cellContentView={
                                    <TextInput
                                        style={{ fontSize: 16, flex: 1, color: TEXT_INPUT_COLOR, height: "100%", width: "100%" }}
                                        placeholder="Password"
                                        ref={refs["password"]}
                                        autoCorrect={false}
                                        enablesReturnKeyAutomatically={true}
                                        onChangeText={(value) => setPassword(value)}
                                        value={password}
                                        autoCapitalize="none"
                                        onSubmitEditing={onSubmitPassword}
                                        returnKeyType="go"
                                        placeholderTextColor={PLACEHOLDER_TEXT_INPUT_COLOR}
                                        secureTextEntry={true}
                                    />
                                }
                                    backgroundColor={CELL_BACKGROUND_COLOR}
                                />
                            </Section>
                        </View>

                        <View style={{ width: "60%", maxWidth: 300, marginTop: 20, justifyContent: "center" }}>
                            <Button
                                buttonStyle={{
                                    backgroundColor: BUTTON_COLOR_ONE
                                }}
                                onPress={logUserIn}
                                title='Login'
                            />
                        </View>
                        <View style={{ width: "60%", maxWidth: 300, marginTop: 40, justifyContent: "center", marginBottom: 50 }}>
                            <Button
                                buttonStyle={{
                                    backgroundColor: BUTTON_COLOR_ONE
                                }}
                                title="Create New Account"
                                onPress={() => navigation.navigate("SignUp")}
                            />
                        </View>
                    </View>
                </ScrollView>
            </View>
            <AlertModal
                modalVisible={alertModalVisibile}
                setModalVisible={setAlertModalVisible}
                alertTitleText={alertInfo.alertTitleText}
                alertSubtitleText={alertInfo.alertSubtitleText}
                alertPrimaryButtonText={alertInfo.alertPrimaryButtonText}
                alertPrimaryAction={alertInfo.alertPrimaryAction}
                alertSecondaryButtonText={alertInfo.alertSecondaryButtonText}
                alertSecondaryAction={alertInfo.alertSecondaryAction}
            />
        </View>
    );
}