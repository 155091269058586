import React, { useState, useEffect } from 'react';
import {
    View,
    Text,
    ScrollView,
    Image,
    Dimensions,
} from 'react-native';
import firebase from "firebase/app"
import "firebase/auth"
import "firebase/firestore"
import "firebase/functions"
import { Button } from 'react-native-elements';
import styles from './styles';
import TopHeading from '../../../TopHeading.js'
import { BUTTON_COLOR_ONE, BUTTON_COLOR_TWO, TEXT_COLOR_ONE, TEXT_COLOR_THREE } from '../../../constants';
import { ActivityIndicator } from 'react-native';
import AlertModal from '../AlertModal';


export default function Rewards({ navigation }) {

    const [pageLoading, setPageLoading] = useState(true)
    const [rewardsPoints, setRewardsPoints] = useState(0)
    const [alertModalVisibile, setAlertModalVisible] = useState(false);
    const [alertInfo, setAlertInfo] = useState({})
    const closeAlertModal = () => {
        setAlertModalVisible(false)
    }
    const openAlertModal = (title, subtitle, primaryButtonText, primaryButtonAction, secondaryButtonText = null, secondaryButtonAction = null) => {
        setAlertInfo({
            alertTitleText: title,
            alertSubtitleText: subtitle,
            alertPrimaryButtonText: primaryButtonText,
            alertPrimaryAction: () => primaryButtonAction(),
            alertSecondaryButtonText: secondaryButtonText,
            alertSecondaryAction: () => secondaryButtonAction()
        })
        setAlertModalVisible(true)
    }

    useEffect(() => {
        const user = firebase.auth().currentUser;
        const userSnapshotSubscriber = firebase.firestore()
            .collection('userProfiles').doc(user.uid)
            .onSnapshot(docSnapshot => {
                setPageLoading(true)
                setRewardsPoints(docSnapshot.data().rewards)
                setPageLoading(false)
            });
        return () => {
            userSnapshotSubscriber();
        }
    }, [])


    const rewardsFAQs = () => {
        navigation.navigate("Rewards FAQs")
    }

    const redeemPoints = () => {
        navigation.navigate("Use Rewards")
    }

    if (pageLoading) {
        return (
            <View style={styles.parent}>
                <ActivityIndicator />
            </View>
        )
    }

    return (
        <View style={styles.parent}>
            <View style={{ zIndex: 10 }}>
                <TopHeading navigation={navigation} currentPage="Rewards" />
            </View>
            <View style={{ flex: 1, marginTop: 50, zIndex: 0 }}>
                <ScrollView>
                    <View style={styles.imageContainer}>
                        <Image source={require("../../assets/Tumble_Logo.png")} style={{ width: 150, height: 150 }} />
                    </View>
                    <View style={{ position: 'absolute', marginTop: 150, width: "100%", zIndex: 3, height: '20%', alignItems: "center" }}>
                        <Text style={{ color: TEXT_COLOR_THREE, fontSize: 40 }}>Available Points:</Text>
                    </View>
                    <View style={{ position: 'absolute', width: "100%", marginTop: 220, alignItems: "center" }}>
                        <Image source={require("../../assets/Rewards_Present.png")} style={{ height: 340, width: 310, position: 'absolute' }} />
                        <Text style={{ color: TEXT_COLOR_THREE, fontSize: 80, position: "absolute", marginTop: 175 }}>{rewardsPoints}</Text>
                    </View>
                    <View style={{ position: 'absolute', height: 50, width: "100%", marginTop: 600, alignItems: "center", flex: 1 }}>
                        <Button
                            onPress={redeemPoints}
                            title='Redeem Points'
                            buttonStyle={{
                                backgroundColor: BUTTON_COLOR_TWO,
                                width: 300
                            }}
                        />
                    </View>
                    <View style={{ position: 'absolute', height: 100, width: "100%", marginTop: 675, alignItems: "center", flex: 1 }}>
                        <Button
                            onPress={rewardsFAQs}
                            title='Rewards FAQs'
                            buttonStyle={{
                                backgroundColor: BUTTON_COLOR_TWO,
                                width: 300
                            }}
                        />
                    </View>
                </ScrollView>
            </View>
            <AlertModal
                modalVisible={alertModalVisibile}
                setModalVisible={setAlertModalVisible}
                alertTitleText={alertInfo.alertTitleText}
                alertSubtitleText={alertInfo.alertSubtitleText}
                alertPrimaryButtonText={alertInfo.alertPrimaryButtonText}
                alertPrimaryAction={alertInfo.alertPrimaryAction}
                alertSecondaryButtonText={alertInfo.alertSecondaryButtonText}
                alertSecondaryAction={alertInfo.alertSecondaryAction}
            />
        </View>
    );
}