import React, { useEffect, useState, useLayoutEffect } from 'react';
import {
    View,
    SafeAreaView,
    Text,
    ScrollView,
    Image,
    Dimensions,
    TouchableOpacity,
    Alert,
    ActivityIndicator
} from 'react-native';
// import { RaisedTextButton } from 'react-native-material-buttons';
import { Card, Button } from 'react-native-elements';
import styles from './styles';
import DetailPage from './DetailPage.js'
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs'
import { ACCENT_COLOR_TWO, TEXT_COLOR_ONE } from '../../../constants';
const TopTab = createMaterialTopTabNavigator();

const window = Dimensions.get("window");
const screen = Dimensions.get("screen");

function PricingDetailTopTabNavigator() {
    return (
        <TopTab.Navigator tabBarOptions={{ labelStyle: { fontSize: 10 }, style: { backgroundColor: ACCENT_COLOR_TWO }, activeTintColor: TEXT_COLOR_ONE }}>
            <TopTab.Screen name="General"
                component={DetailPage}
                initialParams={{
                    pageName: "General",
                    displayName: "General"
                }}
            />
            <TopTab.Screen name="Wash and Fold"
                component={DetailPage}
                initialParams={{
                    pageName: "WashAndFold",
                    displayName: "Wash and Fold Pickup"
                }}
            />
            <TopTab.Screen name="Biweekly"
                component={DetailPage}
                initialParams={{
                    pageName: "Biweekly",
                    displayName: "Biweekly (Every Two Weeks) Plan"
                }}
            />
            <TopTab.Screen name="Weekly"
                component={DetailPage}
                initialParams={{
                    pageName: "Weekly",
                    displayName: "Weekly Plan"
                }}
            />
        </TopTab.Navigator>
    );
}

export default function PricingDetail({ navigation }) {

    useLayoutEffect(() => {
        navigation.setOptions({
            headerRight: () => (
                <Button
                    onPress={() => navigation.goBack()}
                    title="Done"
                    type="clear"
                    titleStyle={{
                        fontWeight: "normal"
                    }}
                    buttonStyle={{
                        marginRight: 10
                    }}
                />
            ),
            headerLeft: null
        })
    })

    const dimensions = { window, screen }

    useEffect(() => {
    }, []);

    return (
        <View style={styles.parent}>
            <View style={{ flex: 1, marginTop: 0, zIndex: 0 }}>
                <PricingDetailTopTabNavigator />
            </View>
        </View>
    );
}