import React, { useEffect, useState, useLayoutEffect } from 'react';
import {
    View,
    ScrollView,
    ActivityIndicator,
} from 'react-native';
import styles from './styles';
import { Cell, Section } from 'react-native-tableview-simple';
import firebase from "firebase/app"
import "firebase/auth"
import "firebase/firestore"
import "firebase/functions"
import AddCreditCard from '../AddCreditCard'
import AlertModal from '../AlertModal'
import { CELL_BACKGROUND_COLOR, CELL_TITLE_TEXT_COLOR, CONNECTION_ERROR_BUTTON, CONNECTION_ERROR_HEADER, CONNECTION_ERROR_MESSAGE, HEADER_TEXT_COLOR } from '../../../constants';
import { HeaderBackButton } from '@react-navigation/stack';


export default function PaymentInfo({ navigation }) {

    useLayoutEffect(() => {
        navigation.setOptions({
            headerRight: null,
            headerLeft: () => (
                <HeaderBackButton
                    tintColor={HEADER_TEXT_COLOR}
                    disabled={pageLoading}
                    onPress={() => navigation.goBack()}
                />
            )
        })
    })

    const [cardSelectionAccessory, setCardSelectionAccessory] = useState({})
    const [paymentList, setPaymentList] = useState([])
    const [pageLoading, setPageLoading] = useState(true)
    const [defaultPayment, setDefaultPayment] = useState()
    const [modalVisible, setModalVisible] = useState(false)
    const [noExistingPayment, setNoExistingPayment] = useState(false)
    const [selectedCard, setSelectedCard] = useState()
    const [alertModalVisibile, setAlertModalVisible] = useState(false);
    const [alertInfo, setAlertInfo] = useState({})
    const closeAlertModal = () => {
        setAlertModalVisible(false)
    }
    const openAlertModal = (title, subtitle, primaryButtonText, primaryButtonAction, secondaryButtonText = null, secondaryButtonAction = null) => {
        setAlertInfo({
            alertTitleText: title,
            alertSubtitleText: subtitle,
            alertPrimaryButtonText: primaryButtonText,
            alertPrimaryAction: () => primaryButtonAction(),
            alertSecondaryButtonText: secondaryButtonText,
            alertSecondaryAction: () => secondaryButtonAction()
        })
        setAlertModalVisible(true)
    }

    useEffect(() => {
        getUserInfo();
    }, []);

    const getUserInfo = async () => {
        setPageLoading(true)
        const user = firebase.auth().currentUser;
        const userInfo = (await firebase.firestore().collection("userProfiles").doc(user.uid).get()).data();
        if (userInfo) {
            getCustomerPaymentInfo()
        }
        else {
            console.log('error getting user info')
        }
    }


    const addCard = () => {
        // navigation.navigate("Add Card Pickup")
        setModalVisible(true)
    }

    const setCustomerPaymentInfo = (paymentMethods, defaultPaymentMethod) => {
        var temp_dict = {}
        for (var i = 0; i < paymentMethods.length; i++) {
            if (paymentMethods[i].id == defaultPaymentMethod) {
                temp_dict[paymentMethods[i].id] = "Checkmark"
            }
            else {
                temp_dict[paymentMethods[i].id] = "none"
            }
        }
        if (paymentMethods.length == 0) {
            setNoExistingPayment(true)
        }
        else {
            setNoExistingPayment(false)
        }
        setCardSelectionAccessory(temp_dict)
        setPaymentList(paymentMethods)
        setDefaultPayment(defaultPaymentMethod)
        setSelectedCard(defaultPaymentMethod)
    }

    const getCustomerPaymentInfo = async () => {
        setPageLoading(true)
        try {
            var getPaymentMethods = firebase.functions().httpsCallable('getPaymentMethods');
            const response = await getPaymentMethods({})
            if (!response.error) {
                setCustomerPaymentInfo(response.data.payment_methods, response.data.default_payment_method)
            }
            else {
                console.log('error from firebase functions:', response.error)
            }
        }
        catch (err) {
            console.log('error: ', err)
        }
        setPageLoading(false)
    }

    const changeCard = async (cardIndex) => {
        closeAlertModal()
        setPageLoading(true)
        try {
            var updateDefaultPaymentMethod = firebase.functions().httpsCallable('updateDefaultPaymentMethod');
            const response = await updateDefaultPaymentMethod({
                paymentMethodId: cardIndex
            })
            console.log('response:', response)
            if (response.data.success) {
                selectCard(cardIndex)
                setPageLoading(false)
            }
            else {
                console.log('error updating default payment: ', response.data.error)
                setPageLoading(false)
                openAlertModal('Error', 'Error updating default payment method.  Please try again.  If this error persists, please contact us.', 'Ok', closeAlertModal)
            }
        }
        catch (err) {
            setPageLoading(false)
            openAlertModal(CONNECTION_ERROR_HEADER, CONNECTION_ERROR_MESSAGE, CONNECTION_ERROR_BUTTON, closeAlertModal)
        }
    }

    const confirmChangeCard = (cardIndex) => {
        if (cardIndex !== selectedCard) {
            const newFunctionCall = () => { changeCard(cardIndex) }
            openAlertModal('Change Default Payment?', 'You are about to change your default payment method.  The new default payment method will be used for all your upcoming plan charges.', 'Yes', newFunctionCall, 'Cancel', closeAlertModal)
        }
    }

    const selectCard = (cardIndex) => {
        var temp_dict = { ...cardSelectionAccessory }
        for (var key in cardSelectionAccessory) {
            if (key == cardIndex) {
                temp_dict[key] = "Checkmark"
            }
            else {
                temp_dict[key] = "none"
            }
        }
        console.log(temp_dict)
        setCardSelectionAccessory(temp_dict)
        setSelectedCard(cardIndex)
    }

    const createTable = () => {
        var valueArray = []
        for (let i = 0; i < paymentList.length; i++) {
            let this_id = paymentList[i].id
            var brand = paymentList[i].card.brand
            if (paymentList[i].card.brand == "visa") {
                brand = "Visa"
            }
            let title = brand + " Ending In " + paymentList[i].card.last4
            valueArray.push(<Cell key={i} cellStyle="Basic" backgroundColor={CELL_BACKGROUND_COLOR} titleTextColor={CELL_TITLE_TEXT_COLOR} title={title} accessory={cardSelectionAccessory[this_id]} onPress={() => confirmChangeCard(this_id)} />)
        }
        if (paymentList.length == 0) {
            valueArray.push(<Cell key={0} cellStyle="Basic" backgroundColor={CELL_BACKGROUND_COLOR} titleTextColor={CELL_TITLE_TEXT_COLOR} title="No cards on file.  Please add a card." />)
        }
        return valueArray
    }

    if (pageLoading) {
        return (
            <View style={styles.parent}>
                <ActivityIndicator />
            </View>
        )
    }

    return (
        <View style={styles.parent}>
            <ScrollView keyboardShouldPersistTaps='handled'>
                <Section headerTextColor={HEADER_TEXT_COLOR} header="DEFAULT PAYMENT METHOD" footer="">
                    {createTable()}
                </Section>
                <Section header="" footer="">
                    <Cell backgroundColor={CELL_BACKGROUND_COLOR} titleTextColor={CELL_TITLE_TEXT_COLOR} cellStyle="Basic" title="+ Add New Card..." onPress={addCard} />
                </Section>
            </ScrollView>
            <AddCreditCard
                modalVisible={modalVisible}
                setModalVisible={setModalVisible}
                noExistingPayment={noExistingPayment}
                setPageLoading={setPageLoading}
                getCustomerPaymentInfo={getCustomerPaymentInfo}
            />
            <AlertModal
                modalVisible={alertModalVisibile}
                setModalVisible={setAlertModalVisible}
                alertTitleText={alertInfo.alertTitleText}
                alertSubtitleText={alertInfo.alertSubtitleText}
                alertPrimaryButtonText={alertInfo.alertPrimaryButtonText}
                alertPrimaryAction={alertInfo.alertPrimaryAction}
                alertSecondaryButtonText={alertInfo.alertSecondaryButtonText}
                alertSecondaryAction={alertInfo.alertSecondaryAction}
            />
        </View>
    );
}