import React, { Component, useEffect, useState, useRef, useLayoutEffect } from 'react';
import {
    View,
    SafeAreaView,
    ScrollView,
    Dimensions,
    TextInput,
    Text,
    Alert,
    ActivityIndicator,
    useWindowDimensions,
    TouchableHighlight
} from 'react-native';
import styles from './styles';
import KeyboardSpacer from '../KeyboardSpacer';
import { Button, Input, Card } from 'react-native-elements';
import { Cell, Section, TableView } from 'react-native-tableview-simple';
import firebase from "firebase/app"
import "firebase/auth"
import "firebase/firestore"
import "firebase/functions"
import Modal from 'react-native-modal'
import { BACKGROUND_COLOR, BUTTON_COLOR_TWO, HEADER_TEXT_COLOR, PLACEHOLDER_TEXT_INPUT_COLOR, SEPARATOR_TINT_COLOR_TWO, TEXT_COLOR_THREE, TEXT_INPUT_COLOR, UNDERLAY_COLOR_ONE } from '../../../constants';


export default function ChangePassword({ navigation }) {

    useEffect(() => {
        getUserInfo();
    }, []);

    const refs = {
        "userName": useRef(),
        "loginEmail": useRef(),
        "loginPassword": useRef(),
        "password": useRef(),
        "confirmPassword": useRef()
    }

    const dimensions = useWindowDimensions()
    const [pageLoading, setPageLoading] = useState(true)
    const [changePasswordRequestLoading, setChangePasswordRequestLoading] = useState(false)

    const [modalVisible, setModalVisible] = useState(true)
    const [loginEmail, setLoginEmail] = useState()
    const [loginPassword, setLoginPassword] = useState()
    const [password, setPassword] = useState()
    const [confirmPassword, setConfirmPassword] = useState()
    const [reauthenticated, setReauthenticated] = useState(false)
    const [loginError, setLoginError] = useState(false)

    var widthPercent = "100%"
    var marginPercent = "0%"
    if (dimensions.width >= 1100) {
        widthPercent = 1000;
        marginPercent = (dimensions.width - 1000) / 2;
    }
    else if ((dimensions.width >= 500) && (dimensions.width < 1100)) {
        widthPercent = "90%";
        marginPercent = "5%";
    }
    else {
        widthPercent = "95%";
        marginPercent = "2.5%"
    }

    useLayoutEffect(() => {
        navigation.setOptions({
            headerRight: () => (
                <Button
                    onPress={() => navigation.goBack()}
                    title="Cancel"
                    type="clear"
                    titleStyle={{
                        fontWeight: "normal"
                    }}
                    buttonStyle={{
                        marginRight: 10
                    }}
                    disabled={changePasswordRequestLoading}
                />
            ),
            headerLeft: null
        })
    })

    const getUserInfo = async () => {
        const user = firebase.auth().currentUser;
        // console.log(user);
        const userInfo = (await firebase.firestore().collection("userProfiles").doc(user.uid).get()).data();
        console.log(user)
        setLoginEmail(user.email)
        setPageLoading(false)
    }

    const updatePassword = () => {
        // TODO: check validity of the new password
        // confirm that the passwords match
        if (password != confirmPassword) {
            Alert.alert("Error", "Passwords do not match")
        }
        else {
            setChangePasswordRequestLoading(true)
            var user = firebase.auth().currentUser
            user.updatePassword(password).then(() => {
                setChangePasswordRequestLoading(false)
                navigation.navigate("Login Info")
            }).catch((error) => {
                setChangePasswordRequestLoading(false)
                console.log(error)
                Alert.alert("error")
            })
        }
    }

    const reauthenticate = () => {
        setPageLoading(true)
        var user = firebase.auth().currentUser
        try {
            var cred = firebase.auth.EmailAuthProvider.credential(loginEmail, loginPassword)
            user.reauthenticateWithCredential(cred).then(() => {
                setModalVisible(false)
                setReauthenticated(true)
                setPageLoading(false)
            })
        }
        catch (err) {
            console.log(err)
            setLoginError(true)
            setPageLoading(false)
        }
    }

    const onSubmitLoginEmail = () => {

    }

    const onSubmitLoginPassword = () => {

    }

    const onSubmitPassword = () => {

    }

    const onSubmitConfirmPassword = () => {

    }

    const cancelEmailChange = () => {
        setModalVisible(false)
        navigation.goBack()
    }

    if (pageLoading) {
        return (
            <View style={styles.parent}>
                <ActivityIndicator />
            </View>
        )
    }

    if (loginError) {
        return (
            <Modal isVisible={modalVisible} keyboardShouldPersistTaps="handled" propagateSwipe={true} style={{ alignItems: 'center' }} >
                <View style={{ width: widthPercent, minWidth: 300, maxWidth: 550, backgroundColor: BACKGROUND_COLOR, borderRadius: 5 }}>
                    <View style={{ alignItems: 'center' }}>
                        <Text style={{ textAlign: 'center', color: HEADER_TEXT_COLOR, fontSize: 30, marginTop: 20 }}>Error</Text>
                    </View>
                    <View style={{ alignItems: 'center' }}>
                        <Text style={{ textAlign: 'center', color: HEADER_TEXT_COLOR, fontSize: 20, marginTop: 20, marginLeft: 10, marginRight: 10 }}>Invalid email or password.  Please try again.</Text>
                    </View>
                    <View style={{ marginTop: 20, borderTopWidth: 1, borderTopColor: SEPARATOR_TINT_COLOR_TWO }}>
                        <View style={{ width: "100%" }}>
                            <TouchableHighlight style={{ alignItems: 'center' }} underlayColor={UNDERLAY_COLOR_ONE} onPress={() => setLoginError(false)}>
                                <View style={{ alignItems: 'center', width: "100%" }}>
                                    <Text style={{ marginTop: 15, marginBottom: 15, fontSize: 15, color: TEXT_COLOR_THREE, fontWeight: 'bold' }}>Ok</Text>
                                </View>
                            </TouchableHighlight>
                        </View>
                    </View>
                </View>
            </Modal>
        )
    }

    return (
        <View style={styles.parent}>
            <ScrollView keyboardShouldPersistTaps='handled'>
                <Section headerTextColor={HEADER_TEXT_COLOR} header="PASSWORD">
                    <Cell backgroundColor={BACKGROUND_COLOR} cellContentView={
                        <TextInput
                            style={{ fontSize: 16, flex: 1, color: TEXT_INPUT_COLOR }}
                            placeholder="Password"
                            ref={refs["password"]}
                            autoCorrect={false}
                            enablesReturnKeyAutomatically={true}
                            onChangeText={(value) => setPassword(value)}
                            value={password}
                            onSubmitEditing={onSubmitPassword}
                            secureTextEntry={true}
                            autoCapitalize="none"
                            returnKeyType="done"
                            returnKeyType="done"
                            placeholderTextColor={PLACEHOLDER_TEXT_INPUT_COLOR}
                        />
                    } />
                    <Cell backgroundColor={BACKGROUND_COLOR} cellContentView={
                        <TextInput
                            style={{ fontSize: 16, flex: 1, color: TEXT_INPUT_COLOR }}
                            placeholder="Confirm Password"
                            ref={refs["confirmPassword"]}
                            autoCorrect={false}
                            enablesReturnKeyAutomatically={true}
                            onChangeText={(value) => setConfirmPassword(value)}
                            value={confirmPassword}
                            onSubmitEditing={onSubmitConfirmPassword}
                            secureTextEntry={true}
                            autoCapitalize="none"
                            returnKeyType="done"
                            returnKeyType="done"
                            placeholderTextColor={PLACEHOLDER_TEXT_INPUT_COLOR}
                        />
                    } />
                </Section>
                <Button
                    onPress={updatePassword}
                    title="Update Password"
                    type="clear"
                    titleStyle={{
                        fontWeight: "normal"
                    }}
                    buttonStyle={{

                    }}
                    disabled={!reauthenticated}
                    loading={changePasswordRequestLoading}
                />
            </ScrollView>
            <Modal isVisible={modalVisible} propagateSwipe={true} style={{ justifyContent: 'flex-end', margin: 0 }} >
                <View style={{ height: dimensions.height * 0.95, width: widthPercent, marginLeft: marginPercent, backgroundColor: BACKGROUND_COLOR, borderTopLeftRadius: 5, borderTopStartRadius: 5, borderTopRightRadius: 5, borderTopEndRadius: 5 }}>
                    <View style={{ flexDirection: 'row', alignItems: 'center', marginTop: 10, marginBottom: 10 }}>
                        <View
                            style={{
                                width: "25%",
                                height: 50,
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}>
                        </View>
                        <View
                            style={{
                                width: "50%",
                                height: 50,
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}>
                            <Text style={{ fontSize: 20, fontWeight: '500', color: HEADER_TEXT_COLOR }} >
                                Sign In
                            </Text>
                        </View>
                        <View
                            style={{
                                width: "25%",
                                height: 50,
                                justifyContent: 'center',
                                alignItems: 'flex-end'
                            }}>
                            <View style={{ flexDirection: 'row', justifyContent: 'space-between', marginRight: 10 }}>
                                <Button
                                    onPress={cancelEmailChange}
                                    title="Cancel"
                                    type="clear"
                                    titleStyle={{
                                        fontWeight: "normal"
                                    }}
                                    buttonStyle={{

                                    }}
                                    disabled={pageLoading}
                                />
                            </View>
                        </View>
                    </View>
                    <ScrollView>
                        <View>
                            <Text style={styles.subheading}>Please sign in again to change your password.</Text>
                            <Section headerTextColor={HEADER_TEXT_COLOR} header="EMAIL">
                                <Cell backgroundColor={BACKGROUND_COLOR} cellContentView={
                                    <TextInput
                                        style={{ fontSize: 16, flex: 1, color: TEXT_INPUT_COLOR }}
                                        placeholder="Email"
                                        ref={refs["loginEmail"]}
                                        autoCorrect={false}
                                        enablesReturnKeyAutomatically={true}
                                        onChangeText={(value) => setLoginEmail(value)}
                                        value={loginEmail}
                                        autoCapitalize="none"
                                        onSubmitEditing={onSubmitLoginEmail}
                                        returnKeyType="done"
                                        returnKeyType="done"
                                        placeholderTextColor={PLACEHOLDER_TEXT_INPUT_COLOR}
                                    />
                                } />
                            </Section>
                            <Section headerTextColor={HEADER_TEXT_COLOR} header="PASSWORD">
                                <Cell backgroundColor={BACKGROUND_COLOR} cellContentView={
                                    <TextInput
                                        style={{ fontSize: 16, flex: 1, color: TEXT_INPUT_COLOR }}
                                        placeholder="Password"
                                        ref={refs["loginPassword"]}
                                        autoCorrect={false}
                                        enablesReturnKeyAutomatically={true}
                                        onChangeText={(value) => setLoginPassword(value)}
                                        value={loginPassword}
                                        autoCapitalize="none"
                                        onSubmitEditing={onSubmitLoginPassword}
                                        returnKeyType="done"
                                        returnKeyType="done"
                                        placeholderTextColor={PLACEHOLDER_TEXT_INPUT_COLOR}
                                        secureTextEntry={true}
                                    />
                                } />
                            </Section>
                            <View style={{ height: 50, width: "100%", marginTop: 40, alignItems: 'center', marginBottom: 50 }}>
                                <View style={{ width: "70%", maxWidth: 250, minWidth: 200 }} >
                                    <Button
                                        onPress={reauthenticate}
                                        title='Sign In'
                                        buttonStyle={{
                                            backgroundColor: BUTTON_COLOR_TWO
                                        }}
                                        loading={pageLoading}
                                    />
                                </View>
                            </View>
                        </View>
                    </ScrollView>
                    <KeyboardSpacer />
                </View>
            </Modal>
        </View>
    );
}
