export const DEV = false;

export const BACKGROUND_COLOR = '#cedde3';
export const ACCENT_COLOR_ONE = '#9cc8da';
export const ACCENT_COLOR_TWO = '#4f89a1';
export const ACCENT_COLOR_THREE = '#244959';
export const ACCENT_COLOR_FOUR = '#537b8c';
export const ACCENT_COLOR_FIVE = '#9bb8c4';
export const ACCENT_COLOR_SIX = '#437990';
export const ACCENT_COLOR_SEVEN = '#000000';
export const TEXT_COLOR_ONE = '#FFFFFF';
export const TEXT_COLOR_TWO = '#4f89a1';
export const TEXT_COLOR_THREE = '#244959';
export const TEXT_COLOR_FOUR = '#000000';
export const TEXT_INPUT_COLOR = '#244959';
export const DISABLED_TEXT_INPUT_COLOR = '#808080';
export const PLACEHOLDER_TEXT_INPUT_COLOR = '#808080';
export const HEADER_TEXT_COLOR = '#244959';
export const CELL_BACKGROUND_COLOR = '#cedde3';
export const CELL_TITLE_TEXT_COLOR = '#244959';
export const CELL_DETAIL_TEXT_COLOR = '#244959';
export const RIGHT_DETAIL_TEXT_COLOR = '#244959';
export const FOOTER_TEXT_COLOR = '#244959';
export const DISABLED_TEXT_COLOR = '#808080';
export const BUTTON_COLOR_ONE = '#4f89a1';
export const BUTTON_COLOR_TWO = '#244959';
export const BUTTON_TITLE_COLOR_ONE = '#FFFFFF';
export const BUTTON_TITLE_COLOR_TWO = '#4f89a1';
export const DEFAULT_TEXT_COLOR = '#FFFFFF';
export const SEPARATOR_TINT_COLOR_ONE = '#dedde3';
export const SEPARATOR_TINT_COLOR_TWO = '#c7c6cc';
export const UNDERLAY_COLOR_ONE = '#c7c6cc';
export const TOP_TAB_HEADER_ICON_COLOR = '#FFFFFF'

export const TOP_TAB_HEADER_ICON_SIZE = 24
export const HEADING_TEXT_SIZE = 40;
export const SUBHEADING_TEXT_SIZE = 20;
export const SUBSUBHEADING_TEXT_SIZE = 20;
export const PARAGRAPH_TEXT_SIZE = 15;

export const CONNECTION_ERROR_HEADER = 'Error';
export const CONNECTION_ERROR_MESSAGE = 'Error connecting to our services.  Please try again.  If this issue persists, please contact us.';
export const CONNECTION_ERROR_BUTTON = 'Ok';
export const CURRENT_PLAN_MESSAGE = 'This is your current plan.';
export const RECOMMENDED_PLAN_MESSAGE = 'Recommended plan for your household';

export const WASH_AND_FOLD_PICKUP_NAME = "washAndFoldPickup";
export const WASH_AND_FOLD_PICKUP_DISPLAY_NAME = "Wash and Fold";
export const WASH_AND_FOLD_PICKUP_DESCRIPTION = "Wash and Fold Pickup";

export const DRY_CLEAN_PICKUP_NAME = "dryCleanPickup";
export const DRY_CLEAN_PICKUP_DISPLAY_NAME = "Dry Cleaning";
export const DRY_CLEAN_PICKUP_DESCRIPTION = "Dry-Cleaning Pickup"

export const ONE_TIME_PICKUP = {
    displayName: "One Time Pickup",
    description: "One Time Pickup",
    name: "oneTimePickup",
    weeksPerPickup: 0
}
export const WEEKLY_PLAN = {
    displayName: "Weekly Pickups",
    description: "Weekly Pickups",
    name: "weekly",
    weeksPerPickup: 1
}

export const BI_WEEKLY_PLAN = {
    displayName: "Bi-Weekly Pickups (Every Two Weeks)",
    description: "Bi-Weekly Pickups (Every Two Weeks)",
    name: "biweekly",
    weeksPerPickup: 2
}

export const DRY_CLEAN_PRICES = {
    'Pants': '$7.50',
    '2 pc Suits': '$14.50',
    '3 pc Suits': '$18.50',
    'Shirt Laundered': '$6',
    'Shirt Dry Cleaned': '$8',
    'Jackets': '$10',
    'Coats': '$14',
    'Dresses': '$11.50',
    'Skirts': '$8.50',
    'Blouses': '$8.50',
    'Uniforms': '$11.50'
}