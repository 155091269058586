import React, { useState, useEffect, useRef, useLayoutEffect } from 'react'
import { View, ActivityIndicator, ScrollView, BackHandler, Dimensions } from 'react-native'
import firebase from "firebase/app"
import "firebase/auth"
import "firebase/firestore"
import "firebase/functions"
import { Cell, Section, TableView } from 'react-native-tableview-simple';
import { HeaderBackButton } from '@react-navigation/stack'
import styles from '../styles';
import { Button } from 'react-native-elements';
import { BACKGROUND_COLOR, BUTTON_COLOR_ONE, BUTTON_COLOR_TWO, CELL_BACKGROUND_COLOR, CELL_TITLE_TEXT_COLOR, CONNECTION_ERROR_BUTTON, CONNECTION_ERROR_HEADER, CONNECTION_ERROR_MESSAGE, FOOTER_TEXT_COLOR, HEADER_TEXT_COLOR, SEPARATOR_TINT_COLOR_ONE, SEPARATOR_TINT_COLOR_TWO } from '../../../../constants';
import AlertModal from '../../AlertModal'


export default function LaundryPreferences({ route, navigation }) {

    useLayoutEffect(() => {
        navigation.setOptions({
            headerRight: null,
            headerLeft: () => {
                return <HeaderBackButton
                    label="Back"
                    onPress={() => navigation.goBack()}
                    disabled={pageLoading}
                />
            },
        })
    })

    const logout = async () => {
        try {
            await auth().signOut();
            setPageLoading(false)
        }
        catch (err) {
            openAlertModal('Error Logging Out', 'Please try again', 'Try Again', logout)
        }
    }

    const nextPage = async () => {
        setPageLoading(true)
        try {
            const user = firebase.auth().currentUser;
            await firebase.firestore().collection('userProfiles').doc(user.uid).update({
                detergent: detergent,
                dryerSheet: dryerSheet,
                fabricSoftener: fabricSoftener
            })
            navigation.navigate("Account Creation Completed")
            setPageLoading(false)
        }
        catch (err) {
            console.log('error updating profile in firebase:', err)
            setPageLoading(false)
            openAlertModal('Error Updating Profile', 'We are unable to update your profile at this time.  Please try again.  If this issue persists, please log out and contact us.', 'Try Again', nextPage, 'Log Out', logout)
        }
    }

    const [pageLoading, setPageLoading] = useState(true);
    const [detergents, setDetergents] = useState([])
    const [dryerSheets, setDryerSheets] = useState([])
    const [fabricSofteners, setFabricSofteners] = useState([])
    const [detergent, setDetergent] = useState("")
    const [dryerSheet, setDryerSheet] = useState("")
    const [fabricSoftener, setFabricSoftener] = useState("")
    const [detergentAccessory, setDetergentAccessory] = useState({})
    const [fabricSoftenerAccessory, setFabricSoftenerAccessory] = useState({})
    const [dryerSheetAccessory, setDryerSheetAccessory] = useState({})
    const [alertModalVisibile, setAlertModalVisible] = useState(false);
    const [alertInfo, setAlertInfo] = useState({})
    const openAlertModal = (title, subtitle, primaryButtonText, primaryButtonAction, secondaryButtonText = null, secondaryButtonAction = null) => {
        setAlertInfo({
            alertTitleText: title,
            alertSubtitleText: subtitle,
            alertPrimaryButtonText: primaryButtonText,
            alertPrimaryAction: () => primaryButtonAction(),
            alertSecondaryButtonText: secondaryButtonText,
            alertSecondaryAction: () => secondaryButtonAction()
        })
        setAlertModalVisible(true)
    }
    const closeAlertModal = () => {
        setAlertModalVisible(false)
    }

    const selectDetergent = (scent, accessoryParam = null) => {
        var accessoryLocal = accessoryParam
        if (!accessoryLocal) {
            accessoryLocal = detergentAccessory
        }
        setDetergent(scent)
        for (var key in accessoryLocal) {
            if (key == scent) {
                accessoryLocal[key] = "Checkmark"
            }
            else {
                accessoryLocal[key] = "none"
            }
        }
        setDetergentAccessory(accessoryLocal)
    }

    const selectDryerSheet = (scent, accessoryParam = null) => {
        var accessoryLocal = accessoryParam
        if (!accessoryLocal) {
            accessoryLocal = dryerSheetAccessory
        }
        setDryerSheet(scent)
        for (var key in accessoryLocal) {
            if (key == scent) {
                accessoryLocal[key] = "Checkmark"
            }
            else {
                accessoryLocal[key] = "none"
            }
        }
        setDryerSheetAccessory(accessoryLocal)
    }

    const selectFabricSoftenter = (scent, accessoryParam = null) => {
        var accessoryLocal = accessoryParam
        if (!accessoryLocal) {
            accessoryLocal = fabricSoftenerAccessory
        }
        setFabricSoftener(scent)
        for (var key in accessoryLocal) {
            if (key == scent) {
                accessoryLocal[key] = "Checkmark"
            }
            else {
                accessoryLocal[key] = "none"
            }
        }
        setFabricSoftenerAccessory(accessoryLocal)
    }

    const getPageAndUserInfo = async () => {
        setPageLoading(true)
        try {
            const laundryDoc = await firebase.firestore().collection('pageInfo').doc('laundry').get()
            var detergentsLocal = []
            var dryerSheetsLocal = []
            var fabricSoftenersLocal = []
            var detergentAccessoryLocal = {}
            var dryerSheetAccessoryLocal = {}
            var fabricSoftenerAccessoryLocal = {}
            if (laundryDoc.data() && laundryDoc.data().detergent && laundryDoc.data().dryerSheet && laundryDoc.data().fabricSoftener) {
                detergentsLocal = laundryDoc.data().detergent
                dryerSheetsLocal = laundryDoc.data().dryerSheet
                fabricSoftenersLocal = laundryDoc.data().fabricSoftener
                var index = 0
                detergentsLocal.forEach((detergentLocal) => {
                    if (index === 0) {
                        setDetergent(detergent)
                        detergentAccessoryLocal[detergentLocal] = "Checkmark"
                        index++
                    }
                    else {
                        detergentAccessoryLocal[detergentLocal] = "none"
                    }
                })
                index = 0
                dryerSheetsLocal.forEach((dryerSheetLocal) => {
                    if (index === 0) {
                        setDryerSheet(dryerSheetLocal)
                        dryerSheetAccessoryLocal[dryerSheetLocal] = "Checkmark"
                        index++
                    }
                    else {
                        dryerSheetAccessoryLocal[dryerSheetLocal] = "none"
                    }
                })
                index = 0
                fabricSoftenersLocal.forEach((fabricSoftenerLocal) => {
                    if (index === 0) {
                        setFabricSoftener(fabricSoftenerLocal)
                        fabricSoftenerAccessoryLocal[fabricSoftenerLocal] = "Checkmark"
                        index++
                    }
                    else {
                        fabricSoftenerAccessoryLocal[fabricSoftenerLocal] = "none"
                    }
                })
                setDetergentAccessory(detergentAccessoryLocal)
                setDryerSheetAccessory(dryerSheetAccessoryLocal)
                setFabricSoftenerAccessory(fabricSoftenerAccessoryLocal)
                setDetergents(detergentsLocal)
                setDryerSheets(dryerSheetsLocal)
                setFabricSofteners(fabricSoftenersLocal)
            }
            else {
                setPageLoading(false)
                openAlertModal(CONNECTION_ERROR_HEADER, CONNECTION_ERROR_MESSAGE, CONNECTION_ERROR_BUTTON, getPageAndUserInfo)
                return
            }

            // get user info
            const user = firebase.auth().currentUser;
            const userInfo = (await firebase.firestore().collection("userProfiles").doc(user.uid).get()).data();
            if (userInfo) {
                if (userInfo.detergent && userInfo.dryerSheet && userInfo.fabricSoftener) {
                    selectDetergent(userInfo.detergent, detergentAccessoryLocal)
                    selectDryerSheet(userInfo.dryerSheet, dryerSheetAccessoryLocal)
                    selectFabricSoftenter(userInfo.fabricSoftener, fabricSoftenerAccessoryLocal)
                }
            }
            else {
                console.log('Error: no account for this user')
                setPageLoading(false)
                openAlertModal('Error', 'We do not have a profile created for you.  Please logout and login again.', 'Log Out', logout)
                return
            }
            setPageLoading(false)
        }
        catch (err) {
            console.log('error:', err)
            setPageLoading(false)
            openAlertModal(CONNECTION_ERROR_HEADER, CONNECTION_ERROR_MESSAGE, CONNECTION_ERROR_BUTTON, getPageAndUserInfo)
            return
        }
    }

    useEffect(() => {
        getPageAndUserInfo()
        BackHandler.addEventListener('hardwareBackPress', onBackPress);
        return () => {
            BackHandler.removeEventListener('hardwareBackPress', onBackPress);
        }
    }, []);

    const onBackPress = () => {
        return true;
    }

    const createDetergentTable = () => {
        var valueArray = []
        var index = 0
        detergents.forEach((detergent) => {
            valueArray.push(
                <Cell key={index} cellStyle="Basic" backgroundColor={CELL_BACKGROUND_COLOR} titleTextColor={CELL_TITLE_TEXT_COLOR} title={detergent} accessory={detergentAccessory[detergent]} onPress={() => selectDetergent(detergent)} />
            )
            index++
        })
        return valueArray
    }

    const createFabricSoftenerTable = () => {
        var valueArray = []
        var index = 0
        fabricSofteners.forEach((fabricSoftener) => {
            valueArray.push(
                <Cell key={index} cellStyle="Basic" backgroundColor={CELL_BACKGROUND_COLOR} titleTextColor={CELL_TITLE_TEXT_COLOR} title={fabricSoftener} accessory={fabricSoftenerAccessory[fabricSoftener]} onPress={() => selectFabricSoftenter(fabricSoftener)} />
            )
            index++
        })
        return valueArray
    }

    const createDryerSheetTable = () => {
        var valueArray = []
        var index = 0
        dryerSheets.forEach((dryerSheet) => {
            valueArray.push(
                <Cell key={index} cellStyle="Basic" backgroundColor={CELL_BACKGROUND_COLOR} titleTextColor={CELL_TITLE_TEXT_COLOR} title={dryerSheet} accessory={dryerSheetAccessory[dryerSheet]} onPress={() => selectDryerSheet(dryerSheet)} />
            )
            index++
        })
        return valueArray
    }

    if (pageLoading) {
        return (
            <View style={styles.parent}>
                <ActivityIndicator />
            </View>
        )
    }

    return (
        <View style={styles.parent}>
            <ScrollView>
                <Section headerTextColor={HEADER_TEXT_COLOR} footerTextColor={FOOTER_TEXT_COLOR} separatorTintColor={SEPARATOR_TINT_COLOR_TWO} header="DETERGENT" footer="">
                    {createDetergentTable()}
                </Section>
                <Section headerTextColor={HEADER_TEXT_COLOR} footerTextColor={FOOTER_TEXT_COLOR} separatorTintColor={SEPARATOR_TINT_COLOR_TWO} header="FABRIC SOFTENER" footer="">
                    {createFabricSoftenerTable()}
                </Section>
                <Section headerTextColor={HEADER_TEXT_COLOR} footerTextColor={FOOTER_TEXT_COLOR} separatorTintColor={SEPARATOR_TINT_COLOR_TWO} header="DRYER SHEET" footer="">
                    {createDryerSheetTable()}
                </Section>
                <View style={{ height: 50, width: "100%", marginTop: 40, alignItems: 'center', marginBottom: 50 }}>
                    <View style={{ width: "70%", maxWidth: 250, minWidth: 200 }} >
                        <Button
                            onPress={nextPage}
                            title='Continue'
                            buttonStyle={{
                                backgroundColor: BUTTON_COLOR_TWO
                            }}
                        />
                    </View>
                </View>
            </ScrollView>
            <AlertModal
                modalVisible={alertModalVisibile}
                setModalVisible={setAlertModalVisible}
                alertTitleText={alertInfo.alertTitleText}
                alertSubtitleText={alertInfo.alertSubtitleText}
                alertPrimaryButtonText={alertInfo.alertPrimaryButtonText}
                alertPrimaryAction={alertInfo.alertPrimaryAction}
                alertSecondaryButtonText={alertInfo.alertSecondaryButtonText}
                alertSecondaryAction={alertInfo.alertSecondaryAction}
            />
        </View>
    );
}