import React, { useEffect, useState } from 'react';
import {
    View,
    SafeAreaView,
    Text,
    ScrollView,
    Image,
    Dimensions,
    TouchableOpacity,
    Alert
} from 'react-native';
// import { RaisedTextButton } from 'react-native-material-buttons';
import { Card, Button } from 'react-native-elements';
import { ACCENT_COLOR_ONE, ACCENT_COLOR_THREE, TEXT_COLOR_ONE, TEXT_COLOR_THREE, ACCENT_COLOR_FIVE, TEXT_COLOR_FOUR, ACCENT_COLOR_SIX } from '../../../constants';
import styles from './styles';

const window = Dimensions.get("window");
const screen = Dimensions.get("screen");

export default function Every4Weeks({ navigation }) {

    useEffect(() => {

    }, []);

    return (
        <View style={styles.parent}>
            <ScrollView keyboardShouldPersistTaps='handled'>
                <SafeAreaView style={styles.safeContainer}>
                    <View style={{ marginTop: 50, alignItems: 'center' }}>
                        <View style={{ width: "95%", maxWidth: 600, backgroundColor: ACCENT_COLOR_FIVE, }}>
                            <Text style={{ textAlign: 'center', color: TEXT_COLOR_FOUR, flexWrap: "wrap", fontSize: 25, width: "90%", marginLeft: "5%", marginTop: 15, marginBottom: 15, textDecorationLine: 'underline' }}>How to Use Rewards</Text>
                            <Text style={{ textAlign: 'left', color: TEXT_COLOR_FOUR, flexWrap: "wrap", fontSize: 20, width: "90%", marginLeft: "5%", marginTop: 15, marginBottom: 15 }}>Tumble points can be used to redeem free washes, preload gift cards, and earn discounts on Tumble items.</Text>
                            <Text style={{ textAlign: 'left', color: TEXT_COLOR_FOUR, flexWrap: "wrap", fontSize: 20, width: "90%", marginLeft: "5%", marginTop: 15, marginBottom: 15 }}>You can use Tumble points by selecting the "redeem popints" button on the rewards page.  After selecting this button, you will need to choose the reward you would like to redeem.  Apply earned points to your next pickup!</Text>
                        </View>
                        <View style={{ width: "95%", maxWidth: 600, backgroundColor: ACCENT_COLOR_THREE, }}>
                            <Text style={{ textAlign: 'center', color: TEXT_COLOR_ONE, flexWrap: "wrap", fontSize: 25, width: "90%", marginLeft: "5%", marginTop: 15, marginBottom: 15 }}>Points Per Pickup</Text>
                        </View>
                        <View style={{ width: "95%", maxWidth: 600, backgroundColor: ACCENT_COLOR_SIX, marginBottom: 20 }}>
                            <ScrollView style={{ minHeight: 100, maxHeight: 300 }}>
                                <View>
                                    <View style={{ width: "100%", marginTop: 10 }}>
                                        <Text style={{ textAlign: 'left', color: TEXT_COLOR_ONE, marginLeft: 10, flexWrap: "wrap", fontSize: 25, width: "90%", marginTop: 10 }}>Tumble University</Text>
                                    </View>
                                    <View style={{ width: "100%", flexDirection: "row", marginTop: 5 }}>
                                        <View style={{ width: "50%" }}>
                                            <Text style={{ textAlign: 'left', color: TEXT_COLOR_ONE, marginLeft: 10, flexWrap: "wrap", fontSize: 15, width: "90%", marginTop: 5 }}>Monthly Plan</Text>
                                        </View>
                                        <View style={{ width: "50%" }}>
                                            <Text style={{ textAlign: 'center', color: TEXT_COLOR_ONE, flexWrap: "wrap", fontSize: 15, width: "90%", marginTop: 5 }}>5 pts</Text>
                                        </View>
                                    </View>
                                    <View style={{ width: "100%", flexDirection: "row", marginTop: 5 }}>
                                        <View style={{ width: "50%" }}>
                                            <Text style={{ textAlign: 'left', color: TEXT_COLOR_ONE, marginLeft: 10, flexWrap: "wrap", fontSize: 15, width: "90%", marginTop: 10 }}>Annual Plan</Text>
                                        </View>
                                        <View style={{ width: "50%" }}>
                                            <Text style={{ textAlign: 'center', color: TEXT_COLOR_ONE, flexWrap: "wrap", fontSize: 15, width: "90%", marginTop: 10 }}>10 pts</Text>
                                        </View>
                                    </View>
                                </View>
                                <View>
                                    <View style={{ width: "100%", marginTop: 10 }}>
                                        <Text style={{ textAlign: 'left', color: TEXT_COLOR_ONE, marginLeft: 10, flexWrap: "wrap", fontSize: 25, width: "90%", marginTop: 10 }}>Tumble Solo</Text>
                                    </View>
                                    <View style={{ width: "100%", flexDirection: "row", marginTop: 5 }}>
                                        <View style={{ width: "50%" }}>
                                            <Text style={{ textAlign: 'left', color: TEXT_COLOR_ONE, marginLeft: 10, flexWrap: "wrap", fontSize: 15, width: "90%", marginTop: 5 }}>Monthly Plan</Text>
                                        </View>
                                        <View style={{ width: "50%" }}>
                                            <Text style={{ textAlign: 'center', color: TEXT_COLOR_ONE, flexWrap: "wrap", fontSize: 15, width: "90%", marginTop: 5 }}>5 pts</Text>
                                        </View>
                                    </View>
                                    <View style={{ width: "100%", flexDirection: "row", marginTop: 5 }}>
                                        <View style={{ width: "50%" }}>
                                            <Text style={{ textAlign: 'left', color: TEXT_COLOR_ONE, marginLeft: 10, flexWrap: "wrap", fontSize: 15, width: "90%", marginTop: 10 }}>Annual Plan</Text>
                                        </View>
                                        <View style={{ width: "50%" }}>
                                            <Text style={{ textAlign: 'center', color: TEXT_COLOR_ONE, flexWrap: "wrap", fontSize: 15, width: "90%", marginTop: 10 }}>10 pts</Text>
                                        </View>
                                    </View>
                                </View>
                                <View>
                                    <View style={{ width: "100%", marginTop: 10 }}>
                                        <Text style={{ textAlign: 'left', color: TEXT_COLOR_ONE, marginLeft: 10, flexWrap: "wrap", fontSize: 25, width: "90%", marginTop: 10 }}>Little Tumble</Text>
                                    </View>
                                    <View style={{ width: "100%", flexDirection: "row", marginTop: 5 }}>
                                        <View style={{ width: "50%" }}>
                                            <Text style={{ textAlign: 'left', color: TEXT_COLOR_ONE, marginLeft: 10, flexWrap: "wrap", fontSize: 15, width: "90%", marginTop: 5 }}>Monthly Plan</Text>
                                        </View>
                                        <View style={{ width: "50%" }}>
                                            <Text style={{ textAlign: 'center', color: TEXT_COLOR_ONE, flexWrap: "wrap", fontSize: 15, width: "90%", marginTop: 5 }}>10 pts</Text>
                                        </View>
                                    </View>
                                    <View style={{ width: "100%", flexDirection: "row", marginTop: 5 }}>
                                        <View style={{ width: "50%" }}>
                                            <Text style={{ textAlign: 'left', color: TEXT_COLOR_ONE, marginLeft: 10, flexWrap: "wrap", fontSize: 15, width: "90%", marginTop: 10 }}>Annual Plan</Text>
                                        </View>
                                        <View style={{ width: "50%" }}>
                                            <Text style={{ textAlign: 'center', color: TEXT_COLOR_ONE, flexWrap: "wrap", fontSize: 15, width: "90%", marginTop: 10 }}>15 pts</Text>
                                        </View>
                                    </View>
                                </View>
                                <View style={{ marginBottom: 20 }}>
                                    <View style={{ width: "100%", marginTop: 10 }}>
                                        <Text style={{ textAlign: 'left', color: TEXT_COLOR_ONE, marginLeft: 10, flexWrap: "wrap", fontSize: 25, width: "90%", marginTop: 10 }}>Steady Tumble</Text>
                                    </View>
                                    <View style={{ width: "100%", flexDirection: "row", marginTop: 5 }}>
                                        <View style={{ width: "50%" }}>
                                            <Text style={{ textAlign: 'left', color: TEXT_COLOR_ONE, marginLeft: 10, flexWrap: "wrap", fontSize: 15, width: "90%", marginTop: 5 }}>Monthly Plan</Text>
                                        </View>
                                        <View style={{ width: "50%" }}>
                                            <Text style={{ textAlign: 'center', color: TEXT_COLOR_ONE, flexWrap: "wrap", fontSize: 15, width: "90%", marginTop: 5 }}>15 pts</Text>
                                        </View>
                                    </View>
                                    <View style={{ width: "100%", flexDirection: "row", marginTop: 5 }}>
                                        <View style={{ width: "50%" }}>
                                            <Text style={{ textAlign: 'left', color: TEXT_COLOR_ONE, marginLeft: 10, flexWrap: "wrap", fontSize: 15, width: "90%", marginTop: 10 }}>Annual Plan</Text>
                                        </View>
                                        <View style={{ width: "50%" }}>
                                            <Text style={{ textAlign: 'center', color: TEXT_COLOR_ONE, flexWrap: "wrap", fontSize: 15, width: "90%", marginTop: 10 }}>20 pts</Text>
                                        </View>
                                    </View>
                                </View>
                            </ScrollView>
                        </View>
                    </View>
                </SafeAreaView>
            </ScrollView>
        </View>
    );
}