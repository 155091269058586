import React, { Component, useState, useEffect, useContext, useRef, useCallback } from 'react';
import {
    View,
    SafeAreaView,
    Text,
    Alert,
    ScrollView,
    Image,
    Dimensions,
    TextInput,
    ActivityIndicator,
    useWindowDimensions,
} from 'react-native';
import { Appbar } from 'react-native-paper';
import { Button, Input, Card } from 'react-native-elements';
import styles from './styles';
import KeyboardSpacer from '../KeyboardSpacer';
import { Cell, Section, TableView } from 'react-native-tableview-simple';
import firebase from "firebase/app"
import "firebase/auth"
import "firebase/firestore"
import "firebase/functions"
import AuthContext from '../../../AuthContext.js'
import HoverableOpacity from '../../../HoverableOpacity.js'
import TopHeading from '../../../TopHeading.js'
import AlertModal from '../AlertModal'
import { ACCENT_COLOR_ONE, ACCENT_COLOR_TWO, BUTTON_COLOR_ONE, TEXT_COLOR_ONE, TEXT_COLOR_THREE } from '../../../constants';
import { useFocusEffect } from '@react-navigation/native';

const window = Dimensions.get("window");
const screen = Dimensions.get("screen");

export default function Home({ navigation }) {
    const dimensions = useWindowDimensions()

    const refs = {
        "address": useRef(),
        "date": useRef(),
    }

    const [pageLoading, setPageLoading] = useState(true)
    const [userFirstName, setUserFirstName] = useState("")
    const [pickups, setPickups] = useState()
    const [noPlan, setNoPlan] = useState(true)
    const [alertModalVisibile, setAlertModalVisible] = useState(false)
    const [alertInfo, setAlertInfo] = useState({})
    const closeAlertModal = () => {
        setAlertModalVisible(false)
    }
    const openAlertModal = (title, subtitle, primaryButtonText, primaryButtonAction, secondaryButtonText = null, secondaryButtonAction = null) => {
        setAlertInfo({
            alertTitleText: title,
            alertSubtitleText: subtitle,
            alertPrimaryButtonText: primaryButtonText,
            alertPrimaryAction: () => primaryButtonAction(),
            alertSecondaryButtonText: secondaryButtonText,
            alertSecondaryAction: () => secondaryButtonAction()
        })
        setAlertModalVisible(true)
    }

    useFocusEffect(
        useCallback(() => {
            setPageLoading(true)
            getUserInfo()
            const user = firebase.auth().currentUser;
            const snapshotSubscriber = firebase.firestore()
                .collection('pickups')
                .where('userId', '==', user.uid)
                .onSnapshot(querySnapshot => {
                    setPageLoading(true)
                    const data = querySnapshot.docs.map(doc => doc)
                    setPickups(data);
                    setPageLoading(false)
                });
            return () => {
                snapshotSubscriber();
            }
        }, [])
    )


    useEffect(() => {

    }, []);

    const getUserInfo = async () => {
        setPageLoading(true)
        const user = firebase.auth().currentUser;
        const userInfo = (await firebase.firestore().collection("userProfiles").doc(user.uid).get()).data();

        if (userInfo) {
            setUserFirstName(userInfo.firstName)
            if (userInfo.plan === 'None') {
                setNoPlan(true)
            }
            else {
                setNoPlan(false)
            }
        }

    }

    const schedulePickups = () => {
        navigation.navigate("Schedule Options")
    }

    const openDetails = (pickupId) => {
        navigation.navigate("Pickup Detail", {
            pickupId: pickupId,
            pickupCurrent: true
        })
    }

    const convertDayOfWeekToString = (dayOfWeekNumber) => {
        switch (dayOfWeekNumber) {
            case 0:
                return "Sunday"
                break;
            case 1:
                return "Monday"
                break;
            case 2:
                return "Tuesday"
                break;
            case 3:
                return "Wednesday"
                break;
            case 4:
                return "Thursday"
                break;
            case 5:
                return "Friday"
                break;
            case 6:
                return "Saturday"
                break;
            default:
                return "Day of Week"
                break;
        }
    }

    const convertMonthToString = (monthNumber) => {
        switch (monthNumber) {
            case 0:
                return "January"
                break;
            case 1:
                return "February"
                break;
            case 2:
                return "March"
                break;
            case 3:
                return "April"
                break;
            case 4:
                return "May"
                break;
            case 5:
                return "June"
                break;
            case 6:
                return "July"
                break;
            case 7:
                return "August"
                break;
            case 8:
                return "September"
                break;
            case 9:
                return "October"
                break;
            case 10:
                return "November"
                break;
            case 11:
                return "December"
                break;
            default:
                return "Month"
                break;
        }
    }

    const displayNextPickup = () => {
        var valueArray = []
        let currentDate = new Date()
        const maximumDate = new Date()
        maximumDate.setDate(maximumDate.getDate() + 30)
        let soonestDate = maximumDate
        let tableContents = (<Cell cellStyle="Basic" title="No upcoming pickups" />)
        let nextPickupDate = ""
        let nextPickupDayOfWeek = ""
        let nextPickupMonth = ""
        let nextPickupDayOfMonth = ""
        let nextPickupToday = false
        let upcomingPickups = false
        let pickupId = 0
        if (pickups) {
            for (var i = 0; i < pickups.length; i++) {
                let pickupDate = new Date(pickups[i].data().pickupDate)
                if ((pickupDate >= currentDate) && (pickupDate < soonestDate)) {
                    upcomingPickups = true
                    soonestDate = pickupDate
                    if (pickupDate.toDateString() === currentDate.toDateString()) {
                        nextPickupToday = true
                    }
                    nextPickupDate = pickupDate
                    nextPickupDayOfWeek = pickupDate.getDay()
                    nextPickupMonth = pickupDate.getMonth()
                    nextPickupDayOfMonth = pickupDate.getDate()
                    let title = pickups[i].data().pickupDay
                    pickupId = pickups[i].id
                    tableContents = (<Cell cellStyle="Basic" title={title} accessory="DisclosureIndicator" onPress={() => openDetails(pickupId)} />)
                }
            }
            if (upcomingPickups) {
                let monthString = convertMonthToString(nextPickupMonth)
                let dayOfWeekString = convertDayOfWeekToString(nextPickupDayOfWeek)
                if (nextPickupToday) {
                    dayOfWeekString = "Today"
                }
                return (
                    <>
                        <View style={{ width: "100%", alignItems: 'center' }}>
                            <Text style={{ fontSize: 25, textAlign: 'center', color: TEXT_COLOR_THREE, marginTop: 10 }}>
                                Welcome, Your next Pickup is:
                            </Text>
                            <View style={{ width: "50%", minWidth: 200, maxWidth: 350, marginTop: 10, marginBottom: 10 }}>
                                <Section header="" footer="" separatorInsetLeft={false}>
                                    <Cell
                                        cellContentView={
                                            <View style={{ alignItems: 'center', flex: 1, paddingVertical: 15 }}>
                                                <Text allowFontScaling numberOfLines={1} style={{ fontSize: 25, textAlign: 'center', color: TEXT_COLOR_ONE }} >
                                                    {dayOfWeekString}
                                                </Text>
                                            </View>
                                        }
                                        backgroundColor={ACCENT_COLOR_TWO}
                                        onPress={() => openDetails(pickupId)}
                                        accessoryColor={TEXT_COLOR_ONE}
                                    />
                                    <Cell
                                        cellContentView={
                                            <View
                                                style={{ alignItems: 'center', flex: 1, paddingVertical: 15, marginTop: 7 }}
                                            >
                                                <Text
                                                    style={{ flex: 1, fontSize: 25, marginTop: 5, color: TEXT_COLOR_ONE }}
                                                >
                                                    {monthString}
                                                </Text>
                                                <Text
                                                    style={{ flex: 1, fontSize: 35, marginTop: 10, marginBottom: 12, color: TEXT_COLOR_ONE }}
                                                >
                                                    {nextPickupDayOfMonth}
                                                </Text>
                                            </View>
                                        }
                                        onPress={() => openDetails(pickupId)}
                                        backgroundColor={ACCENT_COLOR_ONE}
                                    />
                                </Section>
                            </View>
                        </View>
                    </>
                )
            }
            else {
                return (
                    <>
                        <View style={{ width: "100%", marginTop: 50, marginBottom: 50, justifyContent: "center" }}>
                            <Text style={{ fontSize: 35, textAlign: 'center', color: TEXT_COLOR_THREE, marginTop: 20, marginBottom: 50 }}>
                                No upcoming pickups
                            </Text>
                        </View>
                    </>
                )
            }

        }
    }

    const viewUpcomingPickups = () => {
        navigation.navigate('Upcoming')
    }

    const primaryButton = () => {
        if (noPlan) {
            return (
                <Button
                    onPress={schedulePickups}
                    title='Schedule Pickups'
                    buttonStyle={{
                        backgroundColor: BUTTON_COLOR_ONE
                    }}
                />
            )
        }
        else {
            return (
                <Button
                    onPress={schedulePickups}
                    title='Schedule Pickups'
                    buttonStyle={{
                        backgroundColor: BUTTON_COLOR_ONE
                    }}
                />
            )
        }
    }

    const secondaryButton = () => {
        if (noPlan) {
            return (
                <Button
                    onPress={viewUpcomingPickups}
                    title='View Upcoming Pickups'
                    buttonStyle={{
                        backgroundColor: BUTTON_COLOR_ONE
                    }}
                />
            )
        }
        else {
            return (
                <Button
                    onPress={viewUpcomingPickups}
                    title='View Upcoming Pickups'
                    buttonStyle={{
                        backgroundColor: BUTTON_COLOR_ONE
                    }}
                />
            )
        }

    }

    if (pageLoading) {
        return (
            <View style={styles.parent}>
                <View style={{ zIndex: 10 }}>
                    <TopHeading navigation={navigation} currentPage="Home" />
                </View>
                <View style={{ flex: 1, marginTop: 50, zIndex: 0 }}>
                    <View style={styles.imageContainer}>
                        <Image source={require("../../assets/Tumble_Logo.png")} style={{ width: 170, height: 170 }} />
                    </View>
                </View>
                <View style={{ height: "100%", alignContent: 'center', justifyContent: 'center' }}>
                    <ActivityIndicator />
                </View>
            </View>
        )
    }

    return (
        <View style={styles.parent}>
            <View style={{ zIndex: 10 }}>
                <TopHeading navigation={navigation} currentPage="Home" />
            </View>
            <View style={{ flex: 1, marginTop: 50, zIndex: 0 }}>
                <ScrollView style={{ flex: 1, width: "100%", marginLeft: "0%" }}>
                    <View style={styles.imageContainer}>
                        <Image source={require("../../assets/Tumble_Logo.png")} style={{ width: 170, height: 170 }} />
                    </View>
                    <View style={{ width: "100%" }}>
                        {displayNextPickup()}
                    </View>
                    <View style={{ height: 5, width: "100%", marginTop: 20, alignItems: 'center' }}>
                        <View style={{ width: "70%", maxWidth: 425, minWidth: 225 }} >
                            {primaryButton()}
                        </View>
                    </View>
                    <View style={{ height: 5, width: "100%", marginTop: 80, alignItems: 'center' }}>
                        <View style={{ width: "70%", maxWidth: 425, minWidth: 225 }} >
                            {secondaryButton()}
                        </View>
                    </View>
                </ScrollView>
            </View>
            <AlertModal
                modalVisible={alertModalVisibile}
                setModalVisible={setAlertModalVisible}
                alertTitleText={alertInfo.alertTitleText}
                alertSubtitleText={alertInfo.alertSubtitleText}
                alertPrimaryButtonText={alertInfo.alertPrimaryButtonText}
                alertPrimaryAction={alertInfo.alertPrimaryAction}
                alertSecondaryButtonText={alertInfo.alertSecondaryButtonText}
                alertSecondaryAction={alertInfo.alertSecondaryAction}
            />
        </View>
    );
}