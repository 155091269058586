import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
import {
    ActivityIndicator,
    View,
    ScrollView,
    Dimensions,
    TextInput,
    Text
} from 'react-native';
import { Button } from 'react-native-elements';
import styles from './styles';
import { Cell, Section } from 'react-native-tableview-simple';
import firebase from "firebase/app"
import "firebase/auth"
import "firebase/firestore"
import "firebase/functions"
import { CELL_BACKGROUND_COLOR, CELL_TITLE_TEXT_COLOR, DISABLED_TEXT_INPUT_COLOR, HEADER_TEXT_COLOR, PLACEHOLDER_TEXT_INPUT_COLOR, TEXT_INPUT_COLOR, BUTTON_COLOR_TWO, CONNECTION_ERROR_HEADER, CONNECTION_ERROR_MESSAGE, CONNECTION_ERROR_BUTTON } from '../../../../constants';
import AlertModal from '../../AlertModal'
import { HeaderBackButton } from '@react-navigation/stack';


export default function ContactInfo({ route, navigation }) {

    useLayoutEffect(() => {
        navigation.setOptions({
            headerRight: () => (
                <Button
                    onPress={cancelPickup}
                    title="Cancel"
                    type="clear"
                    titleStyle={{
                        fontWeight: "normal",
                        color: HEADER_TEXT_COLOR
                    }}
                    buttonStyle={{
                        marginRight: 10,
                    }}
                    disabled={pageLoading}
                />
            ),
            headerLeft: () => (
                <HeaderBackButton
                    onPress={() => navigation.goBack()}
                    disabled={pageLoading}
                    tintColor={HEADER_TEXT_COLOR}
                />
            )
        })
    })

    const { pickupDay, pickupTime, pickupDate } = route.params

    const getUserInfo = async () => {
        const user = firebase.auth().currentUser;
        const userInfo = (await firebase.firestore().collection("userProfiles").doc(user.uid).get()).data();
        const pageInfo = (await firebase.firestore().collection('pageInfo').doc('WashAndFoldPickup').collection('pages').doc('ContactInfo').get()).data();
        if (userInfo && pageInfo) {
            if (userInfo.contactEmail) {
                setEmail(userInfo.contactEmail)
                setOriginalEmail(userInfo.contactEmail)
            }
            if (userInfo.contactNumber) {
                setPhoneNumber(userInfo.contactNumber)
                setOriginalPhoneNumber(userInfo.contactNumber)
            }
            if (userInfo.preferredContactMethod) {
                setPreferredContactMethod(userInfo.preferredContactMethod)
                setOriginalPreferredContactMethod(userInfo.preferredContactMethod)
            }
            if (pageInfo.message1) {
                setMessage1(pageInfo.message1)
            }
            if (pageInfo.message2) {
                setMessage2(pageInfo.message2)
            }
        }
        else {
            setPageLoading(false)
            openAlertModal(CONNECTION_ERROR_HEADER, CONNECTION_ERROR_MESSAGE, CONNECTION_ERROR_BUTTON, cancelPickup)
            return
        }
        setPageLoading(false)
    }

    const refs = {
        "phoneNumber": useRef(),
        "email": useRef(),
    }

    const [message1, setMessage1] = useState('')
    const [message2, setMessage2] = useState('')
    const [pageLoading, setPageLoading] = useState(true)
    const [phoneNumber, setPhoneNumber] = useState("")
    const [originalPhoneNumber, setOriginalPhoneNumber] = useState("")
    const [email, setEmail] = useState("")
    const [originalEmail, setOriginalEmail] = useState("")
    const [preferredContactMethod, setPreferredContactMethod] = useState("Text")
    const [orignalPreferredContactMethod, setOriginalPreferredContactMethod] = useState("Text")
    const [alertModalVisibile, setAlertModalVisible] = useState(false);
    const [alertInfo, setAlertInfo] = useState({})
    const closeAlertModal = () => {
        setAlertModalVisible(false)
    }
    const openAlertModal = (title, subtitle, primaryButtonText, primaryButtonAction, secondaryButtonText = null, secondaryButtonAction = null) => {
        setAlertInfo({
            alertTitleText: title,
            alertSubtitleText: subtitle,
            alertPrimaryButtonText: primaryButtonText,
            alertPrimaryAction: () => primaryButtonAction(),
            alertSecondaryButtonText: secondaryButtonText,
            alertSecondaryAction: () => secondaryButtonAction()
        })
        setAlertModalVisible(true)
    }

    const cancelPickup = () => {
        setPageLoading(false)
        navigation.navigate("Home")
    }

    const [contactAccessory, setContactAccessory] = useState({
        "Call": "none", "Text": "Checkmark", "Email": "none"
    })

    const selectPreferredContact = (contactMethod) => {
        setPreferredContactMethod(contactMethod)
        const updateDict = {}
        for (var key in contactAccessory) {
            if (key == contactMethod) {
                updateDict[key] = "Checkmark"
            }
            else {
                updateDict[key] = "none"
            }
        }
        setContactAccessory(updateDict)
    }

    useEffect(() => {
        getUserInfo()
    }, []);

    const onSubmitPhoneNumber = () => {
        refs['email'].current.focus()
    }

    const onSubmitEmail = () => {
        nextPage()
    }

    const nextPage = async () => {

        if (!phoneNumber || phoneNumber === '') {
            setPageLoading(false)
            openAlertModal('Error', 'Please enter your phone number.', 'Ok', closeAlertModal)
            return
        }
        // regex check to make sure valid phone number
        let phoneNumberRegex = /^\([1-9]\d{2}\)\s\d{3}-\d{4}/;
        if (!phoneNumberRegex.test(phoneNumber)) {
            setPageLoading(false)
            openAlertModal('Invalid Phone Number', 'Please enter a valid phone number.', 'Ok', closeAlertModal)
            return
        }
        try {
            if (phoneNumber !== originalPhoneNumber || preferredContactMethod !== preferredContactMethod) {
                const updateUserContactInfo = firebase.functions().httpsCallable('updateUserContactInfo');
                const updateDict = {
                    contactNumber: phoneNumber,
                    preferredContactMethod: preferredContactMethod,
                }
                const response = await updateUserContactInfo({
                    updateDict: updateDict,
                    updateContext: 'new_pickup'
                })
                if (response.data.success) {
                    setPageLoading(false)
                    navigation.navigate("Wash and Fold Pickup Laundry Preferences", {
                        pickupDay: pickupDay,
                        pickupTime: pickupTime,
                        pickupDate: pickupDate,
                    })
                    return
                }
                else {
                    if (response.data.message) {
                        setPageLoading(false)
                        openAlertModal('Error', response.data.message, 'Ok', closeAlertModal)
                        return
                    }
                    else {
                        setPageLoading(false)
                        openAlertModal(CONNECTION_ERROR_HEADER, CONNECTION_ERROR_MESSAGE, CONNECTION_ERROR_BUTTON, closeAlertModal)
                        return
                    }
                }
            }
            else {
                setPageLoading(false)
                navigation.navigate("Wash and Fold Pickup Laundry Preferences", {
                    pickupDay: pickupDay,
                    pickupTime: pickupTime,
                    pickupDate: pickupDate,
                })
                return
            }
        }
        catch {
            console.log('error updating preferred pickup day and time', err)
            setPageLoading(false)
            openAlertModal(CONNECTION_ERROR_HEADER, CONNECTION_ERROR_MESSAGE, CONNECTION_ERROR_BUTTON, closeAlertModal)
            return
        }
    }

    const insertCharacterAtIndex = (str, index, character) => {
        return str.substr(0, index) + character + str.substr(index);
    }

    const changePhoneNumber = (value) => {
        var numbersOnly = value.replace(/[^\d]/g, '')
        var newValue = ''
        if (numbersOnly.length > 0) {
            if (numbersOnly.length > 10) {
                numbersOnly = numbersOnly.substr(0, 10)
            }
            newValue = numbersOnly
            newValue = insertCharacterAtIndex(newValue, 0, '(')
            if (numbersOnly.length > 3) {
                newValue = insertCharacterAtIndex(newValue, 4, ') ')
            }
            if (numbersOnly.length > 6) {
                newValue = insertCharacterAtIndex(newValue, 9, '-')
            }

        }
        setPhoneNumber(newValue)
    }

    if (pageLoading) {
        return (
            <View style={styles.parent}>
                <ActivityIndicator />
            </View>
        )
    }

    return (
        <View style={styles.parent}>
            <ScrollView>
                <View style={{ marginLeft: 10, marginRight: 10, marginTop: 10, marginBottom: 10 }}>
                    <Text style={styles.subsubheading}>{message1}</Text>
                </View>
                <Section header="CONTACT" headerTextColor={HEADER_TEXT_COLOR} footer="">
                    <Cell backgroundColor={CELL_BACKGROUND_COLOR} cellContentView={
                        <TextInput
                            style={{ fontSize: 16, flex: 1, color: TEXT_INPUT_COLOR }}
                            placeholder="Phone Number"
                            ref={refs["address"]}
                            autoCorrect={false}
                            enablesReturnKeyAutomatically={true}
                            onChangeText={(value) => changePhoneNumber(value)}
                            value={phoneNumber}
                            onSubmitEditing={onSubmitPhoneNumber}
                            returnKeyType="next"
                            placeholderTextColor={PLACEHOLDER_TEXT_INPUT_COLOR}
                            keyboardType='phone-pad'
                        />
                    } />
                    <Cell backgroundColor={CELL_BACKGROUND_COLOR} cellContentView={
                        <TextInput
                            style={{ fontSize: 16, flex: 1, color: TEXT_INPUT_COLOR }}
                            placeholder="Email"
                            ref={refs["email"]}
                            autoCorrect={false}
                            enablesReturnKeyAutomatically={true}
                            onChangeText={(value) => setEmail(value)}
                            value={email}
                            onSubmitEditing={onSubmitEmail}
                            returnKeyType="go"
                            placeholderTextColor={PLACEHOLDER_TEXT_INPUT_COLOR}
                            autoCapitalize="none"
                            keyboardType='email-address'
                            editable={false}
                        />
                    } />
                </Section>
                <Section headerTextColor={HEADER_TEXT_COLOR} header="PREFERRED CONTACT METHOD" footer="">
                    <Cell cellStyle="Basic" backgroundColor={CELL_BACKGROUND_COLOR} titleTextColor={CELL_TITLE_TEXT_COLOR} title="Call" accessory={contactAccessory["Call"]} onPress={() => selectPreferredContact("Call")} />
                    <Cell cellStyle="Basic" backgroundColor={CELL_BACKGROUND_COLOR} titleTextColor={CELL_TITLE_TEXT_COLOR} title="Text" accessory={contactAccessory["Text"]} onPress={() => selectPreferredContact("Text")} />
                    <Cell cellStyle="Basic" backgroundColor={CELL_BACKGROUND_COLOR} titleTextColor={CELL_TITLE_TEXT_COLOR} title="Email" accessory={contactAccessory["Email"]} onPress={() => selectPreferredContact("Email")} />
                </Section>
                <View style={{ marginLeft: 10, marginRight: 10, marginTop: 10, marginBottom: 10 }}>
                    <Text style={styles.subsubheading}>{message2}</Text>
                </View>
                <View style={{ height: 50, width: "100%", marginTop: 40, alignItems: 'center', marginBottom: 50 }}>
                    <View style={{ width: "70%", maxWidth: 250, minWidth: 200 }} >
                        <Button
                            onPress={nextPage}
                            title='Continue'
                            buttonStyle={{
                                backgroundColor: BUTTON_COLOR_TWO
                            }}
                        />
                    </View>
                </View>
            </ScrollView>
            <AlertModal
                modalVisible={alertModalVisibile}
                setModalVisible={setAlertModalVisible}
                alertTitleText={alertInfo.alertTitleText}
                alertSubtitleText={alertInfo.alertSubtitleText}
                alertPrimaryButtonText={alertInfo.alertPrimaryButtonText}
                alertPrimaryAction={alertInfo.alertPrimaryAction}
                alertSecondaryButtonText={alertInfo.alertSecondaryButtonText}
                alertSecondaryAction={alertInfo.alertSecondaryAction}
            />
        </View>
    );
}