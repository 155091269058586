import React, { useEffect, useState, useRef, useLayoutEffect } from 'react';
import {
    View,
    ScrollView,
    Dimensions,
    ActivityIndicator,
    Text,
    Switch,
    Alert,
    TextInput,
    useWindowDimensions,
    TouchableHighlight
} from 'react-native';
import { Button } from 'react-native-elements';
import KeyboardSpacer from '../KeyboardSpacer';
import firebase from "firebase/app"
import "firebase/auth"
import "firebase/firestore"
import "firebase/functions"
import styles from './styles';
import { Cell, Section } from 'react-native-tableview-simple';
import Modal from 'react-native-modal'
import { BACKGROUND_COLOR, BUTTON_COLOR_ONE, BUTTON_COLOR_TWO, CELL_BACKGROUND_COLOR, CELL_DETAIL_TEXT_COLOR, CELL_TITLE_TEXT_COLOR, FOOTER_TEXT_COLOR, HEADER_TEXT_COLOR, PLACEHOLDER_TEXT_INPUT_COLOR, SEPARATOR_TINT_COLOR_TWO, TEXT_COLOR_THREE, TEXT_INPUT_COLOR, UNDERLAY_COLOR_ONE } from '../../../constants';
import { HeaderBackButton } from '@react-navigation/stack';


export default function AddCreditCard({ modalVisible, setModalVisible, noExistingPayment, setPageLoading, getCustomerPaymentInfo }) {

    const dimensions = useWindowDimensions()
    // const [visible, setVisible] = useState(false)
    const [makeDefault, setMakeDefault] = useState(false)
    const [emailVerified, setEmailVerified] = useState(false)
    const [email, setEmail] = useState("")
    const [modalLoading, setModalLoading] = useState(true)
    const [cardNumber, setCardNumber] = useState()
    const [expiry, setExpiry] = useState()
    const [cvc, setCVC] = useState()
    const [formValid, setFormValid] = useState(true)
    const toggleSwitch = () => {
        setMakeDefault(previousState => !previousState)
    }
    const [alertModalVisible, setAlertModalVisible] = useState(false)
    const [alertModalInfo, setAlertModalInfo] = useState({})

    const openAlertModal = (title, subtitle, primaryButtonText, primaryButtonAction, secondaryButtonText = null, secondaryButtonAction = null) => {
        setAlertModalInfo({
            alertTitleText: title,
            alertSubtitleText: subtitle,
            alertPrimaryButtonText: primaryButtonText,
            alertPrimaryAction: () => primaryButtonAction(),
            alertSecondaryButtonText: secondaryButtonText,
            alertSecondaryAction: () => secondaryButtonAction()
        })
        setAlertModalVisible(true)
        setModalLoading(false)
    }

    const openEmailNotVerifiedAlert = () => {
        setAlertModalInfo({
            alertTitleText: 'Your email is not yet verified',
            alertSubtitleText: 'Please try again.  If you just clicked the link, please be patient - sometimes our systems take a few seconds to update.',
            alertPrimaryButtonText: 'Ok',
            alertPrimaryAction: () => closeAlertModal(),
            alertSecondaryButtonText: '',
            alertSecondaryAction: () => () => { }
        })
        setAlertModalVisible(true)
    }

    const resendEmail = async () => {
        setModalLoading(true)
        const user = firebase.auth().currentUser;
        try {
            await user.sendEmailVerification()
            setModalLoading(false)
            setAlertModalVisible(false)
        }
        catch (err) {
            console.log('error:', err)
            setModalLoading(false)
            setAlertModalInfo({
                alertTitleText: 'Error',
                alertSubtitleText: 'Error resending verification email.  If this issue persists, please contact us.',
                alertPrimaryButtonText: 'Ok',
                alertPrimaryAction: () => closeAlertModal()
            })
            setAlertModalVisible(true)
        }

    }

    const resendEmailAlert = () => {
        setAlertModalInfo({
            alertTitleText: 'Resend Verification Email',
            alertSubtitleText: 'Do you want to resend the email?  Please only do this if you can\'t find the previous email.  After requesting a new email, it may take a few minutes for the email to arrive.',
            alertPrimaryButtonText: 'Yes',
            alertPrimaryAction: () => resendEmail(),
            alertSecondaryButtonText: 'Cancel',
            alertSecondaryAction: () => closeAlertModal()
        })
        setAlertModalVisible(true)
    }

    const closeAlertModal = () => {
        setAlertModalVisible(false)
    }

    var widthPercent = "100%"
    var marginPercent = "0%"
    if (dimensions.width >= 1100) {
        widthPercent = 1000;
        marginPercent = (dimensions.width - 1000) / 2;
    }
    else if ((dimensions.width >= 500) && (dimensions.width < 1100)) {
        widthPercent = "90%";
        marginPercent = "5%";
    }
    else {
        widthPercent = "95%";
        marginPercent = "2.5%"
    }

    useEffect(() => {
        checkEmailVerified()
        setModalLoading(false)
    }, [modalVisible])

    const checkEmailVerified = async (buttonPress = false) => {
        setModalLoading(true)
        // check that email is verified
        const user = firebase.auth().currentUser;
        await user.reload()
        let emailVerifiedLocal = user.emailVerified;
        setEmail(user.email)
        if (!emailVerifiedLocal && buttonPress) {
            openEmailNotVerifiedAlert()
        }
        setEmailVerified(emailVerifiedLocal)
        setModalLoading(false)
    }

    const cardNumberValid = (ccNum) => {
        if (ccNum.length > 19 || ccNum.length < 13) {
            return false
        }
        return true
    }

    const expiryValid = (exp) => {
        if (exp.length != 5 || !exp.includes('/')) {
            return false
        }
        return true
    }

    const cvcValid = (verificationCode) => {
        var numbersOnlyRegex = /^\d+$/
        if ((verificationCode.length === 3 || verificationCode.length === 4) && numbersOnlyRegex.test(verificationCode)) {
            return true
        }
        return false
    }

    const addPaymentMethod = async () => {
        if (!cardNumber || cardNumber === '') {
            openAlertModal('Error', 'Please enter your credit card number', 'Ok', closeAlertModal)
            return
        }
        if (!expiry || expiry === '') {
            openAlertModal('Error', 'Please enter credit card expiration date.', 'Ok', closeAlertModal)
            return
        }
        if (!cvc || cvc === '') {
            openAlertModal('Error', 'Please enter credit card CVC.', 'Ok', closeAlertModal)
            return
        }
        const ccNumOnly = cardNumber.replace(/[^\d]/g, '')
        if (!cardNumberValid(ccNumOnly)) {
            openAlertModal('Error', 'Credit card number is invalid.', 'Ok', closeAlertModal)
            return
        }
        if (!expiryValid(expiry)) {
            openAlertModal('Error', 'Expiration date is invalid.  Please enter in format MM/YY', 'Ok', closeAlertModal)
            return
        }
        if (!cvcValid(cvc)) {
            openAlertModal('Error', 'CVC is invalid.  With most cards, this is the final three digits of the number printed on the signature strip on reverse of your card.  On AMEX cards, it is usually a four digit code on the front.', 'Ok', closeAlertModal)
            return
        }
        if (formValid) {
            setModalLoading(true)
            let expiryArray = expiry.split("/")
            let exp_month = expiryArray[0]
            let exp_year = expiryArray[1]
            var newDefault = false
            if (makeDefault == true || noExistingPayment == true) {
                newDefault = true
            }
            try {
                var createPaymentMethod = firebase.functions().httpsCallable('createPaymentMethod');
                var response = await createPaymentMethod({
                    number: ccNumOnly,
                    exp_month: exp_month,
                    exp_year: exp_year,
                    cvc: cvc,
                    makeDefault: newDefault
                })
                if (response.data && response.data.success) {
                    getCustomerPaymentInfo()
                    setModalVisible(false)
                    setModalLoading(false)
                }
                else {
                    if (response.data) {
                        openAlertModal('Error', response.data.error, 'Ok', closeAlertModal)
                        return
                    }
                    openAlertModal('Error', 'Error adding credit card.  Please try again.  If this issue persists, please contact us.', 'Ok', closeAlertModal)
                    return
                }
            }
            catch (err) {
                console.log('error adding credit card:', err)
                openAlertModal('Error', 'Error adding credit card.  Please try again.  If this issue persists, please contact us.', 'Ok', closeAlertModal)
                return
            }
        }
        else {
            openAlertModal('Error', 'Credit card information is invalid.  Please try again.', 'Ok', closeAlertModal)
            return
        }
    }

    const createDefaultButton = () => {
        if (noExistingPayment) {
            return <></>
        }
        else {
            return (
                <Section footer='Your default card will be used for all upcoming plan-related charges.' footerTextColor={FOOTER_TEXT_COLOR}>
                    <Cell backgroundColor={CELL_BACKGROUND_COLOR} titleTextColor={CELL_TITLE_TEXT_COLOR} title="Make this my default card" cellAccessoryView={
                        <Switch
                            trackColor={{ false: "#767577", true: "#81b0ff" }}
                            thumbColor={makeDefault ? "#f5dd4b" : "#f4f3f4"}
                            ios_backgroundColor="#3e3e3e"
                            onValueChange={toggleSwitch}
                            value={makeDefault}
                        />
                    } />
                </Section>)
        }
    }

    const insertCharacterAtIndex = (str, index, character) => {
        return str.substr(0, index) + character + str.substr(index);
    }

    const changeCardNumber = (value) => {
        var newValue = value.replace(/[^\d]/g, '')
        // Credit card number formatter
        if (newValue.length > 4) {
            if (newValue[4] != '-') {
                newValue = insertCharacterAtIndex(newValue, 4, '-')
            }
            // Card specific
            if (newValue[0] === '3' && (newValue[1] === '4' || newValue[1] === '7')) {
                // American express
                if (newValue.length > 11) {
                    if (newValue[11] != '-') {
                        newValue = insertCharacterAtIndex(newValue, 11, '-')
                    }
                }
                if (newValue.length > 17) {
                    // too long.  amex has 15 digits (plus two dashes)
                    newValue = newValue.substring(0, 17)
                }
            }
            else if (newValue[0] === '4') {
                // Visa
                if (newValue.length > 9) {
                    newValue = insertCharacterAtIndex(newValue, 9, '-')
                }
                if (newValue.length > 14) {
                    newValue = insertCharacterAtIndex(newValue, 14, '-')
                }
                if (newValue.length > 19) {
                    // too long.  visa has max 16 digits (plus three dashes)
                    newValue = newValue.substring(0, 19)
                }
            }
            else if (newValue[0] === '5' || newValue[0] === '2') {
                // Mastercard
                if (newValue.length > 9) {
                    newValue = insertCharacterAtIndex(newValue, 9, '-')
                }
                if (newValue.length > 14) {
                    newValue = insertCharacterAtIndex(newValue, 14, '-')
                }
                if (newValue.length > 19) {
                    // too long.  mastercard has 16 digits (plus three dashes)
                    newValue = newValue.substring(0, 19)
                }
            }
            else if (newValue[0] === '6') {
                // Discover
                if (newValue.length > 9) {
                    newValue = insertCharacterAtIndex(newValue, 9, '-')
                }
                if (newValue.length > 14) {
                    newValue = insertCharacterAtIndex(newValue, 14, '-')
                }
                if (newValue.length > 19) {
                    // too long.  discover has 16 digits (plus three dashes)
                    newValue = newValue.substring(0, 19)
                }
            }
            else if (newValue[0] === '3' && (newValue[1] === '0' || newValue[1] === '6' || newValue[1] === '8')) {
                // Diners Club and Carte Blanche
                if (newValue.length > 9) {
                    newValue = insertCharacterAtIndex(newValue, 9, '-')
                }
                if (newValue.length > 14) {
                    newValue = insertCharacterAtIndex(newValue, 14, '-')
                }
                if (newValue.length > 19) {
                    // too long.  max 16 digits (plus three dashes)
                    newValue = newValue.substring(0, 19)
                }
            }
            else {
                // Unknown card type.  Format as default
                if (newValue.length > 9) {
                    newValue = insertCharacterAtIndex(newValue, 9, '-')
                }
                if (newValue.length > 14) {
                    newValue = insertCharacterAtIndex(newValue, 14, '-')
                }
                if (newValue.length > 19) {
                    // too long.  max 16 digits (plus three dashes)
                    newValue = newValue.substring(0, 19)
                }
            }
        }
        setCardNumber(newValue)
    }

    const changeExpiration = (value) => {
        var newValue = value.replace(/[^\d]/g, '')
        // Expiration date formatter
        if (newValue.length > 2) {
            if (newValue[2] != '/') {
                newValue = insertCharacterAtIndex(newValue, 2, '/')
            }
            if (newValue.length > 5) {
                newValue = newValue.substring(0, 5)
            }
        }
        setExpiry(newValue)
    }

    const changeCVC = (value) => {
        var newValue = value.replace(/[^\d]/g, '')
        // No CVC is more than 4 digits
        // Most are 3
        if (newValue.length > 4) {
            newValue = newValue.substring(0, 4)
        }
        setCVC(newValue)
    }

    const refs = {
        cardNumber: useRef(), expiry: useRef(), cvc: useRef()
    }

    const onSubmitCardNumber = () => {
        refs['expiry'].current.focus()
    }
    const onSubmitExpiry = () => {
        refs['cvc'].current.focus()
    }

    const onSubmitCVC = () => {
        addPaymentMethod()
    }

    const alertButtons = () => {
        if (!alertModalInfo.alertSecondaryButtonText || alertModalInfo.alertSecondaryButtonText === '') {
            // only one button
            return (
                <View style={{ width: "100%" }}>
                    <TouchableHighlight style={{ alignItems: 'center' }} underlayColor={UNDERLAY_COLOR_ONE} onPress={alertModalInfo.alertPrimaryAction}>
                        <View style={{ alignItems: 'center', width: "100%" }}>
                            <Text style={{ marginTop: 15, marginBottom: 15, fontSize: 15, color: TEXT_COLOR_THREE, fontWeight: 'bold' }}>{alertModalInfo.alertPrimaryButtonText}</Text>
                        </View>
                    </TouchableHighlight>
                </View>
            )
        }
        else {
            // two buttons
            return (
                <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                    <View style={{ width: "50%", borderRightWidth: 1, borderRightColor: SEPARATOR_TINT_COLOR_TWO }}>
                        <TouchableHighlight style={{ alignItems: 'center' }} underlayColor={UNDERLAY_COLOR_ONE} onPress={alertModalInfo.alertSecondaryAction}>
                            <View style={{ alignItems: 'center', width: "100%" }}>
                                <Text style={{ marginTop: 15, marginBottom: 15, fontSize: 15, color: TEXT_COLOR_THREE, fontWeight: 'normal' }}>{alertModalInfo.alertSecondaryButtonText}</Text>
                            </View>
                        </TouchableHighlight>
                    </View>
                    <View style={{ width: "50%", borderLeftWidth: 1, borderLeftColor: SEPARATOR_TINT_COLOR_TWO }}>
                        <TouchableHighlight style={{ alignItems: 'center' }} underlayColor={UNDERLAY_COLOR_ONE} onPress={alertModalInfo.alertPrimaryAction}>
                            <View style={{ alignItems: 'center', width: "100%" }}>
                                <Text style={{ marginTop: 15, marginBottom: 15, fontSize: 15, color: TEXT_COLOR_THREE, fontWeight: 'bold' }}>{alertModalInfo.alertPrimaryButtonText}</Text>
                            </View>
                        </TouchableHighlight>
                    </View>
                </View>

            )
        }
    }

    if (modalLoading) {
        return (
            <Modal isVisible={modalVisible} propagateSwipe={true} style={{ justifyContent: 'flex-end', margin: 0 }} >
                <View style={{ height: dimensions.height * 0.95, width: widthPercent, marginLeft: marginPercent, backgroundColor: BACKGROUND_COLOR }}>
                    <ActivityIndicator />
                </View>
            </Modal >
        )
    }

    if (alertModalVisible) {
        return (
            <Modal isVisible={modalVisible} keyboardShouldPersistTaps="handled" propagateSwipe={true} style={{ alignItems: 'center' }} >
                <View style={{ width: widthPercent, minWidth: 300, maxWidth: 550, backgroundColor: BACKGROUND_COLOR, borderRadius: 5 }}>
                    <View style={{ alignItems: 'center' }}>
                        <Text style={{ textAlign: 'center', color: HEADER_TEXT_COLOR, fontSize: 30, marginTop: 20 }}>{alertModalInfo.alertTitleText}</Text>
                    </View>
                    <View style={{ alignItems: 'center' }}>
                        <Text style={{ textAlign: 'center', color: HEADER_TEXT_COLOR, fontSize: 20, marginTop: 20, marginLeft: 10, marginRight: 10 }}>{alertModalInfo.alertSubtitleText}</Text>
                    </View>
                    <View style={{ marginTop: 20, borderTopWidth: 1, borderTopColor: SEPARATOR_TINT_COLOR_TWO }}>
                        {alertButtons()}
                    </View>
                </View>
            </Modal>
        )
    }

    if (!emailVerified) {
        return (
            <Modal isVisible={modalVisible} propagateSwipe={true} style={{ justifyContent: 'flex-end', margin: 0 }} >
                <View style={{ height: dimensions.height * 0.95, width: widthPercent, marginLeft: marginPercent, backgroundColor: BACKGROUND_COLOR, borderTopLeftRadius: 5, borderTopStartRadius: 5, borderTopRightRadius: 5, borderTopEndRadius: 5 }}>
                    <View style={{ flexDirection: 'row', alignItems: 'center', marginTop: 10, marginBottom: 10 }}>
                        <View
                            style={{
                                width: "25%",
                                height: 50,
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}>
                        </View>
                        <View
                            style={{
                                width: "50%",
                                height: 50,
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}>
                            <Text style={{ fontSize: 20, fontWeight: '500', color: HEADER_TEXT_COLOR }} >
                                Confirm Email
                            </Text>
                        </View>
                        <View
                            style={{
                                width: "25%",
                                height: 50,
                                justifyContent: 'center',
                                alignItems: 'flex-end'
                            }}>
                            <View style={{ flexDirection: 'row', justifyContent: 'space-between', marginRight: 10 }}>
                                <Button
                                    onPress={() => setModalVisible(false)}
                                    title="Cancel"
                                    type="clear"
                                    titleStyle={{
                                        fontWeight: "normal"
                                    }}
                                    buttonStyle={{

                                    }}
                                />
                            </View>
                        </View>
                    </View>
                    <ScrollView>
                        <View>
                            <Text style={styles.subheading}>You need to confirm your email ({email}) before you can make any purchases or add payment methods.</Text>
                            <Text style={styles.subheading}>Please go to your email and click the Confirm Email link we sent to you.</Text>
                            <View style={{ height: 30, width: "100%", marginTop: 20, alignItems: 'center' }}>
                                <View style={{ width: "70%", maxWidth: 425, minWidth: 225 }} >
                                    <Button
                                        onPress={() => checkEmailVerified(true)}
                                        title='Continue'
                                        buttonStyle={{
                                            backgroundColor: BUTTON_COLOR_ONE
                                        }}
                                    />
                                </View>
                            </View>
                            <View style={{ height: 30, width: "100%", marginTop: 30, alignItems: 'center' }}>
                                <View style={{ width: "70%", maxWidth: 425, minWidth: 225 }} >
                                    <Button
                                        onPress={resendEmailAlert}
                                        title='Resend Email'
                                        buttonStyle={{
                                            backgroundColor: BUTTON_COLOR_ONE
                                        }}
                                    />
                                </View>
                            </View>
                        </View>
                    </ScrollView>
                </View>
            </Modal>
        )
    }

    return (
        <Modal isVisible={modalVisible} propagateSwipe={true} style={{ justifyContent: 'flex-end', margin: 0 }} >
            <View style={{ height: dimensions.height * 0.95, width: widthPercent, marginLeft: marginPercent, backgroundColor: BACKGROUND_COLOR, borderTopLeftRadius: 5, borderTopStartRadius: 5, borderTopRightRadius: 5, borderTopEndRadius: 5 }}>
                <View style={{ flexDirection: 'row', alignItems: 'center', marginTop: 10, marginBottom: 10 }}>
                    <View
                        style={{
                            width: "25%",
                            height: 50,
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}>
                    </View>
                    <View
                        style={{
                            width: "50%",
                            height: 50,
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}>
                        <Text style={{ fontSize: 20, fontWeight: '500', color: HEADER_TEXT_COLOR }} >
                            Add New Card
                        </Text>
                    </View>
                    <View
                        style={{
                            width: "25%",
                            height: 50,
                            justifyContent: 'center',
                            alignItems: 'flex-end'
                        }}>
                        <View style={{ flexDirection: 'row', justifyContent: 'space-between', marginRight: 10 }}>
                            <Button
                                onPress={() => setModalVisible(false)}
                                title="Cancel"
                                type="clear"
                                titleStyle={{
                                    fontWeight: "normal"
                                }}
                                buttonStyle={{

                                }}
                            />
                        </View>
                    </View>
                </View>
                <ScrollView>
                    <View>
                        <Section header="NUMBER" headerTextColor={HEADER_TEXT_COLOR}>
                            <Cell cellStyle="RightDetail" backgroundColor={CELL_BACKGROUND_COLOR} rightDetailColor={CELL_DETAIL_TEXT_COLOR} rightDetail="Ayy" cellContentView={
                                <TextInput
                                    style={{ fontSize: 16, flex: 1, color: TEXT_INPUT_COLOR }}
                                    placeholder="1234 5678 1234 5678"
                                    ref={refs["cardNumber"]}
                                    autoCorrect={false}
                                    enablesReturnKeyAutomatically={true}
                                    onChangeText={(value) => changeCardNumber(value)}
                                    value={cardNumber}
                                    onSubmitEditing={onSubmitCardNumber}
                                    textContentType="creditCardNumber"
                                    keyboardType='numeric'
                                    returnKeyType="next"
                                    placeholderTextColor={PLACEHOLDER_TEXT_INPUT_COLOR}
                                />
                            } />
                        </Section>
                        <Section header="EXPIRATION" headerTextColor={HEADER_TEXT_COLOR}>
                            <Cell backgroundColor={CELL_BACKGROUND_COLOR} cellContentView={
                                <TextInput
                                    style={{ fontSize: 16, flex: 1, color: TEXT_INPUT_COLOR }}
                                    placeholder="MM/YY"
                                    ref={refs["expiry"]}
                                    autoCorrect={false}
                                    enablesReturnKeyAutomatically={true}
                                    onChangeText={(value) => changeExpiration(value)}
                                    value={expiry}
                                    onSubmitEditing={onSubmitExpiry}
                                    keyboardType='numeric'
                                    returnKeyType="next"
                                    placeholderTextColor={PLACEHOLDER_TEXT_INPUT_COLOR}
                                />
                            } />
                        </Section>
                        <Section header="CVC" headerTextColor={HEADER_TEXT_COLOR}>
                            <Cell backgroundColor={CELL_BACKGROUND_COLOR} cellContentView={
                                <TextInput
                                    style={{ fontSize: 16, flex: 1, color: TEXT_INPUT_COLOR }}
                                    placeholder="123"
                                    ref={refs["cvc"]}
                                    autoCorrect={false}
                                    enablesReturnKeyAutomatically={true}
                                    onChangeText={(value) => changeCVC(value)}
                                    value={cvc}
                                    onSubmitEditing={onSubmitCVC}
                                    keyboardType='numeric'
                                    returnKeyType="go"
                                    placeholderTextColor={PLACEHOLDER_TEXT_INPUT_COLOR}
                                />
                            } />
                        </Section>
                        {createDefaultButton()}
                        <View style={{ height: 50, width: "100%", marginTop: 40, alignItems: 'center', marginBottom: 50 }}>
                            <View style={{ width: "70%", maxWidth: 250, minWidth: 200 }} >
                                <Button
                                    onPress={addPaymentMethod}
                                    title='Add Card'
                                    buttonStyle={{
                                        backgroundColor: BUTTON_COLOR_TWO
                                    }}
                                />
                            </View>
                        </View>
                    </View>
                </ScrollView>
            </View>
        </Modal>
    );
}