import { StyleSheet } from 'react-native'
import { BACKGROUND_COLOR, HEADER_TEXT_COLOR } from '../../../../constants';

export default StyleSheet.create({
    heading: {
        fontSize: 25,
        textAlign: 'center',
        color: HEADER_TEXT_COLOR
    },
    subheading: {
        fontSize: 20,
        fontWeight: "bold",
        textAlign: 'center'
    },
    subsubheading: {
        fontSize: 15,
        textAlign: 'left',
        color: HEADER_TEXT_COLOR
    },
    leftAlign: {
        fontSize: 20,
        textAlign: 'left'
    },
    rightAlign: {
        fontSize: 20,
        textAlign: 'right'
    },
    input: {
        marginTop: 10,
        marginLeft: 5,
        marginRight: 5,
    },
    button: {
        marginTop: 20,
    },
    parent: {
        backgroundColor: BACKGROUND_COLOR,
        flex: 1
    },
    safeContainer: {
        marginTop: 0
    },
    twoPickers: {
        width: 50,
        height: 100,
        backgroundColor: '#FFF0E0',
        borderColor: 'black',
        borderWidth: 1,
    },
    twoPickerItems: {
        height: 125,
        color: 'black'
    },
    imageContainer: {
        justifyContent: 'center',
        alignItems: 'center',
    },
    field: {
        width: 300,
        color: '#449aeb',
        borderColor: '#000',
        borderWidth: 1,
        borderRadius: 5,
    }
});