import React, { Component, useEffect, useState, useRef } from 'react';
import {
    View,
    SafeAreaView,
    ScrollView,
    Dimensions,
    TextInput,
    Text,
    ActivityIndicator
} from 'react-native';
import { useFocusEffect } from '@react-navigation/native'
import styles from './styles';
import KeyboardSpacer from '../KeyboardSpacer';
import { Cell, Section, TableView } from 'react-native-tableview-simple';
import firebase from "firebase/app"
import "firebase/auth"
import "firebase/firestore"
import "firebase/functions"
import { BACKGROUND_COLOR, CELL_BACKGROUND_COLOR, CELL_TITLE_TEXT_COLOR, DISABLED_TEXT_COLOR } from '../../../constants';

const window = Dimensions.get("window");
const screen = Dimensions.get("screen");

export default function LoginInfo({ navigation }) {

    useFocusEffect(
        React.useCallback(() => {
            getUserInfo();
        }, [])
    );

    const [email, setEmail] = useState()
    const [pageLoading, setPageLoading] = useState(true)

    const getUserInfo = async () => {
        setPageLoading(true)
        const user = firebase.auth().currentUser;
        // console.log(user);
        const userInfo = (await firebase.firestore().collection("userProfiles").doc(user.uid).get()).data();
        console.log(user)
        setEmail(user.email)
        setPageLoading(false)
    }

    const changeEmail = () => {
        navigation.navigate('Change Email')
    }

    const changePassword = () => {
        navigation.navigate('Change Password')
    }

    if (pageLoading) {
        return (
            <View style={styles.parent}>
                <ActivityIndicator />
            </View>
        )
    }

    return (
        <View style={styles.parent}>
            <ScrollView keyboardShouldPersistTaps='handled'>
                <Section header="EMAIL ADDRESS">
                    <Cell backgroundColor={CELL_BACKGROUND_COLOR} cellStyle="Basic" titleTextColor={DISABLED_TEXT_COLOR} title={email} />
                </Section>
                <Section header="PASSWORD">
                    <Cell backgroundColor={CELL_BACKGROUND_COLOR} cellStyle="Basic" titleTextColor={CELL_TITLE_TEXT_COLOR} title="Change Password" onPress={changePassword} accessory="DisclosureIndicator" />
                </Section>
            </ScrollView>
            <KeyboardSpacer />
        </View>
    );
}