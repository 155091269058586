import React, { useEffect, useState } from 'react';
import {
    View,
    SafeAreaView,
    Text,
    ScrollView,
    Image,
    Dimensions,
    TouchableOpacity,
    Alert
} from 'react-native';
// import { RaisedTextButton } from 'react-native-material-buttons';
import { Card, Button } from 'react-native-elements';
import styles from './styles';
import KeyboardSpacer from '../KeyboardSpacer';
import { ACCENT_COLOR_FOUR, ACCENT_COLOR_ONE, ACCENT_COLOR_TWO, ACCENT_COLOR_THREE, ACCENT_COLOR_FIVE, TEXT_COLOR_ONE, TEXT_COLOR_THREE, ACCENT_COLOR_SIX, TEXT_COLOR_FOUR, BACKGROUND_COLOR } from '../../../constants';

const window = Dimensions.get("window");
const screen = Dimensions.get("screen");

export default function RewardsExpiration({ navigation }) {

    useEffect(() => {

    }, []);

    return (
        <View style={styles.parent}>
            <ScrollView keyboardShouldPersistTaps='handled'>
                <SafeAreaView style={styles.safeContainer}>
                    <View style={{ marginTop: 50, marginBottom: 20, alignItems: 'center' }}>
                        <View style={{ width: "95%", maxWidth: 600, backgroundColor: ACCENT_COLOR_FIVE, }}>
                            <Text style={{ textAlign: 'center', color: TEXT_COLOR_FOUR, flexWrap: "wrap", fontSize: 25, width: "90%", marginLeft: "5%", marginTop: 15, marginBottom: 15, textDecorationLine: 'underline' }}>Rewards Expiration</Text>
                            <Text style={{ textAlign: 'left', color: TEXT_COLOR_FOUR, flexWrap: "wrap", fontSize: 20, width: "90%", marginLeft: "5%", marginTop: 15, marginBottom: 15 }}>Tumble points expire after each calendar year.</Text>
                            <Text style={{ textAlign: 'left', color: TEXT_COLOR_FOUR, flexWrap: "wrap", fontSize: 20, width: "90%", marginLeft: "5%", marginTop: 15, marginBottom: 15 }}>Remaining poinits at the end of the calendar year can be transferred to Tumble gift cards and gifted to friends, family members, or coworkers!  Please contact us to get a Tumble gift card.</Text>
                            <Text style={{ textAlign: 'left', color: TEXT_COLOR_FOUR, flexWrap: "wrap", fontSize: 20, width: "90%", marginLeft: "5%", marginTop: 15, marginBottom: 15 }}>Only one reward can be redeemed each billing cycle.  Please contact our team if you have any questions.</Text>
                        </View>
                    </View>
                </SafeAreaView>
            </ScrollView>
        </View>
    );
}