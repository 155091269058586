import React, { useState, useEffect, useRef, useLayoutEffect } from 'react'
import { View, ActivityIndicator, ScrollView, BackHandler, Dimensions } from 'react-native'
import firebase from "firebase/app"
import "firebase/auth"
import "firebase/firestore"
import "firebase/functions"
import { Cell, Section, TableView } from 'react-native-tableview-simple';
import { HeaderBackButton } from '@react-navigation/stack'
import styles from '../styles';
import { Button } from 'react-native-elements';
import { BACKGROUND_COLOR, BUTTON_COLOR_ONE, BUTTON_COLOR_TWO, CELL_BACKGROUND_COLOR, CELL_TITLE_TEXT_COLOR, CONNECTION_ERROR_BUTTON, CONNECTION_ERROR_HEADER, CONNECTION_ERROR_MESSAGE, FOOTER_TEXT_COLOR, HEADER_TEXT_COLOR, SEPARATOR_TINT_COLOR_TWO } from '../../../../constants';
import AlertModal from '../../AlertModal'


export default function PickupDayTime({ route, navigation }) {

    useLayoutEffect(() => {
        navigation.setOptions({
            headerRight: null,
            headerLeft: () => {
                return <HeaderBackButton
                    label="Back"
                    onPress={() => navigation.goBack()}
                    disabled={pageLoading}
                />
            },
        })
    })

    const logout = async () => {
        try {
            await firebase.auth().signOut();
            setPageLoading(false)
        }
        catch (err) {
            openAlertModal('Error Logging Out', 'Please try again', 'Try Again', logout)
        }
    }

    const nextPage = async () => {
        setPageLoading(true)
        try {
            const user = firebase.auth().currentUser;
            await firebase.firestore().collection('userProfiles').doc(user.uid).update({
                preferredPickupDay: day,
                preferredPickupTime: time
            })
            navigation.navigate("Account Creation Pickup Day Time")
            setPageLoading(false)
        }
        catch (err) {
            console.log('error updating profile in firebase:', err)
            setPageLoading(false)
            openAlertModal('Error Updating Profile', 'We are unable to update your profile at this time.  Please try again.  If this issue persists, please log out and contact us.', 'Try Again', nextPage, 'Log Out', logout)
        }
    }

    const [pageLoading, setPageLoading] = useState(true);
    const [days, setDays] = useState([])
    const [times, setTimes] = useState([])
    const [day, setDay] = useState("")
    const [time, setTime] = useState("")
    const [timeAccessory, setTimeAccessory] = useState({})
    const [dayAccessory, setDayAccessory] = useState({})
    const [alertModalVisibile, setAlertModalVisible] = useState(false);
    const [alertInfo, setAlertInfo] = useState({})
    const openAlertModal = (title, subtitle, primaryButtonText, primaryButtonAction, secondaryButtonText = null, secondaryButtonAction = null) => {
        setAlertInfo({
            alertTitleText: title,
            alertSubtitleText: subtitle,
            alertPrimaryButtonText: primaryButtonText,
            alertPrimaryAction: () => primaryButtonAction(),
            alertSecondaryButtonText: secondaryButtonText,
            alertSecondaryAction: () => secondaryButtonAction()
        })
        setAlertModalVisible(true)
    }
    const closeAlertModal = () => {
        setAlertModalVisible(false)
    }

    const getPageAndUserInfo = async () => {
        setPageLoading(true)
        try {
            const dayTimeDoc = await firebase.firestore().collection('residentialInfo').doc('PickupSchedule').get()
            var pickupDays = []
            var pickupTimes = []
            var dayAccessoryLocal = {}
            var timeAccessoryLocal = {}
            if (dayTimeDoc.data() && dayTimeDoc.data().days && dayTimeDoc.data().times) {
                pickupDays = dayTimeDoc.data().days
                pickupTimes = dayTimeDoc.data().times
                var index = 0
                dayTimeDoc.data().days.forEach((day) => {
                    if (index === 0) {
                        setDay(day)
                        dayAccessoryLocal[day] = "Checkmark"
                        index++
                    }
                    else {
                        dayAccessoryLocal[day] = "none"
                    }
                })
                index = 0
                dayTimeDoc.data().times.forEach((time) => {
                    if (index === 0) {
                        setTime(time)
                        timeAccessoryLocal[time] = "Checkmark"
                        index++
                    }
                    else {
                        timeAccessoryLocal[time] = "none"
                    }
                })
                setDayAccessory(dayAccessoryLocal)
                setTimeAccessory(timeAccessoryLocal)
                setDays(pickupDays)
                setTimes(pickupTimes)
            }
            else {
                setPageLoading(false)
                openAlertModal(CONNECTION_ERROR_HEADER, CONNECTION_ERROR_MESSAGE, CONNECTION_ERROR_BUTTON, getPageAndUserInfo)
                return
            }

            // get user info
            const user = firebase.auth().currentUser;
            const userInfo = (await firebase.firestore().collection("userProfiles").doc(user.uid).get()).data();
            if (userInfo) {
                if (userInfo.preferredPickupDay && userInfo.preferredPickupTime) {
                    selectDay(userInfo.preferredPickupDay, dayAccessoryLocal)
                    selectTime(userInfo.preferredPickupTime, timeAccessoryLocal)
                }
                else {
                    selectDay(dayTimeDoc.data().days[0], dayAccessoryLocal)
                    selectTime(dayTimeDoc.data().times[0], timeAccessoryLocal)
                }
            }
            else {
                console.log('Error: no account for this user')
                setPageLoading(false)
                openAlertModal('Error', 'We do not have a profile created for you.  Please logout and login again.', 'Log Out', logout)
                return
            }
            setPageLoading(false)
        }
        catch (err) {
            console.log('error:', err)
            setPageLoading(false)
            openAlertModal(CONNECTION_ERROR_HEADER, CONNECTION_ERROR_MESSAGE, CONNECTION_ERROR_BUTTON, getPageAndUserInfo)
            return
        }
    }

    useEffect(() => {
        getPageAndUserInfo()
        BackHandler.addEventListener('hardwareBackPress', onBackPress);
        return () => {
            BackHandler.removeEventListener('hardwareBackPress', onBackPress);
        }
    }, []);

    const onBackPress = () => {
        return true;
    }

    const selectDay = async (selectedDay, accessoryParam = null) => {
        var accessoryLocal = accessoryParam
        if (!accessoryLocal) {
            accessoryLocal = dayAccessory
        }
        setDay(selectedDay)
        for (var key in accessoryLocal) {
            if (key == selectedDay) {
                accessoryLocal[key] = "Checkmark"
            }
            else {
                accessoryLocal[key] = "none"
            }
        }
        setDayAccessory(accessoryLocal)
    }

    const selectTime = async (selectedTime, accessoryParam = null) => {
        var accessoryLocal = accessoryParam
        if (!accessoryLocal) {
            accessoryLocal = timeAccessory
        }
        setTime(selectedTime)
        for (var key in accessoryLocal) {
            if (key == selectedTime) {
                accessoryLocal[key] = "Checkmark"
            }
            else {
                accessoryLocal[key] = "none"
            }
        }
        setTimeAccessory(accessoryLocal)
    }

    const createPickupDayTable = () => {
        var valueArray = []
        var index = 0
        days.forEach((day) => {
            valueArray.push(
                <Cell key={index} backgroundColor={CELL_BACKGROUND_COLOR} cellStyle="Basic" titleTextColor={CELL_TITLE_TEXT_COLOR} title={day} accessory={dayAccessory[day]} isDisabled={pageLoading} onPress={() => selectDay(day)} />
            )
            index++
        })
        return valueArray
    }

    const createPickupTimeTable = () => {
        var valueArray = []
        var index = 0
        times.forEach((time) => {
            valueArray.push(
                <Cell key={index} backgroundColor={CELL_BACKGROUND_COLOR} cellStyle="Basic" titleTextColor={CELL_TITLE_TEXT_COLOR} title={time} accessory={timeAccessory[time]} isDisabled={pageLoading} onPress={() => selectTime(time)} />
            )
            index++
        })
        return valueArray
    }

    if (pageLoading) {
        return (
            <View style={styles.parent}>
                <ActivityIndicator />
            </View>
        )
    }

    return (
        <View style={styles.parent}>
            <ScrollView>
                <Section header="PREFERRED PICKUP DAY" footer="" separatorTintColor={SEPARATOR_TINT_COLOR_TWO} headerTextColor={HEADER_TEXT_COLOR} footerTextColor={FOOTER_TEXT_COLOR}>
                    {createPickupDayTable()}
                </Section>
                <Section header="PREFERRED PICKUP TIME" footer="" separatorTintColor={SEPARATOR_TINT_COLOR_TWO} headerTextColor={HEADER_TEXT_COLOR} footerTextColor={FOOTER_TEXT_COLOR}>
                    {createPickupTimeTable()}
                </Section>
                <View style={{ height: 50, width: "100%", marginTop: 40, alignItems: 'center', marginBottom: 50 }}>
                    <View style={{ width: "70%", maxWidth: 250, minWidth: 200 }} >
                        <Button
                            onPress={nextPage}
                            title='Continue'
                            buttonStyle={{
                                backgroundColor: BUTTON_COLOR_TWO
                            }}
                        />
                    </View>
                </View>
            </ScrollView>
            <AlertModal
                modalVisible={alertModalVisibile}
                setModalVisible={setAlertModalVisible}
                alertTitleText={alertInfo.alertTitleText}
                alertSubtitleText={alertInfo.alertSubtitleText}
                alertPrimaryButtonText={alertInfo.alertPrimaryButtonText}
                alertPrimaryAction={alertInfo.alertPrimaryAction}
                alertSecondaryButtonText={alertInfo.alertSecondaryButtonText}
                alertSecondaryAction={alertInfo.alertSecondaryAction}
            />
        </View>
    );
}