import React, { useState, useEffect, useLayoutEffect } from 'react';
import {
    View,
    SafeAreaView,
    Text,
    ScrollView,
    Image,
    Dimensions,
    TouchableOpacity,
    ActivityIndicator
} from 'react-native';
import firebase from "firebase/app"
import "firebase/auth"
import "firebase/firestore"
import "firebase/functions"
import { Button } from 'react-native-elements';
import styles from './styles';
import { ACCENT_COLOR_FIVE, HEADER_TEXT_COLOR, ACCENT_COLOR_ONE, ACCENT_COLOR_THREE, BACKGROUND_COLOR, BUTTON_COLOR_ONE, BUTTON_COLOR_TWO, TEXT_COLOR_ONE, TEXT_COLOR_THREE, DISABLED_TEXT_COLOR } from '../../../constants';
import AlertModal from '../AlertModal';


export default function UseRewards({ navigation }) {

    useLayoutEffect(() => {
        navigation.setOptions({
            headerRight: () => (
                <Button
                    onPress={cancelUseRewards}
                    title="Cancel"
                    type="clear"
                    titleStyle={{
                        fontWeight: "normal",
                        color: HEADER_TEXT_COLOR
                    }}
                    buttonStyle={{
                        marginRight: 10
                    }}
                />
            ),
            headerLeft: null
        })
    })

    const cancelUseRewards = () => {
        navigation.navigate('Rewards')
    }

    const getPageAndUserInfo = async () => {
        setAlertModalVisible(false)
        setPageLoading(true)
        try {
            const getUseRewardsInfo = firebase.functions().httpsCallable('getUseRewardsInfo');
            const response = await getUseRewardsInfo({})
            if (response.data.success) {
                console.log(response.data)
                setNumberOfPointsMesage(response.data.numberOfPointsMessage)
                setUseRewardsOptions(response.data.useRewardsOptions)
                setPageLoading(false)
            }
            else {
                console.log('error getting rewards info', response.data.error)
                setPageLoading(false)
                openAlertModal('Error', 'Sorry, there was an error getting rewards information.  Please try again.  If this issue persists, please contact us.', 'Try Again', getPageAndUserInfo, 'Go Back', cancelUseRewards)
            }
        }
        catch (err) {
            console.log('error getting rewards information:', err)
            setPageLoading(false)
            openAlertModal('Error', 'Sorry, there was an error getting rewards information.  Please try again.  If this issue persists, please contact us.', 'Try Again', getPageAndUserInfo, 'Go Back', cancelUseRewards)
        }
    }

    const [pageLoading, setPageLoading] = useState(true)
    const [numberOfPointsMessage, setNumberOfPointsMesage] = useState('')
    const [useRewardsOptions, setUseRewardsOptions] = useState([])
    const [alertModalVisibile, setAlertModalVisible] = useState(false);
    const [alertInfo, setAlertInfo] = useState({})
    const closeAlertModal = () => {
        setAlertModalVisible(false)
    }
    const openAlertModal = (title, subtitle, primaryButtonText, primaryButtonAction, secondaryButtonText = null, secondaryButtonAction = null) => {
        setAlertInfo({
            alertTitleText: title,
            alertSubtitleText: subtitle,
            alertPrimaryButtonText: primaryButtonText,
            alertPrimaryAction: () => primaryButtonAction(),
            alertSecondaryButtonText: secondaryButtonText,
            alertSecondaryAction: () => secondaryButtonAction()
        })
        setAlertModalVisible(true)
    }

    useEffect(() => {
        getPageAndUserInfo()
    }, [])


    const rewardsFAQs = () => {
        navigation.navigate("Rewards FAQs")
    }

    const redeemPoints = (optionsObj) => {
        console.log('optionsObj:', optionsObj)
        navigation.navigate("Confirm Use Rewards", {
            optionsObj: optionsObj
        })
    }

    const createUseRewardsOptions = () => {
        const valueArray = []
        var index = 0
        useRewardsOptions.forEach((optionsObj) => {
            const textColor = optionsObj.disabled ? DISABLED_TEXT_COLOR : TEXT_COLOR_ONE
            valueArray.push(
                <TouchableOpacity key={index} style={{ marginTop: 50, alignItems: 'center' }} onPress={() => redeemPoints(optionsObj)} disabled={optionsObj.disabled}>
                    <View style={{ width: "90%", maxWidth: 600, flexDirection: "row", backgroundColor: ACCENT_COLOR_THREE }}>
                        <View style={{ width: "50%" }}>
                            <Text style={{ textAlign: 'left', color: textColor, marginLeft: 15, marginTop: 5, marginBottom: 5, fontSize: 25 }}>{optionsObj.optionTitle}</Text>
                        </View>
                        <View style={{ width: "50%" }}>
                            <Text style={{ textAlign: 'right', color: textColor, marginRight: 15, marginTop: 5, marginBottom: 5, fontSize: 25 }}>{'>'}</Text>
                        </View>
                    </View>
                    <View style={{ width: "90%", maxWidth: 600, flexDirection: "row", backgroundColor: ACCENT_COLOR_FIVE }}>
                        <View style={{ width: "50%" }}>
                            <Text style={{ textAlign: 'left', color: textColor, marginLeft: 15, marginTop: 15, marginBottom: 15, fontSize: 25 }}>Redeem</Text>
                        </View>
                        <View style={{ width: "50%" }}>
                            <Text style={{ textAlign: 'right', color: textColor, marginRight: 15, marginTop: 15, marginBottom: 15, fontSize: 25 }}>{optionsObj.numberOfPoints}</Text>
                        </View>
                    </View>
                </TouchableOpacity>
            )
            index++
        })
        return valueArray
    }

    if (pageLoading) {
        return (
            <View style={styles.parent}>
                <ActivityIndicator />
            </View>
        )
    }

    return (
        <View style={styles.parent}>
            <ScrollView style={{ width: "100%" }}>
                <View style={styles.imageContainer}>
                    <Image source={require("../../assets/Tumble_Logo.png")} style={{ height: 150, width: 150 }} />
                </View>
                <View style={{ marginLeft: 10, marginRight: 10, marginTop: 10 }}>
                    <Text style={styles.subheading}>{numberOfPointsMessage}</Text>
                </View>
                {createUseRewardsOptions()}
            </ScrollView>
            <AlertModal
                modalVisible={alertModalVisibile}
                setModalVisible={setAlertModalVisible}
                alertTitleText={alertInfo.alertTitleText}
                alertSubtitleText={alertInfo.alertSubtitleText}
                alertPrimaryButtonText={alertInfo.alertPrimaryButtonText}
                alertPrimaryAction={alertInfo.alertPrimaryAction}
                alertSecondaryButtonText={alertInfo.alertSecondaryButtonText}
                alertSecondaryAction={alertInfo.alertSecondaryAction}
            />
        </View>
    );
}