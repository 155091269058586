import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
import {
    ActivityIndicator,
    View,
    ScrollView,
    Dimensions,
    TextInput,
    Text
} from 'react-native';
import { Button } from 'react-native-elements';
import styles from './styles';
import { Cell, Section } from 'react-native-tableview-simple';
import firebase from "firebase/app"
import "firebase/auth"
import "firebase/firestore"
import "firebase/functions"
import { CELL_BACKGROUND_COLOR, CELL_TITLE_TEXT_COLOR, DISABLED_TEXT_INPUT_COLOR, HEADER_TEXT_COLOR, PLACEHOLDER_TEXT_INPUT_COLOR, TEXT_INPUT_COLOR, BUTTON_COLOR_TWO, CONNECTION_ERROR_HEADER, CONNECTION_ERROR_MESSAGE, CONNECTION_ERROR_BUTTON } from '../../../../constants';
import AddressPicker from '../../AddressPicker';
import AlertModal from '../../AlertModal'
import { HeaderBackButton } from '@react-navigation/stack';

export default function DeliveryAddress({ route, navigation }) {

    useLayoutEffect(() => {
        navigation.setOptions({
            headerRight: () => (
                <Button
                    onPress={cancelPickup}
                    title="Cancel"
                    type="clear"
                    titleStyle={{
                        fontWeight: "normal",
                        color: HEADER_TEXT_COLOR
                    }}
                    buttonStyle={{
                        marginRight: 10,
                    }}
                    disabled={pageLoading}
                />
            ),
            headerLeft: () => (
                <HeaderBackButton
                    onPress={() => navigation.goBack()}
                    disabled={pageLoading}
                    tintColor={HEADER_TEXT_COLOR}
                />
            )
        })
    })

    const { pickupDay, pickupTime, pickupDate } = route.params

    const [pageLoading, setPageLoading] = useState(true)

    const getPageAndUserInfo = async () => {
        setPageLoading(true)
        const user = firebase.auth().currentUser;
        const userInfo = (await firebase.firestore().collection("userProfiles").doc(user.uid).get()).data();
        const pageInfo = (await firebase.firestore().collection('pageInfo').doc('DryCleaningPickup').collection('pages').doc('DeliveryAddress').get()).data();
        if (userInfo && pageInfo) {
            if (userInfo.addressMainText && userInfo.addressSecondaryText && userInfo.userAddress) {
                setAddressTextColor(TEXT_INPUT_COLOR)
                setAddressMainText(userInfo.addressMainText)
                setUserAddress(userInfo.userAddress)
                setOriginalUserAddress(userInfo.userAddress)
                setAddressSecondaryText(userInfo.addressSecondaryText)
            }
            if (userInfo.aptNum) {
                setAptNum(userInfo.aptNum)
            }
            if (userInfo.additionalInstructions) {
                setAdditionalInstructions(userInfo.additionalInstructions)
            }
            if (pageInfo.message1) {
                setMessage1(pageInfo.message1)
            }
            if (pageInfo.message2) {
                setMessage2(pageInfo.message2)
            }
        }
        else {
            setPageLoading(false)
            openAlertModal(CONNECTION_ERROR_HEADER, CONNECTION_ERROR_MESSAGE, CONNECTION_ERROR_BUTTON, cancelPickup)
            return
        }
        setPageLoading(false)
    }

    const refs = {
        "address": useRef(),
        "aptNum": useRef(),
        "additionalInstructions": useRef()
    }

    const [message1, setMessage1] = useState('')
    const [message2, setMessage2] = useState('')
    const [originalUserAddress, setOriginalUserAddress] = useState("")
    const [userAddress, setUserAddress] = useState("")
    const [addressDialogVisible, setAddressDialogVisible] = useState(false)
    const [addressMainText, setAddressMainText] = useState("Street Address")
    const [addressSecondaryText, setAddressSecondaryText] = useState("City & State")
    const [addressTextColor, setAddressTextColor] = useState(PLACEHOLDER_TEXT_INPUT_COLOR)
    const [aptNum, setAptNum] = useState('')
    const [additionalInstructions, setAdditionalInstructions] = useState('')
    const [alertModalVisibile, setAlertModalVisible] = useState(false);
    const [alertInfo, setAlertInfo] = useState({})
    const closeAlertModal = () => {
        setAlertModalVisible(false)
    }
    const openAlertModal = (title, subtitle, primaryButtonText, primaryButtonAction, secondaryButtonText = null, secondaryButtonAction = null) => {
        setAlertInfo({
            alertTitleText: title,
            alertSubtitleText: subtitle,
            alertPrimaryButtonText: primaryButtonText,
            alertPrimaryAction: () => primaryButtonAction(),
            alertSecondaryButtonText: secondaryButtonText,
            alertSecondaryAction: () => secondaryButtonAction()
        })
        setAlertModalVisible(true)
    }

    const cancelPickup = () => {
        setPageLoading(false)
        navigation.navigate("Home")
    }

    useEffect(() => {
        getPageAndUserInfo()
    }, []);

    const onSubmitAptNum = () => {
        refs['additionalInstructions'].current.focus()
    };

    const onSubmitAdditionalInstructions = () => {
        nextPage()
    };

    const nextPage = async () => {
        // check that an address is selected
        if (!userAddress || userAddress === '') {
            openAlertModal('Error', 'Please select your address.', 'Ok', closeAlertModal)
            return
        }
        setPageLoading(true)
        try {
            if (originalUserAddress !== userAddress) {
                const user = firebase.auth().currentUser;
                const updateUserAddress = firebase.functions().httpsCallable('updateUserAddress');
                const updateDict = {
                    userAddress: userAddress,
                    addressMainText: addressMainText,
                    addressSecondaryText: addressSecondaryText,
                    additionalInstructions: additionalInstructions,
                    aptNum: aptNum,
                }
                const response = await updateUserAddress({
                    userId: user.uid,
                    updateDict: updateDict,
                    updateContext: 'new_pickup'
                })
                if (!response.data.success) {
                    if (response.data.message) {
                        setPageLoading(false)
                        openAlertModal('Error', response.data.message, 'Ok', closeAlertModal)
                        return
                    }
                    else {
                        setPageLoading(false)
                        openAlertModal(CONNECTION_ERROR_HEADER, CONNECTION_ERROR_MESSAGE, CONNECTION_ERROR_BUTTON, closeAlertModal)
                        return
                    }
                }
            }
            setPageLoading(false)
            navigation.navigate("Dry Cleaning Pickup Contact Info", {
                pickupDay: pickupDay,
                pickupTime: pickupTime,
                pickupDate: pickupDate,
            })
            return
        }
        catch (err) {
            console.log('error updating preferred pickup day and time', err)
            setPageLoading(false)
            openAlertModal(CONNECTION_ERROR_HEADER, CONNECTION_ERROR_MESSAGE, CONNECTION_ERROR_BUTTON, closeAlertModal)
            return
        }
    }

    const openAddressDialog = () => {
        setAddressDialogVisible(true)
    }

    if (pageLoading) {
        return (
            <View style={styles.parent}>
                <ActivityIndicator />
            </View>
        )
    }

    return (
        <View style={styles.parent}>
            <ScrollView>
                <View style={{ marginLeft: 10, marginRight: 10, marginTop: 10, marginBottom: 10 }}>
                    <Text style={styles.subsubheading}>{message1}</Text>
                </View>
                <Section headerTextColor={HEADER_TEXT_COLOR} header="ADDRESS" footer="">
                    <Cell cellStyle="Basic" backgroundColor={CELL_BACKGROUND_COLOR} titleTextColor={CELL_TITLE_TEXT_COLOR} title={`${addressMainText}`} onPress={openAddressDialog} />
                    <Cell cellStyle="Basic" backgroundColor={CELL_BACKGROUND_COLOR} titleTextColor={CELL_TITLE_TEXT_COLOR} title={`${addressSecondaryText}`} onPress={openAddressDialog} />
                    <Cell backgroundColor={CELL_BACKGROUND_COLOR} cellContentView={
                        <TextInput
                            style={{ fontSize: 16, flex: 1, color: TEXT_INPUT_COLOR }}
                            placeholder="Apt/Suite # (optional)"
                            ref={refs["aptNum"]}
                            autoCorrect={false}
                            enablesReturnKeyAutomatically={true}
                            onChangeText={(value) => setAptNum(value)}
                            value={aptNum}
                            onSubmitEditing={onSubmitAptNum}
                            returnKeyType="done"
                            placeholderTextColor={PLACEHOLDER_TEXT_INPUT_COLOR}
                        />
                    } />
                </Section>
                <Section headerTextColor={HEADER_TEXT_COLOR} header="ADDITIONAL INSTRUCTIONS">
                    <Cell backgroundColor={CELL_BACKGROUND_COLOR} cellContentView={
                        <TextInput
                            style={{ fontSize: 16, flex: 1, color: TEXT_INPUT_COLOR }}
                            placeholder="Additional Instructions (optional)"
                            ref={refs["additionalInstructions"]}
                            autoCorrect={false}
                            enablesReturnKeyAutomatically={true}
                            onChangeText={(value) => setAdditionalInstructions(value)}
                            value={additionalInstructions}
                            onSubmitEditing={onSubmitAdditionalInstructions}
                            returnKeyType="done"
                            placeholderTextColor={PLACEHOLDER_TEXT_INPUT_COLOR}
                        />
                    } />
                </Section>
                <View style={{ marginLeft: 10, marginRight: 10, marginTop: 10, marginBottom: 10 }}>
                    <Text style={styles.subsubheading}>{message2}</Text>
                </View>
                <View style={{ height: 50, width: "100%", marginTop: 40, alignItems: 'center', marginBottom: 50 }}>
                    <View style={{ width: "70%", maxWidth: 250, minWidth: 200 }} >
                        <Button
                            onPress={nextPage}
                            title='Continue'
                            buttonStyle={{
                                backgroundColor: BUTTON_COLOR_TWO
                            }}
                        />
                    </View>
                </View>
            </ScrollView>
            <AddressPicker
                modalVisible={addressDialogVisible}
                setModalVisible={setAddressDialogVisible}
                setUserAddress={setUserAddress}
                setAddressMainText={setAddressMainText}
                setAddressSecondaryText={setAddressSecondaryText}
            />
            <AlertModal
                modalVisible={alertModalVisibile}
                setModalVisible={setAlertModalVisible}
                alertTitleText={alertInfo.alertTitleText}
                alertSubtitleText={alertInfo.alertSubtitleText}
                alertPrimaryButtonText={alertInfo.alertPrimaryButtonText}
                alertPrimaryAction={alertInfo.alertPrimaryAction}
                alertSecondaryButtonText={alertInfo.alertSecondaryButtonText}
                alertSecondaryAction={alertInfo.alertSecondaryAction}
            />
        </View>
    );
}