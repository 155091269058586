import React, { useEffect, useState } from 'react';
import {
    Text,
    View
} from 'react-native';
import styles from './styles';
import firebase from "firebase/app"
import "firebase/auth"
import "firebase/firestore"
import "firebase/functions"
import { MaterialIcons, MaterialCommunityIcons, AntDesign, Entypo, Ionicons, FontAwesome } from '@expo/vector-icons';
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs'
import { ACCENT_COLOR_TWO, CELL_TITLE_TEXT_COLOR, CONNECTION_ERROR_BUTTON, CONNECTION_ERROR_HEADER, CONNECTION_ERROR_MESSAGE, TEXT_COLOR_ONE, TOP_TAB_HEADER_ICON_COLOR, TOP_TAB_HEADER_ICON_SIZE, BUTTON_COLOR_ONE, WASH_AND_FOLD_PICKUP_DISPLAY_NAME } from '../../../constants';
import { ActivityIndicator } from 'react-native';
import { ScrollView } from 'react-native-gesture-handler';
import { Button } from 'react-native-elements';
import WashAndFoldPickupPage from './WashAndFoldPickupPage';
import PlanPage from './PlanPage.js';

const TopTab = createMaterialTopTabNavigator();

export default function PickupOptionSelector() {

    useEffect(() => {
        setPageLoading(true)
        getPlanInfo()
    }, []);

    const [pageLoading, setPageLoading] = useState(true)
    const [connectionError, setConnectionError] = useState(false)
    const [planInfo, setPlanInfo] = useState([])
    const [pricePerPound, setPricePerPound] = useState(0)

    const getPlanInfo = async () => {
        try {
            const plansCollection = await firebase.firestore().collection('residentialInfo').doc('Plans').collection('options').get()
            const pricingInfoDoc = await firebase.firestore().collection('residentialInfo').doc('Pricing').get()
            const pricingInfo = pricingInfoDoc.data()
            const pricePerPoundLocal = pricingInfoDoc.data().pricePerPound
            setPricePerPound(pricePerPoundLocal)
            setPricePerPound(pricingInfo.pricePerPound)
            const planInfoLocal = []
            plansCollection.forEach((doc) => {
                planInfoLocal.push(doc.data())
            })
            setPlanInfo(planInfoLocal)
            setConnectionError(false)
            setPageLoading(false)
        }
        catch (err) {
            console.log(err)
            setConnectionError(true)
            setPageLoading(false)
        }
    }

    const topTabScreens = () => {
        const topTabScreens = []
        var index = 0
        topTabScreens.push(
            <TopTab.Screen name={WASH_AND_FOLD_PICKUP_DISPLAY_NAME}
                key={index}
                options={{
                    tabBarIcon: ({ tintColor, focused }) => (
                        <View style={{ alignItems: 'center' }}>
                            <MaterialCommunityIcons name="repeat-off" size={TOP_TAB_HEADER_ICON_SIZE} color={TOP_TAB_HEADER_ICON_COLOR} />
                        </View>
                    ),
                    cardStyle: { backgroundColor: 'rgba(20, 21, 24, .0)' }
                }}
            >
                {() => <WashAndFoldPickupPage
                    pricePerPound={pricePerPound}
                />}
            </TopTab.Screen>
        )
        planInfo.forEach((planObj) => {
            index++
            const planPageComponent = () => {

            }
            topTabScreens.push(
                <TopTab.Screen name={planObj['displayName']}
                    key={index}
                    options={{
                        tabBarIcon: ({ tintColor, focused }) => (
                            <View style={{ alignItems: 'center' }}>
                                <MaterialCommunityIcons name="repeat" size={TOP_TAB_HEADER_ICON_SIZE} color={TOP_TAB_HEADER_ICON_COLOR} />
                            </View>
                        ),
                        cardStyle: { backgroundColor: 'rgba(20, 21, 24, .0)' }
                    }}
                >
                    {() => <PlanPage
                        optionName={planObj['name']}
                        displayName={planObj['displayName']}
                        description={planObj['description']}
                        pricePerPound={pricePerPound}
                    />}
                </TopTab.Screen>
            )
        })
        return topTabScreens
    }

    if (connectionError) {
        return (
            <View style={styles.parent}>
                <ScrollView keyboardShouldPersistTaps='handled'>
                    <View style={{ marginTop: 10, flex: 1, alignItems: 'center' }}>
                        <View style={{ height: 65, width: "100%", marginLeft: 0, marginTop: 5, justifyContent: "center" }}>
                            <Text style={styles.heading}>Connection Error</Text>
                        </View>
                    </View>
                    <View style={{ width: "100%", alignItems: 'center' }}>
                        <View style={{ height: 50, width: "70%", maxWidth: 400, marginLeft: 0, marginTop: 50, flex: 1 }}>
                            <Button
                                onPress={() => getPlanInfo()}
                                title='Try Again'
                                buttonStyle={{
                                    backgroundColor: BUTTON_COLOR_ONE
                                }}
                            />
                        </View>
                    </View>
                </ScrollView>
            </View>
        )
    }

    if (pageLoading) {
        return (
            <View style={styles.parent}>
                <ActivityIndicator />
            </View>
        )
    }

    return (
        <TopTab.Navigator screenOptions={{ cardStyle: { backgroundColor: 'rgba(20, 21, 24, .0)' } }} tabBarOptions={{ showIcon: true, showLabel: true, labelStyle: { fontSize: 15, textTransform: 'none' }, style: { backgroundColor: ACCENT_COLOR_TWO }, activeTintColor: TEXT_COLOR_ONE }}>
            {topTabScreens()}
        </TopTab.Navigator>
    );
}