import React, { useEffect, useState } from 'react';
import {
    View,
    SafeAreaView,
    Text,
    ScrollView,
    Image,
    Dimensions,
    TouchableOpacity,
    Alert
} from 'react-native';
import { Card, Button } from 'react-native-elements';
import styles from './styles';
import firebase from "firebase/app"
import "firebase/auth"
import "firebase/firestore"
import "firebase/functions"
import { ACCENT_COLOR_ONE, ACCENT_COLOR_THREE, BACKGROUND_COLOR, BUTTON_COLOR_ONE, DRY_CLEAN_PICKUP_DESCRIPTION, TEXT_COLOR_ONE, ACCENT_COLOR_SEVEN, TEXT_COLOR_THREE, DRY_CLEAN_PRICES } from '../../../constants';
import AlertModal from '../AlertModal';

export default function DryCleaningPickupPage({ navigation, route }) {

    const { usersCurrentPlan, userHasAPlanAlready, pricePerPound, userCity, minimumCharge } = route.params

    const tumbleBagImages = {
        '0': require('../../assets/tumble_bags/tumble_bag.png'),
        '1': require('../../assets/tumble_bags/tumble_bag1.png'),
        '2': require('../../assets/tumble_bags/tumble_bag2.png'),
        '3': require('../../assets/tumble_bags/tumble_bag3.png'),
        '4': require('../../assets/tumble_bags/tumble_bag4.png'),
        '5': require('../../assets/tumble_bags/tumble_bag5.png'),
        '6': require('../../assets/tumble_bags/tumble_bag6.png'),
        '7': require('../../assets/tumble_bags/tumble_bag7.png'),
        '8': require('../../assets/tumble_bags/tumble_bag8.png'),
        '9': require('../../assets/tumble_bags/tumble_bag9.png'),
        '10': require('../../assets/tumble_bags/tumble_bag10.png'),
    }

    const [alertModalVisibile, setAlertModalVisible] = useState(false);
    const [alertInfo, setAlertInfo] = useState({})
    const closeAlertModal = () => {
        setAlertModalVisible(false)
    }
    const openAlertModal = (title, subtitle, primaryButtonText, primaryButtonAction, secondaryButtonText = null, secondaryButtonAction = null) => {
        setAlertInfo({
            alertTitleText: title,
            alertSubtitleText: subtitle,
            alertPrimaryButtonText: primaryButtonText,
            alertPrimaryAction: () => primaryButtonAction(),
            alertSecondaryButtonText: secondaryButtonText,
            alertSecondaryAction: () => secondaryButtonAction()
        })
        setAlertModalVisible(true)
    }

    const goToDryCleaningPickupPage = () => {
        closeAlertModal()
        navigation.navigate("Dry Cleaning Pickup Date Time")
    }

    const schedulePickup = () => {
        if (userHasAPlanAlready) {
            openAlertModal(
                'Already Have a Plan',
                `You already have the ${usersCurrentPlan} plan.  Are you sure you want to schedule an additional pickup?  This will be in addition to your existing pickups.`,
                'Yes', goToDryCleaningPickupPage, 'Cancel', closeAlertModal
            )
        }
        else {
            goToDryCleaningPickupPage()
        }
    }

    const schedulePickupButton = () => {
        var buttonTitle = 'Schedule Pickup'
        return (
            <View style={{ height: 50, width: "70%", maxWidth: 400, marginLeft: 0, marginTop: 40, flex: 1 }}>
                <Button
                    onPress={() => schedulePickup()}
                    title={buttonTitle}
                    buttonStyle={{
                        backgroundColor: BUTTON_COLOR_ONE
                    }}
                />
            </View>
        )
    }

    const pricingInfoTable = () => {
        const rowList = []
        var index = 0
        const dryCleanPriceLength = Object.keys(DRY_CLEAN_PRICES).length
        for (const item in DRY_CLEAN_PRICES) {
            var marginBottom = 0
            if (index === (dryCleanPriceLength - 1)) {
                marginBottom = 20
            }
            rowList.push(
                <View style={{ flex: 1, alignSelf: 'stretch', flexDirection: 'row', marginTop: 20, marginLeft: 20, marginRight: 20, marginBottom: marginBottom }}>
                    <View style={{ flex: 1, alignSelf: 'stretch' }}><Text style={{ color: TEXT_COLOR_THREE, fontSize: 20 }}>{item}</Text></View>
                    <View style={{ flex: 1, alignSelf: 'stretch', alignContent: 'flex-end' }}><Text style={{ textAlign: 'right', color: TEXT_COLOR_THREE, fontSize: 20 }}>{DRY_CLEAN_PRICES[item]}</Text></View>
                </View>
            )
            index += 1
        }
        return (
            <>
                <View style={{ width: "75%", maxWidth: 400 }}>
                    <View style={{ width: "100%", borderColor: ACCENT_COLOR_SEVEN, borderWidth: 1 }}>
                        {rowList}
                    </View>
                </View>
            </>
        )
    }

    return (
        <View style={styles.parent}>
            <ScrollView keyboardShouldPersistTaps='handled'>
                <View style={{ marginTop: 10, flex: 1, alignItems: 'center' }}>
                    <View style={{ height: 40, width: "100%", marginLeft: 0, marginTop: 5, justifyContent: "center" }}>
                        <Text style={styles.heading}>{DRY_CLEAN_PICKUP_DESCRIPTION}</Text>
                    </View>
                    <View style={{ height: 40, width: "100%", marginLeft: 0, justifyContent: "center" }}>
                        <Text style={styles.subsubheading}></Text>
                    </View>
                    {pricingInfoTable()}
                </View>
                <View style={{ width: "100%", alignItems: 'center', marginBottom: 50 }}>
                    {schedulePickupButton()}
                    <View style={{ height: 50, width: "70%", maxWidth: 400, marginLeft: 0, marginTop: 50, flex: 1 }}>
                        <Button
                            onPress={() => navigation.navigate("Pricing Detail")}
                            title='More Info'
                            buttonStyle={{
                                backgroundColor: BUTTON_COLOR_ONE
                            }}
                        />
                    </View>
                </View>
            </ScrollView>
            <AlertModal
                modalVisible={alertModalVisibile}
                setModalVisible={setAlertModalVisible}
                alertTitleText={alertInfo.alertTitleText}
                alertSubtitleText={alertInfo.alertSubtitleText}
                alertPrimaryButtonText={alertInfo.alertPrimaryButtonText}
                alertPrimaryAction={alertInfo.alertPrimaryAction}
                alertSecondaryButtonText={alertInfo.alertSecondaryButtonText}
                alertSecondaryAction={alertInfo.alertSecondaryAction}
            />
        </View>
    );
}