import React, { useState, useEffect, useLayoutEffect, useContext } from 'react'
import { View, Image, Text, ActivityIndicator, ScrollView, BackHandler } from 'react-native'
import firebase from "firebase/app"
import "firebase/auth"
import "firebase/firestore"
import "firebase/functions"
import styles from '../styles';
import { Button } from 'react-native-elements';
import { BUTTON_COLOR_ONE, BUTTON_COLOR_TWO, CONNECTION_ERROR_BUTTON, CONNECTION_ERROR_HEADER, CONNECTION_ERROR_MESSAGE } from '../../../../constants';
import AlertModal from '../../AlertModal'


export default function Welcome({ navigation }) {

    useLayoutEffect(() => {
        navigation.setOptions({
            headerRight: null,
            headerLeft: null
        })
    })

    const nextPage = () => {
        navigation.navigate("Account Creation Contact Info")
    }

    const [pageLoading, setPageLoading] = useState(true);
    const [messages, setMessages] = useState([])
    const [alertModalVisibile, setAlertModalVisible] = useState(false);
    const [alertInfo, setAlertInfo] = useState({})
    const openAlertModal = (title, subtitle, primaryButtonText, primaryButtonAction, secondaryButtonText = null, secondaryButtonAction = null) => {
        setAlertInfo({
            alertTitleText: title,
            alertSubtitleText: subtitle,
            alertPrimaryButtonText: primaryButtonText,
            alertPrimaryAction: () => primaryButtonAction(),
            alertSecondaryButtonText: secondaryButtonText,
            alertSecondaryAction: () => secondaryButtonAction()
        })
        setAlertModalVisible(true)
    }
    const closeAlertModal = () => {
        setAlertModalVisible(false)
    }

    const logout = async () => {
        try {
            await firebase.auth().signOut();
            setPageLoading(false)
        }
        catch (err) {
            openAlertModal('Error Logging Out', 'Please try again', 'Try Again', logout)
        }
    }

    const createUserAccount = async () => {
        setPageLoading(true)
        try {
            const user = firebase.auth().currentUser
            await user.sendEmailVerification()
            var createUserProfile = firebase.functions().httpsCallable('createUserProfile')
            const response = await createUserProfile({})
            if (!response.data.success) {
                console.log('Error creating user profile:', response.data.reason)
                setPageLoading(false)
                openAlertModal('Error Creating Profile', 'We are unable to create your profile at this time.  Please try again.  If this issue persists, please log out and contact us.', 'Try Again', createUserAccount, 'Log Out', logout)
                return
            }
            setPageLoading(false)
        }
        catch (err) {
            console.log('error sending email verification or creating user profile:', err)
            setPageLoading(false)
            openAlertModal('Error Creating Profile', 'We are unable to create your profile at this time.  Please try again.  If this issue persists, please log out and contact us.', 'Try Again', createUserAccount, 'Log Out', logout)
        }
    }

    const getPageAndUserInfo = async () => {
        setPageLoading(true)
        const user = firebase.auth().currentUser;
        const userEmail = user.email
        try {
            const pageInfo = await firebase.firestore().collection('pageInfo').doc('AccountCreation').collection('pages').doc('welcome').get()
            if (pageInfo.data() && pageInfo.data().messages) {
                var newMessages = []
                pageInfo.data().messages.forEach((message) => {
                    if (message.search('{email}') !== -1) {
                        // insert the email address
                        var newMessage = message.replace('{email}', userEmail)
                        newMessages.push(newMessage)
                    }
                    else {
                        newMessages.push(message)
                    }
                })
                setMessages(newMessages)
            }
            else {
                console.log('error getting page data')
                setPageLoading(false)
                openAlertModal(CONNECTION_ERROR_HEADER, CONNECTION_ERROR_MESSAGE, CONNECTION_ERROR_BUTTON, getPageAndUserInfo, 'Log Out', logout)
                return
            }
            const userInfo = (await firebase.firestore().collection("userProfiles").doc(user.uid).get()).data();
            if (!userInfo) {
                createUserAccount()
            }
            else {
                setPageLoading(false)
            }
        }
        catch (err) {
            console.log('error getting page or data:', err)
            setPageLoading(false)
            openAlertModal(CONNECTION_ERROR_HEADER, CONNECTION_ERROR_MESSAGE, CONNECTION_ERROR_BUTTON, getPageAndUserInfo, 'Log Out', logout)
            return
        }
    }

    useEffect(() => {
        const user = firebase.auth().currentUser
        getPageAndUserInfo()
        BackHandler.addEventListener('hardwareBackPress', onBackPress);
        return () => {
            BackHandler.removeEventListener('hardwareBackPress', onBackPress);
        }
    }, []);


    const onBackPress = () => {
        return true;
    }

    const displayMessages = () => {
        var valueArray = []
        var index = 0
        messages.forEach((message) => {
            valueArray.push(
                <Text key={index} style={styles.subheading}>
                    {message}
                </Text>
            )
            index++
        })
        return valueArray
    }

    if (pageLoading) {
        return (
            <View style={styles.parent}>
                <ActivityIndicator />
            </View>
        )
    }

    return (
        <View style={styles.parent}>
            <ScrollView keyboardShouldPersistTaps='handled'>
                <View style={styles.imageContainer}>
                    <Image source={require("../../../assets/Tumble_Logo.png")} style={{ height: 200, width: 200 }} />
                </View>
                {displayMessages()}
                <View style={{ height: 50, width: "100%", marginTop: 40, alignItems: 'center', marginBottom: 50 }}>
                    <View style={{ width: "70%", maxWidth: 250, minWidth: 200 }} >
                        <Button
                            onPress={nextPage}
                            title='Continue'
                            buttonStyle={{
                                backgroundColor: BUTTON_COLOR_TWO
                            }}
                        />
                    </View>
                </View>
            </ScrollView>
            <AlertModal
                modalVisible={alertModalVisibile}
                setModalVisible={setAlertModalVisible}
                alertTitleText={alertInfo.alertTitleText}
                alertSubtitleText={alertInfo.alertSubtitleText}
                alertPrimaryButtonText={alertInfo.alertPrimaryButtonText}
                alertPrimaryAction={alertInfo.alertPrimaryAction}
                alertSecondaryButtonText={alertInfo.alertSecondaryButtonText}
                alertSecondaryAction={alertInfo.alertSecondaryAction}
            />
        </View>
    );
}