import React, { useState, useEffect, useLayoutEffect } from 'react';
import {
    View,
    SafeAreaView,
    Text,
    ScrollView,
    Image,
    Dimensions,
    ActivityIndicator
} from 'react-native';
import { Button } from 'react-native-elements';
import firebase from "firebase/app"
import "firebase/auth"
import "firebase/firestore"
import "firebase/functions"
import styles from './styles';
import { ACCENT_COLOR_FIVE, ACCENT_COLOR_ONE, ACCENT_COLOR_THREE, BACKGROUND_COLOR, BUTTON_COLOR_ONE, BUTTON_COLOR_TWO, HEADER_TEXT_COLOR, TEXT_COLOR_ONE, TEXT_COLOR_THREE } from '../../../constants';
import { TouchableOpacity } from 'react-native-gesture-handler';
import { Alert } from 'react-native';
import { HeaderBackButton } from '@react-navigation/stack'
import AlertModal from '../AlertModal';


export default function ConfirmUseRewards({ route, navigation }) {

    const { optionsObj } = route.params

    useLayoutEffect(() => {
        navigation.setOptions({
            headerRight: () => (
                <Button
                    onPress={() => navigation.navigate('Rewards')}
                    title="Cancel"
                    type="clear"
                    titleStyle={{
                        fontWeight: "normal",
                        color: HEADER_TEXT_COLOR
                    }}
                    buttonStyle={{
                        marginRight: 10
                    }}
                />
            ),
            headerLeft: () => (
                <HeaderBackButton
                    label="Back"
                    tintColor={HEADER_TEXT_COLOR}
                    onPress={() => navigation.goBack()}
                />
            )
        })
    })

    useEffect(() => {

    }, [])

    const [pageLoading, setPageLoading] = useState(false)
    const [alertModalVisibile, setAlertModalVisible] = useState(false);
    const [alertInfo, setAlertInfo] = useState({})
    const closeAlertModal = () => {
        setAlertModalVisible(false)
    }
    const openAlertModal = (title, subtitle, primaryButtonText, primaryButtonAction, secondaryButtonText = null, secondaryButtonAction = null) => {
        setAlertInfo({
            alertTitleText: title,
            alertSubtitleText: subtitle,
            alertPrimaryButtonText: primaryButtonText,
            alertPrimaryAction: () => primaryButtonAction(),
            alertSecondaryButtonText: secondaryButtonText,
            alertSecondaryAction: () => secondaryButtonAction()
        })
        setAlertModalVisible(true)
    }


    const confirmUseRewards = async () => {
        if (optionsObj.optionTitle) {
            try {
                setPageLoading(true)
                setAlertModalVisible(false)
                const redeemRewards = firebase.functions().httpsCallable('redeemRewards');
                const response = await redeemRewards({
                    optionTitle: optionsObj.optionTitle
                })
                if (response.data.success) {
                    console.log(response.data)
                    setPageLoading(false)
                    openAlertModal(response.data.messageTitle, response.data.message, 'Ok', () => navigation.navigate('Rewards'))
                }
                else {
                    console.log('error getting rewards info', response.data.error)
                    setPageLoading(false)
                    openAlertModal('Error', 'Sorry, there was an error redeeming your rewards.  Please try again.  If this issue persists, please contact us.', 'Try Again', confirmUseRewards, 'Go Back', () => navigation.goBack())
                }
            }
            catch (err) {
                console.log('error getting rewards information:', err)
                setPageLoading(false)
                openAlertModal('Error', 'Sorry, there was an error getting rewards information.  Please try again.  If this issue persists, please contact us.', 'Try Again', confirmUseRewards, 'Go Back', () => navigation.goBack())
            }
        }
        else {
            openAlertModal('Error', 'Error getting your rewards information.  Please try again.', 'Ok', () => navigation.navigate('Use Rewards'))
        }
    }

    if (pageLoading) {
        return (
            <View style={styles.parent}>
                <ActivityIndicator />
            </View>
        )
    }

    return (
        <View style={styles.parent}>
            <ScrollView style={{ width: "100%" }}>
                <View style={styles.imageContainer}>
                    <Image source={require("../../assets/Tumble_Logo.png")} style={{ height: 150, width: 150 }} />
                </View>
                <Text style={{ textAlign: 'center', fontSize: 35, color: HEADER_TEXT_COLOR }}>Confirm</Text>
                <View style={{ marginTop: 40, alignItems: 'center' }}>
                    <View style={{ width: "90%", maxWidth: 600, backgroundColor: ACCENT_COLOR_THREE }}>
                        <Text style={{ textAlign: 'center', color: TEXT_COLOR_ONE, marginLeft: 15, marginTop: 15, marginBottom: 15, fontSize: 25 }}>{optionsObj.optionTitle}</Text>
                    </View>
                    <View style={{ width: "90%", maxWidth: 600, backgroundColor: ACCENT_COLOR_FIVE }}>
                        <Text style={{ textAlign: 'center', color: TEXT_COLOR_ONE, marginLeft: 15, marginTop: 15, marginBottom: 15, fontSize: 25 }}>{optionsObj.numberOfPoints}</Text>
                    </View>
                    <View style={{ width: "90%", maxWidth: 500 }}>
                        <Text style={{ textAlign: 'center', color: TEXT_COLOR_THREE, marginLeft: 25, marginRight: 25, marginTop: 25, fontSize: 25 }}>{optionsObj.confirmMessage}</Text>
                    </View>
                </View>
                <View style={{ marginTop: 20, width: "100%", alignItems: 'center', height: 50, flex: 1 }}>
                    <Button
                        onPress={confirmUseRewards}
                        title='Confirm'
                        buttonStyle={{
                            backgroundColor: BUTTON_COLOR_TWO,
                            width: 250
                        }}
                    />
                </View>
            </ScrollView>
            <AlertModal
                modalVisible={alertModalVisibile}
                setModalVisible={setAlertModalVisible}
                alertTitleText={alertInfo.alertTitleText}
                alertSubtitleText={alertInfo.alertSubtitleText}
                alertPrimaryButtonText={alertInfo.alertPrimaryButtonText}
                alertPrimaryAction={alertInfo.alertPrimaryAction}
                alertSecondaryButtonText={alertInfo.alertSecondaryButtonText}
                alertSecondaryAction={alertInfo.alertSecondaryAction}
            />
        </View>
    );
}