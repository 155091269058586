import React, { useState, useEffect, useLayoutEffect } from 'react';
import {
    ActivityIndicator,
    View,
    Alert,
    ScrollView,
    Dimensions,
    BackHandler,
    Text
} from 'react-native';
import { Button } from 'react-native-elements';
import styles from './styles';
import { Cell, Section } from 'react-native-tableview-simple';
import firebase from "firebase/app"
import "firebase/auth"
import "firebase/firestore"
import "firebase/functions"
import DatePicker from 'react-date-picker'
import { BACKGROUND_COLOR, CELL_BACKGROUND_COLOR, CELL_TITLE_TEXT_COLOR, HEADER_TEXT_COLOR, BUTTON_COLOR_TWO, CONNECTION_ERROR_HEADER, CONNECTION_ERROR_MESSAGE, CONNECTION_ERROR_BUTTON } from '../../../../constants';
import { requestPermissionsAsync } from 'expo-av/build/Audio';
import AlertModal from '../../AlertModal'

export default function DateTime({ navigation }) {

    useLayoutEffect(() => {
        navigation.setOptions({
            headerRight: () => (
                <Button
                    onPress={cancelPickup}
                    title="Cancel"
                    type="clear"
                    titleStyle={{
                        fontWeight: "normal",
                        color: HEADER_TEXT_COLOR
                    }}
                    buttonStyle={{
                        marginRight: 10
                    }}
                    disabled={pageLoading}
                />
            ),
            headerLeft: null
        })
    })

    const getPageAndUserInfo = async () => {
        try {
            const pickupDateDoc = await firebase.firestore().collection('residentialInfo').doc('PickupSchedule').get()
            var pickupTimes = []
            var timeAccessoryLocal = {}
            if (pickupDateDoc.data() && pickupDateDoc.data().times) {
                pickupTimes = pickupDateDoc.data().times
                var index = 0
                pickupDateDoc.data().times.forEach((time) => {
                    if (index === 0) {
                        setTime(time)
                        timeAccessoryLocal[time] = "Checkmark"
                        index++
                    }
                    else {
                        timeAccessoryLocal[time] = "none"
                    }
                })
                setTimeAccessory(timeAccessoryLocal)
                setTimes(pickupTimes)
            }
            else {
                setPageLoading(false)
                openAlertModal(CONNECTION_ERROR_HEADER, CONNECTION_ERROR_MESSAGE, CONNECTION_ERROR_BUTTON, cancelPickup)
                return
            }

            // get page info
            const pageInfo = (await firebase.firestore().collection('pageInfo').doc('DryCleaningPickup').collection('pages').doc('DateTime').get()).data();
            if (pageInfo) {
                if (pageInfo.message1) {
                    setMessage1(pageInfo.message1)
                }
                if (pageInfo.message2) {
                    setMessage2(pageInfo.message2)
                }
            }
            else {
                setPageLoading(false)
                openAlertModal(CONNECTION_ERROR_HEADER, CONNECTION_ERROR_MESSAGE, CONNECTION_ERROR_BUTTON, cancelPickup)
                return
            }

            // get user info
            const user = firebase.auth().currentUser;
            const userInfo = (await firebase.firestore().collection("userProfiles").doc(user.uid).get()).data();
            if (userInfo) {
                if (userInfo.preferredPickupTime) {
                    selectTime(userInfo.preferredPickupTime, timeAccessoryLocal)
                }
                else {
                    selectTime(pickupDateDoc.data().times[0], timeAccessoryLocal)
                }
            }
            else {
                setPageLoading(false)
                openAlertModal(CONNECTION_ERROR_HEADER, CONNECTION_ERROR_MESSAGE, CONNECTION_ERROR_BUTTON, cancelPickup)
                return
            }
            setPageLoading(false)
        }
        catch (err) {
            console.log('error:', err)
            setPageLoading(false)
            openAlertModal(CONNECTION_ERROR_HEADER, CONNECTION_ERROR_MESSAGE, CONNECTION_ERROR_BUTTON, cancelPickup)
            return
        }
    }

    const [date, setDate] = useState(new Date(Date.now() + (1000 * 60 * 60 * 24)))
    const [time, setTime] = useState("")
    const [message1, setMessage1] = useState('')
    const [message2, setMessage2] = useState('')
    const [times, setTimes] = useState([])
    const [timeAccessory, setTimeAccessory] = useState({})
    const [pageLoading, setPageLoading] = useState(true)
    const [alertModalVisibile, setAlertModalVisible] = useState(false);
    const [alertInfo, setAlertInfo] = useState({})
    const closeAlertModal = () => {
        setAlertModalVisible(false)
    }
    const openAlertModal = (title, subtitle, primaryButtonText, primaryButtonAction, secondaryButtonText = null, secondaryButtonAction = null) => {
        setAlertInfo({
            alertTitleText: title,
            alertSubtitleText: subtitle,
            alertPrimaryButtonText: primaryButtonText,
            alertPrimaryAction: () => primaryButtonAction(),
            alertSecondaryButtonText: secondaryButtonText,
            alertSecondaryAction: () => secondaryButtonAction()
        })
        setAlertModalVisible(true)
    }

    const cancelPickup = () => {
        setPageLoading(false)
        navigation.navigate("Home")
    }

    useEffect(() => {
        let tomorrowDate = new Date(Date.now() + (1000 * 60 * 60 * 24))
        if (tomorrowDate.getDay() == 0) {
            // Can't be a Sunday, so move to Monday
            setDate(new Date(Date.now() + (1000 * 60 * 60 * 48)))
        }
        getPageAndUserInfo()
        BackHandler.addEventListener('hardwareBackPress', onBackPress);
        return () => {
            BackHandler.removeEventListener('hardwareBackPress', onBackPress);
        }
    }, []);

    const onBackPress = () => {
        return true;
    }

    const selectTime = async (selectedTime, accessoryParam = null) => {
        var accessoryLocal = accessoryParam
        if (!accessoryLocal) {
            accessoryLocal = timeAccessory
        }
        setTime(selectedTime)
        for (var key in accessoryLocal) {
            if (key == selectedTime) {
                accessoryLocal[key] = "Checkmark"
            }
            else {
                accessoryLocal[key] = "none"
            }
        }
        setTimeAccessory(accessoryLocal)
    }

    const nextPage = async () => {
        setPageLoading(true)
        if (!date || !time || time === '') {
            openAlertModal('Error', 'Please select a day and time.', 'Ok', closeAlertModal)
            setPageLoading(false)
            return
        }
        try {
            const user = firebase.auth().currentUser;
            var checkPickupDate = firebase.functions().httpsCallable('checkPickupDate');
            const response = await checkPickupDate({
                pickupDay: date.toDateString(),
                pickupTime: time
            })
            if (response.data) {
                if (response.data.valid) {
                    if (response.data.pickupDate) {
                        setPageLoading(false)
                        navigation.navigate("Dry Cleaning Pickup Delivery Address", {
                            pickupDay: date.toDateString(),
                            pickupTime: time,
                            pickupDate: response.data.pickupDate
                        })
                    }
                    else {
                        openAlertModal('Error', 'Error checking pickup day and time.  Please try again.  If this error persists, please contact us.', 'Ok', closeAlertModal)
                        setPageLoading(false)
                    }
                }
                else {
                    if (response.data.reason) {
                        openAlertModal('Error', response.data.reason, 'Ok', closeAlertModal)
                        setPageLoading(false)
                    }
                    else {
                        openAlertModal('Error', 'Invalid pickup date.  Please try a different day or time.', 'Ok', closeAlertModal)
                        setPageLoading(false)
                    }
                }
            }
            else {
                openAlertModal('Error', 'Error checking pickup day and time.  Please try again.  If this error persists, please contact us.', 'Ok', closeAlertModal)
                setPageLoading(false)
            }
        }
        catch (err) {
            console.log('error:', err)
            setPageLoading(false)
            openAlertModal(CONNECTION_ERROR_HEADER, CONNECTION_ERROR_MESSAGE, CONNECTION_ERROR_BUTTON, cancelPickup)
            return
        }

    }

    const createPickupTimeTable = () => {
        var valueArray = []
        var index = 0
        times.forEach((time) => {
            valueArray.push(
                <Cell key={index} backgroundColor={CELL_BACKGROUND_COLOR} cellStyle="Basic" titleTextColor={CELL_TITLE_TEXT_COLOR} title={time} accessory={timeAccessory[time]} isDisabled={pageLoading} onPress={() => selectTime(time)} />
            )
            index++
        })
        return valueArray
    }

    if (pageLoading) {
        return (
            <View style={styles.parent}>
                <ActivityIndicator />
            </View>
        )
    }

    return (
        <View style={styles.parent}>
            <ScrollView>
                <View style={{ marginLeft: 10, marginRight: 10, marginTop: 10, marginBottom: 10 }}>
                    <Text style={styles.subsubheading}>{message1}</Text>
                </View>
                <Section headerTextColor={HEADER_TEXT_COLOR} header="PICKUP DAY" footer="">
                </Section>
                <DatePicker onChange={setDate} value={date} style={{ zIndex: 10 }} disabled={pageLoading} />
                <Section headerTextColor={HEADER_TEXT_COLOR} header="PICKUP TIME" footer="">
                    {createPickupTimeTable()}
                </Section>
                <View style={{ marginLeft: 10, marginRight: 10, marginTop: 10, marginBottom: 10 }}>
                    <Text style={styles.subsubheading}>{message2}</Text>
                </View>
                <View style={{ height: 50, width: "100%", marginTop: 40, alignItems: 'center', marginBottom: 50 }}>
                    <View style={{ width: "70%", maxWidth: 250, minWidth: 200 }} >
                        <Button
                            onPress={nextPage}
                            title='Continue'
                            buttonStyle={{
                                backgroundColor: BUTTON_COLOR_TWO
                            }}
                        />
                    </View>
                </View>
            </ScrollView>
            <AlertModal
                modalVisible={alertModalVisibile}
                setModalVisible={setAlertModalVisible}
                alertTitleText={alertInfo.alertTitleText}
                alertSubtitleText={alertInfo.alertSubtitleText}
                alertPrimaryButtonText={alertInfo.alertPrimaryButtonText}
                alertPrimaryAction={alertInfo.alertPrimaryAction}
                alertSecondaryButtonText={alertInfo.alertSecondaryButtonText}
                alertSecondaryAction={alertInfo.alertSecondaryAction}
            />
        </View>
    );
}