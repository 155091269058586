import React, { useState, useLayoutEffect, useRef, useEffect } from 'react';
import {
    View,
    ScrollView,
    Dimensions,
    Alert,
    TextInput,
    ActivityIndicator,
    BackHandler,
} from 'react-native';
import { Button } from 'react-native-elements';
import styles from './styles';
import { Cell, Section } from 'react-native-tableview-simple';
import firebase from "firebase/app"
import "firebase/auth"
import "firebase/firestore"
import "firebase/functions"
import { HeaderBackButton } from '@react-navigation/stack'
import { BUTTON_COLOR_TWO, CELL_BACKGROUND_COLOR, CELL_TITLE_TEXT_COLOR, CONNECTION_ERROR_BUTTON, CONNECTION_ERROR_HEADER, CONNECTION_ERROR_MESSAGE, DISABLED_TEXT_INPUT_COLOR, FOOTER_TEXT_COLOR, HEADER_TEXT_COLOR, PLACEHOLDER_TEXT_INPUT_COLOR, TEXT_INPUT_COLOR } from '../../../constants';
import AlertModal from '../AlertModal';
import { Text } from 'react-native';


export default function ManageSubscription({ navigation }) {

    useLayoutEffect(() => {
        navigation.setOptions({
            headerRight: null,
            headerLeft: () => {
                return <HeaderBackButton
                    label="Back"
                    tintColor={HEADER_TEXT_COLOR}
                    disabled={pageLoading}
                    onPress={() => navigation.goBack()}
                />

            },
        })
    })

    useEffect(() => {
        getUserPlanInfo();
        BackHandler.addEventListener('hardwareBackPress', onBackPress);
        return () => {
            BackHandler.removeEventListener('hardwareBackPress', onBackPress);
        }
    }, []);

    const getUserPlanInfo = async () => {
        setPageLoading(true)
        const user = firebase.auth().currentUser;
        try {
            const userInfo = (await firebase.firestore().collection("userProfiles").doc(user.uid).get()).data()
            if (userInfo.plan !== 'None') {
                setUserHasPlan(true)
                setPlanName(userInfo.plan)
            }
            setPageLoading(false)
        }
        catch (err) {
            setPageLoading(false)
            openAlertModal(CONNECTION_ERROR_HEADER, CONNECTION_ERROR_MESSAGE, CONNECTION_ERROR_BUTTON, () => navigation.goBack())
            return
        }
    }

    const onBackPress = () => {
        return true;
    }

    const [pageLoading, setPageLoading] = useState(true)
    const [userHasPlan, setUserHasPlan] = useState(false)
    const [planName, setPlanName] = useState('')
    const [alertModalVisibile, setAlertModalVisible] = useState(false);
    const [alertInfo, setAlertInfo] = useState({})
    const closeAlertModal = () => {
        setAlertModalVisible(false)
    }
    const openAlertModal = (title, subtitle, primaryButtonText, primaryButtonAction, secondaryButtonText = null, secondaryButtonAction = null) => {
        setAlertInfo({
            alertTitleText: title,
            alertSubtitleText: subtitle,
            alertPrimaryButtonText: primaryButtonText,
            alertPrimaryAction: () => primaryButtonAction(),
            alertSecondaryButtonText: secondaryButtonText,
            alertSecondaryAction: () => secondaryButtonAction()
        })
        setAlertModalVisible(true)
    }

    if (pageLoading) {
        return (
            <View style={styles.parent}>
                <ActivityIndicator />
            </View>
        )
    }

    if (!userHasPlan) {
        return (
            <View style={styles.parent}>
                <ScrollView keyboardShouldPersistTaps='handled'>
                    <View style={{ marginLeft: 10, marginRight: 10, marginTop: 10, marginBottom: 10 }}>
                        <Text style={styles.subheading}>You do not currently have a plan.</Text>
                    </View>
                    <View style={{ height: 50, width: "100%", marginTop: 40, alignItems: 'center', marginBottom: 50 }}>
                        <View style={{ width: "70%", maxWidth: 250, minWidth: 200 }} >
                            <Button
                                onPress={() => navigation.navigate('Schedule Options')}
                                title='Purchase a Plan'
                                buttonStyle={{
                                    backgroundColor: BUTTON_COLOR_TWO
                                }}
                            />
                        </View>
                    </View>
                </ScrollView>
                <AlertModal
                    modalVisible={alertModalVisibile}
                    setModalVisible={setAlertModalVisible}
                    alertTitleText={alertInfo.alertTitleText}
                    alertSubtitleText={alertInfo.alertSubtitleText}
                    alertPrimaryButtonText={alertInfo.alertPrimaryButtonText}
                    alertPrimaryAction={alertInfo.alertPrimaryAction}
                    alertSecondaryButtonText={alertInfo.alertSecondaryButtonText}
                    alertSecondaryAction={alertInfo.alertSecondaryAction}
                />
            </View>
        )
    }

    return (
        <View style={styles.parent}>
            <ScrollView keyboardShouldPersistTaps='handled'>
                <View style={{ marginLeft: 10, marginRight: 10, marginTop: 10, marginBottom: 10 }}>
                    <Text style={styles.subheading}>You currently have the {planName} plan.</Text>
                </View>
                <View style={{ marginLeft: 10, marginRight: 10, marginTop: 10, marginBottom: 10 }}>
                    <Text style={styles.subheading}>If you'd like to switch or cancel your plan, please contact us.</Text>
                </View>
            </ScrollView>
            <AlertModal
                modalVisible={alertModalVisibile}
                setModalVisible={setAlertModalVisible}
                alertTitleText={alertInfo.alertTitleText}
                alertSubtitleText={alertInfo.alertSubtitleText}
                alertPrimaryButtonText={alertInfo.alertPrimaryButtonText}
                alertPrimaryAction={alertInfo.alertPrimaryAction}
                alertSecondaryButtonText={alertInfo.alertSecondaryButtonText}
                alertSecondaryAction={alertInfo.alertSecondaryAction}
            />
        </View>
    )
}