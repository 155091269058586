import React from 'react';
import {
    View,
    Text,
    ScrollView,
    Image,
    Linking,
    TouchableHighlight
} from 'react-native';
import styles from './styles';
import { Button } from 'react-native-elements';
import { MaterialIcons, MaterialCommunityIcons, AntDesign, Entypo, Ionicons, FontAwesome } from '@expo/vector-icons';
import TopHeading from '../../../TopHeading.js'
import { ACCENT_COLOR_FOUR, ACCENT_COLOR_THREE, ACCENT_COLOR_TWO, TEXT_COLOR_ONE, TEXT_COLOR_THREE, BUTTON_COLOR_TWO } from '../../../constants';


export default function Contact({ navigation }) {

    return (
        <View style={styles.parent}>
            <View style={{ zIndex: 10 }}>
                <TopHeading navigation={navigation} currentPage="Contact" />
            </View>
            <View style={{ flex: 1, marginTop: 50, zIndex: 0 }}>
                <ScrollView style={{ width: "100%" }}>
                    <View style={{ width: "100%", alignItems: 'center' }}>
                        <View style={styles.imageContainer}>
                            <Image source={require("../../assets/Tumble_Logo.png")} style={{ height: 150, width: 150 }} />
                        </View>
                        <Text style={{ color: TEXT_COLOR_THREE, fontSize: 40, textAlign: 'center' }}>Contact Us</Text>
                        <View style={{ backgroundColor: ACCENT_COLOR_FOUR, width: "90%", maxWidth: 500, marginTop: 20 }}>
                            <View style={{ marginTop: 20 }}>
                                <FontAwesome style={{ textAlign: 'center', color: TEXT_COLOR_ONE, flexWrap: "wrap", width: "100%", marginTop: 5, marginBottom: 15 }} name="mobile-phone" size={60} color={TEXT_COLOR_ONE} />
                                <Text onPress={() => { Linking.openURL('tel:2094071576'); }} style={{ textDecorationLine: 'underline', textAlign: 'center', color: TEXT_COLOR_ONE, flexWrap: "wrap", fontSize: 20, width: "100%", marginTop: 5, marginBottom: 15 }}>(209) 407-1576</Text>
                                <MaterialIcons style={{ textAlign: 'center', color: TEXT_COLOR_ONE, flexWrap: "wrap", width: "100%", marginTop: 5, marginBottom: 15 }} name="email" size={60} color={TEXT_COLOR_ONE} />
                                <Text onPress={() => { window.open('mailto:admin@tumbledelivery.com', '_blank'); }} style={{ textDecorationLine: 'underline', textAlign: 'center', color: TEXT_COLOR_ONE, flexWrap: "wrap", fontSize: 20, width: "100%", marginTop: 5, marginBottom: 15 }}>admin@tumbledelivery.com</Text>
                            </View>

                            <View style={{ marginTop: 50 }}>
                                <Text style={{ textAlign: 'center', color: TEXT_COLOR_ONE, flexWrap: "wrap", fontSize: 25, width: "100%", marginTop: 5, marginBottom: 5 }}>Follow us on Social Media!</Text>
                            </View>
                            <View style={{ width: "100%", marginTop: 20, marginBottom: 40, flexDirection: "row" }}>
                                <View style={{ width: "50%", alignItems: 'center' }}>
                                    <TouchableHighlight onPress={() => { window.open('https://www.facebook.com/TumbleDelivery1', '_blank'); }}>
                                        <Image source={require("../../assets/facebook_logo.png")} style={{ height: 80, width: 80 }} />
                                    </TouchableHighlight>
                                </View>
                                <View style={{ width: "50%", alignItems: 'center' }}>
                                    <TouchableHighlight onPress={() => { window.open('https://www.instagram.com/tumble_delivery/', '_blank'); }}>
                                        <Image source={require("../../assets/instagram_logo.png")} style={{ height: 80, width: 80 }} />
                                    </TouchableHighlight>
                                </View>
                            </View>
                        </View>
                        <View style={{ height: 50, width: "100%", alignItems: "center", flex: 1, marginTop: 25, marginBottom: 50 }}>
                            <Button
                                onPress={() => { navigation.navigate('FAQs') }}
                                title='FAQs'
                                buttonStyle={{
                                    backgroundColor: BUTTON_COLOR_TWO,
                                    width: 300
                                }}
                            />
                        </View>
                    </View>
                </ScrollView>
            </View>
        </View>
    );
}