import React, { useState, useEffect, useLayoutEffect } from 'react';
import {
    ActivityIndicator,
    View,
    Alert,
    ScrollView,
    Dimensions,
    BackHandler,
    Text
} from 'react-native';
import { Button } from 'react-native-elements';
import styles from './styles';
import { Cell, Section } from 'react-native-tableview-simple';
import firebase from "firebase/app"
import "firebase/auth"
import "firebase/firestore"
import "firebase/functions"
import { BACKGROUND_COLOR, CELL_BACKGROUND_COLOR, CELL_TITLE_TEXT_COLOR, HEADER_TEXT_COLOR, BUTTON_COLOR_TWO, CONNECTION_ERROR_HEADER, FOOTER_TEXT_COLOR, SEPARATOR_TINT_COLOR_TWO, CONNECTION_ERROR_MESSAGE, CONNECTION_ERROR_BUTTON } from '../../../../constants';
import { requestPermissionsAsync } from 'expo-av/build/Audio';
import AlertModal from '../../AlertModal'

export default function DayTime({ route, navigation }) {

    const { plan } = route.params

    useLayoutEffect(() => {
        navigation.setOptions({
            headerRight: () => (
                <Button
                    onPress={cancelPlan}
                    title="Cancel"
                    type="clear"
                    titleStyle={{
                        fontWeight: "normal",
                        color: HEADER_TEXT_COLOR
                    }}
                    buttonStyle={{
                        marginRight: 10
                    }}
                    disabled={pageLoading}
                />
            ),
            headerLeft: null
        })
    })

    const getPageAndUserInfo = async () => {
        try {
            const dayTimeDoc = await firebase.firestore().collection('residentialInfo').doc('PickupSchedule').get()
            var pickupDays = []
            var pickupTimes = []
            var dayAccessoryLocal = {}
            var timeAccessoryLocal = {}
            if (dayTimeDoc.data() && dayTimeDoc.data().days && dayTimeDoc.data().times) {
                pickupDays = dayTimeDoc.data().days
                pickupTimes = dayTimeDoc.data().times
                var index = 0
                dayTimeDoc.data().days.forEach((day) => {
                    if (index === 0) {
                        setDay(day)
                        dayAccessoryLocal[day] = "Checkmark"
                        index++
                    }
                    else {
                        dayAccessoryLocal[day] = "none"
                    }
                })
                index = 0
                dayTimeDoc.data().times.forEach((time) => {
                    if (index === 0) {
                        setTime(time)
                        timeAccessoryLocal[time] = "Checkmark"
                        index++
                    }
                    else {
                        timeAccessoryLocal[time] = "none"
                    }
                })
                setDayAccessory(dayAccessoryLocal)
                setTimeAccessory(timeAccessoryLocal)
                setDays(pickupDays)
                setTimes(pickupTimes)
            }
            else {
                setPageLoading(false)
                openAlertModal(CONNECTION_ERROR_HEADER, CONNECTION_ERROR_MESSAGE, CONNECTION_ERROR_BUTTON, cancelPlan)
                return
            }

            // get page info
            const pageInfo = (await firebase.firestore().collection('pageInfo').doc('Plan').collection('pages').doc('DayTime').get()).data();
            if (pageInfo) {
                if (pageInfo.message1) {
                    setMessage1(pageInfo.message1)
                }
                if (pageInfo.message2) {
                    setMessage2(pageInfo.message2)
                }
            }
            else {
                setPageLoading(false)
                openAlertModal(CONNECTION_ERROR_HEADER, CONNECTION_ERROR_MESSAGE, CONNECTION_ERROR_BUTTON, cancelPlan)
                return
            }

            // get user info
            const user = firebase.auth().currentUser;
            const userInfo = (await firebase.firestore().collection("userProfiles").doc(user.uid).get()).data();
            if (userInfo) {
                if (userInfo.preferredPickupDay && userInfo.preferredPickupTime) {
                    setOriginalDay(userInfo.preferredPickupDay)
                    setOriginalTime(userInfo.preferredPickupTime)
                    selectDay(userInfo.preferredPickupDay, dayAccessoryLocal)
                    selectTime(userInfo.preferredPickupTime, timeAccessoryLocal)
                }
            }
            else {
                setPageLoading(false)
                openAlertModal(CONNECTION_ERROR_HEADER, CONNECTION_ERROR_MESSAGE, CONNECTION_ERROR_BUTTON, cancelPlan)
                return
            }
            setPageLoading(false)
        }
        catch (err) {
            console.log('error:', err)
            setPageLoading(false)
            openAlertModal(CONNECTION_ERROR_HEADER, CONNECTION_ERROR_MESSAGE, CONNECTION_ERROR_BUTTON, cancelPlan)
            return
        }
    }

    const [originalDay, setOriginalDay] = useState("")
    const [day, setDay] = useState("")
    const [originalTime, setOriginalTime] = useState("")
    const [time, setTime] = useState("")
    const [message1, setMessage1] = useState('')
    const [message2, setMessage2] = useState('')
    const [days, setDays] = useState([])
    const [times, setTimes] = useState([])
    const [dayAccessory, setDayAccessory] = useState({})
    const [timeAccessory, setTimeAccessory] = useState({})
    const [pageLoading, setPageLoading] = useState(true)
    const [alertModalVisibile, setAlertModalVisible] = useState(false);
    const [alertInfo, setAlertInfo] = useState({})
    const closeAlertModal = () => {
        setAlertModalVisible(false)
    }
    const openAlertModal = (title, subtitle, primaryButtonText, primaryButtonAction, secondaryButtonText = null, secondaryButtonAction = null) => {
        setAlertInfo({
            alertTitleText: title,
            alertSubtitleText: subtitle,
            alertPrimaryButtonText: primaryButtonText,
            alertPrimaryAction: () => primaryButtonAction(),
            alertSecondaryButtonText: secondaryButtonText,
            alertSecondaryAction: () => secondaryButtonAction()
        })
        setAlertModalVisible(true)
    }

    const cancelPlan = () => {
        setPageLoading(false)
        navigation.navigate("Home")
    }

    useEffect(() => {
        getPageAndUserInfo()
        BackHandler.addEventListener('hardwareBackPress', onBackPress);
        return () => {
            BackHandler.removeEventListener('hardwareBackPress', onBackPress);
        }
    }, []);

    const onBackPress = () => {
        return true;
    }

    const selectDay = async (selectedDay, accessoryParam = null) => {
        var accessoryLocal = accessoryParam
        if (!accessoryLocal) {
            accessoryLocal = dayAccessory
        }
        setDay(selectedDay)
        for (var key in accessoryLocal) {
            if (key == selectedDay) {
                accessoryLocal[key] = "Checkmark"
            }
            else {
                accessoryLocal[key] = "none"
            }
        }
        setDayAccessory(accessoryLocal)
    }

    const selectTime = async (selectedTime, accessoryParam = null) => {
        var accessoryLocal = accessoryParam
        if (!accessoryLocal) {
            accessoryLocal = timeAccessory
        }
        setTime(selectedTime)
        for (var key in accessoryLocal) {
            if (key == selectedTime) {
                accessoryLocal[key] = "Checkmark"
            }
            else {
                accessoryLocal[key] = "none"
            }
        }
        setTimeAccessory(accessoryLocal)
    }

    const nextPage = async () => {
        if (!day || !time || day === '' || time === '') {
            openAlertModal('Error', 'Please select a day and time', 'Ok', closeAlertModal)
        }
        setPageLoading(true)
        try {
            if (day !== originalDay || time !== originalTime) {
                const user = firebase.auth().currentUser;
                const updatePickupDayAndTime = firebase.functions().httpsCallable('updatePickupDayAndTime');
                const updateDict = {
                    preferredPickupDay: day,
                    preferredPickupTime: time,
                }
                const response = await updatePickupDayAndTime({
                    userId: user.uid,
                    updateDict: updateDict,
                    updateContext: 'new_plan'
                })
                if (response.data.success) {
                    setPageLoading(false)
                    navigation.navigate('Plan Delivery Address', {
                        plan: plan,
                    })
                    return
                }
                else {
                    if (response.data.message) {
                        setPageLoading(false)
                        openAlertModal('Error', response.data.message, 'Ok', closeAlertModal)
                        return
                    }
                    else {
                        setPageLoading(false)
                        openAlertModal(CONNECTION_ERROR_HEADER, CONNECTION_ERROR_MESSAGE, CONNECTION_ERROR_BUTTON, closeAlertModal)
                        return
                    }

                }
            }
            else {
                setPageLoading(false)
                navigation.navigate('Plan Delivery Address', {
                    plan: plan,
                })
                return
            }
        }
        catch (err) {
            console.log('error updating preferred pickup day and time', err)
            setPageLoading(false)
            openAlertModal(CONNECTION_ERROR_HEADER, CONNECTION_ERROR_MESSAGE, CONNECTION_ERROR_BUTTON, closeAlertModal)
            return
        }
    }

    const createPickupDayTable = () => {
        var valueArray = []
        var index = 0
        days.forEach((day) => {
            valueArray.push(
                <Cell key={index} backgroundColor={CELL_BACKGROUND_COLOR} cellStyle="Basic" titleTextColor={CELL_TITLE_TEXT_COLOR} title={day} accessory={dayAccessory[day]} isDisabled={pageLoading} onPress={() => selectDay(day)} />
            )
            index++
        })
        return valueArray
    }

    const createPickupTimeTable = () => {
        var valueArray = []
        var index = 0
        times.forEach((time) => {
            valueArray.push(
                <Cell key={index} backgroundColor={CELL_BACKGROUND_COLOR} cellStyle="Basic" titleTextColor={CELL_TITLE_TEXT_COLOR} title={time} accessory={timeAccessory[time]} isDisabled={pageLoading} onPress={() => selectTime(time)} />
            )
            index++
        })
        return valueArray
    }

    if (pageLoading) {
        return (
            <View style={styles.parent}>
                <ActivityIndicator />
            </View>
        )
    }

    return (
        <View style={styles.parent}>
            <ScrollView>
                <View style={{ marginLeft: 10, marginRight: 10, marginTop: 10, marginBottom: 10 }}>
                    <Text style={styles.subsubheading}>{message1}</Text>
                </View>
                <Section header="PREFERRED PICKUP DAY" footer="" separatorTintColor={SEPARATOR_TINT_COLOR_TWO} headerTextColor={HEADER_TEXT_COLOR} footerTextColor={FOOTER_TEXT_COLOR}>
                    {createPickupDayTable()}
                </Section>
                <Section header="PREFERRED PICKUP TIME" footer="" separatorTintColor={SEPARATOR_TINT_COLOR_TWO} headerTextColor={HEADER_TEXT_COLOR} footerTextColor={FOOTER_TEXT_COLOR}>
                    {createPickupTimeTable()}
                </Section>
                <View style={{ marginLeft: 10, marginRight: 10, marginTop: 10, marginBottom: 10 }}>
                    <Text style={styles.subsubheading}>{message2}</Text>
                </View>
                <View style={{ height: 50, width: "100%", marginTop: 40, alignItems: 'center', marginBottom: 50 }}>
                    <View style={{ width: "70%", maxWidth: 250, minWidth: 200 }} >
                        <Button
                            onPress={nextPage}
                            title='Continue'
                            buttonStyle={{
                                backgroundColor: BUTTON_COLOR_TWO
                            }}
                        />
                    </View>
                </View>
            </ScrollView>
            <AlertModal
                modalVisible={alertModalVisibile}
                setModalVisible={setAlertModalVisible}
                alertTitleText={alertInfo.alertTitleText}
                alertSubtitleText={alertInfo.alertSubtitleText}
                alertPrimaryButtonText={alertInfo.alertPrimaryButtonText}
                alertPrimaryAction={alertInfo.alertPrimaryAction}
                alertSecondaryButtonText={alertInfo.alertSecondaryButtonText}
                alertSecondaryAction={alertInfo.alertSecondaryAction}
            />
        </View>
    );
}