import { View, ScaledSize, Dimensions, ActivityIndicator, Image, useWindowDimensions, Text } from 'react-native'
import { createStackNavigator } from '@react-navigation/stack'
import { NavigationContainer } from '@react-navigation/native'
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs'
import { createDrawerNavigator } from '@react-navigation/drawer'
import { Appbar } from 'react-native-paper';
import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
import HoverableOpacity from './HoverableOpacity.js'

export default function TopHeading({ navigation, currentPage }) {

    const [showDrawerMenu, setShowDrawerMenu] = useState(false)

    const showDrawer = () => {
        setShowDrawerMenu(!showDrawerMenu)
    }

    const openPage = (pageName) => {
        setShowDrawerMenu(false)
        navigation.navigate(pageName)
    }

    const dimensions = useWindowDimensions()
    if (dimensions.width > 750) {
        return (
            <View style={{ flexDirection: 'row', alignItems: 'center', backgroundColor: "white", position: "absolute", width: "100%" }}>
                <HoverableOpacity
                    style={{
                        width: "25%",
                        height: 50,
                        alignItems: 'center',
                        justifyContent: 'center',
                        textAlign: 'center',
                        backgroundColor: currentPage === "Home" ? '#040405' : '#202124',
                    }}
                    onPress={() => openPage("Home")}
                    onHover={{ backgroundColor: '#040405' }}
                >
                    <Text style={{ fontSize: 20, fontWeight: '500', textAlign: 'center', color: 'white' }} >
                        Home
                    </Text>
                </HoverableOpacity>
                <HoverableOpacity
                    style={{
                        width: "25%",
                        height: 50,
                        alignItems: 'center',
                        justifyContent: 'center',
                        textAlign: 'center',
                        backgroundColor: currentPage === "Account" ? '#040405' : '#202124',
                    }}
                    onPress={() => openPage("Account")}
                    onHover={{ backgroundColor: '#040405' }}
                >
                    <Text style={{ fontSize: 20, fontWeight: '500', textAlign: 'center', color: 'white' }} >
                        Account
                    </Text>
                </HoverableOpacity>

                <HoverableOpacity
                    style={{
                        width: "25%",
                        height: 50,
                        alignItems: 'center',
                        justifyContent: 'center',
                        textAlign: 'center',
                        backgroundColor: currentPage === "Pricing" ? '#040405' : '#202124',
                    }}
                    onPress={() => openPage("Pricing")}
                    onHover={{ backgroundColor: '#040405' }}
                >
                    <Text style={{ fontSize: 20, fontWeight: '500', textAlign: 'center', color: 'white' }} >
                        Pricing
                    </Text>
                </HoverableOpacity>
                <HoverableOpacity
                    style={{
                        width: "25%",
                        height: 50,
                        alignItems: 'center',
                        justifyContent: 'center',
                        textAlign: 'center',
                        backgroundColor: currentPage === "Contact" ? '#040405' : '#202124',
                    }}
                    onPress={() => openPage("Contact")}
                    onHover={{ backgroundColor: '#040405' }}
                >
                    <Text style={{ fontSize: 20, fontWeight: '500', textAlign: 'center', color: 'white' }} >
                        Contact
                    </Text>
                </HoverableOpacity>
            </View>
        )
    }
    else {
        return (
            <View style={{ position: "absolute", width: "100%", height: "100%" }}>
                <View style={{ flexDirection: 'row', alignItems: 'center', width: "100%", backgroundColor: "transparent" }}>
                    <HoverableOpacity
                        style={{
                            height: 50,
                            alignItems: 'center',
                            justifyContent: 'center',
                            backgroundColor: '#202124',
                        }}
                        onPress={() => openPage("Home")}
                        onHover={{ backgroundColor: '#040405' }}
                    >
                        <Text style={{ fontSize: 20, fontWeight: '500', textAlign: 'center', marginRight: 20, marginLeft: 20, color: 'white' }} >
                            Home
                        </Text>
                    </HoverableOpacity>
                    <View style={{ height: 50, flex: 1, backgroundColor: '#202124' }} />
                    <HoverableOpacity
                        style={{
                            height: 50,
                            alignItems: 'left',
                            justifyContent: 'left',
                            textAlign: 'left',
                            backgroundColor: '#202124',
                        }}
                        onPress={showDrawer}
                        onHover={{ backgroundColor: '#040405' }}
                    >
                        <Appbar.Action
                            color="white"
                            icon="menu"
                            onPress={showDrawer}
                        />
                    </HoverableOpacity>
                </View>
                {
                    showDrawerMenu ? (
                        <>
                            <View style={{ flexDirection: 'column', flex: 1, alignItems: 'center', width: "100%", backgroundColor: "transparent", position: 'relative' }}>
                                <View style={{ backgroundColor: '#202124', width: "100%" }}>
                                    <HoverableOpacity
                                        style={{
                                            height: 50,
                                            width: "100%",
                                            alignItems: 'left',
                                            justifyContent: 'center',
                                            textAlign: 'left',
                                            backgroundColor: '#202124',
                                        }}
                                        onPress={() => openPage('Account')}
                                        onHover={{ backgroundColor: '#040405' }}
                                    >
                                        <Text style={{ fontSize: 20, fontWeight: '500', textAlign: 'left', marginRight: 20, marginLeft: 20, color: 'white' }} >
                                            Account
                                        </Text>
                                    </HoverableOpacity>
                                    <HoverableOpacity
                                        style={{
                                            height: 50,
                                            width: "100%",
                                            alignItems: 'left',
                                            justifyContent: 'center',
                                            textAlign: 'left',
                                            backgroundColor: '#202124',
                                        }}
                                        onPress={() => openPage('Pricing')}
                                        onHover={{ backgroundColor: '#040405' }}
                                    >
                                        <Text style={{ fontSize: 20, fontWeight: '500', textAlign: 'left', marginRight: 20, marginLeft: 20, color: 'white' }} >
                                            Pricing
                                        </Text>
                                    </HoverableOpacity>
                                    <HoverableOpacity
                                        style={{
                                            height: 50,
                                            width: "100%",
                                            alignItems: 'left',
                                            justifyContent: 'center',
                                            textAlign: 'left',
                                            backgroundColor: '#202124',
                                        }}
                                        onPress={() => openPage('Contact')}
                                        onHover={{ backgroundColor: '#040405' }}
                                    >
                                        <Text style={{ fontSize: 20, fontWeight: '500', textAlign: 'left', marginRight: 20, marginLeft: 20, color: 'white' }} >
                                            Contact
                                        </Text>
                                    </HoverableOpacity>
                                </View>
                                <View style={{ color: "rgba(100,100,100,0.2", height: dimensions.height, width: dimensions.width }} >
                                    <HoverableOpacity
                                        style={{
                                            height: dimensions.height,
                                            width: dimensions.width,
                                            flex: 1
                                        }}
                                        onPress={showDrawer}
                                    >
                                    </HoverableOpacity>
                                </View>
                            </View>

                        </>

                    ) : (
                        <>
                        </>
                    )
                }
            </View>
        )
    }
}