import React, { useEffect, useState, useRef, useLayoutEffect } from 'react';
import {
    View,
    ScrollView,
    Dimensions,
    TextInput,
    Text,
    Alert,
    ActivityIndicator
} from 'react-native';
import styles from './styles';
import KeyboardSpacer from '../KeyboardSpacer';
import { Button } from 'react-native-elements';
import { Cell, Section } from 'react-native-tableview-simple';
import firebase from "firebase/app"
import "firebase/auth"
import "firebase/firestore"
import "firebase/functions"
import Modal from 'react-native-modal'
import { BACKGROUND_COLOR, PLACEHOLDER_TEXT_INPUT_COLOR, TEXT_INPUT_COLOR } from '../../../constants';

const window = Dimensions.get("window");
const screen = Dimensions.get("screen");

export default function ChangeEmail({ navigation }) {

    useEffect(() => {
        getUserInfo();
    }, []);

    const refs = {
        "userName": useRef(),
        "loginEmail": useRef(),
        "password": useRef(),
        "email": useRef()
    }

    const dimensions = { window, screen }
    const [loginLoading, setLoginLoading] = useState(false)
    const [pageLoading, setPageLoading] = useState(true)
    const [changeEmailRequestLoading, setChangeEmailRequestLoading] = useState(false)

    const [modalVisible, setModalVisible] = useState(true)
    const [loginEmail, setLoginEmail] = useState()
    const [password, setPassword] = useState()
    const [email, setEmail] = useState()
    const [reauthenticated, setReauthenticated] = useState(false)


    useLayoutEffect(() => {
        navigation.setOptions({
            headerRight: () => (
                <Button
                    onPress={() => navigation.goBack()}
                    title="Cancel"
                    type="clear"
                    titleStyle={{
                        fontWeight: "normal"
                    }}
                    buttonStyle={{
                        marginRight: 10
                    }}
                    disabled={changeEmailRequestLoading}
                />
            ),
            headerLeft: null
        })
    })

    const getUserInfo = async () => {
        const user = firebase.auth().currentUser;
        // console.log(user);
        console.log(user)
        setLoginEmail(user.email)
        setEmail(user.email)
        setPageLoading(false)
    }

    const changeEmailAndLogout = async () => {
        var user = firebase.auth().currentUser
        user.updateEmail(email).then(() => {
            // update contact info for user as well
            firebase.firestore().collection("userProfiles").doc(user.uid).update({ contactEmail: email })
                .then(async () => {
                    setChangeEmailRequestLoading(false)
                    try {
                        logOut();
                        console.log("Logged out");
                        await firebase.auth().signOut();
                    } catch (error) {
                        console.log(error);
                    }
                    // navigation.navigate("Login Info")
                }).catch((error) => {
                    setChangeEmailRequestLoading(false)
                    console.log(error)
                    Alert.alert("error")
                })
        }).catch((error) => {
            setChangeEmailRequestLoading(false)
            console.log(error)
            Alert.alert("error")
        })
    }

    const updateEmail = () => {
        setChangeEmailRequestLoading(true)
        Alert.alert(
            'Change Email',
            'Are you sure you want to change your email address?  You will be logged out and you won\'t be able to sign in again until you validate your new email address.',
            [
                { text: 'Cancel', onPress: () => { return null } },
                {
                    text: 'Yes', onPress: async () => {
                        changeEmailAndLogout()
                    },
                }],
            { cancelable: false }
        )
    }

    const onSubmitEmail = () => {

    }

    const reauthenticate = () => {
        setLoginLoading(true)
        var user = firebase.auth().currentUser
        var cred = auth.EmailAuthProvider.credential(loginEmail, password)
        user.reauthenticateWithCredential(cred).then(() => {
            setModalVisible(false)
            setReauthenticated(true)
            setLoginLoading(false)
        }).catch((error) => {
            console.log(error)
            Alert.alert("error")
            setLoginLoading(false)
        })
    }

    const onSubmitLoginEmail = () => {

    }

    const onSubmitPassword = () => {

    }

    const cancelEmailChange = () => {
        setModalVisible(false)
        navigation.goBack()
    }

    if (pageLoading) {
        return (
            <View style={styles.parent}>
                <ActivityIndicator />
            </View>
        )
    }

    return (
        <View style={styles.parent}>
            <ScrollView keyboardShouldPersistTaps='handled'>
                <Section header="EMAIL">
                    <Cell cellContentView={
                        <TextInput
                            style={{ fontSize: 16, flex: 1, color: TEXT_INPUT_COLOR }}
                            placeholder="Email"
                            ref={refs["email"]}
                            autoCorrect={false}
                            enablesReturnKeyAutomatically={true}
                            onChangeText={(value) => setEmail(value)}
                            value={email}
                            onSubmitEditing={onSubmitEmail}
                            autoCapitalize="none"
                            returnKeyType="done"
                            returnKeyType="done"
                            placeholderTextColor={DISABLED_TEXT_INPUT_COLOR}
                        />
                    } />
                </Section>
                <Button
                    onPress={updateEmail}
                    title="Update Email"
                    type="clear"
                    titleStyle={{
                        fontWeight: "normal"
                    }}
                    buttonStyle={{

                    }}
                    disabled={!reauthenticated}
                    loading={changeEmailRequestLoading}
                />
            </ScrollView>
            <KeyboardSpacer />
            <Modal isVisible={modalVisible} propagateSwipe={true} style={{ justifyContent: 'flex-end', margin: 0 }} >
                <View style={{ height: dimensions.window.height * 0.9, backgroundColor: BACKGROUND_COLOR }}>
                    <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                        <View
                            style={{
                                width: dimensions.window.width * 0.25,
                                height: 50,
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}>
                        </View>
                        <View
                            style={{
                                width: dimensions.window.width * 0.5,
                                height: 50,
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}>
                            <Text style={{ fontSize: 20, fontWeight: '500' }} >
                                Sign In
                            </Text>
                        </View>
                        <View
                            style={{
                                width: dimensions.window.width * 0.25,
                                height: 50,
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}>
                            <Button
                                onPress={cancelEmailChange}
                                title="Cancel"
                                type="clear"
                                titleStyle={{
                                    fontWeight: "normal"
                                }}
                                buttonStyle={{

                                }}
                                disabled={loginLoading}
                            />
                        </View>
                    </View>
                    <ScrollView>
                        <View>
                            <Text style={styles.subheading}>Please sign in again to change your email.</Text>
                            <Section header="EMAIL">
                                <Cell cellContentView={
                                    <TextInput
                                        style={{ fontSize: 16, flex: 1, color: TEXT_INPUT_COLOR }}
                                        placeholder="Email"
                                        ref={refs["loginEmail"]}
                                        autoCorrect={false}
                                        enablesReturnKeyAutomatically={true}
                                        onChangeText={(value) => setLoginEmail(value)}
                                        value={loginEmail}
                                        autoCapitalize="none"
                                        onSubmitEditing={onSubmitLoginEmail}
                                        returnKeyType="done"
                                        returnKeyType="done"
                                        placeholderTextColor={PLACEHOLDER_TEXT_INPUT_COLOR}
                                    />
                                } />
                            </Section>
                            <Section header="PASSWORD">
                                <Cell cellContentView={
                                    <TextInput
                                        style={{ fontSize: 16, flex: 1, color: TEXT_INPUT_COLOR }}
                                        placeholder="Password"
                                        ref={refs["password"]}
                                        autoCorrect={false}
                                        enablesReturnKeyAutomatically={true}
                                        onChangeText={(value) => setPassword(value)}
                                        value={password}
                                        autoCapitalize="none"
                                        onSubmitEditing={onSubmitPassword}
                                        returnKeyType="done"
                                        returnKeyType="done"
                                        placeholderTextColor={PLACEHOLDER_TEXT_INPUT_COLOR}
                                        secureTextEntry={true}
                                    />
                                } />
                            </Section>
                            <Button type="clear" title="Sign In" onPress={reauthenticate} loading={loginLoading} />
                        </View>
                    </ScrollView>
                    <KeyboardSpacer />
                </View>
            </Modal>
        </View>
    );
}