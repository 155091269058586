import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
import {
    ActivityIndicator,
    View,
    ScrollView,
    Dimensions,
    TextInput,
    Text
} from 'react-native';
import { Button } from 'react-native-elements';
import styles from './styles';
import { Cell, Section } from 'react-native-tableview-simple';
import firebase from "firebase/app"
import "firebase/auth"
import "firebase/firestore"
import "firebase/functions"
import { CELL_BACKGROUND_COLOR, CELL_TITLE_TEXT_COLOR, DISABLED_TEXT_INPUT_COLOR, HEADER_TEXT_COLOR, PLACEHOLDER_TEXT_INPUT_COLOR, TEXT_INPUT_COLOR, BUTTON_COLOR_TWO, CONNECTION_ERROR_HEADER, CONNECTION_ERROR_MESSAGE, CONNECTION_ERROR_BUTTON, ONE_TIME_PICKUP, WEEKLY_PLAN, BI_WEEKLY_PLAN } from '../../../constants';
import AlertModal from '../AlertModal'
import { HeaderBackButton } from '@react-navigation/stack';


export default function ContactInfo({ route, navigation }) {

    useLayoutEffect(() => {
        navigation.setOptions({
            headerRight: () => (
                <Button
                    onPress={cancelPickup}
                    title="Cancel"
                    type="clear"
                    titleStyle={{
                        fontWeight: "normal",
                        color: HEADER_TEXT_COLOR
                    }}
                    buttonStyle={{
                        marginRight: 10,
                    }}
                    disabled={pageLoading}
                />
            ),
            headerLeft: () => {}
        })
    })

    const selectScheduleOption = (scheduleOption) => {
        setSelectedScheduleOption(scheduleOption)
        const updateDict = {}
        for (var key in scheduleAccessory) {
            if (key == scheduleOption) {
                updateDict[key] = "Checkmark"
            }
            else {
                updateDict[key] = "none"
            }
        }
        setScheduleAccessory(updateDict)
    }

    const [scheduleAccessory, setScheduleAccessory] = useState({
        [ONE_TIME_PICKUP['name']]: "Checkmark", [WEEKLY_PLAN['name']]: "none", [BI_WEEKLY_PLAN['name']]: "none"
    })
    const [selectedScheduleOption, setSelectedScheduleOption] = useState(ONE_TIME_PICKUP['name'])
    const [pageLoading, setPageLoading] = useState(true)
    const [alertModalVisibile, setAlertModalVisible] = useState(false);
    const [alertInfo, setAlertInfo] = useState({})
    const closeAlertModal = () => {
        setAlertModalVisible(false)
    }
    const openAlertModal = (title, subtitle, primaryButtonText, primaryButtonAction, secondaryButtonText = null, secondaryButtonAction = null) => {
        setAlertInfo({
            alertTitleText: title,
            alertSubtitleText: subtitle,
            alertPrimaryButtonText: primaryButtonText,
            alertPrimaryAction: () => primaryButtonAction(),
            alertSecondaryButtonText: secondaryButtonText,
            alertSecondaryAction: () => secondaryButtonAction()
        })
        setAlertModalVisible(true)
    }

    const nextPage = () => {
        if (selectedScheduleOption === ONE_TIME_PICKUP['name']) {
            navigation.navigate('Wash and Fold Pickup Date Time')
        }
        else {
            navigation.navigate('Plan Day Time', {
                plan: selectedScheduleOption
            })
        }
    }

    const cancelPickup = () => {
        setPageLoading(false)
        navigation.goBack()
    }

    useEffect(() => {
        setPageLoading(false)
    }, []);

    if (pageLoading) {
        return (
            <View style={styles.parent}>
                <ActivityIndicator />
            </View>
        )
    }

    return (
        <View style={styles.parent}>
            <ScrollView>
                <View style={{ marginLeft: 10, marginRight: 10, marginTop: 10, marginBottom: 10 }}>
                    <Text style={styles.subsubheading}>Please select the scheduling option you would like:</Text>
                </View>
                <Section headerTextColor={HEADER_TEXT_COLOR} header="Schedule Options" footer="">
                    <Cell cellStyle="Basic" backgroundColor={CELL_BACKGROUND_COLOR} titleTextColor={CELL_TITLE_TEXT_COLOR} title={ONE_TIME_PICKUP['displayName']} accessory={scheduleAccessory[ONE_TIME_PICKUP['name']]} onPress={() => selectScheduleOption(ONE_TIME_PICKUP['name'])} />
                    <Cell cellStyle="Basic" backgroundColor={CELL_BACKGROUND_COLOR} titleTextColor={CELL_TITLE_TEXT_COLOR} title={WEEKLY_PLAN['displayName']} accessory={scheduleAccessory[WEEKLY_PLAN['name']]} onPress={() => selectScheduleOption(WEEKLY_PLAN['name'])} />
                    <Cell cellStyle="Basic" backgroundColor={CELL_BACKGROUND_COLOR} titleTextColor={CELL_TITLE_TEXT_COLOR} title={BI_WEEKLY_PLAN['displayName']} accessory={scheduleAccessory[BI_WEEKLY_PLAN['name']]} onPress={() => selectScheduleOption(BI_WEEKLY_PLAN['name'])} />
                </Section>
                <View style={{ marginLeft: 10, marginRight: 10, marginTop: 10, marginBottom: 10 }}>
                    <Text style={styles.subsubheading}></Text>
                </View>
                <View style={{ height: 50, width: "100%", marginTop: 40, alignItems: 'center', marginBottom: 50 }}>
                    <View style={{ width: "70%", maxWidth: 250, minWidth: 200 }} >
                        <Button
                            onPress={ () => nextPage() }
                            title='Continue'
                            buttonStyle={{
                                backgroundColor: BUTTON_COLOR_TWO
                            }}
                        />
                    </View>
                </View>
            </ScrollView>
            <AlertModal
                modalVisible={alertModalVisibile}
                setModalVisible={setAlertModalVisible}
                alertTitleText={alertInfo.alertTitleText}
                alertSubtitleText={alertInfo.alertSubtitleText}
                alertPrimaryButtonText={alertInfo.alertPrimaryButtonText}
                alertPrimaryAction={alertInfo.alertPrimaryAction}
                alertSecondaryButtonText={alertInfo.alertSecondaryButtonText}
                alertSecondaryAction={alertInfo.alertSecondaryAction}
            />
        </View>
    );
}