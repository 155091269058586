import React, { useState, useEffect, useRef, useLayoutEffect } from 'react'
import { View, ActivityIndicator, ScrollView, BackHandler, Dimensions, TextInput, Alert } from 'react-native'
import firebase from "firebase/app"
import "firebase/auth"
import "firebase/firestore"
import "firebase/functions"
import { Cell, Section, TableView } from 'react-native-tableview-simple';
import { HeaderBackButton } from '@react-navigation/stack'
import styles from '../styles';
// import Modal from 'react-native-modal'
import { Button } from 'react-native-elements';
import { BACKGROUND_COLOR, BUTTON_COLOR_ONE, BUTTON_COLOR_TWO, CELL_BACKGROUND_COLOR, CELL_TITLE_TEXT_COLOR, HEADER_TEXT_COLOR, PLACEHOLDER_TEXT_INPUT_COLOR, SEPARATOR_TINT_COLOR_TWO, TEXT_COLOR_FOUR, TEXT_INPUT_COLOR } from '../../../../constants';
import AddressPicker from '../../AddressPicker';
import AlertModal from '../../AlertModal'


export default function DeliveryAddress({ route, navigation }) {

    useLayoutEffect(() => {
        navigation.setOptions({
            headerRight: null,
            headerLeft: () => {
                return <HeaderBackButton
                    label="Back"
                    onPress={() => navigation.goBack()}
                    disabled={pageLoading}
                />
            },
        });
    });

    const logout = async () => {
        try {
            await firebase.auth().signOut();
            setPageLoading(false)
        }
        catch (err) {
            openAlertModal('Error Logging Out', 'Please try again', 'Try Again', logout)
        }
    }

    const nextPage = async () => {
        setPageLoading(true)
        // No validation necessary.  Address is not required at this point.
        try {
            const user = firebase.auth().currentUser;
            await firebase.firestore().collection('userProfiles').doc(user.uid).update({
                userAddress: userAddress,
                addressMainText: addressMainText,
                addressSecondaryText: addressSecondaryText,
                aptNum: aptNum,
                additionalInstructions: additionalInstructions
            })
            navigation.navigate("Account Creation Laundry Preferences")
            setPageLoading(false)
        }
        catch (err) {
            console.log('error updating profile in firebase:', err)
            setPageLoading(false)
            openAlertModal('Error Updating Profile', 'We are unable to update your profile at this time.  Please try again.  If this issue persists, please log out and contact us.', 'Try Again', nextPage, 'Log Out', logout)
        }
    }

    const [addressTextColor, setAddressTextColor] = useState(PLACEHOLDER_TEXT_INPUT_COLOR)
    const [pageLoading, setPageLoading] = useState(true);
    const [userAddress, setUserAddress] = useState('');
    const [addressDialogVisible, setAddressDialogVisible] = useState(false);
    const [addressMainText, setAddressMainText] = useState('Street Address');
    const [addressSecondaryText, setAddressSecondaryText] = useState('City & State',);
    const [aptNum, setAptNum] = useState('');
    const [additionalInstructions, setAdditionalInstructions] = useState('');
    const [alertModalVisibile, setAlertModalVisible] = useState(false);
    const [alertInfo, setAlertInfo] = useState({})
    const openAlertModal = (title, subtitle, primaryButtonText, primaryButtonAction, secondaryButtonText = null, secondaryButtonAction = null) => {
        setAlertInfo({
            alertTitleText: title,
            alertSubtitleText: subtitle,
            alertPrimaryButtonText: primaryButtonText,
            alertPrimaryAction: () => primaryButtonAction(),
            alertSecondaryButtonText: secondaryButtonText,
            alertSecondaryAction: () => secondaryButtonAction()
        })
        setAlertModalVisible(true)
    }
    const closeAlertModal = () => {
        setAlertModalVisible(false)
    }

    const openAddressDialog = () => {
        setAddressDialogVisible(true);
    };

    const refs = {
        'aptNum': useRef(),
        'additionalInstructions': useRef(),
    };

    const getUserInfo = async () => {
        setPageLoading(true)
        const user = firebase.auth().currentUser
        try {
            const userInfo = (await firebase.firestore().collection("userProfiles").doc(user.uid).get()).data();
            if (userInfo) {
                if (userInfo.userAddress && userInfo.addressMainText && addressSecondaryText) {
                    setAddressTextColor(CELL_TITLE_TEXT_COLOR)
                    setUserAddress(userInfo.userAddress)
                    setAddressMainText(userInfo.addressMainText)
                    setAddressSecondaryText(userInfo.addressSecondaryText)
                }
            }
            else {
                console.log('Error: no account for this user')
                setPageLoading(false)
                openAlertModal('Error', 'We do not have a profile created for you.  Please logout and login again.', 'Log Out', logout)
                return
            }
            setPageLoading(false);
        }
        catch (err) {
            console.log('error getting page or data:', err)
            setPageLoading(false)
            openAlertModal('Error Creating Profile', 'We are unable to connect to our services.  Please try again.  If this issue persists, please log out and contact us.', 'Try Again', getUserInfo, 'Log Out', logout)
            return
        }
    }

    useEffect(() => {
        getUserInfo()
        BackHandler.addEventListener('hardwareBackPress', onBackPress);
        return () => {
            BackHandler.removeEventListener('hardwareBackPress', onBackPress);
        };
    }, []);

    const onBackPress = () => {
        return true;
    };

    const onSubmitAptNum = () => {
        refs['additionalInstructions'].current.focus()
    };

    const onSubmitAdditionalInstructions = () => {
        nextPage()
    };

    if (pageLoading) {
        return (
            <View style={styles.parent}>
                <ActivityIndicator />
            </View>
        );
    }

    return (
        <View style={styles.parent}>
            <ScrollView>
                <Section header="ADDRESS" footer="" headerTextColor={HEADER_TEXT_COLOR} separatorTintColor={SEPARATOR_TINT_COLOR_TWO}>
                    <Cell backgroundColor={CELL_BACKGROUND_COLOR} cellStyle="Basic" titleTextColor={addressTextColor} title={`${addressMainText}`} onPress={openAddressDialog} />
                    <Cell backgroundColor={CELL_BACKGROUND_COLOR} cellStyle="Basic" titleTextColor={addressTextColor} title={`${addressSecondaryText}`} onPress={openAddressDialog} />
                    <Cell backgroundColor={CELL_BACKGROUND_COLOR} cellContentView={
                        <TextInput
                            style={{ fontSize: 16, flex: 1, color: TEXT_INPUT_COLOR }}
                            placeholder="Apt/Suite # (optional)"
                            ref={refs["aptNum"]}
                            autoCorrect={false}
                            enablesReturnKeyAutomatically={true}
                            onChangeText={(value) => setAptNum(value)}
                            value={aptNum}
                            onSubmitEditing={onSubmitAptNum}
                            returnKeyType="next"
                            placeholderTextColor={PLACEHOLDER_TEXT_INPUT_COLOR}
                        />
                    } />
                    <Cell backgroundColor={CELL_BACKGROUND_COLOR} cellContentView={
                        <TextInput
                            style={{ fontSize: 16, flex: 1, color: TEXT_INPUT_COLOR }}
                            placeholder="Additional Instructions (optional)"
                            ref={refs["additionalInstructions"]}
                            autoCorrect={false}
                            enablesReturnKeyAutomatically={true}
                            onChangeText={(value) => setAdditionalInstructions(value)}
                            value={additionalInstructions}
                            onSubmitEditing={onSubmitAdditionalInstructions}
                            returnKeyType="go"
                            placeholderTextColor={PLACEHOLDER_TEXT_INPUT_COLOR}
                        />
                    } />
                </Section>
                <View style={{ height: 50, width: "100%", marginTop: 40, alignItems: 'center', marginBottom: 50 }}>
                    <View style={{ width: "70%", maxWidth: 250, minWidth: 200 }} >
                        <Button
                            onPress={nextPage}
                            title='Continue'
                            buttonStyle={{
                                backgroundColor: BUTTON_COLOR_TWO
                            }}
                        />
                    </View>
                </View>
            </ScrollView>
            <AddressPicker
                modalVisible={addressDialogVisible}
                setModalVisible={setAddressDialogVisible}
                setUserAddress={setUserAddress}
                setAddressMainText={setAddressMainText}
                setAddressSecondaryText={setAddressSecondaryText}
                setAddressTextColor={setAddressTextColor}
            />
            <AlertModal
                modalVisible={alertModalVisibile}
                setModalVisible={setAlertModalVisible}
                alertTitleText={alertInfo.alertTitleText}
                alertSubtitleText={alertInfo.alertSubtitleText}
                alertPrimaryButtonText={alertInfo.alertPrimaryButtonText}
                alertPrimaryAction={alertInfo.alertPrimaryAction}
                alertSecondaryButtonText={alertInfo.alertSecondaryButtonText}
                alertSecondaryAction={alertInfo.alertSecondaryAction}
            />
        </View>
    );
}