import React, { useEffect, useLayoutEffect, useState } from 'react';
import {
    View,
    SafeAreaView,
    ScrollView,
    Dimensions,
    ActivityIndicator
} from 'react-native';
import styles from './styles';
import KeyboardSpacer from '../KeyboardSpacer';
import { Cell, Section } from 'react-native-tableview-simple';
import firebase from "firebase/app"
import "firebase/auth"
import "firebase/firestore"
import "firebase/functions"
import { BACKGROUND_COLOR, CELL_DETAIL_TEXT_COLOR, CELL_TITLE_TEXT_COLOR, CONNECTION_ERROR_BUTTON, CONNECTION_ERROR_HEADER, CONNECTION_ERROR_MESSAGE, FOOTER_TEXT_COLOR, HEADER_TEXT_COLOR } from '../../../constants';
import { HeaderBackButton } from '@react-navigation/stack';
import AlertModal from '../AlertModal';

export default function CleaningPreferences({ navigation }) {

    useLayoutEffect(() => {
        navigation.setOptions({
            headerRight: null,
            headerLeft: () => {
                return <HeaderBackButton
                    label="Back"
                    tintColor={HEADER_TEXT_COLOR}
                    disabled={pageLoading}
                    onPress={() => navigation.goBack()}
                />

            },
        })
    })

    const [quietDeliveryAccessory, setQuietDeliveryAccessory] = useState({
        "1": "Checkmark", "2": "none"
    })
    const [pickupDay, setPickupDay] = useState("")
    const [pickupTime, setPickupTime] = useState("")
    const [pageLoading, setPageLoading] = useState(true)
    const [alertModalVisibile, setAlertModalVisible] = useState(false);
    const [alertInfo, setAlertInfo] = useState({})
    const closeAlertModal = () => {
        setAlertModalVisible(false)
    }
    const openAlertModal = (title, subtitle, primaryButtonText, primaryButtonAction, secondaryButtonText = null, secondaryButtonAction = null) => {
        setAlertInfo({
            alertTitleText: title,
            alertSubtitleText: subtitle,
            alertPrimaryButtonText: primaryButtonText,
            alertPrimaryAction: () => primaryButtonAction(),
            alertSecondaryButtonText: secondaryButtonText,
            alertSecondaryAction: () => secondaryButtonAction()
        })
        setAlertModalVisible(true)
    }

    const getUserInfo = async () => {
        const user = firebase.auth().currentUser;
        const userInfo = (await firebase.firestore().collection("userProfiles").doc(user.uid).get()).data();
        if (userInfo) {
            let quietDelivery = userInfo.quietDelivery;
            if (quietDelivery == "Yes") {
                selectCell("1", false)
            }
            else if (quietDelivery == "No") {
                selectCell("2", false)
            }
        }
        setPageLoading(false)
    }

    useEffect(() => {
        const user = firebase.auth().currentUser;
        const subscriber = firebase.firestore().collection('userProfiles').doc(user.uid).onSnapshot(documentSnapshot => {
            let data = documentSnapshot.data()
            setPickupDay(data.preferredPickupDay)
            setPickupTime(data.preferredPickupTime)
        });
        getUserInfo();
        return () => {
            subscriber();
        }
    }, []);

    const selectCell = async (selectedCell, changeDatabase) => {
        if (changeDatabase) {
            setPageLoading(true)
            const updateDatabaseDict = {}
            if (selectedCell == "1") {
                updateDatabaseDict.quietDelivery = "Yes"
            }
            else if (selectedCell == "2") {
                updateDatabaseDict.quietDelivery = "No"
            }
            try {
                const updateUserQuietDelivery = firebase.functions().httpsCallable('updateUserQuietDelivery');
                const response = await updateUserQuietDelivery({
                    updateDict: updateDatabaseDict,
                    updateContext: 'account_page'
                })
                if (response.data) {
                    if (response.data.success) {
                        await getUserInfo()
                        setPageLoading(false)
                        return
                    }
                    else {
                        console.log('error updating address:', response.data.message)
                        openAlertModal('Error', 'Error updating your account info.  Please try again.  If this error persists, please contact us.', 'Ok', closeAlertModal)
                        setPageLoading(false)
                        return
                    }
                }
                else {
                    openAlertModal('Error', 'Error updating your account info.  Please try again.  If this error persists, please contact us.', 'Ok', closeAlertModal)
                    setPageLoading(false)
                    return
                }
            }
            catch (err) {
                console.log('error:', err)
                setPageLoading(false)
                openAlertModal(CONNECTION_ERROR_HEADER, CONNECTION_ERROR_MESSAGE, CONNECTION_ERROR_BUTTON, closeAlertModal)
                return
            }
        }
        let updateDict = {}
        updateDict["1"] = selectedCell == "1" ? "Checkmark" : "none"
        updateDict["2"] = selectedCell == "2" ? "Checkmark" : "none"
        setQuietDeliveryAccessory(updateDict)
    }

    if (pageLoading) {
        return (
            <View style={styles.parent}>
                <ActivityIndicator />
            </View>
        )
    }

    return (
        <View style={styles.parent}>
            <ScrollView>
                <Section headerTextColor={HEADER_TEXT_COLOR} header="PREFERRED PICKUP INFO" footer="">
                    <Cell cellStyle="RightDetail" detailTextStyle={{ color: CELL_DETAIL_TEXT_COLOR }} backgroundColor={BACKGROUND_COLOR} titleTextColor={CELL_TITLE_TEXT_COLOR} title="Preferred Pickup Day" detail={pickupDay} accessory="DisclosureIndicator" onPress={() => navigation.navigate("Pickup Day")} />
                    <Cell cellStyle="RightDetail" detailTextStyle={{ color: CELL_DETAIL_TEXT_COLOR }} backgroundColor={BACKGROUND_COLOR} titleTextColor={CELL_TITLE_TEXT_COLOR} title="Preferred Pickup Time" detail={pickupTime} accessory="DisclosureIndicator" onPress={() => navigation.navigate("Pickup Time")} />
                </Section>
                <Section headerTextColor={HEADER_TEXT_COLOR} footerTextColor={FOOTER_TEXT_COLOR} header="QUIET DELIVERY" footer="If you will be sleeping at the time of your delivery, or if you have any pets that may be startled by our associates ringing the doorbell, we suggest selecting yes for this option.">
                    <Cell cellStyle="Basic" backgroundColor={BACKGROUND_COLOR} titleTextColor={CELL_TITLE_TEXT_COLOR} title="Yes" accessory={quietDeliveryAccessory["1"]} onPress={() => selectCell("1", true)} />
                    <Cell cellStyle="Basic" backgroundColor={BACKGROUND_COLOR} titleTextColor={CELL_TITLE_TEXT_COLOR} title="No" accessory={quietDeliveryAccessory["2"]} onPress={() => selectCell("2", true)} />
                </Section>
            </ScrollView>
            <AlertModal
                modalVisible={alertModalVisibile}
                setModalVisible={setAlertModalVisible}
                alertTitleText={alertInfo.alertTitleText}
                alertSubtitleText={alertInfo.alertSubtitleText}
                alertPrimaryButtonText={alertInfo.alertPrimaryButtonText}
                alertPrimaryAction={alertInfo.alertPrimaryAction}
                alertSecondaryButtonText={alertInfo.alertSecondaryButtonText}
                alertSecondaryAction={alertInfo.alertSecondaryAction}
            />
        </View>
    );
}