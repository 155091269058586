import React, { useEffect, useLayoutEffect, useState } from 'react';
import {
    View,
    SafeAreaView,
    ScrollView,
    Dimensions,
    ActivityIndicator,
    Text,
    BackHandler
} from 'react-native';
import styles from './styles';
import KeyboardSpacer from '../KeyboardSpacer';
import { Cell, Section } from 'react-native-tableview-simple';
import firebase from "firebase/app"
import "firebase/auth"
import "firebase/firestore"
import "firebase/functions"
import { BACKGROUND_COLOR, CELL_BACKGROUND_COLOR, CELL_TITLE_TEXT_COLOR, CONNECTION_ERROR_BUTTON, CONNECTION_ERROR_HEADER, CONNECTION_ERROR_MESSAGE, HEADER_TEXT_COLOR, FOOTER_TEXT_COLOR, SEPARATOR_TINT_COLOR_TWO } from '../../../constants';
import { HeaderBackButton } from '@react-navigation/stack';
import AlertModal from '../AlertModal';

export default function CleaningPreferences({ navigation }) {

    useLayoutEffect(() => {
        navigation.setOptions({
            headerRight: null,
            headerLeft: () => {
                return <HeaderBackButton
                    label="Back"
                    tintColor={HEADER_TEXT_COLOR}
                    disabled={pageLoading}
                    onPress={() => navigation.goBack()}
                />

            },
        })
    })

    const [pageLoading, setPageLoading] = useState(true)
    const [detergents, setDetergents] = useState([])
    const [dryerSheets, setDryerSheets] = useState([])
    const [fabricSofteners, setFabricSofteners] = useState([])
    const [detergent, setDetergent] = useState("Fresh Linen Scent")
    const [fabricSoftener, setFabricSoftener] = useState("Fresh Linen Scent")
    const [dryerSheet, setDryerSheet] = useState("Fresh Linen Scent")
    const [detergentAccessory, setDetergentAccessory] = useState({})
    const [fabricSoftenerAccessory, setFabricSoftenerAccessory] = useState({})
    const [dryerSheetAccessory, setDryerSheetAccessory] = useState({})
    const [alertModalVisibile, setAlertModalVisible] = useState(false);
    const [alertInfo, setAlertInfo] = useState({})
    const closeAlertModal = () => {
        setAlertModalVisible(false)
    }
    const openAlertModal = (title, subtitle, primaryButtonText, primaryButtonAction, secondaryButtonText = null, secondaryButtonAction = null) => {
        setAlertInfo({
            alertTitleText: title,
            alertSubtitleText: subtitle,
            alertPrimaryButtonText: primaryButtonText,
            alertPrimaryAction: () => primaryButtonAction(),
            alertSecondaryButtonText: secondaryButtonText,
            alertSecondaryAction: () => secondaryButtonAction()
        })
        setAlertModalVisible(true)
    }

    const onBackPress = () => {
        return true;
    }

    const selectDetergent = async (scent, accessoryParam = null, changeDatabase = true) => {
        setPageLoading(true)
        if (changeDatabase) {
            try {
                const updateDatabaseDict = {}
                updateDatabaseDict.detergent = scent
                const updateUserLaundryInfo = firebase.functions().httpsCallable('updateUserLaundryInfo');
                const response = await updateUserLaundryInfo({
                    updateDict: updateDatabaseDict,
                    updateContext: 'account_page'
                })
                if (response.data) {
                    if (response.data.success) {
                        // await getPageAndUserInfo()
                        // setPageLoading(false)
                    }
                    else {
                        console.log('error updating laundry:', response.data.message)
                        openAlertModal('Error', 'Error updating your account info.  Please try again.  If this error persists, please contact us.', 'Ok', closeAlertModal)
                        setPageLoading(false)
                        return
                    }
                }
                else {
                    openAlertModal('Error', 'Error updating your account info.  Please try again.  If this error persists, please contact us.', 'Ok', closeAlertModal)
                    setPageLoading(false)
                    return
                }
            }
            catch (err) {
                console.log('error:', err)
                setPageLoading(false)
                openAlertModal('Error', 'Error updating your account info.  Please try again.  If this error persists, please contact us.', 'Ok', closeAlertModal)
                return
            }
        }
        var accessoryLocal = accessoryParam
        if (!accessoryLocal) {
            accessoryLocal = detergentAccessory
        }
        setDetergent(scent)
        for (var key in accessoryLocal) {
            if (key == scent) {
                accessoryLocal[key] = "Checkmark"
            }
            else {
                accessoryLocal[key] = "none"
            }
        }
        setDetergentAccessory(accessoryLocal)
        setPageLoading(false)
    }

    const selectDryerSheet = async (scent, accessoryParam = null, changeDatabase = true) => {
        setPageLoading(true)
        if (changeDatabase) {
            try {
                const updateDatabaseDict = {}
                updateDatabaseDict.dryerSheet = scent
                const updateUserLaundryInfo = firebase.functions().httpsCallable('updateUserLaundryInfo');
                const response = await updateUserLaundryInfo({
                    updateDict: updateDatabaseDict,
                    updateContext: 'account_page'
                })
                if (response.data) {
                    if (response.data.success) {
                        // await getPageAndUserInfo()
                        // setPageLoading(false)
                    }
                    else {
                        console.log('error updating address:', response.data.message)
                        openAlertModal('Error', 'Error updating your account info.  Please try again.  If this error persists, please contact us.', 'Ok', closeAlertModal)
                        setPageLoading(false)
                        return
                    }
                }
                else {
                    openAlertModal('Error', 'Error updating your account info.  Please try again.  If this error persists, please contact us.', 'Ok', closeAlertModal)
                    setPageLoading(false)
                    return
                }
            }
            catch (err) {
                console.log('error:', err)
                setPageLoading(false)
                openAlertModal('Error', 'Error updating your account info.  Please try again.  If this error persists, please contact us.', 'Ok', closeAlertModal)
                return
            }
        }
        var accessoryLocal = accessoryParam
        if (!accessoryLocal) {
            accessoryLocal = dryerSheetAccessory
        }
        setDryerSheet(scent)
        for (var key in accessoryLocal) {
            if (key == scent) {
                accessoryLocal[key] = "Checkmark"
            }
            else {
                accessoryLocal[key] = "none"
            }
        }
        setDryerSheetAccessory(accessoryLocal)
        setPageLoading(false)
    }

    const selectFabricSoftenter = async (scent, accessoryParam = null, changeDatabase = true) => {
        setPageLoading(true)
        if (changeDatabase) {
            try {
                const updateDatabaseDict = {}
                updateDatabaseDict.fabricSoftener = scent
                const updateUserLaundryInfo = firebase.functions().httpsCallable('updateUserLaundryInfo');
                const response = await updateUserLaundryInfo({
                    updateDict: updateDatabaseDict,
                    updateContext: 'account_page'
                })
                if (response.data) {
                    if (response.data.success) {
                        // await getPageAndUserInfo()
                        // setPageLoading(false)
                    }
                    else {
                        console.log('error updating address:', response.data.message)
                        openAlertModal('Error', 'Error updating your account info.  Please try again.  If this error persists, please contact us.', 'Ok', closeAlertModal)
                        setPageLoading(false)
                        return
                    }
                }
                else {
                    openAlertModal('Error', 'Error updating your account info.  Please try again.  If this error persists, please contact us.', 'Ok', closeAlertModal)
                    setPageLoading(false)
                    return
                }
            }
            catch (err) {
                console.log('error:', err)
                setPageLoading(false)
                openAlertModal('Error', 'Error updating your account info.  Please try again.  If this error persists, please contact us.', 'Ok', closeAlertModal)
                return
            }
        }
        var accessoryLocal = accessoryParam
        if (!accessoryLocal) {
            accessoryLocal = fabricSoftenerAccessory
        }
        setFabricSoftener(scent)
        for (var key in accessoryLocal) {
            if (key == scent) {
                accessoryLocal[key] = "Checkmark"
            }
            else {
                accessoryLocal[key] = "none"
            }
        }
        setFabricSoftenerAccessory(accessoryLocal)
        setPageLoading(false)
    }

    const getPageAndUserInfo = async () => {
        setPageLoading(true)
        try {
            const laundryDoc = await firebase.firestore().collection('pageInfo').doc('laundry').get()
            var detergentsLocal = []
            var dryerSheetsLocal = []
            var fabricSoftenersLocal = []
            var detergentAccessoryLocal = {}
            var dryerSheetAccessoryLocal = {}
            var fabricSoftenerAccessoryLocal = {}
            if (laundryDoc.data() && laundryDoc.data().detergent && laundryDoc.data().dryerSheet && laundryDoc.data().fabricSoftener) {
                detergentsLocal = laundryDoc.data().detergent
                dryerSheetsLocal = laundryDoc.data().dryerSheet
                fabricSoftenersLocal = laundryDoc.data().fabricSoftener
                var index = 0
                detergentsLocal.forEach((detergentLocal) => {
                    if (index === 0) {
                        setDetergent(detergent)
                        detergentAccessoryLocal[detergentLocal] = "Checkmark"
                        index++
                    }
                    else {
                        detergentAccessoryLocal[detergentLocal] = "none"
                    }
                })
                index = 0
                dryerSheetsLocal.forEach((dryerSheetLocal) => {
                    if (index === 0) {
                        setDryerSheet(dryerSheetLocal)
                        dryerSheetAccessoryLocal[dryerSheetLocal] = "Checkmark"
                        index++
                    }
                    else {
                        dryerSheetAccessoryLocal[dryerSheetLocal] = "none"
                    }
                })
                index = 0
                fabricSoftenersLocal.forEach((fabricSoftenerLocal) => {
                    if (index === 0) {
                        setFabricSoftener(fabricSoftenerLocal)
                        fabricSoftenerAccessoryLocal[fabricSoftenerLocal] = "Checkmark"
                        index++
                    }
                    else {
                        fabricSoftenerAccessoryLocal[fabricSoftenerLocal] = "none"
                    }
                })
                setDetergentAccessory(detergentAccessoryLocal)
                setDryerSheetAccessory(dryerSheetAccessoryLocal)
                setFabricSoftenerAccessory(fabricSoftenerAccessoryLocal)
                setDetergents(detergentsLocal)
                setDryerSheets(dryerSheetsLocal)
                setFabricSofteners(fabricSoftenersLocal)
            }
            else {
                setPageLoading(false)
                openAlertModal('Error', 'Sorry, we had an error connecting to our services.  If this issue persists, please contact us.', 'Try Again', () => getPageAndUserInfo(), 'Go Back', () => navigation.goBack())
                return
            }

            // get user info
            const user = firebase.auth().currentUser;
            const userInfo = (await firebase.firestore().collection("userProfiles").doc(user.uid).get()).data();
            if (userInfo) {
                if (userInfo.detergent && userInfo.dryerSheet && userInfo.fabricSoftener) {
                    selectDetergent(userInfo.detergent, detergentAccessoryLocal, false)
                    selectDryerSheet(userInfo.dryerSheet, dryerSheetAccessoryLocal, false)
                    selectFabricSoftenter(userInfo.fabricSoftener, fabricSoftenerAccessoryLocal, false)
                }
            }
            else {
                console.log('Error: no account for this user')
                setPageLoading(false)
                openAlertModal('Error', 'Sorry, we had an error connecting to our services.  If this issue persists, please contact us.', 'Try Again', () => getPageAndUserInfo(), 'Go Back', () => navigation.goBack())
                return
            }
            setPageLoading(false)
        }
        catch (err) {
            console.log('error:', err)
            setPageLoading(false)
            openAlertModal('Error', 'Sorry, we had an error connecting to our services.  If this issue persists, please contact us.', 'Try Again', () => getPageAndUserInfo(), 'Go Back', () => navigation.goBack())
            return
        }
    }

    useEffect(() => {
        getPageAndUserInfo()
        BackHandler.addEventListener('hardwareBackPress', onBackPress);
        return () => {
            BackHandler.removeEventListener('hardwareBackPress', onBackPress);
        }
    }, []);

    const createDetergentTable = () => {
        var valueArray = []
        var index = 0
        detergents.forEach((detergent) => {
            valueArray.push(
                <Cell key={index} cellStyle="Basic" backgroundColor={CELL_BACKGROUND_COLOR} titleTextColor={CELL_TITLE_TEXT_COLOR} title={detergent} accessory={detergentAccessory[detergent]} onPress={() => selectDetergent(detergent)} />
            )
            index++
        })
        return valueArray
    }

    const createFabricSoftenerTable = () => {
        var valueArray = []
        var index = 0
        fabricSofteners.forEach((fabricSoftener) => {
            valueArray.push(
                <Cell key={index} cellStyle="Basic" backgroundColor={CELL_BACKGROUND_COLOR} titleTextColor={CELL_TITLE_TEXT_COLOR} title={fabricSoftener} accessory={fabricSoftenerAccessory[fabricSoftener]} onPress={() => selectFabricSoftenter(fabricSoftener)} />
            )
            index++
        })
        return valueArray
    }

    const createDryerSheetTable = () => {
        var valueArray = []
        var index = 0
        dryerSheets.forEach((dryerSheet) => {
            valueArray.push(
                <Cell key={index} cellStyle="Basic" backgroundColor={CELL_BACKGROUND_COLOR} titleTextColor={CELL_TITLE_TEXT_COLOR} title={dryerSheet} accessory={dryerSheetAccessory[dryerSheet]} onPress={() => selectDryerSheet(dryerSheet)} />
            )
            index++
        })
        return valueArray
    }

    if (pageLoading) {
        return (
            <View style={styles.parent}>
                <ActivityIndicator />
            </View>
        )
    }

    return (
        <View style={styles.parent}>
            <ScrollView>
                <View style={{ marginLeft: 10, marginRight: 10, marginTop: 10, marginBottom: 10 }}>
                    <Text style={styles.subheading}>NOTE: This will change settings for upcoming plan pickups.</Text>
                </View>
                <Section headerTextColor={HEADER_TEXT_COLOR} footerTextColor={FOOTER_TEXT_COLOR} separatorTintColor={SEPARATOR_TINT_COLOR_TWO} header="DETERGENT" footer="">
                    {createDetergentTable()}
                </Section>
                <Section headerTextColor={HEADER_TEXT_COLOR} footerTextColor={FOOTER_TEXT_COLOR} separatorTintColor={SEPARATOR_TINT_COLOR_TWO} header="FABRIC SOFTENER" footer="">
                    {createFabricSoftenerTable()}
                </Section>
                <Section headerTextColor={HEADER_TEXT_COLOR} footerTextColor={FOOTER_TEXT_COLOR} separatorTintColor={SEPARATOR_TINT_COLOR_TWO} header="DRYER SHEET" footer="">
                    {createDryerSheetTable()}
                </Section>
            </ScrollView>
            <AlertModal
                modalVisible={alertModalVisibile}
                setModalVisible={setAlertModalVisible}
                alertTitleText={alertInfo.alertTitleText}
                alertSubtitleText={alertInfo.alertSubtitleText}
                alertPrimaryButtonText={alertInfo.alertPrimaryButtonText}
                alertPrimaryAction={alertInfo.alertPrimaryAction}
                alertSecondaryButtonText={alertInfo.alertSecondaryButtonText}
                alertSecondaryAction={alertInfo.alertSecondaryAction}
            />
        </View>
    );
}