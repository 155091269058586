import React, { useEffect } from 'react';
import {
    View,
    Dimensions,
    useWindowDimensions
} from 'react-native';
// import { RaisedTextButton } from 'react-native-material-buttons';
require("firebase/auth");
require("firebase/firestore");
import PublicTopHeading from '../../../PublicTopHeading.js'
import { MaterialIcons, MaterialCommunityIcons, AntDesign, Entypo, Ionicons, FontAwesome } from '@expo/vector-icons';
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs'
import { createStackNavigator } from '@react-navigation/stack'
import { ACCENT_COLOR_TWO, CELL_TITLE_TEXT_COLOR, CONNECTION_ERROR_BUTTON, CONNECTION_ERROR_HEADER, CONNECTION_ERROR_MESSAGE, TEXT_COLOR_ONE, TOP_TAB_HEADER_ICON_COLOR, TOP_TAB_HEADER_ICON_SIZE } from '../../../constants';
import PublicScheduleOptionSelector from '../PublicScheduleOptionSelector'

export default function PublicPricing({ navigation }) {

    const dimensions = useWindowDimensions();

    let widthPercent = "70%";
    let marginPercent = "15%";

    if (dimensions.width >= 1100) {
        widthPercent = 1000;
        marginPercent = (dimensions.width - 1000) / 2;
    }
    else if ((dimensions.width >= 500) && (dimensions.width < 1200)) {
        widthPercent = "90%";
        marginPercent = "5%";
    }
    else {
        widthPercent = "95%";
        marginPercent = "2.5%"
    }

    useEffect(() => {

    }, []);

    return (
        <View style={{ maxHeight: dimensions.height }}>
            <View style={{ zIndex: 10, width: widthPercent, marginLeft: marginPercent, position: "absolute" }}>
                <PublicTopHeading navigation={navigation} currentPage="PublicPricing" />
            </View>
            <View style={{ marginTop: 50, width: widthPercent, marginLeft: marginPercent, position: "absolute", height: dimensions.height - 50 }}>
                <PublicScheduleOptionSelector />
            </View>
        </View>
    );
}