import React, { useState, useEffect, useRef, useLayoutEffect, useContext } from 'react'
import { View, Text, ActivityIndicator, ScrollView, BackHandler, Dimensions, Image } from 'react-native'
import firebase from "firebase/app"
import "firebase/auth"
import "firebase/firestore"
import "firebase/functions"
import styles from '../styles';
import { Button } from 'react-native-elements';
import AuthContext from '../../../../AuthContext.js'
import { BUTTON_COLOR_ONE, CONNECTION_ERROR_BUTTON, CONNECTION_ERROR_HEADER, CONNECTION_ERROR_MESSAGE } from '../../../../constants';
import AlertModal from '../../AlertModal'


export default function Completed({ navigation }) {

    const { finishNewAccountCreation } = useContext(AuthContext);

    useLayoutEffect(() => {
        navigation.setOptions({
            headerRight: null,
            headerLeft: null,
        })
    })

    const logout = async () => {
        try {
            await firebase.auth().signOut();
            setPageLoading(false)
        }
        catch (err) {
            openAlertModal('Error Logging Out', 'Please try again', 'Try Again', logout)
        }
    }

    const [pageLoading, setPageLoading] = useState(true);
    const [messages, setMessages] = useState([])
    const [alertModalVisibile, setAlertModalVisible] = useState(false);
    const [alertInfo, setAlertInfo] = useState({})
    const openAlertModal = (title, subtitle, primaryButtonText, primaryButtonAction, secondaryButtonText = null, secondaryButtonAction = null) => {
        setAlertInfo({
            alertTitleText: title,
            alertSubtitleText: subtitle,
            alertPrimaryButtonText: primaryButtonText,
            alertPrimaryAction: () => primaryButtonAction(),
            alertSecondaryButtonText: secondaryButtonText,
            alertSecondaryAction: () => secondaryButtonAction()
        })
        setAlertModalVisible(true)
    }
    const closeAlertModal = () => {
        setAlertModalVisible(false)
    }

    const getPageAndUserInfo = async () => {
        setPageLoading(true)

        try {
            const pageInfo = await firebase.firestore().collection('pageInfo').doc('AccountCreation').collection('pages').doc('completed').get()
            if (pageInfo.data() && pageInfo.data().messages) {
                var newMessages = []
                pageInfo.data().messages.forEach((message) => {
                    newMessages.push(message)
                })
                setMessages(newMessages)
                setPageLoading(false)
            }
            else {
                console.log('error getting page data')
                setPageLoading(false)
                openAlertModal(CONNECTION_ERROR_HEADER, CONNECTION_ERROR_MESSAGE, CONNECTION_ERROR_BUTTON, getPageAndUserInfo, 'Log Out', logout)
                return
            }
        }
        catch (err) {
            console.log('error getting page or data:', err)
            setPageLoading(false)
            openAlertModal(CONNECTION_ERROR_HEADER, CONNECTION_ERROR_MESSAGE, CONNECTION_ERROR_BUTTON, getPageAndUserInfo, 'Log Out', logout)
            return
        }
    }

    useEffect(() => {
        getPageAndUserInfo(false)
        BackHandler.addEventListener('hardwareBackPress', onBackPress);
        return () => {
            BackHandler.removeEventListener('hardwareBackPress', onBackPress);
        }
    }, []);

    const onBackPress = () => {
        return true;
    }

    const goToHomepage = () => {
        finishNewAccountCreation()
    }

    const schedulePickups = () => {
        finishNewAccountCreation().then(() => {
            navigation.navigate('Schedule Options')
        })
    }

    const displayMessages = () => {
        var valueArray = []
        var index = 0
        messages.forEach((message) => {
            valueArray.push(
                <Text key={index} style={styles.subheading}>
                    {message}
                </Text>
            )
            index++
        })
        return valueArray
    }

    if (pageLoading) {
        return (
            <View style={styles.parent}>
                <ActivityIndicator />
            </View>
        )
    }

    return (
        <View style={styles.parent}>
            <ScrollView>
                <View style={styles.imageContainer}>
                    <Image source={require("../../../assets/Tumble_Logo.png")} style={{ height: 200, width: 200 }} />
                </View>
                {displayMessages()}
                <View style={{ height: 50, width: "100%", marginTop: 40, alignItems: 'center' }}>
                    <View style={{ width: "70%", maxWidth: 425, minWidth: 225 }} >
                        <Button
                            onPress={schedulePickups}
                            title='Schedule Pickups'
                            buttonStyle={{
                                backgroundColor: BUTTON_COLOR_ONE
                            }}
                        />
                    </View>
                </View>
                <View style={{ height: 50, width: "100%", marginTop: 40, alignItems: 'center', marginBottom: 50 }}>
                    <View style={{ width: "70%", maxWidth: 425, minWidth: 225 }} >
                        <Button
                            onPress={goToHomepage}
                            title='Go to Home Page'
                            buttonStyle={{
                                backgroundColor: BUTTON_COLOR_ONE
                            }}
                        />
                    </View>
                </View>
            </ScrollView>
            <AlertModal
                modalVisible={alertModalVisibile}
                setModalVisible={setAlertModalVisible}
                alertTitleText={alertInfo.alertTitleText}
                alertSubtitleText={alertInfo.alertSubtitleText}
                alertPrimaryButtonText={alertInfo.alertPrimaryButtonText}
                alertPrimaryAction={alertInfo.alertPrimaryAction}
                alertSecondaryButtonText={alertInfo.alertSecondaryButtonText}
                alertSecondaryAction={alertInfo.alertSecondaryAction}
            />
        </View>
    );
}