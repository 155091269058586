import { StyleSheet, ColorPropType } from 'react-native'
import { BACKGROUND_COLOR } from '../../../constants'

export default StyleSheet.create({
    heading: {
        fontSize: 35,
        textAlign: 'center',
    },
    subheading: {
        fontSize: 30,
        textAlign: 'center',
        color: BACKGROUND_COLOR
    },
    input: {
        marginTop: 10,
        marginLeft: 200,
        marginRight: 200,
        borderBottomColor: 'black',
        borderWidth: 1,
    },
    parent: {
        flex: 1,
        backgroundColor: BACKGROUND_COLOR,
    },
    button: {

    },
    safeContainer: {
        marginTop: 0
    },
    imageContainer: {
        justifyContent: 'center',
        alignItems: 'center',
    },
    itemStyle: {
        flex: 1,
        height: 36,
        alignItems: 'center',
        justifyContent: 'center',
        borderWidth: 1
    },
    field: {
        width: 300,
        color: '#449aeb',
        borderColor: '#000',
        borderWidth: 1,
        borderRadius: 5,
    }
})