import React, { useEffect, useState, useLayoutEffect } from 'react';
import {
    View,
    SafeAreaView,
    Text,
    ScrollView,
    Image,
    Dimensions,
    TouchableOpacity,
    Alert,
    ActivityIndicator,
    useWindowDimensions
} from 'react-native';
// import { RaisedTextButton } from 'react-native-material-buttons';
import { Card, Button } from 'react-native-elements';
import styles from './styles';
import DetailPage from './DetailPage.js'
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs'
import { ACCENT_COLOR_TWO, BACKGROUND_COLOR, HEADER_TEXT_COLOR, TEXT_COLOR_ONE } from '../../../constants';
const TopTab = createMaterialTopTabNavigator();

const window = Dimensions.get("window");
const screen = Dimensions.get("screen");

function PricingDetailTopTabNavigator() {
    return (
        <TopTab.Navigator tabBarOptions={{ labelStyle: { fontSize: 10 }, style: { backgroundColor: ACCENT_COLOR_TWO }, activeTintColor: TEXT_COLOR_ONE }}>
            <TopTab.Screen name="General"
                component={DetailPage}
                initialParams={{
                    pageName: "General",
                    displayName: "General"
                }}
            />
            <TopTab.Screen name="Wash and Fold"
                component={DetailPage}
                initialParams={{
                    pageName: "WashAndFold",
                    displayName: "Wash and Fold Pickup"
                }}
            />
            <TopTab.Screen name="Biweekly"
                component={DetailPage}
                initialParams={{
                    pageName: "Biweekly",
                    displayName: "Biweekly (Every Two Weeks) Plan"
                }}
            />
            <TopTab.Screen name="Weekly"
                component={DetailPage}
                initialParams={{
                    pageName: "Weekly",
                    displayName: "Weekly Plan"
                }}
            />
        </TopTab.Navigator>
    );
}

export default function PricingDetail({ navigation }) {

    const [loading, setLoading] = useState(true)

    const dimensions = useWindowDimensions();

    let widthPercent = "70%";
    let marginPercent = "15%";

    if (dimensions.width >= 1100) {
        widthPercent = 1000;
        marginPercent = (dimensions.width - 1000) / 2;
    }
    else if ((dimensions.width >= 500) && (dimensions.width < 1200)) {
        widthPercent = "90%";
        marginPercent = "5%";
    }
    else {
        widthPercent = "95%";
        marginPercent = "2.5%"
    }


    useEffect(() => {
        setLoading(false)
    }, []);

    if (loading) {
        return (
            <View style={styles.parent}>
                <ActivityIndicator />
            </View>
        )
    }

    return (
        <View style={{ maxHeight: dimensions.height }}>
            <View style={{ height: 50, backgroundColor: BACKGROUND_COLOR, width: widthPercent, marginLeft: marginPercent, position: "absolute", }}>
                <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                    <View
                        style={{
                            width: "25%",
                            height: 50,
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}>
                    </View>
                    <View
                        style={{
                            width: "50%",
                            height: 50,
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}>
                        <Text style={{ fontSize: 20, fontWeight: '500', color: HEADER_TEXT_COLOR }} >
                            Pricing Detail
                        </Text>
                    </View>
                    <View
                        style={{
                            width: "25%",
                            height: 50,
                            justifyContent: 'center',
                            alignItems: 'flex-end'
                        }}>
                        <View style={{ flexDirection: 'row', justifyContent: 'space-between', marginRight: 10 }}>
                            <Button
                                onPress={() => navigation.goBack()}
                                title="Done"
                                type="clear"
                                titleStyle={{
                                    fontWeight: "normal"
                                }}
                                buttonStyle={{

                                }}
                                disabled={loading}
                            />
                        </View>
                    </View>
                </View>
            </View>
            <View style={{ marginTop: 50, width: widthPercent, marginLeft: marginPercent, position: "absolute", height: dimensions.height - 50 }}>
                <PricingDetailTopTabNavigator />
            </View>
        </View>
    );
}