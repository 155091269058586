import React, { useEffect, useState, useLayoutEffect } from 'react';
import {
    View,
    SafeAreaView,
    Text,
    ScrollView,
    Image,
    Dimensions,
    TouchableOpacity,
    Alert,
    ActivityIndicator
} from 'react-native';
// import { RaisedTextButton } from 'react-native-material-buttons';
import { Card, Button } from 'react-native-elements';
import styles from './styles';
import UseRewards from './UseRewards'
import TrackPoints from './TrackPoints'
import RewardsExpiration from './RewardsExpiration'
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs'
import { ACCENT_COLOR_TWO, TEXT_COLOR_ONE } from '../../../constants';
const TopTab = createMaterialTopTabNavigator();

const window = Dimensions.get("window");
const screen = Dimensions.get("screen");

function RewardsFAQTopTabNavigator() {
    return (
        <TopTab.Navigator tabBarOptions={{ labelStyle: { fontSize: 10 }, style: { backgroundColor: ACCENT_COLOR_TWO }, activeTintColor: TEXT_COLOR_ONE }}>
            <TopTab.Screen name="Use Rewards" component={UseRewards} />
            <TopTab.Screen name="Track Points" component={TrackPoints} />
            <TopTab.Screen name="Rewards Expiration" component={RewardsExpiration} />
        </TopTab.Navigator>
    );
}

export default function RewardsFAQ({ navigation }) {

    const [loading, setLoading] = useState(true)

    useLayoutEffect(() => {
        navigation.setOptions({
            headerRight: () => (
                <Button
                    onPress={() => navigation.goBack()}
                    title="Done"
                    type="clear"
                    titleStyle={{
                        fontWeight: "normal"
                    }}
                    buttonStyle={{
                        marginRight: 10
                    }}
                />
            ),
            headerLeft: null
        })
    })

    const dimensions = { window, screen }

    useEffect(() => {
        setLoading(false)
    }, []);

    if (loading) {
        return (
            <View style={styles.parent}>
                <ActivityIndicator />
            </View>
        )
    }

    return (
        <View style={styles.parent}>
            <View style={{ flex: 1, marginTop: 0, zIndex: 0 }}>
                <RewardsFAQTopTabNavigator />
            </View>
        </View>
    );
}