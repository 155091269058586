import React, { useEffect, useState } from 'react';
import {
    View,
    SafeAreaView,
    Text,
    ScrollView,
    Image,
    Dimensions,
    TouchableOpacity,
    Alert
} from 'react-native';
// import { RaisedTextButton } from 'react-native-material-buttons';
import { Card, Button } from 'react-native-elements';
import styles from './styles';
import KeyboardSpacer from '../KeyboardSpacer';
import { ACCENT_COLOR_FOUR, ACCENT_COLOR_ONE, ACCENT_COLOR_TWO, ACCENT_COLOR_THREE, ACCENT_COLOR_FIVE, TEXT_COLOR_ONE, TEXT_COLOR_THREE, ACCENT_COLOR_SIX, TEXT_COLOR_FOUR, BACKGROUND_COLOR } from '../../../constants';

const window = Dimensions.get("window");
const screen = Dimensions.get("screen");

export default function UseRewards({ navigation }) {

    useEffect(() => {

    }, []);

    return (
        <View style={styles.parent}>
            <ScrollView keyboardShouldPersistTaps='handled'>
                <SafeAreaView style={styles.safeContainer}>
                    <View style={{ marginTop: 50, marginBottom: 20, alignItems: 'center' }}>
                        <View style={{ width: "95%", maxWidth: 600, backgroundColor: ACCENT_COLOR_FIVE, }}>
                            <Text style={{ textAlign: 'center', color: TEXT_COLOR_FOUR, flexWrap: "wrap", fontSize: 25, width: "90%", marginLeft: "5%", marginTop: 15, marginBottom: 15, textDecorationLine: 'underline' }}>How to Use Rewards</Text>
                            <Text style={{ textAlign: 'left', color: TEXT_COLOR_FOUR, flexWrap: "wrap", fontSize: 20, width: "90%", marginLeft: "5%", marginTop: 15, marginBottom: 15 }}>Tumble points can be used to redeem free washes, preload gift cards, and earn discounts on Tumble items.</Text>
                            <Text style={{ textAlign: 'left', color: TEXT_COLOR_FOUR, flexWrap: "wrap", fontSize: 20, width: "90%", marginLeft: "5%", marginTop: 15, marginBottom: 15 }}>You can use Tumble points by selecting the "redeem popints" button on the rewards page.  After selecting this button, you will need to choose the reward you would like to redeem.  Apply earned points to your next pickup!</Text>
                        </View>
                        <View style={{ width: "95%", maxWidth: 600, backgroundColor: ACCENT_COLOR_THREE, }}>
                            <Text style={{ textAlign: 'center', color: TEXT_COLOR_ONE, flexWrap: "wrap", fontSize: 25, width: "90%", marginLeft: "5%", marginTop: 15, marginBottom: 15 }}>Earn Tumble Points</Text>
                        </View>
                        <View style={{ width: "95%", maxWidth: 600, backgroundColor: ACCENT_COLOR_SIX, }}>
                            <View style={{ width: "100%", flexDirection: "row", marginTop: 10 }}>
                                <View style={{ width: "50%" }}>
                                    <Text style={{ textAlign: 'center', color: TEXT_COLOR_ONE, flexWrap: "wrap", fontSize: 15, width: "90%", marginLeft: "5%", marginTop: 10, marginBottom: "5%" }}>1 month of free pickups</Text>
                                </View>
                                <View style={{ width: "50%" }}>
                                    <Text style={{ textAlign: 'center', color: TEXT_COLOR_ONE, flexWrap: "wrap", fontSize: 15, width: "90%", marginLeft: "5%", marginTop: 10, marginBottom: "5%" }}>850 pts</Text>
                                </View>
                            </View>
                            <View style={{ width: "100%", flexDirection: "row", marginTop: 10 }}>
                                <View style={{ width: "50%" }}>
                                    <Text style={{ textAlign: 'center', color: TEXT_COLOR_ONE, flexWrap: "wrap", fontSize: 15, width: "90%", marginLeft: "5%", marginTop: 10, marginBottom: "5%" }}>1 free pickup</Text>
                                </View>
                                <View style={{ width: "50%" }}>
                                    <Text style={{ textAlign: 'center', color: TEXT_COLOR_ONE, flexWrap: "wrap", fontSize: 15, width: "90%", marginLeft: "5%", marginTop: 10, marginBottom: "5%" }}>250 pts</Text>
                                </View>
                            </View>
                            <View style={{ width: "100%", flexDirection: "row", marginTop: 10, marginBottom: 15 }}>
                                <View style={{ width: "50%" }}>
                                    <Text style={{ textAlign: 'center', color: TEXT_COLOR_ONE, flexWrap: "wrap", fontSize: 15, width: "90%", marginLeft: "5%", marginTop: 10, marginBottom: "5%" }}>$10 off next pickup</Text>
                                </View>
                                <View style={{ width: "50%" }}>
                                    <Text style={{ textAlign: 'center', color: TEXT_COLOR_ONE, flexWrap: "wrap", fontSize: 15, width: "90%", marginLeft: "5%", marginTop: 10, marginBottom: "5%" }}>100 pts</Text>
                                </View>
                            </View>
                        </View>
                    </View>
                </SafeAreaView>
            </ScrollView>
        </View>
    );
}