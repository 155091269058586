import React, { useEffect, useState, useRef } from 'react';
import {
    View,
    Dimensions,
    ActivityIndicator,
    Text,
    useWindowDimensions,
    TouchableHighlight
} from 'react-native';
import { Button } from 'react-native-elements';
import styles from './styles'
import firebase from "firebase/app"
import "firebase/auth"
import "firebase/firestore"
import "firebase/functions"
import Modal from 'react-native-modal'
import { BACKGROUND_COLOR, CELL_BACKGROUND_COLOR, TEXT_INPUT_COLOR, TEXT_COLOR_THREE, HEADER_TEXT_COLOR, SEPARATOR_TINT_COLOR_TWO, UNDERLAY_COLOR_ONE, CELL_TITLE_TEXT_COLOR } from '../../../constants';
import { GooglePlacesAutocomplete } from 'react-native-google-places-autocomplete';
import { WEB_GOOGLE_API_KEY } from '../../../apiKeys';

const GOOGLE_PLACES_API_KEY = WEB_GOOGLE_API_KEY

export default function AddressPicker({ modalVisible, setModalVisible, setUserAddress, setAddressMainText, setAddressSecondaryText, setAddressTextColor = null }) {

    const dimensions = useWindowDimensions()
    const [] = useState(false)
    const [modalLoading, setModalLoading] = useState(true)
    const [addressDialogCancelButtonDisabled, setAddressDialogCancelButtonDisabled] = useState(false)
    const [addressData, setAddressData] = useState()
    const [addressDetails, setAddressDetails] = useState()
    const [alertModalVisible, setAlertModalVisible] = useState(false)
    const [alertModalInfo, setAlertModalInfo] = useState({})
    const [googlePlacesAutocompleteUrlPrefix, setGooglePlacesAutocompleteUrlPrefix] = useState('')

    const showAlert = (alertTitle, alertSubtitle) => {
        setAlertModalInfo({
            alertTitleText: alertTitle,
            alertSubtitleText: alertSubtitle,
            alertPrimaryButtonText: 'Ok',
            alertPrimaryAction: () => closeAlertModal(),
            alertSecondaryButtonText: '',
            alertSecondaryAction: () => () => { }
        })
        setAlertModalVisible(true)
    }

    const closeAlertModal = () => {
        setAlertModalVisible(false)
    }

    const submitNewAddress = async (data, details) => {
        setAddressData(data)
        setAddressDetails(details)
        if (!data || !details) {
            showAlert('Error', 'Please select an address.')
            return
        }
        setModalLoading(true)
        setAddressDialogCancelButtonDisabled(true)
        try {
            const checkAddress = firebase.functions().httpsCallable('checkAddress');
            const result = await checkAddress({
                addressData: data,
                addressDetails: details
            })
            if (result.data) {
                if (result.data.valid) {
                    setAddressDialogCancelButtonDisabled(false)
                    setModalLoading(false)
                    setUserAddress(data.description)
                    setAddressMainText(data.structured_formatting.main_text)
                    setAddressSecondaryText(data.structured_formatting.secondary_text)
                    if (setAddressTextColor) {
                        setAddressTextColor(CELL_TITLE_TEXT_COLOR)
                    }
                    setModalVisible(false)
                }
                else {
                    const errorMessage = result.data.message
                    setModalLoading(false)
                    setAddressDialogCancelButtonDisabled(false)
                    showAlert('Address Out of Service Area', errorMessage)
                }
            }
            else {
                setModalLoading(false)
                setAddressDialogCancelButtonDisabled(false)
                showAlert('Error', 'Sorry, we are having trouble connecting.  Please try again.  If this issue persists, please contact us.')
            }
        }
        catch (err) {
            setModalLoading(false)
            setAddressDialogCancelButtonDisabled(false)
            showAlert('Error', 'Sorry, we are having trouble connecting.  Please try again.  If this issue persists, please contact us.')
        }
    }

    const closeAddressDialog = () => {
        setModalVisible(false)
        setAddressData()
        setAddressDetails()
    }

    const getGooglePlacesAutocompleteUrlPrefix = async () => {
        const addressPickerDoc = await firebase.firestore().collection('pageInfo').doc('addressPicker').get()
        if (addressPickerDoc.data()) {
            const urlPrefix = addressPickerDoc.data().webUrlPrefix
            setGooglePlacesAutocompleteUrlPrefix(urlPrefix)
        }
        setModalLoading(false)
    }

    useEffect(() => {
        getGooglePlacesAutocompleteUrlPrefix()
    }, [modalVisible])

    var widthPercent = "100%"
    var marginPercent = "0%"
    if (dimensions.width >= 1100) {
        widthPercent = 1000;
        marginPercent = (dimensions.width - 1000) / 2;
    }
    else if ((dimensions.width >= 500) && (dimensions.width < 1100)) {
        widthPercent = "90%";
        marginPercent = "5%";
    }
    else {
        widthPercent = "95%";
        marginPercent = "2.5%"
    }

    const alertButtons = () => {
        if (!alertModalInfo.alertSecondaryButtonText || alertModalInfo.alertSecondaryButtonText === '') {
            // only one button
            return (
                <View style={{ width: "100%" }}>
                    <TouchableHighlight style={{ alignItems: 'center' }} underlayColor={UNDERLAY_COLOR_ONE} onPress={alertModalInfo.alertPrimaryAction}>
                        <View style={{ alignItems: 'center', width: "100%" }}>
                            <Text style={{ marginTop: 15, marginBottom: 15, fontSize: 15, color: TEXT_COLOR_THREE, fontWeight: 'bold' }}>{alertModalInfo.alertPrimaryButtonText}</Text>
                        </View>
                    </TouchableHighlight>
                </View>
            )
        }
        else {
            // two buttons
            return (
                <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                    <View style={{ width: "50%", borderRightWidth: 1, borderRightColor: SEPARATOR_TINT_COLOR_TWO }}>
                        <TouchableHighlight style={{ alignItems: 'center' }} underlayColor={UNDERLAY_COLOR_ONE} onPress={alertModalInfo.alertSecondaryAction}>
                            <View style={{ alignItems: 'center', width: "100%" }}>
                                <Text style={{ marginTop: 15, marginBottom: 15, fontSize: 15, color: TEXT_COLOR_THREE, fontWeight: 'normal' }}>{alertModalInfo.alertSecondaryButtonText}</Text>
                            </View>
                        </TouchableHighlight>
                    </View>
                    <View style={{ width: "50%", borderLeftWidth: 1, borderLeftColor: SEPARATOR_TINT_COLOR_TWO }}>
                        <TouchableHighlight style={{ alignItems: 'center' }} underlayColor={UNDERLAY_COLOR_ONE} onPress={alertModalInfo.alertPrimaryAction}>
                            <View style={{ alignItems: 'center', width: "100%" }}>
                                <Text style={{ marginTop: 15, marginBottom: 15, fontSize: 15, color: TEXT_COLOR_THREE, fontWeight: 'bold' }}>{alertModalInfo.alertPrimaryButtonText}</Text>
                            </View>
                        </TouchableHighlight>
                    </View>
                </View>

            )
        }
    }

    if (modalLoading) {
        return (
            <Modal isVisible={modalVisible} propagateSwipe={true} style={{ justifyContent: 'flex-end', margin: 0 }} >
                <View style={{ height: dimensions.height * 0.95, width: widthPercent, marginLeft: marginPercent, backgroundColor: BACKGROUND_COLOR }}>
                    <ActivityIndicator />
                </View>
            </Modal >
        )
    }

    if (alertModalVisible) {
        return (
            <Modal isVisible={modalVisible} keyboardShouldPersistTaps="handled" propagateSwipe={true} style={{ alignItems: 'center' }} >
                <View style={{ width: widthPercent, minWidth: 300, maxWidth: 550, backgroundColor: BACKGROUND_COLOR, borderRadius: 5 }}>
                    <View style={{ alignItems: 'center' }}>
                        <Text style={{ textAlign: 'center', color: HEADER_TEXT_COLOR, fontSize: 30, marginTop: 20 }}>{alertModalInfo.alertTitleText}</Text>
                    </View>
                    <View style={{ alignItems: 'center' }}>
                        <Text style={{ textAlign: 'center', color: HEADER_TEXT_COLOR, fontSize: 20, marginTop: 20, marginLeft: 10, marginRight: 10 }}>{alertModalInfo.alertSubtitleText}</Text>
                    </View>
                    <View style={{ marginTop: 20, borderTopWidth: 1, borderTopColor: SEPARATOR_TINT_COLOR_TWO }}>
                        {alertButtons()}
                    </View>
                </View>
            </Modal>
        )
    }

    return (
        <Modal isVisible={modalVisible} keyboardShouldPersistTaps="handled" propagateSwipe={true} style={{ justifyContent: 'flex-end', margin: 0 }} >
            <View style={{ height: dimensions.height * 0.95, width: widthPercent, marginLeft: marginPercent, backgroundColor: BACKGROUND_COLOR, borderTopLeftRadius: 5, borderTopStartRadius: 5, borderTopRightRadius: 5, borderTopEndRadius: 5 }}>
                <View style={{ flexDirection: 'row', alignItems: 'center', marginTop: 10, marginBottom: 10 }}>
                    <View
                        style={{
                            width: "25%",
                            height: 50,
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}>
                    </View>
                    <View
                        style={{
                            width: "50%",
                            height: 50,
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}>
                        <Text style={{ fontSize: 20, fontWeight: '500', color: TEXT_COLOR_THREE }} >
                            Enter Address
                        </Text>
                    </View>
                    <View
                        style={{
                            width: "25%",
                            height: 50,
                            justifyContent: 'center',
                            alignItems: 'flex-end'
                        }}>
                        <View style={{ flexDirection: 'row', justifyContent: 'space-between', marginRight: 10 }}>
                            <Button
                                onPress={closeAddressDialog}
                                title="Cancel"
                                type="clear"
                                titleStyle={{
                                    fontWeight: "normal"
                                }}
                                buttonStyle={{

                                }}
                                disabled={addressDialogCancelButtonDisabled}
                            />
                        </View>
                    </View>
                </View>
                <View style={{ marginLeft: 10, marginRight: 10, marginTop: 10, marginBottom: 10 }}>
                    <Text style={styles.subsubheading}>Type your address in the search bar, then select your address from the dropdown.  If your address does not show, please contact us.</Text>
                </View>
                <View keyboardShouldPersistTaps="handled" style={{ height: "100%", width: "100%", flex: 1, backgroundColor: BACKGROUND_COLOR }}>
                    <GooglePlacesAutocomplete
                        query={{
                            key: GOOGLE_PLACES_API_KEY,
                            language: 'en', // language of the results
                        }}
                        style={{

                        }}
                        requestUrl={{ useOnPlatform: "web", url: `${googlePlacesAutocompleteUrlPrefix}https://maps.googleapis.com/maps/api/` }}
                        fetchDetails={true}
                        onPress={(data, details) => submitNewAddress(data, details)}
                        onFail={(error) => console.error(error)}
                        enablePoweredByContainer={false}
                        styles={{
                            textInputContainer: {
                                backgroundColor: CELL_BACKGROUND_COLOR,
                                borderTopWidth: 0,
                                borderBottomWidth: 0,
                            },
                            textInput: {
                                backgroundColor: CELL_BACKGROUND_COLOR,
                                marginLeft: 0,
                                marginRight: 0,
                                height: 38,
                                color: TEXT_INPUT_COLOR,
                                fontSize: 16,
                            },
                            predefinedPlacesDescription: {
                            },
                            listView: {
                                color: CELL_BACKGROUND_COLOR
                            },
                            description: {
                                color: TEXT_COLOR_THREE
                            }
                        }}
                    />
                </View>
            </View>
        </Modal>
    );
}