import { StyleSheet } from 'react-native'
import { BACKGROUND_COLOR } from '../../../constants';

export default StyleSheet.create({
    heading: {
        fontSize: 25,
        textAlign: 'center'
    },
    input: {
        marginTop: 10,
        marginLeft: 5,
        marginRight: 5,
    },
    button: {
        marginTop: 20,
    },
    parent: {
        flex: 1,   
        backgroundColor: BACKGROUND_COLOR
    },
    safeContainer: {
        
    },
    imageContainer: {
    },
    cardContainer: {
        backgroundColor: "black"
    }
});