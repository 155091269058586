import { StyleSheet } from 'react-native'
import { BACKGROUND_COLOR, HEADER_TEXT_COLOR } from '../../../constants';

export default StyleSheet.create({
    heading: {
        fontSize: 30,
        textAlign: 'center',
        color: HEADER_TEXT_COLOR,
        marginBottom: 30,
        marginTop: 30
    },
    subheading: {
        fontSize: 20,
        color: HEADER_TEXT_COLOR,
        textAlign: 'center',
        margin: 20
    },
    subsubheading: {
        fontSize: 20,
        textAlign: 'left'
    },
    input: {
        marginTop: 10,
        marginLeft: 5,
        marginRight: 5,
    },
    button: {
        marginTop: 20,
    },
    parent: {
        flex: 1,
        backgroundColor: BACKGROUND_COLOR
    },
    safeContainer: {
        marginTop: 0
    },
    twoPickers: {
        width: 50,
        height: 100,
        backgroundColor: '#FFF0E0',
        borderColor: 'black',
        borderWidth: 1,
    },
    twoPickerItems: {
        height: 125,
        color: 'black'
    },
    imageContainer: {
        justifyContent: 'center',
        alignItems: 'center',
    }
});