import React, { useState, useLayoutEffect, useRef, useEffect } from 'react';
import {
    View,
    ScrollView,
    Dimensions,
    Alert,
    TextInput,
    ActivityIndicator,
    BackHandler,
    Switch,
} from 'react-native';
import { Button } from 'react-native-elements';
import styles from './styles';
import { Cell, Section } from 'react-native-tableview-simple';
import firebase from "firebase/app"
import "firebase/auth"
import "firebase/firestore"
import "firebase/functions"
import { HeaderBackButton } from '@react-navigation/stack'
import { BACKGROUND_COLOR, CELL_BACKGROUND_COLOR, CELL_TITLE_TEXT_COLOR, CONNECTION_ERROR_BUTTON, CONNECTION_ERROR_HEADER, CONNECTION_ERROR_MESSAGE, DISABLED_TEXT_INPUT_COLOR, FOOTER_TEXT_COLOR, HEADER_TEXT_COLOR, PLACEHOLDER_TEXT_INPUT_COLOR, SEPARATOR_TINT_COLOR_TWO, TEXT_INPUT_COLOR } from '../../../constants';
import AlertModal from '../AlertModal'


export default function ContactInfo({ navigation }) {

    useLayoutEffect(() => {
        navigation.setOptions({
            headerRight: () => {
                if (editMode) {
                    return <Button
                        onPress={() => confirmSaveChanges()}
                        title="Done"
                        type="clear"
                        titleStyle={{
                            fontWeight: "bold",
                            color: HEADER_TEXT_COLOR
                        }}
                        buttonStyle={{
                            marginRight: 10
                        }}
                        disabled={pageLoading}
                    />
                }
                else {
                    return <Button
                        onPress={() => startEditing()}
                        title="Edit"
                        type="clear"
                        titleStyle={{
                            fontWeight: "normal",
                            color: HEADER_TEXT_COLOR
                        }}
                        buttonStyle={{
                            marginRight: 10
                        }}
                        disabled={editButtonDisabled()}
                    />
                }
            },
            headerLeft: () => {
                if (editMode) {
                    return <Button
                        onPress={() => cancelEditing()}
                        title="Cancel"
                        type="clear"
                        titleStyle={{
                            fontWeight: "normal",
                            color: HEADER_TEXT_COLOR
                        }}
                        buttonStyle={{
                            marginLeft: 10
                        }}
                        disabled={pageLoading}
                    />
                }
                else {
                    return <HeaderBackButton
                        label="Back"
                        tintColor={HEADER_TEXT_COLOR}
                        disabled={backButtonDisabled()}
                        onPress={() => navigation.goBack()}
                    />
                }

            },
        })
    })

    const backButtonDisabled = () => {
        return pageLoading
    }

    const editButtonDisabled = () => {
        return pageLoading
    }

    const cancelEditing = () => {
        getUserInfo()
        setEditMode(false)
        setDefaultTextColor(DISABLED_TEXT_INPUT_COLOR)
    }

    const startEditing = () => {
        setEditMode(true)
        setDefaultTextColor(TEXT_INPUT_COLOR)
    }

    const toggleTextRemindersSwitch = () => {
        setTextReminders(previousState => !previousState);
    };

    useEffect(() => {
        getUserInfo();
        BackHandler.addEventListener('hardwareBackPress', onBackPress);
        return () => {
            BackHandler.removeEventListener('hardwareBackPress', onBackPress);
        }
    }, []);

    const getUserInfo = async () => {
        const user = firebase.auth().currentUser;
        const userInfo = (await firebase.firestore().collection("userProfiles").doc(user.uid).get()).data();
        if (userInfo) {
            setPhoneNumber(userInfo.contactNumber)
            setFirstName(userInfo.firstName)
            setLastName(userInfo.lastName)
            setEmail(userInfo.contactEmail)
            setTextReminders(userInfo.textReminders)
            selectPreferredContact(userInfo.preferredContactMethod)
        }
        else {
            console.log("error")
        }
        setPageLoading(false);
    }

    const insertCharacterAtIndex = (str, index, character) => {
        return str.substr(0, index) + character + str.substr(index);
    }

    const changePhoneNumber = (value) => {
        var numbersOnly = value.replace(/[^\d]/g, '')
        var newValue = ''
        if (numbersOnly.length > 0) {
            if (numbersOnly.length > 10) {
                numbersOnly = numbersOnly.substr(0, 10)
            }
            newValue = numbersOnly
            newValue = insertCharacterAtIndex(newValue, 0, '(')
            if (numbersOnly.length > 3) {
                newValue = insertCharacterAtIndex(newValue, 4, ') ')
            }
            if (numbersOnly.length > 6) {
                newValue = insertCharacterAtIndex(newValue, 9, '-')
            }

        }
        setPhoneNumber(newValue)
    }

    const onBackPress = () => {
        return true;
    }

    const [phoneNumber, setPhoneNumber] = useState("")
    const [firstName, setFirstName] = useState("")
    const [lastName, setLastName] = useState("")
    const [textReminders, setTextReminders] = useState(true);
    const [email, setEmail] = useState("")
    const [preferredContactMethod, setPreferredContactMethod] = useState("Text")
    const [pageLoading, setPageLoading] = useState(true)
    const [editMode, setEditMode] = useState(false)
    const [defaultTextColor, setDefaultTextColor] = useState(DISABLED_TEXT_INPUT_COLOR)
    const [alertModalVisibile, setAlertModalVisible] = useState(false);
    const [alertInfo, setAlertInfo] = useState({})
    const openAlertModal = (title, subtitle, primaryButtonText, primaryButtonAction, secondaryButtonText = null, secondaryButtonAction = null) => {
        setAlertInfo({
            alertTitleText: title,
            alertSubtitleText: subtitle,
            alertPrimaryButtonText: primaryButtonText,
            alertPrimaryAction: () => primaryButtonAction(),
            alertSecondaryButtonText: secondaryButtonText,
            alertSecondaryAction: () => secondaryButtonAction()
        })
        setAlertModalVisible(true)
    }
    const closeAlertModal = () => {
        setAlertModalVisible(false)
    }

    const [contactAccessory, setContactAccessory] = useState({
        "Call": "none", "Text": "Checkmark", "Email": "none"
    })

    const selectPreferredContact = (contactMethod) => {
        setPreferredContactMethod(contactMethod)
        const updateDict = {}
        for (var key in contactAccessory) {
            if (key == contactMethod) {
                updateDict[key] = "Checkmark"
            }
            else {
                updateDict[key] = "none"
            }
        }
        setContactAccessory(updateDict)
    }
    const refs = {
        "phoneNumber": useRef(), "email": useRef(), "firstName": useRef(), "lastName": useRef()
    }

    const onSubmitFirstName = () => {
        refs["lastName"].current.focus();
    }

    const onSubmitLastName = () => {
        refs["phoneNumber"].current.focus();
    }

    const onSubmitPhoneNumber = () => {
        refs["phoneNumber"].current.blur();
    }

    const confirmSaveChanges = () => {
        if (!phoneNumber || phoneNumber === '') {
            setPageLoading(false)
            openAlertModal('Error', 'Please enter your phone number.', 'Ok', closeAlertModal)
            return
        }
        // regex check to make sure valid phone number
        let phoneNumberRegex = /^\([1-9]\d{2}\)\s\d{3}-\d{4}/;
        if (!phoneNumberRegex.test(phoneNumber)) {
            setPageLoading(false)
            openAlertModal('Invalid Phone Number', 'Please enter a valid phone number.', 'Ok', closeAlertModal)
            return
        }
        openAlertModal('Confirm Changes', 'Changes will be applied to all upcoming plan pickups.', 'Ok', saveChanges, 'Cancel', closeAlertModal)
    }

    const saveChanges = async () => {
        closeAlertModal()
        setPageLoading(true)
        const contactObject = {}
        contactObject.firstName = firstName
        contactObject.lastName = lastName
        contactObject.contactNumber = phoneNumber
        contactObject.preferredContactMethod = preferredContactMethod
        contactObject.textReminders = textReminders
        try {
            var updateUserContactInfo = firebase.functions().httpsCallable('updateUserContactInfo');
            const response = await updateUserContactInfo({
                updateDict: contactObject,
                updateContext: 'account_page'
            })
            if (response.data) {
                if (response.data.success) {
                    await getUserInfo()
                    setEditMode(false)
                    setDefaultTextColor(DISABLED_TEXT_INPUT_COLOR)
                    setPageLoading(false)
                    return
                }
                else {
                    console.log('error updating contact info:', response.data.message)
                    openAlertModal('Error', 'Error updating your account info.  Please try again.  If this error persists, please contact us.', 'Ok', closeAlertModal)
                    setPageLoading(false)
                }
            }
            else {
                openAlertModal('Error', 'Error updating your account info.  Please try again.  If this error persists, please contact us.', 'Ok', closeAlertModal)
                setPageLoading(false)
            }
        }
        catch (err) {
            console.log('error:', err)
            setPageLoading(false)
            openAlertModal(CONNECTION_ERROR_HEADER, CONNECTION_ERROR_MESSAGE, CONNECTION_ERROR_BUTTON, closeAlertModal)
            return
        }
    }

    if (pageLoading) {
        return (
            <View style={styles.parent}>
                <ActivityIndicator />
            </View>
        )
    }

    return (
        <View style={styles.parent}>
            <ScrollView keyboardShouldPersistTaps='handled'>
                <Section headerTextColor={HEADER_TEXT_COLOR} header="FIRST NAME" footer="">
                    <Cell backgroundColor={BACKGROUND_COLOR} cellContentView={
                        <TextInput
                            style={{ fontSize: 16, flex: 1, color: defaultTextColor }}
                            placeholder="First Name"
                            ref={refs['firstName']}
                            autoCorrect={false}
                            editable={editMode}
                            enablesReturnKeyAutomatically={true}
                            onChangeText={value => setFirstName(value)}
                            value={firstName}
                            onSubmitEditing={onSubmitFirstName}
                            returnKeyType="next"
                            placeholderTextColor={PLACEHOLDER_TEXT_INPUT_COLOR}
                            autoCapitalize="words"
                        />
                    } />
                </Section>
                <Section headerTextColor={HEADER_TEXT_COLOR} header="LAST NAME" footer="">
                    <Cell backgroundColor={BACKGROUND_COLOR} cellContentView={
                        <TextInput
                            style={{ fontSize: 16, flex: 1, color: defaultTextColor }}
                            placeholder="Last Name"
                            ref={refs['lastName']}
                            autoCorrect={false}
                            editable={editMode}
                            enablesReturnKeyAutomatically={true}
                            onChangeText={value => setLastName(value)}
                            value={lastName}
                            onSubmitEditing={onSubmitLastName}
                            returnKeyType="next"
                            placeholderTextColor={PLACEHOLDER_TEXT_INPUT_COLOR}
                            autoCapitalize="words"
                        />
                    } />
                </Section>
                <Section headerTextColor={HEADER_TEXT_COLOR} header="PHONE NUMBER" footer="">
                    <Cell backgroundColor={BACKGROUND_COLOR} cellContentView={
                        <TextInput
                            style={{ fontSize: 16, flex: 1, color: defaultTextColor }}
                            placeholder="Phone Number"
                            ref={refs['phoneNumber']}
                            autoCorrect={false}
                            editable={editMode}
                            enablesReturnKeyAutomatically={true}
                            onChangeText={value => changePhoneNumber(value)}
                            value={phoneNumber}
                            onSubmitEditing={onSubmitPhoneNumber}
                            returnKeyType="go"
                            placeholderTextColor={PLACEHOLDER_TEXT_INPUT_COLOR}
                        />
                    } />
                </Section>
                <Section headerTextColor={HEADER_TEXT_COLOR} footerTextColor={FOOTER_TEXT_COLOR} header="EMAIL ADDRESS" footer="">
                    <Cell backgroundColor={CELL_BACKGROUND_COLOR} cellContentView={
                        <TextInput
                            style={{ fontSize: 16, flex: 1, color: "#808080" }}
                            editable={false}
                            placeholder="Email Address"
                            keyboardType='email-address'
                            textContentType="emailAddress"
                            ref={refs["email"]}
                            onChangeText={(value) => setEmail(value)}
                            value={email}
                            autoCorrect={false}
                            enablesReturnKeyAutomatically={true}
                            onSubmitEditing={onSubmitPhoneNumber}
                            returnKeyType="next"
                        />
                    } />
                </Section>
                <Section headerTextColor={HEADER_TEXT_COLOR} header="PREFERRED CONTACT METHOD" footer="">
                    <Cell cellStyle="Basic" backgroundColor={CELL_BACKGROUND_COLOR} titleTextColor={defaultTextColor} title="Call" accessory={contactAccessory["Call"]} isDisabled={!editMode} onPress={() => selectPreferredContact("Call")} />
                    <Cell cellStyle="Basic" backgroundColor={CELL_BACKGROUND_COLOR} titleTextColor={defaultTextColor} title="Text" accessory={contactAccessory["Text"]} isDisabled={!editMode} onPress={() => selectPreferredContact("Text")} />
                    <Cell cellStyle="Basic" backgroundColor={CELL_BACKGROUND_COLOR} titleTextColor={defaultTextColor} title="Email" accessory={contactAccessory["Email"]} isDisabled={!editMode} onPress={() => selectPreferredContact("Email")} />
                </Section>
                <Section separatorTintColor={SEPARATOR_TINT_COLOR_TWO} header="" headerTextColor={HEADER_TEXT_COLOR} footerTextColor={FOOTER_TEXT_COLOR} footer="Would you like to receive text message reminders for upcoming pickups?">
                    <Cell backgroundColor={BACKGROUND_COLOR} title="Text Message Reminders" titleTextColor={defaultTextColor} cellAccessoryView={
                        <Switch
                            trackColor={{ false: "#767577", true: "#81b0ff" }}
                            thumbColor={textReminders ? "#f5dd4b" : "#f4f3f4"}
                            ios_backgroundColor="#3e3e3e"
                            onValueChange={toggleTextRemindersSwitch}
                            value={textReminders}
                            disabled={!editMode}
                        />
                    } />
                </Section>
            </ScrollView>
            <AlertModal
                modalVisible={alertModalVisibile}
                setModalVisible={setAlertModalVisible}
                alertTitleText={alertInfo.alertTitleText}
                alertSubtitleText={alertInfo.alertSubtitleText}
                alertPrimaryButtonText={alertInfo.alertPrimaryButtonText}
                alertPrimaryAction={alertInfo.alertPrimaryAction}
                alertSecondaryButtonText={alertInfo.alertSecondaryButtonText}
                alertSecondaryAction={alertInfo.alertSecondaryAction}
            />
        </View>
    );
}