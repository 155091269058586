import React, { useEffect, useState, useRef, useLayoutEffect } from 'react';
import {
    View,
    SafeAreaView,
    ScrollView,
    Dimensions,
    ActivityIndicator,
} from 'react-native';
import styles from './styles';
import KeyboardSpacer from '../KeyboardSpacer';
import { Cell, Section } from 'react-native-tableview-simple';
import firebase from "firebase/app"
import "firebase/auth"
import "firebase/firestore"
import "firebase/functions"
import { BACKGROUND_COLOR, CELL_BACKGROUND_COLOR, CELL_TITLE_TEXT_COLOR, CONNECTION_ERROR_BUTTON, CONNECTION_ERROR_HEADER, CONNECTION_ERROR_MESSAGE, HEADER_TEXT_COLOR } from '../../../constants';
import AlertModal from '../AlertModal';
import { HeaderBackButton } from '@react-navigation/stack';


export default function PushNotifications({ navigation }) {

    useLayoutEffect(() => {
        navigation.setOptions({
            headerRight: null,
            headerLeft: () => {
                return <HeaderBackButton
                    label="Back"
                    tintColor={HEADER_TEXT_COLOR}
                    disabled={pageLoading}
                    onPress={() => navigation.goBack()}
                />

            },
        })
    })

    const [accessories, setAccessories] = useState({ "1": "Checkmark", "2": "none", "3": "none", "4": "none" })
    const [pageLoading, setPageLoading] = useState(true)
    const [alertModalVisibile, setAlertModalVisible] = useState(false);
    const [alertInfo, setAlertInfo] = useState({})
    const closeAlertModal = () => {
        setAlertModalVisible(false)
    }
    const openAlertModal = (title, subtitle, primaryButtonText, primaryButtonAction, secondaryButtonText = null, secondaryButtonAction = null) => {
        setAlertInfo({
            alertTitleText: title,
            alertSubtitleText: subtitle,
            alertPrimaryButtonText: primaryButtonText,
            alertPrimaryAction: () => primaryButtonAction(),
            alertSecondaryButtonText: secondaryButtonText,
            alertSecondaryAction: () => secondaryButtonAction()
        })
        setAlertModalVisible(true)
    }


    const getUserInfo = async () => {
        setPageLoading(true)
        const user = firebase.auth().currentUser;
        // console.log(user);
        const userInfo = (await firebase.firestore().collection("userProfiles").doc(user.uid).get()).data();
        if (userInfo) {
            let pushNotifications = userInfo.pushNotifications
            if (pushNotifications == "All - scheduling, service, and special offers") {
                selectCell("1", false)
            }
            else if (pushNotifications == "Scheduling and service only") {
                selectCell("2", false)
            }
            else if (pushNotifications == "Service-related only") {
                selectCell("3", false)
            }
            else if (pushNotifications == "None") {
                selectCell("4", false)
            }
        }
        setPageLoading(false);
    }

    useEffect(() => {
        getUserInfo();
    }, []);

    const selectCell = async (selectedCell, changeDatabase) => {
        if (changeDatabase) {
            setPageLoading(true)
            const updateDatabaseDict = {}
            if (selectedCell == "1") {
                updateDatabaseDict.pushNotifications = "All - scheduling, service, and special offers"
            }
            else if (selectedCell == "2") {
                updateDatabaseDict.pushNotifications = "Scheduling and service only"
            }
            else if (selectedCell == "3") {
                updateDatabaseDict.pushNotifications = "Service-related only"
            }
            else if (selectedCell == "4") {
                updateDatabaseDict.pushNotifications = "None"
            }
            try {
                const updateUserPushNotifications = firebase.functions().httpsCallable('updateUserPushNotifications');
                const response = await updateUserPushNotifications({
                    updateDict: updateDatabaseDict,
                    updateContext: 'account_page'
                })
                if (response.data) {
                    if (response.data.success) {
                        await getUserInfo()
                        setPageLoading(false)
                        return
                    }
                    else {
                        console.log('error updating address:', response.data.message)
                        openAlertModal('Error', 'Error updating your account info.  Please try again.  If this error persists, please contact us.', 'Ok', closeAlertModal)
                        setPageLoading(false)
                        return
                    }
                }
                else {
                    openAlertModal('Error', 'Error updating your account info.  Please try again.  If this error persists, please contact us.', 'Ok', closeAlertModal)
                    setPageLoading(false)
                    return
                }
            }
            catch (err) {
                console.log('error:', err)
                setPageLoading(false)
                openAlertModal(CONNECTION_ERROR_HEADER, CONNECTION_ERROR_MESSAGE, CONNECTION_ERROR_BUTTON, closeAlertModal)
                return
            }
        }
        let updateDict = {}
        updateDict["1"] = selectedCell == "1" ? "Checkmark" : "none"
        updateDict["2"] = selectedCell == "2" ? "Checkmark" : "none"
        updateDict["3"] = selectedCell == "3" ? "Checkmark" : "none"
        updateDict["4"] = selectedCell == "4" ? "Checkmark" : "none"
        setAccessories(updateDict)
    }

    if (pageLoading) {
        return (
            <View style={styles.parent}>
                <ActivityIndicator />
            </View>
        )
    }

    return (
        <View style={styles.parent}>
            <ScrollView>
                <Section header="" footer="">
                    <Cell backgroundColor={CELL_BACKGROUND_COLOR} cellStyle="Basic" titleTextColor={CELL_TITLE_TEXT_COLOR} title="All - scheduling, service, and special offers" accessory={accessories["1"]} onPress={() => selectCell("1", true)} />
                    <Cell backgroundColor={CELL_BACKGROUND_COLOR} cellStyle="Basic" titleTextColor={CELL_TITLE_TEXT_COLOR} title="Scheduling and service only" accessory={accessories["2"]} onPress={() => selectCell("2", true)} />
                    <Cell backgroundColor={CELL_BACKGROUND_COLOR} cellStyle="Basic" titleTextColor={CELL_TITLE_TEXT_COLOR} title="Service-related only" accessory={accessories["3"]} onPress={() => selectCell("3", true)} />
                    <Cell backgroundColor={CELL_BACKGROUND_COLOR} cellStyle="Basic" titleTextColor={CELL_TITLE_TEXT_COLOR} title="None" accessory={accessories["4"]} onPress={() => selectCell("4", true)} />
                </Section>
            </ScrollView>
            <AlertModal
                modalVisible={alertModalVisibile}
                setModalVisible={setAlertModalVisible}
                alertTitleText={alertInfo.alertTitleText}
                alertSubtitleText={alertInfo.alertSubtitleText}
                alertPrimaryButtonText={alertInfo.alertPrimaryButtonText}
                alertPrimaryAction={alertInfo.alertPrimaryAction}
                alertSecondaryButtonText={alertInfo.alertSecondaryButtonText}
                alertSecondaryAction={alertInfo.alertSecondaryAction}
            />
        </View>
    );
}